import * as React from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

interface FormProps<TFieldValues extends FieldValues, TContext>
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  context?: UseFormReturn<TFieldValues, TContext>;
}

export const Form = <F extends FieldValues, C>({
  children,
  context,
  ...rest
}: FormProps<F, C>): JSX.Element => (
  <form {...rest}>
    {context != null ? (
      <FormProvider {...context}>{children}</FormProvider>
    ) : (
      { children }
    )}
  </form>
);
