import * as React from "react";

import { Grid } from "@samacare/design/core";

import { getInsuranceType } from "../../../util/getInsuranceType";
import { useBenefitsVerificationContext } from "../BeneftsVerificationProvider";
import { FormSection } from "./FormSection";
import { ReadonlyField } from "./ReadonlyField";

export const EntryDetails: React.FC = () => {
  const { benefitsVerification } = useBenefitsVerificationContext();
  const requestData = benefitsVerification?.request;
  if (requestData == null) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormSection title="Drug Details">
          <ReadonlyField
            label="Drug Name"
            value={requestData.drugDetails.drugName}
          />
          <ReadonlyField
            label="Drug Code"
            value={requestData.drugDetails.drugCode}
          />
          <ReadonlyField
            label="HCPCS Codes"
            value={requestData.drugDetails.hcpcsCodes?.join(" ")}
          />
          <ReadonlyField
            label="ICD Codes"
            value={requestData.drugDetails.icds?.join(" ")}
          />
        </FormSection>
        <FormSection title="Prescriber Details">
          <ReadonlyField
            label="First Name"
            value={requestData.prescriberDetails?.firstName}
          />
          <ReadonlyField
            label="Last Name"
            value={requestData.prescriberDetails?.lastName}
          />
          <ReadonlyField
            label="Specialty"
            value={requestData.prescriberDetails?.specialtyDescription}
          />
          <ReadonlyField
            label="In Network"
            value={requestData.prescriberInNetwork ? "Yes" : "No"}
          />
          <ReadonlyField
            label="NPI #"
            value={requestData.prescriberDetails?.NPI}
          />
          <ReadonlyField
            label="TAX #"
            value={requestData.prescriberDetails?.TIN}
          />
          <ReadonlyField label="PTAN #" value={requestData.prescriberPTAN} />
        </FormSection>
      </Grid>
      <Grid item xs={6}>
        <FormSection title="Primary Insurance Details">
          <ReadonlyField
            label="Insurance Type"
            value={getInsuranceType(
              requestData.primaryInsuranceDetails?.planType
            )}
          />
          <ReadonlyField
            label="Group Number"
            value={requestData.primaryInsuranceDetails?.groupNumber}
          />
          <ReadonlyField
            label="Member ID"
            value={requestData.primaryInsuranceDetails?.memberId}
          />
          <ReadonlyField
            label="Insurance Issuing State"
            value={requestData.primaryInsuranceDetails?.insuranceState}
          />
          <ReadonlyField
            label="Insurance Organization"
            value={requestData.primaryInsuranceDetails?.insuranceCompany?.name}
          />
          <ReadonlyField
            label="Insurance Organization Phone"
            value={requestData.primaryInsuranceCompanyPhone}
          />
        </FormSection>
        {requestData.secondaryInsuranceDetails && (
          <FormSection title="Secondary Insurance Details">
            <ReadonlyField
              label="Insurance Type"
              value={getInsuranceType(
                requestData.secondaryInsuranceDetails?.planType
              )}
            />
            <ReadonlyField
              label="Group Number"
              value={requestData.secondaryInsuranceDetails?.groupNumber}
            />
            <ReadonlyField
              label="Member ID"
              value={requestData.secondaryInsuranceDetails?.memberId}
            />
            <ReadonlyField
              label="Insurance Issuing State"
              value={requestData.secondaryInsuranceDetails?.insuranceState}
            />
            <ReadonlyField
              label="Insurance Organization"
              value={
                requestData.secondaryInsuranceDetails?.insuranceCompany?.name
              }
            />
            <ReadonlyField
              label="Insurance Organization Phone"
              value={requestData.primaryInsuranceCompanyPhone}
            />
          </FormSection>
        )}
        <FormSection title="Provider Location Details">
          <ReadonlyField
            label="Facility Name"
            value={requestData.locationDetails?.name}
          />
          <ReadonlyField
            label="Address"
            value={requestData.locationDetails?.address}
          />
          <ReadonlyField
            label="City"
            value={requestData.locationDetails?.city}
          />
          <ReadonlyField
            label="State"
            value={requestData.locationDetails?.state}
          />
          <ReadonlyField
            label="Zip Code"
            value={requestData.locationDetails?.zip}
          />
          <ReadonlyField
            label="Office NPI #"
            value={requestData.locationDetails?.NPI}
          />
          <ReadonlyField
            label="Office Tax #"
            value={requestData.locationDetails?.taxId}
          />
          <ReadonlyField
            label="Location PTAN #"
            value={requestData.locationDetails?.PTAN}
          />
        </FormSection>
      </Grid>
    </Grid>
  );
};
