import { detailedFormsQuery } from "../../../../graphql/AuthorizationForm";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { formNumbersFindAllQuery } from "../../../../graphql/FormNumber";

export const fetchForms = async (
  client: ApolloClient<NormalizedCacheObject>,
  selectedPayerId: string | null,
  formInputValue: string | null,
  ids: string[] | null
): Promise<any[]> => {
  if (!selectedPayerId && !formInputValue && !ids) {
    return Promise.resolve([]);
  }

  const filters: {
    InsuranceCompanyId?: string;
    title?: { contains: string };
    id?: { in: string[] };
  } = {};
  if (selectedPayerId) {
    filters.InsuranceCompanyId = selectedPayerId;
  }
  if (formInputValue) {
    filters.title = { contains: formInputValue };
  }
  if (ids) {
    filters.id = { in: ids };
  }

  const result = await client.query({
    query: detailedFormsQuery,
    variables: { filters },
    fetchPolicy: "network-only",
  });

  return result.data.authorizationForms.edges.map((edge: any) => edge.node);
};

export const fetchFormNumbers = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchNumber: string,
  searchDescription: string
): Promise<any[]> => {
  if (!searchDescription && !searchNumber) {
    return Promise.resolve([]);
  }

  const result = await client.query({
    query: formNumbersFindAllQuery,
    variables: {
      description: searchDescription,
      number: searchNumber,
    },
    fetchPolicy: "network-only",
  });

  return result.data.formNumbersFindAll;
};
