import useInsuranceCompanyOptions, {
  type InsuranceCompanyOption,
} from "../hooks/useInsuranceCompanyOptions";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

import AutocompleteField, {
  type AutocompleteFieldProps,
} from "../AutocompleteField";

export type InsuranceCompanyFieldProps = Omit<
  AutocompleteFieldProps<InsuranceCompanyOption, false, true, false>,
  "options"
>;

export const InsuranceCompanyField: React.FC<InsuranceCompanyFieldProps> = (
  props
) => {
  const { watch, register, setValue } = useFormContext();
  const insuranceCompaniesId: string | undefined = watch(props.name);
  const { insuranceCompanies, loading } = useInsuranceCompanyOptions();

  const defaultValue = insuranceCompanies?.find(
    (ins) => ins.id === insuranceCompaniesId
  );

  useEffect(() => {
    setValue("insuranceCompanyName", defaultValue?.label);
  }, [defaultValue]);

  return (
    <>
      <AutocompleteField<InsuranceCompanyOption, false, true, false>
        disableClearable
        loading={loading}
        options={insuranceCompanies ?? []}
        filterSelectedOptions
        value={defaultValue ?? ""}
        setValueAs={(v: InsuranceCompanyOption | string) => {
          if (typeof v === "string") {
            return v;
          }
          return v?.id.toString();
        }}
        onChange={(e, v) => {
          setValue("insuranceCompanyName", v?.label);
        }}
        {...props}
      />
      <input
        type="hidden"
        value={defaultValue?.label ?? ""}
        {...register("insuranceCompanyName")}
      />
    </>
  );
};
