import { Institution } from "@samacare/graphql";

export const getAllPortalsForInstitution = (
  institution?: Institution | null
) => {
  const config = window.CONFIG;
  const allPortals = config.CONSTANTS.PORTALS_FOR_ALL_INSTITUTIONS;

  const experimentPortalsEnabled = config.CONSTANTS.EXPERIMENTAL_PORTALS.filter(
    (portal) => (institution?.extraPortalKeys ?? []).includes(portal.key)
  );

  return allPortals.concat(experimentPortalsEnabled);
};
