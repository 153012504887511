import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@samacare/design";
import { useTheme } from "@samacare/design/core/styles";
import { Maybe } from "@samacare/graphql";
import { LeftRightSpreadCenterV } from "../../../components/LeftRightV2";
import ContentCopy from "@samacare/design/core/icons/ContentCopy";
import { useEffect, useState } from "react";
import CheckCircle from "@samacare/design/core/icons/CheckCircle";

interface LabelValueRowProps {
  label: string;
  value: string | undefined | Maybe<string>;
}
const LabelValueRow: React.VFC<LabelValueRowProps> = (props) => {
  const theme = useTheme();

  const [justCopied, setJustCopied] = useState(false);
  useEffect(() => {
    if (justCopied) {
      const timeout = setTimeout(() => {
        setJustCopied(false);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [justCopied]);

  const copyIconSize = "16px";
  const iconSx = { width: copyIconSize, height: copyIconSize };

  return (
    <TableRow sx={{ border: "none" }}>
      <TableCell sx={{ border: "none", padding: 0.5, textAlign: "right" }}>
        <Typography variant="subtitle2" color={theme.palette.text.primary}>
          {`${props.label}:`}
        </Typography>
      </TableCell>
      <TableCell sx={{ border: "none", padding: 0.5 }}>
        <LeftRightSpreadCenterV>
          <Typography variant="body2" color={theme.palette.text.primary}>
            {props.value ? props.value : "-"}
          </Typography>
          {props.value && (
            <IconButton
              size="small"
              sx={{ ml: 2 }}
              onClick={async () => {
                await navigator.clipboard.writeText(props.value ?? "");
                setJustCopied(true);
              }}
            >
              {justCopied ? (
                <CheckCircle sx={iconSx} />
              ) : (
                <ContentCopy sx={iconSx} />
              )}
            </IconButton>
          )}
        </LeftRightSpreadCenterV>
      </TableCell>
    </TableRow>
  );
};

interface LabelValueTableProps {
  labelToValueMap: Record<string, string | undefined | Maybe<string>>;
}
export const LabelValueTable: React.FC<LabelValueTableProps> = (props) => {
  return (
    <Box sx={{ alignSelf: "flex-start", width: "auto", pl: 2 }}>
      <Table>
        <TableBody>
          {Object.entries(props.labelToValueMap).map(([label, value]) => (
            <LabelValueRow key={label} label={label} value={value} />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
