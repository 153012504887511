import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Button, GridFilterListIcon, Stack } from "@samacare/design/core";
import ModeEditIcon from "@samacare/design/core/icons/ModeEdit";
import { PermalinkButton } from "@samacare/form/PermalinkButton/PermalinkButton";
import {
  ModelType,
  TemplateType,
  UnifiedBenefitsCheckType,
} from "@samacare/graphql";

import { DownloadAsPdf } from "../../../components/AuthorizationSharedComponents/DownloadAsPdf";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { BenefitsVerificationAssigneeSelect } from "./AssigneeSelect";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";

type ActionBarProps = {
  isEditMode: boolean;
  onEditClick?: () => void;
};

export const ActionBar: React.FC<ActionBarProps> = (props) => {
  const { isEditMode, onEditClick } = props;

  const history = useHistory();
  const { unifiedBenefitsCheck, currentAccount } =
    useUnifiedBenefitsCheckContext();

  if (unifiedBenefitsCheck == null || currentAccount == null) {
    return <Box />;
  }

  const isFullBv = unifiedBenefitsCheck.type === UnifiedBenefitsCheckType.Full;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        {currentAccount?.isSamaUser && onEditClick != null && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={isEditMode}
            startIcon={<ModeEditIcon />}
            onClick={() => onEditClick()}
          >
            Edit
          </Button>
        )}
        <Button
          color="primary"
          variant="outlined"
          startIcon={<GridFilterListIcon />}
          onClick={() => {
            history.push(
              `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}?filters=patientId%20equals%20${unifiedBenefitsCheck.patient.id}`
            );
          }}
        >
          {isFullBv ? "See All BV" : "See all Verifications"}
        </Button>
        <PermalinkButton
          url={`#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${unifiedBenefitsCheck.id}`}
          variant="outlined"
          text="Copy BV Link"
        />
        {isFullBv && (
          <DownloadAsPdf
            template={TemplateType.BenefitsVerification}
            modelType={ModelType.BenefitsVerification}
            modelId={unifiedBenefitsCheck.id}
            variant="outlined"
          />
        )}
        {isFullBv && (
          <BenefitsVerificationAssigneeSelect
            benefitsVerificationId={unifiedBenefitsCheck.id}
            requestedById={unifiedBenefitsCheck.fullData?.requestedById}
          />
        )}
      </Stack>
    </Stack>
  );
};
