import gql from "graphql-tag";

export default gql`
  fragment prescriberInfo on Prescriber {
    id
    firstName
    lastName
    label
    specialtyDescription
    NPI
    TIN
    DEA
    licenseNumber
    specialtyCode
  }
`;
