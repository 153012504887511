import { PureComponent } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import _, { memoize } from "lodash";
import {
  withForms,
  withReportMissingInformation,
} from "../../../graphql/AuthorizationForm";
import { withSetFavorite, withDeleteFavorite } from "../../../graphql/Favorite";
import MissingInformationModalContent from "../MissingInformationModalContent";
import FormContent from "./FormContent";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { ModalBodyXL } from "../../../components/ModalStyledComponents";
import { sortForms } from "../../../util/authUtils";

const TitleContainer = styled.div`
  font-weight: 700;
  padding: 10px 0 5px 0;
  margin: 0px 5px 2px 5px;
  font-size: 16px;
  border-bottom: 2px solid ${(props) => props.theme.darkGray};
`;

const ScrollContainer = styled.div`
  max-height: 100%;
  overflow: scroll;
  padding-bottom: 8px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const EmptyState = styled.div`
  font-size: 16px;
  margin: 20px;
  text-align: center;
`;
const StyledModalBody = styled(ModalBodyXL)`
  padding-bottom: 8px;
  max-width: 30%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;

  ${LoadingSpinner} {
    border-color: ${(props) => props.theme.purple};
    border-top-color: white;
    height: 50px;
    width: 50px;
  }
`;

export const VIEWS = {
  NO_FORMS: "NO_FORMS",
  DEFAULT_FORMS: "DEFAULT_FORMS",
  REFERRAL_FORMS: "REFERRAL_FORMS",
  REQUEST_NEW_FORMS: "REQUEST_NEW_FORMS",
  LOADING: "LOADING",
};

export class FormList extends PureComponent {
  getFormView = () => {
    const { forms, referralForms, requestNewForm } = this.props;

    if (requestNewForm) {
      return VIEWS.REQUEST_NEW_FORMS;
    }

    if (!_.isEmpty(referralForms)) {
      return VIEWS.REFERRAL_FORMS;
    }

    if (_.isUndefined(forms)) {
      return VIEWS.LOADING;
    }

    if (_.get(forms, "hasForms")) {
      const { stateForms, payerForms, suggestedForms, favoriteForms } = forms;
      if (
        !_.isEmpty([
          ...stateForms,
          ...suggestedForms,
          ...payerForms,
          ...favoriteForms,
        ])
      ) {
        return VIEWS.DEFAULT_FORMS;
      }
    }

    return VIEWS.NO_FORMS;
  };

  memoizeFavoriteFormIds = memoize((favoriteForms) => {
    return new Set(favoriteForms.map((f) => f.id));
  });
  memoizedSortForms = memoize(sortForms);

  render() {
    const {
      onSelect,
      selectedFormId,
      forms,
      authorizationId,
      state,
      insuranceCompanyId,
      referralForms,
      setFavorite,
      deleteFavorite,
      back,
      isMultiPartyAuth,
      setRequestNewForm,
    } = this.props;

    const view = this.getFormView();

    let favoriteFormIds = new Set();
    if (forms?.favoriteForms) {
      favoriteFormIds = this.memoizeFavoriteFormIds(forms.favoriteForms);
    }

    if (view === VIEWS.DEFAULT_FORMS) {
      const sortedForms = this.memoizedSortForms(forms);

      return (
        <StyledModalBody isSelected={selectedFormId !== null}>
          <ScrollContainer>
            {_.map(sortedForms, (form, index) => {
              if (selectedFormId === null && index === 0) onSelect(form.id);
              return (
                <FormContent
                  index={index}
                  form={form}
                  onSelect={onSelect}
                  setFavorite={async (args) => {
                    await setFavorite(args);
                  }}
                  deleteFavorite={deleteFavorite}
                  isFavorite={favoriteFormIds.has(form.id)}
                  isSelected={selectedFormId === form.id}
                />
              );
            })}
          </ScrollContainer>
        </StyledModalBody>
      );
    }
    if (view === VIEWS.REFERRAL_FORMS) {
      return (
        <ModalBodyXL>
          <TitleContainer>
            Referral Forms
            {` (${referralForms.length} Forms)`}
          </TitleContainer>
          <ScrollContainer>
            {_.map(referralForms, (form, index) => (
              <FormContent index={index} form={form} onSelect={onSelect} />
            ))}
          </ScrollContainer>
        </ModalBodyXL>
      );
    }
    if (view === VIEWS.REQUEST_NEW_FORMS) {
      return (
        <MissingInformationModalContent
          authType={
            isMultiPartyAuth
              ? CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key
              : null
          }
          authorizationId={authorizationId}
          onCancel={() => setRequestNewForm()}
          insuranceCompanyId={insuranceCompanyId}
          state={state}
          onCloseFormListModal={back}
        />
      );
    }
    if (view === VIEWS.LOADING) {
      return (
        <LoadingContainer>
          <LoadingSpinner data-cy="componentLoadingSpinner" />
        </LoadingContainer>
      );
    }

    return (
      <EmptyState>
        No forms were found matching the specified insurance and plan
        information. Please report the missing form using the button below.
      </EmptyState>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withForms,
  withReportMissingInformation,
  withSetFavorite,
  withDeleteFavorite
)(FormList);
