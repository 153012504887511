import _ from "lodash";
import React from "react";

import {
  Box,
  Grid,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Typography,
} from "@samacare/design/core";

import { getInsuranceType } from "../../../util/getInsuranceType";
import { useBenefitsVerificationContext } from "../BeneftsVerificationProvider";
import { humanizeMaybeBoolean } from "../humanizers";
import { BenefitsVerificationCoverageStatus } from "./BenefitsVerificationCoverageStatus";
import { UnifiedBenefitsCheckStatus } from "./UnifiedBenefitsCheckStatus";

export const BenefitsVerificationSummary: React.FC = () => {
  const { benefitsVerification } = useBenefitsVerificationContext();

  if (!benefitsVerification) {
    return <Box />;
  }

  const { request, outcome } = benefitsVerification;

  const drugLabel = _.compact([
    request.drugDetails.drugName,
    request.drugDetails.drugCode,
  ]).join(" - ");
  return (
    <TabContext value="summary">
      <TabList>
        <Tab label="Summary" value="summary" />
      </TabList>
      <TabPanel value="summary">
        <Grid container>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">BV Status</Typography>
              <UnifiedBenefitsCheckStatus />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle2">Auth Required</Typography>
              <Typography variant="body1" color="primary">
                {humanizeMaybeBoolean(outcome?.data?.isAuthRequired)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Drug Name/Code</Typography>
              <Typography variant="body1">{drugLabel}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Coverage Status</Typography>
              <BenefitsVerificationCoverageStatus
                benefitsVerification={benefitsVerification}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Payer</Typography>
              <Typography variant="body1">
                {benefitsVerification.request.primaryInsuranceDetails
                  ?.insuranceCompany?.name || "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Plan Type</Typography>
              <Typography variant="body1">
                {getInsuranceType(request.primaryInsuranceDetails?.planType)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={2}>
              <Typography variant="subtitle2">Member ID</Typography>
              <Typography variant="body1">
                {benefitsVerification.request.primaryInsuranceDetails
                  ?.memberId || "-"}
              </Typography>
            </Box>
          </Grid>
          {benefitsVerification.request.secondaryInsuranceDetails && (
            <>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Box marginTop={2}>
                  <Typography variant="subtitle2">
                    Payer (Secondary Insurance)
                  </Typography>
                  <Typography variant="body1">
                    {benefitsVerification.request.secondaryInsuranceDetails
                      ?.insuranceCompany?.name || "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box marginTop={2}>
                  <Typography variant="subtitle2">
                    Plan Type (Secondary Insurance)
                  </Typography>
                  <Typography variant="body1">
                    {getInsuranceType(
                      request.secondaryInsuranceDetails?.planType
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box marginTop={2}>
                  <Typography variant="subtitle2">
                    Member ID (Secondary Insurance)
                  </Typography>
                  <Typography variant="body1">
                    {benefitsVerification.request.secondaryInsuranceDetails
                      ?.memberId || "-"}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </TabPanel>
    </TabContext>
  );
};
