import { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import { Flex } from "@@ui-kit";
import RequiredFieldModal from "AuthorizationSharedComponents/RequiredFieldModal";
import ContextPane from "AuthorizationSharedComponents/ContextPane";
import { ContextHeader } from "AuthorizationSharedComponents/ContextHeader";
import Prescriber from "AuthorizationSharedSteps/PrescriberSection";
import ResponseSection from "AuthorizationSharedSteps/ResponseSection";
import RequestDetails from "AuthorizationSharedSteps/RequestDetails";
import Signature from "AuthorizationSharedSteps/SignatureSection";
import LomnEditStep from "AuthorizationSharedSteps/LomnSection/LomnEditStep";
import LomnFillStep from "AuthorizationSharedSteps/LomnSection/LomnFillStep";
import { ReviewAndSubmitNote } from "AuthorizationSharedSteps/LomnSection/LomnShared";
import { EnrollmentSection } from "AuthorizationSharedSteps/EnrollmentSection/EnrollmentSection";
import EnrollmentSubmit from "AuthorizationSharedSteps/EnrollmentSubmit";
import strings from "Resources/strings";
import ChangeFormModal from "./ChangeFormModal";
import SelectFormModal from "./SelectFormModal";
import SelectPhoneList from "./SelectFormModal/SelectPhoneList";
import Modal from "../../components/Modal";
import AlertModal from "../../components/AlertModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import * as stepUtils from "../../util/stepUtils";
import {
  resetForm,
  setCurrentStep,
  setFormFields,
  syncAuthorizationDetails,
  toggleHighlightRequiredFields,
  setShouldIncludeCoverLetter,
} from "../../reducers/formReducer";
import InitializeSection from "./InitializeSection";
import PDFEditor from "../../components/PDFEditor";
import ROUTE_PATHS from "../ROUTE_PATHS";
import { withFormById } from "../../graphql/AuthorizationForm";
import {
  withUpdateAuthorizationStatus,
  withUpdateAuthorizationProgress,
  withUpdateAuthorizationProgressWithoutPatient,
  withSubmitAuthorization,
  withUpdateFormDetails,
  withAuthorizationQuery,
  withUpdateAuthorization,
} from "../../graphql/Authorization";
import { withCurrentAccount } from "../../graphql/Account";
import NewAuthorizationHoc from "../../components/NewAuthorizationHoc";
import BaseButton from "../../components/BaseButton";
import { LocateNumberTipModal } from "./LocateNumberTipModal";
import { isDisplayAuthEnrollment } from "../../util/enrollUtils";
import {
  AuthorizationStepKey,
  TerminalStatusUpdateType,
} from "@samacare/graphql";
import { CoverLetterModal } from "../../components/PDFEditor/CoverLetterModal";
import { FormConfigContext } from "../../components/FormConfigContext/FormConfigContext";
import { segmentTypewriter } from "../../../analytics/analytics";

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: row;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  margin: 16px 16px 16px 0px;
  flex-direction: column;
  width: 100%;
  min-width:${({ stepKey }) =>
    stepKey === AuthorizationStepKey.FormDetails ? "" : "800px"};

  @media (min-width: ${({ stepKey }) =>
    stepKey === AuthorizationStepKey.FormDetails ? "1100px" : ""};
}) {
    width: ${({ stepKey }) =>
      stepKey === AuthorizationStepKey.FormDetails ? "900px" : ""};
  }
`;

export const FormTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
`;

export const FormSubTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.darkGray};
  margin-bottom: 10px;
`;

const { ERROR_TYPES, INSURANCE_TYPES, AUTHORIZATION_STATUSES } =
  CONFIG.CONSTANTS;
const fields = CONFIG.DEFAULT_FIELDS;

export const preventFaxNumberUpdate = (authorization) =>
  authorization.submittedAt &&
  authorization.status !== AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT;
export const getInsuranceType = (results) => {
  if (results[CONFIG.DEFAULT_FIELDS.IS_MEDICAID.key]) {
    return INSURANCE_TYPES.IS_MEDICAID;
  }
  if (results[CONFIG.DEFAULT_FIELDS.IS_MEDICARE_ADVANTAGE.key]) {
    return INSURANCE_TYPES.IS_MEDICARE_ADVANTAGE;
  }
  if (results[CONFIG.DEFAULT_FIELDS.IS_COMMERCIAL.key]) {
    return INSURANCE_TYPES.IS_COMMERCIAL;
  }
  return null;
};

const steps = {
  PATIENT_AND_INSURANCE: 1,
  REQUEST_DETAILS: 2,
  PRESCRIBER_AND_LOCATION: 3,
  FORM_DETAILS: 4,
  LOMN_FILL: 4.1,
  LOMN_EDIT: 4.2,
  REVIEW_SIGN_SUBMIT: 5,
  CAREMETX_ENROLLMENT_AND_SUBMIT: 5.5,
  ENROLLMENT_AND_SUBMIT: 5.6,
  SUMMARY: 6,
};

const getStepName = (step) => {
  const stepName = _.findKey(steps, (value) => value === step);
  return stepName ? stepName.toLowerCase() : "unknown";
};

export class Form extends PureComponent {
  state = {
    loading: false,
    requiredFieldModalOpen: false,
    alertModalOpen: false,
    showExternalAuthStatusModal: false,
    showSelectPhoneModal: false,
    showSelectFormModal: false,
    showChangeFormModal: false,
    showLocateNumberTipModal: false,
    isEnrollmentModuleShown: false,
    shouldDisplayCoverSheetModal: false,
    coverletterCallback: null,
  };

  getSignatureId = null;

  componentDidMount() {
    const { syncStepToUrl } = this.props;
    syncStepToUrl();
    this.syncLocalState();
  }

  componentDidUpdate(prevProps) {
    const { onComponentUpdate, authorization, results } = this.props;

    onComponentUpdate(
      prevProps,
      authorization,
      results,
      this.syncLocalState.bind(this)
    );
  }

  syncLocalState() {
    const { authorization, syncLocalState } = this.props;
    if (authorization) {
      syncLocalState(authorization);
    }
  }

  saveChanges = async (patientId = null) => {
    const { saveAuthorizationChanges, shouldIncludeCoverLetter } = this.props;
    const details = { shouldIncludeCoverLetter };

    if (this.getSignatureId) {
      const signatureId = await this.getSignatureId();
      details.signatureId = signatureId;
    }

    await saveAuthorizationChanges({ details, patientId });
  };

  useNewWorkflow = async () => {
    const { setToForm } = this.props;

    await setToForm({
      [fields.DRUG_CODE_TYPE.key]: null,
      [fields.BENEFIT_TYPES.key]: null,
      [fields.REQUEST_TYPE.key]: null,
      [fields.HCPCS_0.key]: null,
      [fields.ALL_HCPCS.key]: null,
      [fields.PRIMARY_NDC.key]: null,
      [fields.PRIMARY_DRUG_NAME.key]: null,
      [fields.PATIENT_MEMBER_ID.key]: null,
      [fields.INSURANCE_COMPANY.key]: null,
      [fields.INSURANCE_PLAN.key]: null,
      [fields.INSURANCE_STATE.key]: null,
    });
    await this.saveChanges();
  };

  sendSegmentStepEvent = () => {
    const { step, authorization, account } = this.props;
    const paStep = getStepName(step);
    segmentTypewriter.paStepCompleted({
      accountId: +account.id,
      email: account.email,
      isAdmin: account.isAdmin,
      institutionIsTop: account.institution.isTop,
      institutionName: account.institution.name,
      institutionSpecialty: account.institution.practicingSpecialty,
      internalAuthId: +authorization.id,
      paType: authorization.type,
      paStep,
    });
  };

  updateAndStep = async (isForward) => {
    const { disabled, hasInvalidResults, takeStep } = this.props;

    if (disabled || !isForward) {
      this.sendSegmentStepEvent();
      await takeStep(isForward);
    } else if (!hasInvalidResults()) {
      await this.saveChanges();
      this.sendSegmentStepEvent();
      await takeStep(isForward);
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  saveAndSubmitAuthorization = () => {
    const { submitAuthorization, authorization, alert } = this.props;
    return this.saveChanges()
      .then(() =>
        submitAuthorization({
          variables: { id: authorization.id, send: false },
        })
      )
      .catch((error) => {
        if (_.includes(error.message, ERROR_TYPES.PAGE_LIMIT_EXCEEDED)) {
          this.setState({ alertModalOpen: true });
          throw new Error(ERROR_TYPES.PAGE_LIMIT_EXCEEDED);
        } else {
          alert.error("There was an error submitting your authorization");
        }
      });
  };

  submitAndStep = (onStart, onEnd, shouldStart = () => true) => {
    const { disabled, hasInvalidResults, takeStep } = this.props;
    if (disabled) {
      this.sendSegmentStepEvent();
      takeStep(true);
    } else if (!hasInvalidResults()) {
      if (!shouldStart()) {
        return;
      }

      if (onStart) {
        onStart();
      }

      this.saveAndSubmitAuthorization()
        .then(() => {
          if (onEnd) {
            onEnd();
          }
          this.sendSegmentStepEvent();
          takeStep(true);
        })
        .catch(() => {
          if (onEnd) {
            onEnd();
          }
        });
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  submitAndSend = async (signatureId, isSkipKickToAuthList = () => false) => {
    const {
      submitAuthorization,
      authorization,
      goToLocation,
      alert,
      disabled,
      hasInvalidResults,
    } = this.props;
    if (disabled) {
      alert.error("Authorization already submitted");
    } else if (!hasInvalidResults()) {
      try {
        await submitAuthorization({
          variables: { id: authorization.id, send: true, signatureId },
        });
        if (isSkipKickToAuthList()) {
          // if the enrollment module is still showing, prevent kicking the
          // user to auth list, it will be done on closing the module dialog
          this.setState({ loading: false });
        } else {
          goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
        }
        // return `true` to indicate that the auth submission was successful
        // used by `<EnrollmentSubmit/>` component to decide whether to redirect the user
        return true;
      } catch (e) {
        this.setState({ loading: false });
        alert.error(`There was an error submitting your authorization, ${e}`);
      }
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
    return false;
  };

  triggerRequiredModalOnInvalid = (manuallyTrigger) => {
    const { hasInvalidResults } = this.props;

    if (hasInvalidResults(manuallyTrigger)) {
      this.setState({ requiredFieldModalOpen: true });
      return true;
    }

    return false;
  };

  toggleExternalAuthStatusModal = () => {
    const { showExternalAuthStatusModal } = this.state;
    this.setState({
      showExternalAuthStatusModal: !showExternalAuthStatusModal,
    });
  };

  openExternalAuthStatusModal = () => {
    this.setState({ showExternalAuthStatusModal: true });
  };

  toggleFormModal = () => {
    const { showSelectFormModal } = this.state;
    this.setState({
      showSelectFormModal: !showSelectFormModal,
    });
  };

  toggleChangeFormModal = () => {
    const { showChangeFormModal } = this.state;
    this.setState({
      showChangeFormModal: !showChangeFormModal,
    });
  };

  setFormNumber = async (formNumberId) => {
    const { authorization, updateAuthorization, alert } = this.props;

    try {
      await updateAuthorization({
        variables: {
          id: authorization.id,
          patch: { FormNumberId: formNumberId },
        },
      });
      this.setState({ showSelectPhoneModal: false });
    } catch {
      alert.error(strings.NEW_AUTHORIZATIONS.ERROR_SETTING_NUMBER);
    }
  };

  procureReviewSignAndSubmitNextStep = () => {
    const { authorization } = this.props;
    if (!isDisplayAuthEnrollment(authorization)) {
      return (signatureId) => {
        if (!this.props.disabled) {
          this.setState({ loading: true });
        }
        this.submitAndSend(signatureId);
        this.sendSegmentStepEvent();
      };
    }
    return () => {
      this.updateAndStep(true);
    };
  };

  getContent = (step) => {
    const {
      authorization,
      results,
      setToForm,
      disabled,
      hasInvalidResults,
      goToLocation,
      account,
    } = this.props;

    const stepKey = stepUtils.getStepByNumber(authorization.steps, step).key;
    const stepMap = {
      [AuthorizationStepKey.Initiate]: (
        <InitializeSection
          saveChanges={this.saveChanges}
          authorization={authorization}
          isInvalid={this.triggerRequiredModalOnInvalid}
          toggleFormModal={this.toggleFormModal}
          nextStep={() => {
            this.updateAndStep(true);
          }}
        />
      ),
      [AuthorizationStepKey.RequestDetails]: (
        <RequestDetails
          authorization={authorization}
          triggerRequiredModalOnInvalid={() => {
            this.triggerRequiredModalOnInvalid(true);
          }}
          back={() => {
            this.updateAndStep(false);
          }}
          nextStep={() => {
            this.updateAndStep(true);
          }}
        />
      ),
      [AuthorizationStepKey.PrescriberAndLocationDetails]: (
        <Prescriber
          authorization={authorization}
          account={account}
          back={() => {
            this.updateAndStep(false);
          }}
          nextStep={() => {
            if (
              authorization.type ===
                CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key &&
              authorization.submissionPhoneNumber == null
            ) {
              this.openExternalAuthStatusModal();
              return;
            }

            this.updateAndStep(true);
          }}
          saveChanges={this.saveChanges}
        />
      ),
      [AuthorizationStepKey.FormDetails]: (
        <div style={{ marginTop: "16px" }}>
          <PDFEditor
            onBack={() => {
              this.updateAndStep(false);
            }}
            onSubmit={(onStart, onEnd, shouldStart, onInvalidFormState) => {
              if (hasInvalidResults()) {
                onInvalidFormState();
                this.setState({ requiredFieldModalOpen: true });
              } else if (
                !stepUtils.hasStep(
                  authorization.steps,
                  AuthorizationStepKey.LomnEdit
                )
              ) {
                this.setState({
                  shouldDisplayCoverSheetModal: true,
                  coverletterCallback: () => {
                    this.submitAndStep(onStart, onEnd, shouldStart);
                  },
                });
              } else {
                this.submitAndStep(onStart, onEnd, shouldStart);
              }
            }}
            authorization={authorization}
            results={results}
            account={account}
            /**
             * If an institution has EMR turned on and we are pulling documents from the EMR,
             * display the EMR document option:
             * - document download loading indicator
             * OR
             * - Attach From EMR button
             */
            showEMRDocumentOption={
              _.get(account, "institution.integrations", []).filter(
                (installation) =>
                  [
                    CONFIG.CONSTANTS.INTEGRATION_TITLE.oncoEMR,
                    CONFIG.CONSTANTS.INTEGRATION_TITLE.modernizingMedicine,
                    CONFIG.CONSTANTS.INTEGRATION_TITLE.nextGen,
                  ].includes(installation.integrationId) &&
                  installation.isEnabled
              ).length > 0
            }
            reportErrorUserDetails={`${_.get(account, "firstName")} ${_.get(
              account,
              "lastName"
            )} with email ${_.get(account, "email")}`}
          />
        </div>
      ),
      [AuthorizationStepKey.LomnFill]: (
        <div style={{ marginTop: "16px" }}>
          <LomnFillStep
            authorization={authorization}
            account={account}
            back={() => this.updateAndStep(false)}
            skip={async () => {
              this.setState({
                shouldDisplayCoverSheetModal: true,
                coverletterCallback: () => {
                  this.setState({ loading: true });
                  this.saveAndSubmitAuthorization().then(() => {
                    this.props.setStepAndUpdateURL(
                      stepUtils.stepNumberForKey(
                        authorization.steps,
                        AuthorizationStepKey.ReviewSignSubmit
                      )
                    );
                    this.setState({ loading: false });
                  });
                },
              });
            }}
            submit={() => this.updateAndStep(true)}
          />
        </div>
      ),
      [AuthorizationStepKey.LomnEdit]: (
        <div style={{ marginTop: "16px" }}>
          <LomnEditStep
            authorization={authorization}
            account={account}
            back={() => this.updateAndStep(false)}
            submit={() => {
              this.setState({
                shouldDisplayCoverSheetModal: true,
                coverletterCallback: () => {
                  this.setState({ loading: true });
                  this.saveAndSubmitAuthorization().then(() => {
                    this.setState({ loading: false });
                    this.props.takeStep(true);
                  });
                },
              });
            }}
          />
        </div>
      ),
      [AuthorizationStepKey.ReviewSignSubmit]: (
        <div style={{ marginTop: "16px" }}>
          <ReviewAndSubmitNote authorization={authorization} />
          <Signature
            back={() => {
              this.updateAndStep(false);
            }}
            disabled={disabled}
            nextStep={this.procureReviewSignAndSubmitNextStep()}
            pdfPath={authorization.submittedFormURL}
            set={setToForm}
            authorization={authorization}
            getSignatureId={(method) => {
              this.getSignatureId = method;
            }}
          />
        </div>
      ),
      [AuthorizationStepKey.CaremetxEnrollmentAndSubmit]: (
        <div style={{ marginTop: "16px" }}>
          <EnrollmentSection
            account={account}
            authorization={authorization}
            back={() => {
              this.updateAndStep(false);
            }}
            disabled={disabled}
            goToLocation={goToLocation}
            submitAuth={async (isSubmitAuthInBackground) => {
              if (!this.props.disabled && !isSubmitAuthInBackground) {
                this.setState({ loading: true });
              }
              await this.submitAndSend(
                authorization.formDetails.signatureId,
                () =>
                  this.state.isEnrollmentModuleShown || isSubmitAuthInBackground
              );
            }}
            onShowModule={() => {
              this.setState({ isEnrollmentModuleShown: true });
            }}
            onHideModule={() => {
              if (
                !this.state.loading &&
                authorization.status !== AUTHORIZATION_STATUSES.PRESUBMISSION
              ) {
                // the user just submitted an enrollment,
                // the auth submission has also finished since !loading
                // finish by kicking the user to the auth list
                goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
              } else {
                this.setState({ isEnrollmentModuleShown: false });
              }
            }}
          />
        </div>
      ),
      [AuthorizationStepKey.EnrollmentAndSubmit]: (
        <EnrollmentSubmit
          authorization={authorization}
          disabled={disabled}
          loading={this.state.loading}
          onBack={() => {
            this.updateAndStep(false);
          }}
          onSubmitAuth={async (skip = false) => {
            if (!this.props.disabled) {
              this.setState({ loading: true });
            }

            return await this.submitAndSend(
              authorization.formDetails.signatureId,
              () => skip
            );
          }}
        />
      ),
      [AuthorizationStepKey.Summary]: (
        <div style={{ marginTop: "16px" }}>
          <ResponseSection
            goToLocation={() => {
              this.props.goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
            }}
            back={() => {
              this.updateAndStep(false);
            }}
            authorization={authorization}
          />
        </div>
      ),
    };

    return (
      <FormContainer stepKey={stepKey}>
        <FormConfigContext.Provider value={authorization.drugConfiguration}>
          {stepMap[stepKey] ?? ""}
        </FormConfigContext.Provider>
      </FormContainer>
    );
  };

  render() {
    const {
      authorization,
      goToLocation,
      disabled,
      step,
      setStepAndUpdateURL,
      form,
      results,
      setToForm,
      account,
      alert,
      updateAuthorization,
      updateAuthorizationStatus,
      setCoverLetter,
    } = this.props;
    const {
      requiredFieldModalOpen,
      loading,
      isEnrollmentModuleShown,
      alertModalOpen,
      showSelectPhoneModal,
      showSelectFormModal,
      showChangeFormModal,
      showExternalAuthStatusModal,
      showLocateNumberTipModal,
      shouldDisplayCoverSheetModal,
      coverletterCallback,
    } = this.state;
    const insuranceCompanyId =
      _.get(authorization, "InsuranceCompanyId") ||
      _.get(authorization, "patient.InsuranceCompanyId");

    if (authorization && account) {
      const content = this.getContent(step);
      const stepKey = stepUtils.getStepByNumber(authorization.steps, step).key;

      return (
        <Flex flexDirection="column" padding="16px 16px 0 16px">
          <ContextHeader
            account={account}
            authorization={authorization}
            backToAuthorizations={() => {
              goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
            }}
            form={form}
            preventFaxNumberUpdate={preventFaxNumberUpdate(authorization)}
            handleFaxNumberUpdate={() =>
              this.setState({ showSelectPhoneModal: true })
            }
            saveChanges={this.saveChanges}
            toggleFormModal={this.toggleChangeFormModal}
          />
          <Container data-cy={_.camelCase(`component_step_${stepKey}`)}>
            {content}
            <ContextPane
              authorizationId={authorization.id}
              account={account}
              backToAuthorizations={() => {
                goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
              }}
              setStep={(stepTo) => {
                if (
                  stepUtils.isStep(
                    authorization.steps,
                    stepTo,
                    AuthorizationStepKey.Summary
                  ) ||
                  disabled
                ) {
                  setStepAndUpdateURL(stepTo);
                } else if (
                  stepUtils.isStep(
                    authorization.steps,
                    stepTo,
                    AuthorizationStepKey.ReviewSignSubmit
                  )
                ) {
                  this.setState({ loading: true });
                  this.saveAndSubmitAuthorization()
                    .then(() => {
                      this.setState({ loading: false });
                      setStepAndUpdateURL(stepTo);
                    })
                    .catch(() => {
                      this.setState({ loading: false });
                    });
                } else {
                  this.saveChanges().then(() => {
                    setStepAndUpdateURL(stepTo);
                  });
                }
              }}
            />
            {shouldDisplayCoverSheetModal && (
              <CoverLetterModal
                setFormFields={setToForm}
                close={() => {
                  this.setState({ shouldDisplayCoverSheetModal: false });
                }}
                submit={(shouldIncludeCoverLetter) => {
                  setCoverLetter(shouldIncludeCoverLetter);
                  const savedCallback = coverletterCallback;
                  this.setState(
                    {
                      shouldDisplayCoverSheetModal: false,
                      coverletterCallback: null,
                    },
                    savedCallback
                  );
                }}
                results={results}
              />
            )}
            <RequiredFieldModal
              closeModal={() => {
                this.setState({ requiredFieldModalOpen: false });
              }}
              open={requiredFieldModalOpen}
            />
            <AlertModal
              closeModal={() => {
                this.setState({ alertModalOpen: false });
              }}
              open={alertModalOpen}
              content={ERROR_TYPES.PAGE_LIMIT_EXCEEDED}
              header="Submission Error"
            />
            <LoadingSpinner open={loading && !isEnrollmentModuleShown} />
            <Modal
              header="Edit Fax Number"
              onClick={() => this.setState({ showSelectPhoneModal: false })}
              open={showSelectPhoneModal}
            >
              <div style={{ width: 600 }}>
                <SelectPhoneList
                  authorizationFormId={_.get(form, "id")}
                  authorizationId={authorization.id}
                  institutionId={authorization.InstitutionId}
                  insuranceCompanyId={insuranceCompanyId}
                  onSelect={this.setFormNumber}
                  showLocateNumberTip={() => {
                    this.setState({
                      showLocateNumberTipModal: true,
                      showSelectPhoneModal: false,
                    });
                  }}
                  missingAuthSelected={null}
                />
              </div>
            </Modal>
            {showChangeFormModal && (
              <ChangeFormModal
                open={showChangeFormModal}
                close={this.toggleChangeFormModal}
                useExistingWorkflow={() => {
                  this.toggleChangeFormModal();
                  this.toggleFormModal();
                }}
                selectNewWorkflow={() => {
                  this.toggleChangeFormModal();
                  setStepAndUpdateURL(1); // If user wants to use a new workflow, always go to step 1
                  this.useNewWorkflow();
                }}
              />
            )}
            {showSelectFormModal && (
              <SelectFormModal
                authorization={authorization}
                close={this.toggleFormModal}
                jCode={results[CONFIG.DEFAULT_FIELDS.HCPCS_0.key]}
                insuranceType={getInsuranceType(results)}
                insuranceCompanyId={insuranceCompanyId}
                insuranceName={
                  results[CONFIG.DEFAULT_FIELDS.INSURANCE_COMPANY.key]
                }
                onSubmit={async ({ formId, formNumberId }) => {
                  try {
                    await updateAuthorization({
                      variables: {
                        id: authorization.id,
                        patch: { formId, FormNumberId: formNumberId },
                      },
                    });
                    this.toggleFormModal();
                    if (step !== 1) {
                      this.saveChanges(true);
                    } else {
                      this.updateAndStep(true);
                    }
                  } catch {
                    alert.error(
                      "There was an error setting the form or submission number"
                    );
                  }
                }}
                open={showSelectFormModal}
                set={setToForm}
                state={results[CONFIG.DEFAULT_FIELDS.INSURANCE_STATE.key]}
                showLocateNumberTip={() => {
                  this.setState({
                    showLocateNumberTipModal: true,
                    showSelectFormModal: false,
                  });
                }}
                missingAuthSelected={null}
                NDC={results[CONFIG.DEFAULT_FIELDS.PRIMARY_NDC.key]}
                benefitType={results[CONFIG.DEFAULT_FIELDS.BENEFIT_TYPES.key]}
              />
            )}

            <Modal
              header="Authorization Status"
              onClick={this.toggleExternalAuthStatusModal}
              open={showExternalAuthStatusModal}
            >
              <div style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                <div style={{ marginBottom: "6px" }}>
                  <BaseButton
                    style={{ width: "100%" }}
                    onClick={async () => {
                      try {
                        await this.saveChanges();
                        await updateAuthorizationStatus({
                          variables: {
                            id: authorization.id,
                            status:
                              CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING,
                          },
                        });
                        goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
                      } catch (err) {
                        this.setState({ loading: false });
                        alert.error(
                          `There was an error submitting your authorization, ${err}`
                        );
                      }
                    }}
                  >
                    This authorization is pending
                  </BaseButton>
                </div>
                <div>
                  <BaseButton
                    data-cy="newAuthorization-authStatus-alreadyApproved"
                    style={{ width: "100%" }}
                    onClick={async () => {
                      try {
                        await this.saveChanges();
                        await updateAuthorizationStatus({
                          variables: {
                            id: authorization.id,
                            status:
                              CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.APPROVED,
                            terminalStatusUpdateType:
                              TerminalStatusUpdateType.User,
                          },
                        });
                        goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
                      } catch (err) {
                        this.setState({ loading: false });
                        alert.error(
                          `There was an error submitting your authorization, ${err}`
                        );
                      }
                    }}
                  >
                    This authorization is already approved
                  </BaseButton>
                </div>
              </div>
            </Modal>
            {showLocateNumberTipModal && (
              <LocateNumberTipModal
                onClick={() => {
                  this.setState({ showLocateNumberTipModal: false });
                }}
              />
            )}
          </Container>
        </Flex>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    attachments: state.form.attachments,
    step: state.form.currentStep,
    disabled: state.form.disabled,
    requiredFieldKeys: state.form.requiredFieldKeys,
    shouldIncludeCoverLetter: state.form.shouldIncludeCoverLetter,
    DrugOptionId: state.form.DrugOptionId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    syncLocalState: ({ config, status, attachments, DrugOptionId }) => {
      dispatch(
        syncAuthorizationDetails({
          config,
          status,
          attachments,
          DrugOptionId,
        })
      );
    },
    goToLocation: (location) => {
      dispatch(push(location));
    },
    setToForm: (toSet) => dispatch(setFormFields(toSet)),
    setStep(step) {
      dispatch(setCurrentStep(step));
    },
    reset() {
      dispatch(resetForm());
    },
    toggleHighlightRequiredFields(shouldBeHighlighted) {
      dispatch(toggleHighlightRequiredFields(shouldBeHighlighted));
    },
    setCoverLetter(shouldIncludeCoverLetter) {
      dispatch(setShouldIncludeCoverLetter(shouldIncludeCoverLetter));
    },
  };
}

export const withAuthorization = graphql(withAuthorizationQuery, {
  options: (props) => {
    const params = new URLSearchParams(props.location.search);
    return {
      variables: { id: _.toNumber(params.get("id")) },
      fetchPolicy: "network-only",
    };
  },
  props: ({ data }) => ({
    authorization: data.authorizationById,
    authorizationRefetch: _.get(data, "refetch"),
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withUpdateAuthorizationProgressWithoutPatient,
    withUpdateFormDetails,
    withSubmitAuthorization,
    withUpdateAuthorizationProgress,
    withUpdateAuthorizationStatus,
    withAuthorization,
    withFormById, // withFormById must be after withAuthorization
    withUpdateAuthorization,
    withCurrentAccount
  )(withAlert()(NewAuthorizationHoc(Form)))
);
