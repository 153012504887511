import * as React from "react";
import styled from "styled-components";

const ResponseObject = styled.object`
  height: ${(props) => props.height ?? "700px"};
  width: ${(props) => props.width ?? "500px"};
`;

export type PdfViewerProps = React.ComponentProps<typeof PdfViewer>;

const PdfViewer: React.VoidFunctionComponent<{
  fileURL: string;
  height?: string;
  mimeTypeOverride?: string;
  width?: string;
}> = ({ fileURL, height, mimeTypeOverride, width }) => {
  return (
    <ResponseObject
      aria-label="Authorization attachment"
      data={`${fileURL}#view=FitH`}
      height={height}
      type={mimeTypeOverride || "application/pdf"}
      width={width}
    />
  );
};

/**
 * @deprecated Reorganize me?
 */
export default PdfViewer;
