import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Stack,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from "@samacare/design/core";
import {
  Mutation,
  MutationMockFaxArgs,
  Query,
  QueryGenerateMockFaxMarkdownForAuthorizationArgs,
  QueryGetAuthorizationLogArgs,
} from "@samacare/graphql";
import React from "react";
import { useAlert } from "react-alert";
import _ from "lodash";
import moment from "moment";

const AuthorizationLogViewer: React.VoidFunctionComponent<{
  authorizationId: number | undefined;
}> = ({ authorizationId }) => {
  const { data, loading } = useQuery<Query, QueryGetAuthorizationLogArgs>(
    gql`
      query getAuthorizationLog($authorizationId: Int!) {
        getAuthorizationLog(authorizationId: $authorizationId) {
          createdAt
          type
          details
        }
      }
    `,
    {
      skip: authorizationId === undefined,
      variables: { authorizationId: authorizationId! },
      pollInterval: 2000,
      fetchPolicy: "network-only",
    }
  );

  if (loading) return <>Loading...</>;
  if (!data || !data.getAuthorizationLog) return <>...nothing found..</>;
  const items = data.getAuthorizationLog;

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {_.map(items, (item, idx) => (
        <ListItem alignItems="flex-start" key={idx}>
          <ListItemText
            primary={item.type}
            secondary={
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
                {" - "}
                {JSON.stringify(item.details)}
              </>
            }
          />
        </ListItem>
      ))}
      <Divider variant="inset" component="li" />
    </List>
  );
};

export const FaxTestDashboard: React.VoidFunctionComponent = () => {
  const [markdown, setMarkdown] = React.useState("");
  const [authorizationIdString, setAuthorizationId] = React.useState("");
  const authorizationId =
    authorizationIdString === "" ? undefined : Number(authorizationIdString);
  const [mockBarcode, setMockBarcode] = React.useState(false);
  const alert = useAlert();
  const [generateMarkdown, { data }] = useLazyQuery<
    Query,
    QueryGenerateMockFaxMarkdownForAuthorizationArgs
  >(
    gql`
      query generateMarkdown($authorizationId: Int!) {
        generateMockFaxMarkdownForAuthorization(
          authorizationId: $authorizationId
        )
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [sendMockFax] = useMutation<Mutation, MutationMockFaxArgs>(
    gql`
      mutation mockFax(
        $authorizationId: Int!
        $markdown: String!
        $mockBarcode: Boolean!
      ) {
        mockFax(
          authorizationId: $authorizationId
          markdown: $markdown
          mockBarcode: $mockBarcode
        ) {
          id
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    if (authorizationId === undefined) return;
    generateMarkdown({
      variables: {
        authorizationId,
      },
    });
  }, [authorizationId]);

  React.useEffect(() => {
    if (!data) return;
    setMarkdown(data.generateMockFaxMarkdownForAuthorization ?? "");
  }, [data]);

  const onSubmit = async () => {
    if (authorizationId === undefined) return;
    try {
      await sendMockFax({
        variables: {
          authorizationId,
          markdown,
          mockBarcode,
        },
      });
      alert.success("Successfully sent mock fax");
    } catch (err) {
      alert.error((err as Error).message);
    }
  };

  return (
    <>
      <Stack gap={2}>
        <TextField
          name="authorizationId"
          label="Authorization ID"
          value={authorizationIdString}
          onChange={(e) => setAuthorizationId(e.target.value)}
        />
        <TextField
          multiline
          name="markdown"
          label="Fax Markdown"
          value={markdown}
          onChange={(e) => setMarkdown(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => setMockBarcode(e.target.checked)}
              name="mockBarcode"
            />
          }
          label="Mock Barcode (novartis)"
        />
        <Button color="primary" variant="outlined" onClick={onSubmit}>
          Send Mock Fax
        </Button>
      </Stack>
      <hr />
      <AuthorizationLogViewer authorizationId={authorizationId} />
    </>
  );
};
