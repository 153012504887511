import styled from "styled-components";
import { PortalCredential, PortalToHubMapping } from "@samacare/graphql";
import {
  InnerTableHeader,
  TableCell,
  TableRow,
} from "app/routes/Admin/AdminStyles";
import { Edit } from "@@ui-kit/Icons";
import * as React from "react";
import { LeftRightCenterAll } from "@@components/LeftRight";
import { WhiteButton } from "@@ui-kit/WhiteButton";

const PortalCredentialTableRow = styled(TableRow)`
  &:hover {
    cursor: default;
  }
`;

interface IPortalCredentialRowProps {
  portalCredential: PortalCredential;
  onEdit: (hubKey: string, cred: PortalCredential | null) => void;
  hub: PortalToHubMapping;
}
export const PortalCredentialRow: React.FC<IPortalCredentialRowProps> = ({
  hub,
  portalCredential,
  onEdit,
}) => {
  return (
    <PortalCredentialTableRow id="pendo_portalRow">
      <TableCell id="pendo_portalRow_portalName">{hub?.title}</TableCell>
      <TableCell
        id="pendo_portalRow_actionButtons"
        style={{ padding: "16px 0px" }}
      >
        <InnerTableHeader
          style={{ justifyContent: "flex-end", padding: "0px 16px 0 0" }}
        >
          {portalCredential == null && (
            <WhiteButton onClick={() => onEdit(hub.hubKey, null)}>
              Setup
            </WhiteButton>
          )}
          {portalCredential != null && (
            <WhiteButton onClick={() => onEdit(hub.hubKey, portalCredential)}>
              <LeftRightCenterAll>
                <Edit size={18} style={{ marginRight: "8px" }} />
                {portalCredential.username}
              </LeftRightCenterAll>
            </WhiteButton>
          )}
        </InnerTableHeader>
      </TableCell>
    </PortalCredentialTableRow>
  );
};
