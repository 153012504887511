import { useCallback } from "react";
import * as React from "react";
import _ from "lodash";
import { useAlert } from "react-alert";
import Section, { Items } from "AuthorizationSharedComponents/Section";
import Modal from "../../Modal";
import FormSubmitButtons from "../../AuthorizationSharedComponents/FormSubmitButtons";
import { setFormFields } from "../../../reducers/formReducer";
import { ProviderType } from "./types/types";
import { FetchResult } from "@apollo/client";
import { useConfig } from "../../../hooks";
import { useDispatch, useSelector, State } from "../../../configureStore";
import { Account, Location } from "@samacare/graphql";
import { Box, Button } from "@samacare/design/core";

export interface UpsertPrescriberOrLocationParams {
  isPrescriber: boolean;
  id?: number | null;
  results?: State["form"]["results"];
  providerType: ProviderType | undefined;
}

export const CreatePrescriberOrLocation: React.VFC<{
  providerType?: ProviderType;
  isPrescriber: boolean;
  fields?: Items;
  trackUpsert: (
    a: Account | Location | undefined,
    isPrescriber: boolean,
    providerType?: ProviderType
  ) => void;
  upsertPrescriberOrLocation: (
    input: UpsertPrescriberOrLocationParams
  ) =>
    | Promise<FetchResult<{ [upsertPrescriber: string]: Account }>>
    | Promise<FetchResult<{ [upsertLocation: string]: Location }>>;
}> = ({
  providerType,
  isPrescriber,
  fields,
  trackUpsert,
  upsertPrescriberOrLocation,
}) => {
  const [shouldRenderCreateModal, setShouldRenderCreateModal] =
    React.useState(false);
  const dispatch = useDispatch();
  const config = useConfig();
  const alert = useAlert();

  const { DEFAULT_FIELDS } = config;
  const { REFERRING_PROVIDER } = config.CONSTANTS.PRESCRIBER_TYPES;

  const set = useCallback(
    (fieldInput: Record<string, unknown>) =>
      dispatch(setFormFields(fieldInput)),
    [dispatch]
  );

  const results = useSelector((state) => state.form.results);

  const handleModal = (shouldOpen = false) => {
    setShouldRenderCreateModal(shouldOpen);
    if (isPrescriber) {
      switch (providerType) {
        case REFERRING_PROVIDER:
          set({
            [DEFAULT_FIELDS.REFERRING_PROVIDER_FIRST_NAME.key]: "",
            [DEFAULT_FIELDS.REFERRING_PROVIDER_LAST_NAME.key]: "",
            [DEFAULT_FIELDS.REFERRING_PROVIDER_NPI.key]: "",
            [DEFAULT_FIELDS.REFERRING_PROVIDER_TIN.key]: "",
          });
          break;
        default:
          set({
            [DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_LAST_NAME.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_SPECIALTY.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_NPI.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_TIN.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_PAYER_ASSIGNED_PROVIDER_ID.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_SUBMITTER_ID.key]: "",
          });
          break;
      }
    } else {
      // We are creating a location here, not a provider
      switch (providerType) {
        case REFERRING_PROVIDER:
          set({
            [DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME.key]: "",
            [DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS.key]: "",
            [DEFAULT_FIELDS.REFERRING_PROVIDER_CITY.key]: "",
            [DEFAULT_FIELDS.REFERRING_PROVIDER_STATE.key]: "",
            [DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP.key]: "",
          });
          break;
        default:
          set({
            [DEFAULT_FIELDS.LOCATION_NAME.key]: "",
            [DEFAULT_FIELDS.FACILITY_NAME.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_CITY.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_STATE.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_ZIP.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]: "",
            [DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]: "",
          });
          break;
      }
    }
  };

  const handleCreate = async () => {
    const requiredFields = _.flatten(fields).filter((field) => field.required);
    const hasAllRequiredFields = _.every(
      _.map(_.flatten(requiredFields), "key"),
      (field: keyof State["form"]["results"]) =>
        results ? results[field] : null
    );

    const errMsgText = isPrescriber ? "prescriber" : "location";
    if (hasAllRequiredFields) {
      try {
        const upsertPrescriberOrLocationResp = await upsertPrescriberOrLocation(
          {
            isPrescriber,
            providerType,
            results,
          }
        );

        const model =
          upsertPrescriberOrLocationResp.data?.[
            isPrescriber ? "upsertPrescriber" : "upsertLocation"
          ];

        trackUpsert(model, isPrescriber, providerType);
        setShouldRenderCreateModal(false);
        alert.success("Success");
      } catch (e) {
        alert.error(
          `There was an error creating the new ${errMsgText}: ${
            (e as Error).message
          }`
        );
      }
    } else {
      alert.error(
        `All ${errMsgText} details fields are required to create a new ${errMsgText}`
      );
    }
  };

  const prescriberOrLocationText = isPrescriber ? "Provider" : "Location";
  return (
    <Box sx={{ display: "flex" }}>
      <Button
        data-cy={`actionNew${isPrescriber ? "Prescriber" : "Location"}`}
        variant="outlined"
        sx={{ ml: 2, mb: 0.5 }}
        onClick={() => {
          handleModal(true);
        }}
      >
        Create + Set New
      </Button>
      {shouldRenderCreateModal && (
        <Modal
          header={`Create New ${prescriberOrLocationText}`}
          onClick={() => {
            handleModal();
          }}
          open={shouldRenderCreateModal}
        >
          {fields && (
            <Section
              section={{
                items: fields,
                title: `${prescriberOrLocationText} Details`,
              }}
            />
          )}
          <br />
          <FormSubmitButtons
            back={() => {
              handleModal();
            }}
            submitText="Create and Set"
            submit={handleCreate}
          />
        </Modal>
      )}
    </Box>
  );
};

export default CreatePrescriberOrLocation;
