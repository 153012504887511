import { useCallback } from "react";
import _ from "lodash";
import { useAlert } from "react-alert";
import SegmentSelector from "Segment/SegmentSelector";
import {
  withUpdateFormDetailsMutation,
  setPrescriberOnAuthMutation,
} from "../../../../graphql/Authorization";
import { formatPrescriber } from "../../../../util/authUtils";
import { useConfig } from "@@hooks/config";
import { Prescriber } from "@samacare/graphql";
import { useDispatch } from "../../../../configureStore";
import { setFormFields } from "../../../../reducers/formReducer";
import { useMutation } from "@apollo/client";
import { withPrescribers } from "../../../../graphql/Prescriber";

export const PrescriberSelector: React.VFC<{
  authorizationId: string;
  afterSelection: (prescriber: Prescriber | undefined) => void;
  prescribers: Prescriber[];
  providerType: string;
  disabled: boolean;
  prescriberId: string;
}> = ({
  authorizationId,
  afterSelection,
  prescribers,
  providerType,
  disabled,
  prescriberId,
}) => {
  const config = useConfig();
  const alert = useAlert();
  const dispatch = useDispatch();
  const set = useCallback(
    (fieldInput: Record<string, unknown>) =>
      dispatch(setFormFields(fieldInput)),
    [dispatch]
  );

  const [updateFormDetails] = useMutation(withUpdateFormDetailsMutation);
  const [setPrescriberOnAuth] = useMutation(setPrescriberOnAuthMutation);

  const { REFERRING_PROVIDER } = config.CONSTANTS.PRESCRIBER_TYPES;
  const { DEFAULT_FIELDS } = config;

  const submit = async (e: { value: string } | null) => {
    try {
      if (e == null) {
        throw new Error();
      }

      const id = e.value;

      const currentProvider = prescribers.find(
        (prescriber) => prescriber.id === id
      );

      // The selector will update the prescriber set for the form, which we want to avoid for
      // setting other types of providers such as referring provider
      switch (providerType) {
        case REFERRING_PROVIDER:
          set({
            [DEFAULT_FIELDS.REFERRING_PROVIDER_FIRST_NAME.key]:
              currentProvider?.firstName,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_LAST_NAME.key]:
              currentProvider?.lastName,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_NPI.key]: currentProvider?.NPI,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_TIN.key]: currentProvider?.TIN,
          });
          break;
        default:
          await setPrescriberOnAuth({
            variables: { authorizationId, prescriberId: id },
          });
          await updateFormDetails({
            variables: { id: authorizationId, details: { prescriberId: id } },
          });
      }

      const prescriber = _.find(prescribers, { id });
      afterSelection(prescriber);
    } catch (err) {
      alert.error(
        "There was an error setting prescriber on this authorization"
      );
    }
  };

  const selected = _.find(prescribers, { id: prescriberId });

  return (
    <div>
      <SegmentSelector
        id="controlPrescriberSelector"
        disabled={disabled}
        onChange={submit}
        options={_.map(prescribers, (prescriber) => ({
          value: prescriber.id,
          label: formatPrescriber(prescriber),
        }))}
        title="Saved Providers"
        help="To begin, type the physician's name or scroll to select the physician from the dropdown. If not found, select 'Create & Set New' to have the option show up in the future."
        value={
          selected
            ? { value: selected.id, label: formatPrescriber(selected) }
            : ""
        }
        sorted
      />
    </div>
  );
};

/**
 * @deprecated Use a MUI component instead
 */
export default withPrescribers(PrescriberSelector);
