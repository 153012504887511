import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import { withDefaultProps } from "./utils";
import insuranceCompanyInfo from "./fragments/insuranceCompanyInfo";

export interface InsuranceCompany {
  id: string;
  name: string;
  searchTags: (string | null)[] | null;
  planParentCompanyId: string | null;
  isArchived: boolean;
}

const INSURANCE_COMPANIES_QUERY = "insuranceCompanies";
export const insuranceCompaniesQuery = gql`
  query insuranceCompanies {
    insuranceCompaniesFindAll {
      ...insuranceCompanyInfo
    }
  }
  ${insuranceCompanyInfo}
`;
export const withInsuranceCompanies = graphql(insuranceCompaniesQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    insuranceCompanies: _.get(data, "insuranceCompaniesFindAll"),
  })),
});

export const withUpsertInsuranceCompany = graphql(
  gql`
    mutation upsertInsuranceCompany(
      $id: Int
      $name: String!
      $parentCompanyName: String
      $parentId: Int
      $searchTags: [String!]
      $supportedCodingTypes: InsuranceCompanySupportedCodingTypes!
    ) {
      upsertInsuranceCompany(
        id: $id
        name: $name
        parentCompanyName: $parentCompanyName
        parentId: $parentId
        searchTags: $searchTags
        supportedCodingTypes: $supportedCodingTypes
      ) {
        ...insuranceCompanyInfo
      }
    }
    ${insuranceCompanyInfo}
  `,
  {
    name: "upsertInsuranceCompany",
    options: () => ({
      refetchQueries: () => [INSURANCE_COMPANIES_QUERY],
      awaitRefetchQueries: true,
    }),
  }
);

const withUpdateInsuranceCompanyMutation = gql`
  mutation updateInsuranceCompany(
    $id: Int!
    $patch: InsuranceCompanyUpdatePatch
  ) {
    updateInsuranceCompanyById(id: $id, patch: $patch) {
      ...insuranceCompanyInfo
    }
  }
  ${insuranceCompanyInfo}
`;
export const withUpdateInsuranceCompany = graphql(
  withUpdateInsuranceCompanyMutation,
  {
    name: "updateInsuranceCompany",
    options: () => ({
      refetchQueries: () => [INSURANCE_COMPANIES_QUERY],
      awaitRefetchQueries: true,
    }),
  }
);
