import _ from "lodash";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@samacare/design/core";

import {
  useFormContext,
  UseControllerProps,
  RegisterOptions,
  useFormState,
  FieldError,
} from "react-hook-form";

export type SelectFieldProps = UseControllerProps &
  SelectProps & {
    name: string;
    rules?: RegisterOptions;
  };

const SelectField: React.FC<SelectFieldProps> = ({
  children,
  name,
  label,
  rules,
  fullWidth,
  required = false,
  disabled = false,
  value,
  ...materialProperties
}) => {
  const { control, register, getValues } = useFormContext();
  const { errors } = useFormState({ control });

  const { ref, ...fields } = register(name, {
    ...rules,
    value: getValues(name),
    required,
  });

  const error: FieldError = _.get(errors, name) as FieldError;
  const helperText: string = error?.message || "";

  const labelId = `SelectField-label-${name}`;

  return (
    <FormControl
      error={Boolean(error)}
      fullWidth={fullWidth}
      disabled={disabled}
      required={required}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        ref={ref}
        label={label}
        labelId={labelId}
        id={`SelectField-${name}`}
        inputProps={{ "data-cy": _.camelCase(`field.${name}`) }}
        defaultValue={value}
        fullWidth
        disabled={disabled}
        required={required}
        {...fields}
        {...materialProperties}
      >
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectField;
