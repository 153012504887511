import { useFormContext } from "react-hook-form";
import { DrugOption, UnifiedBenefitsCheckType } from "@samacare/graphql";
import AutocompleteField, {
  type AutocompleteFieldProps,
} from "../../../../form/AutocompleteField";
import {
  useFeatureFlag,
  useFeatureFlagNames,
  useEnabledDrugOptions,
} from "../../../hooks";

export type FullBenefitsCheckOption = {
  type: UnifiedBenefitsCheckType.Full;
  drug: DrugOption;
};

export type LiteBenefitsCheckOption = {
  type: UnifiedBenefitsCheckType.Lite;
};

export type BenefitsCheckOption =
  | FullBenefitsCheckOption
  | LiteBenefitsCheckOption;

const useBenefitsCheckOptions = ({
  type,
  drugOptionId,
  disableLiteOption,
}: {
  type: UnifiedBenefitsCheckType | null;
  drugOptionId: string | null;
  disableLiteOption: boolean;
}): {
  loading: boolean;
  selected: BenefitsCheckOption | null;
  options: BenefitsCheckOption[];
} => {
  const featureFlags = useFeatureFlagNames();
  const enableUnifiedBenefitsCheck =
    useFeatureFlag<boolean>(featureFlags.EnableUnifiedBenefitsChecks) ?? false;

  const { loading, drugOptions } = useEnabledDrugOptions();

  // build bv lite option + full bv drug options
  const liteOption = {
    type: UnifiedBenefitsCheckType.Lite,
  } as LiteBenefitsCheckOption;

  const fullOptions = drugOptions.map((drug) => ({
    type: UnifiedBenefitsCheckType.Full,
    drug,
  })) as FullBenefitsCheckOption[];

  // find selected item based on form values
  let selected: BenefitsCheckOption | null = null;
  if (type === UnifiedBenefitsCheckType.Lite) {
    selected = liteOption;
  } else if (drugOptionId == null) {
    selected = null;
  } else {
    selected =
      fullOptions.find((option) => option.drug.id === drugOptionId) ?? null;
  }

  return {
    loading,
    selected,
    options: [
      ...fullOptions,
      // only include lite option when BV lite feature is enabled
      ...(enableUnifiedBenefitsCheck && !disableLiteOption ? [liteOption] : []),
    ],
  };
};

/////////////////////////////////////////
// AutocompleteField component helpers //
/////////////////////////////////////////

const getOptionLabel = (option: BenefitsCheckOption) => {
  if (option.type === UnifiedBenefitsCheckType.Lite) {
    return "Benefit Verification Lite for Medical Services";
  }
  const { drug } = option;
  return `${drug.code}: ${drug.drugName!}`;
};

const isOptionEqualToValue = (
  option: BenefitsCheckOption,
  value: BenefitsCheckOption
) => {
  if (option.type === UnifiedBenefitsCheckType.Lite) {
    return option.type === value.type;
  }
  return option.type === value.type && option.drug.id === value.drug.id;
};

const setValueAs = (selected: BenefitsCheckOption | null) => {
  if (selected == null) {
    return null;
  }
  if (selected.type === UnifiedBenefitsCheckType.Lite) {
    return `${UnifiedBenefitsCheckType.Lite}`;
  }
  return `${UnifiedBenefitsCheckType.Full}${selected.drug.id}`;
};

///////////////
// Component //
///////////////

export type BenefitsCheckOptionsProps = Omit<
  AutocompleteFieldProps<BenefitsCheckOption, false, false, false>,
  "options" | "name"
> & {
  typeFieldName: string;
  drugOptionIdFieldName: string;
  disableLiteOption?: boolean;
};

export const BenefitsCheckOptionsField: React.FC<BenefitsCheckOptionsProps> = ({
  typeFieldName,
  drugOptionIdFieldName,
  disableLiteOption = false,
  ...props
}) => {
  const { watch, setValue } = useFormContext();
  const typeValue = watch(typeFieldName) as UnifiedBenefitsCheckType | null;
  const drugOptionId = watch(drugOptionIdFieldName) as string | null;

  // Only show the selected value in dropdown
  // Or if no value is selected, show all enabled options
  const { loading, options, selected } = useBenefitsCheckOptions({
    type: typeValue,
    drugOptionId,
    disableLiteOption,
  });

  if (loading) {
    return null;
  }

  return (
    <AutocompleteField<BenefitsCheckOption, false, false, false>
      sx={{ width: 600 }}
      placeholder="Type to search service..."
      label="Service"
      name={`${typeFieldName}-${drugOptionIdFieldName}`}
      loading={loading}
      options={options}
      value={selected}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      setValueAs={setValueAs}
      filterSelectedOptions
      onChange={(e, newValue) => {
        if (newValue == null) {
          setValue(typeFieldName, null);
          setValue(drugOptionIdFieldName, null);
        } else if (newValue.type === UnifiedBenefitsCheckType.Full) {
          setValue(typeFieldName, newValue.type);
          setValue(drugOptionIdFieldName, newValue.drug.id);
        } else if (newValue.type === UnifiedBenefitsCheckType.Lite) {
          setValue(typeFieldName, newValue.type);
          setValue(drugOptionIdFieldName, null);
        }
      }}
      {...props}
    />
  );
};
