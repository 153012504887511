import _ from "lodash";
import { Stack, Typography } from "@samacare/design";
import { useTheme } from "@samacare/design/core/styles";
import { Maybe } from "@samacare/graphql";

interface ViewAddressBlockProps {
  address?: string | Maybe<string>;
  city?: string | Maybe<string>;
  state?: string | Maybe<string>;
  zip?: string | Maybe<string>;
}
export const ViewAddressBlock: React.FC<ViewAddressBlockProps> = (props) => {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={0.5}>
      <Typography variant="body2" color={theme.palette.text.primary}>
        {props.address}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.primary}>
        {_.compact([props.city, props.state, props.zip]).join(", ")}
      </Typography>
    </Stack>
  );
};
