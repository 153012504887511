import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  GetAllPrescribersByInstitutionQuery,
  GetAllPrescribersByInstitutionQueryVariables,
} from "@@generated/graphql";

const getPrescribers = gql`
  query getAllPrescribersByInstitution($InstitutionIdOverride: String) {
    prescribersFindAll(InstitutionIdOverride: $InstitutionIdOverride) {
      id
      firstName
      lastName
      label
      specialtyDescription
      specialtyCode
      NPI
      TIN
      DEA
      licenseNumber
    }
  }
`;

export const usePrescribers = () => {
  const { data } = useQuery<
    GetAllPrescribersByInstitutionQuery,
    GetAllPrescribersByInstitutionQueryVariables
  >(getPrescribers);

  return data?.prescribersFindAll ?? [];
};
