import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { deepmerge } from "@mui/utils";
import { GlobalStyles, css } from "@mui/system";
import { LicenseInfo } from "@mui/x-license-pro";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import LocalizationProvider from "./LocalizationProvider";
import defaultTheme from "./theme";
LicenseInfo.setLicenseKey("fd0d9bf0863b9fb970a2ad1e2d360479Tz05MDk0MCxFPTE3NDc4NTI5MTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");
const ThemeProvider = ({ children, theme = defaultTheme, popoverContainer, }) => {
    // have to merge the theme with the popover container for theme provider
    // to work in the content script
    const mergedTheme = deepmerge(theme, popoverContainer
        ? {
            MuiPopover: {
                defaultProps: {
                    container: popoverContainer,
                },
            },
            MuiPopper: {
                defaultProps: {
                    container: popoverContainer,
                },
            },
            MuiModal: {
                defaultProps: {
                    container: popoverContainer,
                },
            },
        }
        : {});
    return (_jsxs(_Fragment, { children: [_jsx(GlobalStyles, { styles: css `
          body,
          html {
            height: 100%;
          }
        ` }), _jsxs(MuiThemeProvider, { theme: mergedTheme, children: [_jsx(CssBaseline, {}), _jsx(LocalizationProvider, { children: children })] })] }));
};
export default ThemeProvider;
