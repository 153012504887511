import _ from "lodash";
import { Children, cloneElement, isValidElement } from "react";
import {
  FieldError,
  UseControllerProps,
  useFormContext,
  useFormState,
} from "react-hook-form";

import {
  Box,
  FormHelperText as MuiFormHelperText,
  FormLabel as MuiFormLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  RadioProps,
  FormGroupProps,
} from "@samacare/design/core";

export type RadioGroupFieldProps = MuiRadioGroupProps &
  UseControllerProps &
  FormGroupProps & {
    label?: string;
    required?: boolean;
    hideError?: boolean;
  };

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  children,
  name,
  label = "",
  rules = {},
  defaultValue = "",
  required = false,
  hideError = false,
  ...materialProperties
}) => {
  const { control, register, setValue, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const { ref } = register(name, { required, value: watch(name), ...rules });

  const id = `RadioGroup-${name}`;
  const error = _.get(errors, name) as FieldError;
  const value = watch(name);

  // register every radio button child (or mui form label containing control) to have proper field reference
  const radios = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as React.ReactElement<RadioProps>, {
        ref,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        checked: value === child.props.value,
      });
    }
    return child;
  });

  const props = {
    id,
    ref,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(name, event.target.value, { shouldValidate: true }),
    defaultValue,
    ...materialProperties,
  };

  return (
    <MuiRadioGroup {...props}>
      {label && (
        <MuiFormLabel error={!!error} htmlFor={name}>
          {label}
        </MuiFormLabel>
      )}
      {radios}
      {!hideError && error && (
        <Box flex="0 0 100%">
          <MuiFormHelperText error={!!error} sx={{ mx: 0 }}>
            {error?.message}
          </MuiFormHelperText>
        </Box>
      )}
    </MuiRadioGroup>
  );
};

export default RadioGroupField;
