import React, { useState } from "react";
import { useHistory } from "../hooks/routing";
import styled from "styled-components";
import { useAlert } from "react-alert";
import _ from "lodash";
import colors from "Resources/colors";

import {
  Authorization,
  Mutation,
  MutationCreateAuthorizationFromBvArgs,
  MutationCreateAuthorizationFromEnrollmentArgs,
} from "@samacare/graphql";
import PortalSelectionModal from "./PortalSelectionModal";
import MenuButton from "./MenuButton";
import ROUTE_PATHS from "../routes/ROUTE_PATHS";
import { useCurrentAccount } from "../graphql/Account";
import {
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  useCreateAuthorization,
  withCreateAuthorizationFromBvMutation,
  ALL_AUTHORIZATIONS_QUERY_NAME,
  withCreateAuthorizationFromEnrollmentMutation,
} from "../graphql/Authorization";
import { useMutation } from "@apollo/client";
import { Config, useConfig, useSamaCareBrandName } from "@@hooks/config";
import { Tooltip } from "@samacare/design/core";
import BenefitsVerificationQuery from "../routes/BenefitsVerifications/graphql/BenefitsVerificationQuery.gql";
import { getBenefitsVerificationByIdQuery } from "@@hooks/useBenefitsVerification";
import { useFeatureFlag } from "../hooks";

export type NewPriorAuthButtonProps = React.ComponentProps<
  typeof NewPriorAuthButton
>;

const MenuItem = styled.div`
  padding: 10px;
  text-align: center;
  width: 100px;
  :hover {
    cursor: pointer;
    background-color: ${colors.lightGray};
  }
`;

const generateConfigFieldsForAccount = (
  config: Config,
  account: Record<string, unknown>
): Partial<Authorization["config"]> => {
  if (!account) return {};
  return {
    [config.DEFAULT_FIELDS.REQUESTED_BY_FIRST_NAME.key]: account.firstName,
    [config.DEFAULT_FIELDS.REQUESTED_BY_LAST_NAME.key]: account.lastName,
    [config.DEFAULT_FIELDS.REQUESTED_BY
      .key]: `${account.firstName} ${account.lastName}`,
    [config.DEFAULT_FIELDS.REQUESTED_BY_PHONE.key]: account.phone,
    [config.DEFAULT_FIELDS.REQUESTED_BY_EXTENSION.key]: account.extension,
  };
};

const getDefaultFields = (
  config: Config,
  type: string,
  account: Record<string, unknown>,
  portal?: { key: string; insuranceCompanyName: string }
) => {
  if (!account) {
    throw new Error("Account not yet available");
  }

  if (!type) {
    throw new Error("Authorization type must be specified");
  }

  const defaultFields: Record<string, unknown> = {
    config: {
      ...generateConfigFieldsForAccount(config, account),
      [config.DEFAULT_FIELDS.EMAIL_NOTIFICATION_TYPE.key]: "On",
      [config.DEFAULT_FIELDS.DEFAULT_CHECKMARK_TRUE.key]: "On",
    },
    status: config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION,
    type,
    portalKey: null,
  };

  if (type === config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key) {
    return {
      ...defaultFields,
      portalKey: portal!.key,
      config: {
        ...(defaultFields.config as object),
        [config.DEFAULT_FIELDS.INSURANCE_COMPANY.key]:
          portal!.insuranceCompanyName,
      },
      isWebExtension: (
        config.CONSTANTS.WEB_EXTENSION_PORTAL_KEYS as string[]
      ).includes(portal!.key),
    };
  } else if (type === config.CONSTANTS.AUTHORIZATION_TYPES.REFERRAL.key) {
    return {
      ...defaultFields,
      isReferral: true,
      config: {
        ...(defaultFields.config as object),
        [config.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key]: "Referral Request",
      },
    };
  }
  return defaultFields;
};

const NewPriorAuthButton: React.VoidFunctionComponent<{
  children: React.ReactNode;
  configOverrides?: Record<string, unknown>;
  patientId?: string;
}> = ({ children, configOverrides, patientId }) => {
  const config = useConfig();
  const [portalSelectionOpen, setPortalSelectionOpen] = useState(false);
  const [createAuthorization] = useCreateAuthorization();
  const [account] = useCurrentAccount();
  const history = useHistory();
  const alert = useAlert();
  const brandName = useSamaCareBrandName();

  const isEdiAuthEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.AvailityEdiSubmission
  );

  const [createAuthorizationFromBv] = useMutation<
    Mutation,
    MutationCreateAuthorizationFromBvArgs
  >(withCreateAuthorizationFromBvMutation, {
    refetchQueries: [
      ALL_AUTHORIZATIONS_QUERY_NAME,
      AUTHORIZATION_PAGINATION_QUERY_NAME,
      BenefitsVerificationQuery,
      getBenefitsVerificationByIdQuery,
    ],
  });

  const [createAuthorizationFromExistingEnrollment] = useMutation<
    Mutation,
    MutationCreateAuthorizationFromEnrollmentArgs
  >(withCreateAuthorizationFromEnrollmentMutation, {
    refetchQueries: [
      ALL_AUTHORIZATIONS_QUERY_NAME,
      AUTHORIZATION_PAGINATION_QUERY_NAME,
    ],
  });
  const typeToPathPrefixMap = {
    [config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key]:
      ROUTE_PATHS.FORM_AUTHORIZATION.path,
    [config.CONSTANTS.AUTHORIZATION_TYPES.FORM.key]:
      ROUTE_PATHS.FORM_AUTHORIZATION.path,
    [config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key]:
      ROUTE_PATHS.PORTAL_AUTHORIZATION.path,
    [config.CONSTANTS.AUTHORIZATION_TYPES.REFERRAL.key]:
      ROUTE_PATHS.REFERRAL.path,
    [config.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key]:
      ROUTE_PATHS.MUTLI_PARTY_AUTH.path,
  };

  const doCreate = async ({
    type,
    portal,
    onClickConfigOverride = {},
  }: {
    type: string;
    portal?: { key: string; insuranceCompanyName: string };
    onClickConfigOverride?: Record<string, unknown>;
  }) => {
    if (!account) throw new Error("Account not yet available");
    if (!type) throw new Error("Authorization type must be specified");

    const defaultFields: Record<string, unknown> = {
      PatientId: patientId,
      ...getDefaultFields(config, type, account, portal),
    };
    defaultFields.config = {
      ...(defaultFields as unknown as { config: object }).config,
      ...configOverrides,
      ...onClickConfigOverride,
    };

    try {
      if (configOverrides?.benefitsVerificationId) {
        const res = await createAuthorizationFromBv({
          variables: {
            benefitsVerificationId:
              configOverrides?.benefitsVerificationId as string,
            patch: { type, config: defaultFields.config },
          },
        });
        history.push(
          `${typeToPathPrefixMap[type]}?step=1&id=${
            res.data?.createAuthorizationFromBv?.id || ""
          }`
        );
      } else if (configOverrides?.enrollmentId) {
        const res = await createAuthorizationFromExistingEnrollment({
          variables: {
            enrollmentId: configOverrides?.enrollmentId as string,
            patch: { type, config: defaultFields.config },
          },
        });
        history.push(
          `${typeToPathPrefixMap[type]}?step=1&id=${
            res.data?.createAuthorizationFromEnrollment?.id || ""
          }`
        );
      } else {
        const res = await createAuthorization({ variables: { defaultFields } });
        history.push(
          `${typeToPathPrefixMap[type]}?step=1&id=${
            res.data?.createAuthorization?.id || ""
          }`
        );
      }
    } catch (e) {
      alert.error("There was an error creating a new Authorization");
    }
  };

  const handleEdiAuthCreate = () => {
    history.push(ROUTE_PATHS.EDI_AUTH_CREATE.path);
  };

  if (account == null) return null;

  const authorizationTypes = _.compact([
    {
      key: "newFormAuth",
      text: "Form",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.FORM.key,
      description:
        "An insurance authorization form is electronically filled and faxed to the payer",
    },
    !_.isEmpty(account?.institution?.ReferralForms) && {
      key: "newReferralAuth",
      text: "Referral",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.REFERRAL.key,
      description: undefined,
    },
    {
      key: "newWebAuth",
      text: "Web Portal",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
      description: "The payer uses an online portal to submit authorizations",
      onClick: () => setPortalSelectionOpen(true),
    },
    {
      key: "newExternalAuth",
      text: "External",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key,
      description: `Track an authorization you submitted outside of ${brandName}`,
    },
    account?.institution?.featureFlags?.MultiPartyAuthorization && {
      key: "newMultiAuth",
      text: "Multi Authorization",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key,
      description:
        "The authorization must be faxed to a third-party for completion before it goes to the payer",
    },
    isEdiAuthEnabled && {
      key: "newEdiAuth",
      text: "ePA",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.EDI.key,
      description: "Submit authorizations directly to the payer electronically",
      onClick: handleEdiAuthCreate,
    },
  ]) as {
    key: string;
    text: string;
    type: string;
    description?: string;
    onClick?: () => void;
  }[];

  return (
    <div data-cy="controlNewAuthorization">
      {account?.institution?.featureFlags?.Novartis ? (
        <div
          onClick={async () =>
            doCreate({
              type: config.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key,
              onClickConfigOverride: {
                // Hack which allows us to support Novartis authorizations as pharmacy benefit authorizations
                [config.DEFAULT_FIELDS.REQUEST_TYPE.key]: "Pharmacy Request",

                // We should not be setting requested by details for multiparty authorizations
                [config.DEFAULT_FIELDS.REQUESTED_BY_FIRST_NAME.key]: null,
                [config.DEFAULT_FIELDS.REQUESTED_BY_LAST_NAME.key]: null,
                [config.DEFAULT_FIELDS.REQUESTED_BY.key]: null,
                [config.DEFAULT_FIELDS.REQUESTED_BY_PHONE.key]: null,
                [config.DEFAULT_FIELDS.REQUESTED_BY_EXTENSION.key]: null,
                [config.DEFAULT_FIELDS.EMAIL_NOTIFICATION_TYPE.key]: null,
                [config.DEFAULT_FIELDS.DEFAULT_CHECKMARK_TRUE.key]: null,
              },
            })
          }
        >
          {children}
        </div>
      ) : (
        <MenuButton
          color={colors.green}
          menuButtons={authorizationTypes.map(
            ({ key, text, type, description, onClick }) => (
              <Tooltip placement="left" key={key} title={description}>
                <MenuItem
                  data-cy={_.camelCase(`action_${key}`)}
                  onClick={onClick || (async () => doCreate({ type }))}
                >
                  {text}
                </MenuItem>
              </Tooltip>
            )
          )}
          buttonText="New Prior Authorization"
        >
          {children}
        </MenuButton>
      )}
      {portalSelectionOpen && (
        <PortalSelectionModal
          open={portalSelectionOpen}
          institution={account.institution}
          closeModal={() => {
            setPortalSelectionOpen(false);
          }}
          onSelect={async (portal: {
            key: string;
            insuranceCompanyName: string;
          }) =>
            doCreate({
              type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
              portal,
            })
          }
        />
      )}
    </div>
  );
};

/**
 * @deprecated Reorganize me?
 */
export default NewPriorAuthButton;
