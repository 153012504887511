import _ from "lodash";
import gql from "graphql-tag";
import { PureComponent } from "react";
import { Query } from "@apollo/client/react/components";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import styled from "styled-components";
import Select from "react-select";

import AlertModal from "../../../components/AlertModal";
import { InstitutionView } from "./InstitutionView";
import BaseButton from "../../../components/BaseButton";
import {
  withCreateInstitution,
  SUPPORT_INSTITUTION_QUERY,
} from "../../../graphql/Institution";
import { Button, Typography } from "@samacare/design/core";

const withInstitutionsQuery = gql`
  query ${SUPPORT_INSTITUTION_QUERY} {
    institutionsFindAll {
      id
      name
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
`;

const FilterContainer = styled.div`
  height: 100px;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  height: 100%;
  margin-top: 20px;
`;

const ViewContainer = styled.div`
  ${BaseButton} {
    margin-left: auto;
  }
`;

const OneLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 900px;
  > div {
    width: 360px;
  }
`;

class SupportInstitutions extends PureComponent {
  state = { selectedInstitution: {}, alertModalOpen: false };

  createInstitution = async () => {
    const { createInstitution, alert } = this.props;

    try {
      const res = await createInstitution();
      this.setState({
        selectedInstitution: res.data.createInstitution,
        alertModalOpen: false,
      });
    } catch (e) {
      alert.error(`ERROR!!! ${e}`);
    }
  };

  render() {
    const { alertModalOpen, selectedInstitution } = this.state;

    return (
      <Query query={withInstitutionsQuery}>
        {({ loading, data }) => {
          if (loading) {
            return <div>Loading</div>;
          }
          const institutions = data.institutionsFindAll;
          return (
            <MainContainer>
              <FilterContainer>
                <Typography variant="h6" mb={2}>
                  Institutions
                </Typography>
                <OneLine>
                  <Select
                    styles={{
                      menuList: (styles) => ({
                        ...styles,
                        background: "white",
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 100,
                      }),
                      option: (styles) => ({
                        ...styles,
                        zIndex: 1000,
                      }),
                    }}
                    id="controlInstitutionsSelectInstitution"
                    onChange={(selected) => {
                      this.setState({ selectedInstitution: selected || {} });
                    }}
                    getOptionLabel={(item) => item?.name}
                    getOptionValue={(item) => item?.id}
                    isSearchable
                    isClearable
                    options={institutions}
                    value={selectedInstitution}
                  />
                  <Button
                    sx={{ marginLeft: "16px" }}
                    variant="outlined"
                    onClick={() => {
                      this.setState({ alertModalOpen: true });
                    }}
                  >
                    New Institution
                  </Button>
                </OneLine>
              </FilterContainer>
              <ContentContainer>
                <ViewContainer>
                  {
                    // Need to use _.find because selectedInstitution can be stale
                    // Mapping a single item array with a dynamic key in order to force rerender
                    _.find(institutions, { id: selectedInstitution.id }) && (
                      <div
                        key={`selectedInstitution-id-${selectedInstitution.id}`}
                      >
                        <InstitutionView
                          institutionId={selectedInstitution.id}
                        />
                      </div>
                    )
                  }
                </ViewContainer>
              </ContentContainer>
              <AlertModal
                header="ARE YOU SURE?!"
                open={alertModalOpen}
                closeModal={() => {
                  this.setState({ alertModalOpen: false });
                }}
                content="Are you sure you want to create an institution?"
                buttons={
                  <BaseButton
                    style={{ width: "100%" }}
                    key="createInstitutionButton"
                    onClick={this.createInstitution}
                  >
                    DO IT!!!
                  </BaseButton>
                }
              />
            </MainContainer>
          );
        }}
      </Query>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withCreateInstitution)(withAlert()(SupportInstitutions));
