import * as React from "react";
import _ from "lodash";
import Section, { Items } from "AuthorizationSharedComponents/Section";
import LocationSelector from "./LocationSelector";
import { transformSections } from "../../../../util/sectionConfigurationManager";
import CreatePrescriberOrLocation from "../CreatePrescriberOrLocation";
import { Account, Location, Authorization } from "@samacare/graphql";
import { useConfig } from "../../../../hooks/config/index";
import { ProviderType } from "../types/types";
import { FetchResult } from "@apollo/client";

const LocationDetails: React.VFC<{
  providerType: ProviderType;
  authorization: Authorization;
  locationId?: string;
  disabled: boolean;
  set: () => Promise<void>;
  trackUpsert: () => void;
  upsertPrescriberOrLocation: () =>
    | Promise<FetchResult<{ [upsertPrescriber: string]: Account }>>
    | Promise<FetchResult<{ [upsertLocation: string]: Location }>>;
}> = ({
  providerType = "prescriber",
  set,
  authorization,
  locationId,
  disabled,
  trackUpsert,
  upsertPrescriberOrLocation,
}) => {
  const config = useConfig();
  const { DEFAULT_FIELDS } = config;
  const { PRESCRIBER, REFERRING_PROVIDER } = config.CONSTANTS.PRESCRIBER_TYPES;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectorLocationId: number =
    // @ts-ignore
    locationId ?? authorization?.formDetails?.locationId;

  const getLocationFields = (): Items => {
    switch (providerType) {
      case REFERRING_PROVIDER:
        return [
          {
            ...DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME,
            required: false,
          },
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS, required: false },
          [
            { ...DEFAULT_FIELDS.REFERRING_PROVIDER_CITY, required: false },
            { ...DEFAULT_FIELDS.REFERRING_PROVIDER_STATE, required: false },
            { ...DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP, required: false },
          ],
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_TIN, required: false },
        ];
      default:
        return [
          { ...DEFAULT_FIELDS.LOCATION_NAME, required: true },
          { ...DEFAULT_FIELDS.FACILITY_NAME, required: true },
          DEFAULT_FIELDS.PRESCRIBER_ADDRESS,
          [
            DEFAULT_FIELDS.PRESCRIBER_CITY,
            DEFAULT_FIELDS.PRESCRIBER_STATE,
            DEFAULT_FIELDS.PRESCRIBER_ZIP,
          ],
          [
            { ...DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN, required: false },
            { ...DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI, required: true },
          ],
        ];
    }
  };

  const getSectionItems = (): Items => {
    switch (providerType) {
      case REFERRING_PROVIDER:
        return [
          {
            ...DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME,
            required: false,
          },
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS, required: false },
          [
            { ...DEFAULT_FIELDS.REFERRING_PROVIDER_CITY, required: false },
            { ...DEFAULT_FIELDS.REFERRING_PROVIDER_STATE, required: false },
            { ...DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP, required: false },
          ],
          {
            ...DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_TIN,
            required: false,
          },
        ];
      default:
        return [
          { ...DEFAULT_FIELDS.FACILITY_NAME, required: true },
          DEFAULT_FIELDS.PRESCRIBER_ADDRESS,
          [
            DEFAULT_FIELDS.PRESCRIBER_CITY,
            DEFAULT_FIELDS.PRESCRIBER_STATE,
            DEFAULT_FIELDS.PRESCRIBER_ZIP,
          ],
          [
            DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN,
            DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI,
          ],
        ];
    }
  };

  return (
    <div>
      <Section
        section={_.first(
          transformSections(
            [
              {
                items: getSectionItems(),
                title: "Location Details",
              },
            ],
            authorization.formFieldConfig
          )
        )}
        disabled={disabled}
      >
        <div style={{ display: "flex", alignItems: "end" }}>
          <LocationSelector
            authorizationId={authorization.id}
            locationId={selectorLocationId}
            disabled={disabled}
            trackUpsert={trackUpsert}
            set={set}
            providerType={providerType}
          />
          {providerType === PRESCRIBER && (
            <CreatePrescriberOrLocation
              isPrescriber={false}
              fields={getLocationFields()}
              trackUpsert={trackUpsert}
              upsertPrescriberOrLocation={upsertPrescriberOrLocation}
            />
          )}
        </div>
      </Section>
    </div>
  );
};

export default LocationDetails;
