import gql from "graphql-tag";

export default gql`
  fragment authorizationFormInfo on AuthorizationForm {
    updatedAt
    id
    pdfURL
    title
    description
    specification
    imgURLs
    imgKeys
    taggedWidth
    taggedHeight
    hasTaggedSignature
    isHiddenInstitutionIds
    type
    isArchived
    formFilter {
      jCodes
      NDCs
    }
    approvalRate
    approvalCount
    tags
  }
`;
