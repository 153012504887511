import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { setLocale } from "yup";

import { Form } from "@@ui-kit/forms";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, LoadingButton, Stack } from "@samacare/design/core";
import {
  AddressBlock,
  AutocompleteField,
  DateField,
  FormControlLabelField,
  HcpcsField,
  IcdField,
  InsuranceBlock,
  OfficeBlock,
  PatientBlock,
  PatientInformationBlock,
  PrescriberBlock,
} from "@samacare/form";
import { PatientStatusType, UnifiedBenefitsCheckType } from "@samacare/graphql";

import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { useConfig } from "../../../hooks/config/index";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import {
  buildBenefitVerificationRequestFormSchema,
  mapPatientStatusToHumanizeText,
} from "../formUtils";
import { RequestBenefitVerificationFormDataType } from "../types";
import { BenefitsCheckOptionsField } from "./BenefitsCheckOptionsField";
import useInsuranceCompanyOptions from "@samacare/form/hooks/useInsuranceCompanyOptions";
import { isMedicare } from "../../../util/isMedicare";
import { useFocusFirstError } from "../../../hooks/useFocusFirstError";

type BenefitsVerificationRequestFormProps = {
  canChangePatient?: boolean;
  editable?: boolean;
  defaultValues: RequestBenefitVerificationFormDataType;
  onSubmit: (data: RequestBenefitVerificationFormDataType) => void;
  onBack: () => void;
};

export const BenefitsVerificationRequestFormFullGeneric: React.VoidFunctionComponent<
  BenefitsVerificationRequestFormProps
> = ({
  editable = true,
  canChangePatient = true,
  defaultValues,
  onSubmit,
  onBack,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const config = useConfig();
  const { insuranceCompanies, loading: insuranceCompaniesLoading } =
    useInsuranceCompanyOptions();

  // init bv request form
  const schema = buildBenefitVerificationRequestFormSchema(
    config,
    insuranceCompanies
  );
  const form = useForm<RequestBenefitVerificationFormDataType>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  setLocale({
    mixed: {
      required: "This field is required",
    },
  });

  const {
    trigger,
    handleSubmit,
    clearErrors,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = form;
  useFocusFirstError(errors);

  const insuranceCompanyId = watch("primaryInsurance.InsuranceCompanyId");
  const insuranceType = watch("primaryInsurance.planType");
  const benefitsCheckType = watch("benefitsCheckType");

  const canSubmit =
    benefitsCheckType == null ||
    benefitsCheckType === UnifiedBenefitsCheckType.Full;

  const medicareSelected =
    !insuranceCompaniesLoading &&
    insuranceCompanyId != null &&
    insuranceCompanies?.some(
      (company) =>
        company?.id === insuranceCompanyId && isMedicare(company?.label)
    );

  const showPtan =
    medicareSelected ||
    insuranceType === config.CONSTANTS.INSURANCE_TYPES.IS_MEDICARE_ADVANTAGE;

  return (
    <Form
      context={form}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <EnrollmentSection title="Patient">
        <PatientBlock
          required
          disabled={!canChangePatient || !editable}
          onSelected={async (patientId, patient) => {
            setValue("primaryInsurance", { ...patient.primaryInsurance! });
            setValue("secondaryInsurance", {
              ...patient.secondaryInsurance!,
            });

            await trigger();
            clearErrors();
          }}
          onClear={() =>
            reset({
              ...form.getValues(),
              primaryInsuranceCompanyPhone: null,
              secondaryInsuranceCompanyPhone: null,
              primaryInsurance: {
                memberId: null,
              },
              secondaryInsurance: {
                memberId: null,
              },
            })
          }
        />
      </EnrollmentSection>
      <EnrollmentSection title="Patient Information">
        <Stack spacing={2} direction="column">
          <AddressBlock disabled={!editable} required />
          <PatientInformationBlock disabled={!editable} required />
        </Stack>
      </EnrollmentSection>
      <EnrollmentSection title="Service Details">
        <Stack spacing={2} maxWidth={600}>
          <BenefitsCheckOptionsField
            typeFieldName="benefitsCheckType"
            drugOptionIdFieldName="DrugOptionId"
            disabled
            disableLiteOption
          />
          <>
            <HcpcsField name="hcpcsCodes" disabled={!editable} />
            <IcdField name="icds" required disabled={!editable} />
            <AutocompleteField<string, false, true, false>
              disableClearable
              getOptionLabel={(status) =>
                mapPatientStatusToHumanizeText(status)
              }
              options={[
                PatientStatusType.ContinuingTherapy,
                PatientStatusType.InitiatingTherapy,
              ]}
              label="Patient Status"
              name="patientStatus"
              required
              disabled={!editable}
              value={watch("patientStatus") ?? ""}
            />
            <DateField
              name="prescribedDate"
              label="Prescribed Date"
              required={true}
              disabled={!editable}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </>
        </Stack>
      </EnrollmentSection>
      <EnrollmentSection title="Primary Insurance Information">
        <InsuranceBlock
          usePhone
          disabled={!editable}
          InsuranceTypeInputProps={{
            name: "primaryInsurance.planType",
          }}
          InsuranceMemberIdInputProps={{
            name: "primaryInsurance.memberId",
          }}
          InsuranceStateInputProps={{
            name: "primaryInsurance.insuranceState",
          }}
          InsuranceCompanyInputProps={{
            name: "primaryInsurance.InsuranceCompanyId",
          }}
          PhoneInputProps={{
            name: "primaryInsuranceCompanyPhone",
            required: false,
          }}
          required
        />
      </EnrollmentSection>

      <EnrollmentSection title="Secondary Insurance Information">
        <InsuranceBlock
          usePhone
          disabled={!editable}
          InsuranceTypeInputProps={{
            name: "secondaryInsurance.planType",
          }}
          InsuranceMemberIdInputProps={{
            name: "secondaryInsurance.memberId",
          }}
          InsuranceStateInputProps={{
            name: "secondaryInsurance.insuranceState",
          }}
          InsuranceCompanyInputProps={{
            name: "secondaryInsurance.InsuranceCompanyId",
          }}
          PhoneInputProps={{
            name: "secondaryInsuranceCompanyPhone",
          }}
        />
      </EnrollmentSection>

      <EnrollmentSection title="Provider Details">
        <PrescriberBlock
          required
          disabled={!editable}
          allowNew={false}
          PTANInputProps={{ disabled: !editable, name: "prescriberPTAN" }}
          SpecialtyDescriptionInputProps={{
            name: "prescriber.specialtyDescription",
            label: "SpecialtyDescription",
            required: false,
          }}
          showPtan={showPtan}
        />
        <FormControlLabelField
          name="prescriberInNetwork"
          label="Provider In-network?"
          control={<Checkbox disabled={!editable} />}
        />
      </EnrollmentSection>

      <EnrollmentSection title="Location Details">
        <OfficeBlock
          required
          disabled={!editable}
          allowNew={false}
          PTANInputProps={{ disabled: !editable, name: "locationPTAN" }}
          showPtan={showPtan}
        />
      </EnrollmentSection>

      <Stack
        direction="row"
        sx={{ justifyContent: "space-between" }}
        marginTop={1}
      >
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          loading={insuranceCompaniesLoading}
          variant="contained"
          disabled={!editable || !canSubmit}
          data-cy="actionSubmitNewBenefitsVerification"
          onClick={async () => (await trigger()) && setShowConfirm(true)}
        >
          Submit
        </LoadingButton>
      </Stack>
      <ConfirmationDialog
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={async () => {
          setShowConfirm(false);
          await handleSubmit(onSubmit)();
        }}
      />
    </Form>
  );
};
