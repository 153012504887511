import _ from "lodash";
import { parseISO, isMatch, parse } from "date-fns";

import {
  DateField as MuiDateField,
  DateFieldProps as MuiDateFieldProps,
} from "@samacare/design/core";

import {
  UseControllerProps,
  useFormContext,
  useFormState,
  FieldError,
} from "react-hook-form";

export function getValidDate(
  value: string | Date | undefined | null
): Date | undefined {
  if (value instanceof Date) return value;
  if (!value || value === null) return;

  if (isMatch(value, "yyyy/MM/dd"))
    return parse(value, "yyyy/MM/dd", new Date());

  if (isMatch(value, "yyyy-MM-dd"))
    return parse(value, "yyyy-MM-dd", new Date());

  if (isMatch(value, "MM / dd / yyyy"))
    return parse(value, "MM / dd / yyyy", new Date());

  if (isMatch(value, "MM/dd/yyyy"))
    return parse(value, "MM/dd/yyyy", new Date());

  return parseISO(value);
}

export type DateFieldProps = MuiDateFieldProps<Date> & UseControllerProps;

const DateField: React.FC<DateFieldProps> = ({
  name,
  value,
  rules = {},
  required = false,
  disabled = false,
  shouldUnregister = false,
  ...props
}) => {
  const { register, control, getValues, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const dateValue = getValidDate(getValues(name) as Date | string | undefined);

  const error = _.get(errors, name) as FieldError;

  const { ref, onChange, onBlur, ...fields } = register(name, {
    ...rules,
    required,
    shouldUnregister,
    value: dateValue,
  });

  return (
    <MuiDateField
      inputRef={ref}
      {...fields}
      id={`DateField-${name}`}
      inputProps={{
        "data-cy": _.camelCase(`field.${name}`),
      }}
      onChange={async (option) => setValue(name, option, { shouldDirty: true })}
      helperText={error?.message}
      required={required}
      disabled={disabled}
      format="MM/dd/yyyy"
      value={dateValue}
      //@ts-ignore How do we make this not complain.. can we add the error prop to the @samacare/DateField props?
      error={!!error}
      {...props}
    />
  );
};

export default DateField;
