import { Location } from "@samacare/graphql";
import { formatPhoneNumber } from "../../../components/PDFEditor/InputTypes/PhoneInput";
import { DetailDrawer } from "../_common/DetailDrawer";
import { ViewAddressBlock } from "../_common/ViewAddressBlock";
import { LabelValueTable } from "../_common/LabelValueTable";
import { useDeleteLocation } from "../../../graphql/Location";
import { UpsertLocationDialog } from "./UpsertLocationDialog";
import { useState } from "react";

interface LocationDetailDrawerProps {
  location: Location | null;
  onClose: () => void;
}
export const LocationDetailDrawer: React.FC<LocationDetailDrawerProps> = (
  props
) => {
  const { location } = props;
  const [isEditing, setIsEditing] = useState(false);
  const deleteLocation = useDeleteLocation();

  return (
    <DetailDrawer
      open={!!props.location}
      domain="location"
      id={props.location?.id ? +props.location.id : 0}
      onEdit={() => {
        setIsEditing(true);
      }}
      onDelete={deleteLocation}
      onClose={props.onClose}
      title={location?.name ?? ""}
      subtitleLabel="Facility name"
      subtitle={location?.facilityName}
    >
      <ViewAddressBlock
        address={location?.address}
        city={location?.city}
        state={location?.state}
        zip={location?.zip}
      />

      <LabelValueTable
        labelToValueMap={{
          Fax: location?.fax ? formatPhoneNumber(location?.fax) : undefined,
          NPI: location?.NPI,
          "Tax Id": location?.taxId,
          PTAN: location?.PTAN,
        }}
      />

      <UpsertLocationDialog
        location={isEditing ? location : null}
        onClose={() => setIsEditing(false)}
      />
    </DetailDrawer>
  );
};
