import _ from "lodash";

export const filterNullValue = (obj: {
  [key: string]: undefined | null | string;
}) => {
  return _(obj)
    .omitBy((v) => _.isUndefined(v))
    .omitBy((v) => _.isNull(v))
    .omitBy((value) => value === "")
    .value();
};
