import styled from "styled-components";

const Required = styled.span`
  color: ${(props) => props.theme.red};
  font-weight: 700;
  font-size: 30px;
  line-height: 0px;

  span {
    vertical-align: sub;
  }
`;

const RequiredTag = () => (
  <Required>
    <span>*</span>
  </Required>
);

/**
 * @deprecated Use a MUI component instead
 */
export default RequiredTag;
