import { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { parseISO } from "date-fns";
import { useQuery, useMutation } from "@apollo/client";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import {
  Box,
  Slide,
  Button,
  Switch,
  Typography,
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  Stack,
} from "@samacare/design/core";
import useUpdateNewEnrollmentById from "../../hooks/useUpdateNewEnrollmentById";
import ArchiveIcon from "@samacare/design/core/icons/Archive";
import DeleteIcon from "@samacare/design/core/icons/Delete";

import Enrollment from "../Enrollment";
import EnrollmentQuery from "./EnrollmentsQuery.gql";
import deleteDraftEnrollmentMutation from "./EnrollmentDeleteMutation.gql";
import { useGridFilterUrlSync } from "../BenefitsVerifications/gridHooks";
import { NewEnrollmentStatusEnumType } from "@samacare/graphql";

import {
  EnrollmentsQuery,
  DeleteDraftEnrollmentMutation as DeleteDraftEnrollmentMutationGraphQlType,
} from "@@generated/graphql";
import GridQuickFilter from "../../components/DataGrid/GridQuickFilter";
import ROUTES from "../ROUTE_PATHS";
import { EnrollmentRoutes } from "../Enrollment/Enrollment.types";
import {
  StatusChip,
  StatusOption,
} from "../BenefitsVerifications/components/StatusChip";
import NewPriorAuthButton from "@@components/NewPriorAuthButton";
import GridWrapper from "../../components/DataGrid/GridWrapper";

type EnrollmentsGridRow = {
  id: number;
  firstName: string;
  lastName: string;
  state: string;
  status: NewEnrollmentStatusEnumType;
  dob: string;
  drugName: string;
  updatedAt: Date;
  createdAt: Date;
  program: string;
  patientId: string;
  isArchived: boolean;
};

const EnrollmentToolBar = ({
  showArchived,
  updateShowArchived,
}: {
  showArchived: boolean;
  updateShowArchived: () => void;
}) => {
  const history = useHistory();

  return (
    <Box
      sx={{
        justifyContent: "flex-end",
        display: "flex",
        p: 1,
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          left: 0,
          marginLeft: 2,
        }}
      >
        <GridQuickFilter />
      </Stack>
      <Typography variant="body">Show Archived</Typography>
      <Switch
        name="showArchived"
        checked={showArchived}
        onClick={updateShowArchived}
      />
      <Button
        data-cy="actionNewEnrollment"
        size="small"
        variant="contained"
        onClick={() => {
          history.push(
            `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.program}`
          );
        }}
      >
        New
      </Button>
    </Box>
  );
};

export const Enrollments: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { data, loading } = useQuery<EnrollmentsQuery>(EnrollmentQuery);
  const [update, { loading: updateErnollmentLoading }] =
    useUpdateNewEnrollmentById();

  const [showArchived, setShowArchived] = useState<boolean>(false);
  const { filterModel, setFilterModel } = useGridFilterUrlSync(true);

  const [deleteDraftEnrollment, { loading: deleteEnrollmentLoading }] =
    useMutation<DeleteDraftEnrollmentMutationGraphQlType>(
      deleteDraftEnrollmentMutation,
      {
        refetchQueries: () => ["Enrollments"],
      }
    );

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", minWidth: 100 },
    {
      field: "firstName",
      headerName: "First name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "lastName",
      headerName: "Last name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "dob",
      headerName: "Date of birth",
      editable: false,
      minWidth: 180,
    },
    {
      field: "state",
      headerName: "Status",
      editable: false,
      minWidth: 180,
      renderCell: (params: { row: EnrollmentsGridRow }) => (
        <StatusChip status={_.lowerCase(params.row.state) as StatusOption} />
      ),
    },
    {
      field: "program",
      headerName: "Program",
      editable: false,
      minWidth: 180,
    },
    {
      field: "drugName",
      headerName: "Drug",
      editable: false,
      minWidth: 180,
    },
    {
      field: "authRequired",
      headerName: "Add Auth",
      width: 150,
      renderCell: (params: { row: EnrollmentsGridRow }) => {
        return (
          params.row.status === NewEnrollmentStatusEnumType.Submitted && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <NewPriorAuthButton
                patientId={params.row.patientId}
                configOverrides={{
                  enrollmentId: params.row.id,
                }}
              >
                <Button variant="contained" size="small">
                  Add
                </Button>
              </NewPriorAuthButton>
            </Box>
          )
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Last updated",
      type: "dateTime",
      editable: false,
      minWidth: 180,
      valueFormatter: (params: { value: Date }) =>
        moment(params?.value).format("MM/DD/YYYY"),
    },
    {
      field: "createdAt",
      headerName: "Created",
      type: "dateTime",
      editable: false,
      minWidth: 180,
      valueFormatter: (params: { value: Date }) =>
        moment(params?.value).format("MM/DD/YYYY"),
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 180,
      getActions: (params) => {
        if (params.row.status !== NewEnrollmentStatusEnumType.Draft) {
          return [
            <GridActionsCellItem
              key="GridActionsCellItem"
              icon={<ArchiveIcon />}
              label="Archive"
              onClick={async () => {
                await update({
                  variables: {
                    id: Number(params.id),
                    patch: {
                      isArchived: true,
                    },
                  },
                });
              }}
              showInMenu
            />,
          ];
        }
        return [
          <GridActionsCellItem
            key="GridActionsCellItem"
            icon={<DeleteIcon />}
            label="Delete"
            onClick={async () => {
              await deleteDraftEnrollment({
                variables: {
                  enrollmentId: Number(params.id),
                },
              });
            }}
            showInMenu
          />,
        ];
      },
    },
  ];

  const enrollments: EnrollmentsGridRow[] = (
    data?.getEnrollments || []
  ).map<EnrollmentsGridRow>((item) => {
    return {
      id: parseInt(item?.id ?? ""),
      state: item?.state,
      status: item?.status,
      updatedAt: parseISO(item?.updatedAt as string),
      createdAt: parseISO(item?.createdAt as string),
      lastName: item?.MedicationRequest?.patient?.lastName as string,
      firstName: item?.MedicationRequest?.patient?.firstName as string,
      dob: item?.MedicationRequest?.patient?.dob as string,
      drugName: _.capitalize(item?.EnrollmentProgram?.drugName as string),
      program: _.capitalize(item?.EnrollmentProgram?.channel as string),
      patientId: item?.MedicationRequest?.PatientId as string,
      isArchived: item?.isArchived,
    } as EnrollmentsGridRow;
  });

  const unArchivedEnrollments = enrollments.filter((item) => {
    return !item?.isArchived;
  });

  return (
    <GridWrapper headerText="Enrollments">
      <DataGridPro
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        loading={loading || updateErnollmentLoading || deleteEnrollmentLoading}
        rows={showArchived ? enrollments : unArchivedEnrollments}
        onRowClick={(params: GridRowParams) => {
          const { status } = params.row as EnrollmentsGridRow;
          if (status === NewEnrollmentStatusEnumType.Draft) {
            return history.push(
              `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.details}/${params.id}`
            );
          }
          return history.push(
            `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.summary}/${params.id}`
          );
        }}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        slots={{
          toolbar: EnrollmentToolBar,
        }}
        slotProps={{
          toolbar: {
            updateShowArchived: () => setShowArchived(!showArchived),
            showArchived,
          },
        }}
      />

      <Route path={`${match.path}/create/:view?/:enrollmentId?`}>
        <Slide in direction="left">
          <Box
            bgcolor="white"
            width="100%"
            height="100%"
            position="absolute"
            top="0"
            left="0"
            p={2}
          >
            <Enrollment />
          </Box>
        </Slide>
      </Route>
    </GridWrapper>
  );
};
