import { Component } from "react";
import styled from "styled-components";
import _ from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { BaseText } from "Segment/StyledComponents";
import { withAlert } from "react-alert";

import hcpcsModifiers from "./hcpcsModifiers";
import BaseButton from "./BaseButton";
import colors from "../../resources/colors";
import { PrimaryDrugSelector } from "../routes/PortalAuthorization/PortalAuthorizationLegacy/PrimaryDrugSelector";
import {
  FilterFormBenefitType,
  InsuranceCompanySupportedCodingTypes,
} from "@samacare/graphql";
import { NdcSelector } from "./NdcSelector";
import { connect } from "react-redux";
import { setFormFields } from "../reducers/formReducer";

const df = window.CONFIG.DEFAULT_FIELDS;

const TableContainer = styled.div`
  th {
    font-weight: 400;
  }
  ${BaseText} {
    margin-right: 10px;
  }
`;

const AddRowText = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.purple};
  text-decoration: underline;
  margin: 10px 0;
`;

const Table = styled.table`
  margin-bottom: 10px;
`;

const HCPCS_CODE_KEY = "code";
export const newValue = {
  [HCPCS_CODE_KEY]: "",
  quantity: "",
  modifier: "",
  unit: "",
};
export class HcpcsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: _.isEmpty(props.initializedHCPCSCodes)
        ? [{ ...newValue }]
        : props.initializedHCPCSCodes,
    };
    if (props.setAddHCPCSFromParent) {
      props.setAddHCPCSFromParent(this.addHCPCSFromParent);
    }
  }

  // for BV components using the 'value' prop, reset the rendered options
  // when those options are cleared by the parent form compoent
  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    const { results } = this.state;

    if (
      !_.isEmpty(prevState.results) &&
      prevState.results[0][HCPCS_CODE_KEY] !== "" &&
      !_.isEmpty(results) &&
      results[0][HCPCS_CODE_KEY] !== "" &&
      value === null
    ) {
      this.setState((state) => {
        return { ...state, results: [{ ...newValue }] };
      });
    }
  }

  updateResults = (newResults) => {
    const { onChange, alert } = this.props;

    const withoutBlankCodes = _.compact(_.map(newResults, "code"));
    const hasDuplicates = !_.isEqual(
      _.uniq(withoutBlankCodes),
      withoutBlankCodes
    );

    if (hasDuplicates) {
      alert.error("Duplicate HCPCS codes are not allowed");
    } else {
      this.setState({ results: newResults }, () => onChange(newResults));
    }
  };

  addHCPCSFromParent = ({ code = "", quantity = "", modifier = "" }) => {
    const { results } = this.state;

    this.updateResults(
      results.concat({ [HCPCS_CODE_KEY]: code, quantity, modifier })
    );
  };

  updateRow = (index, key, updatedValue) => {
    const { results } = this.state;
    const newResults = _.map(results, (value, i) => {
      if (index === i) {
        return { ...value, [key]: (updatedValue || "").toString() };
      }
      return value;
    });

    this.updateResults(newResults);
  };

  updateManyFieldsInRow = (index, updates) => {
    const newResults = _.map(this.state.results, (value, i) => {
      if (index === i) {
        const updatedRaw = { ...value };

        for (const key in updates) {
          updatedRaw[key] = (updates[key] || "").toString();
        }

        return updatedRaw;
      }
      return value;
    });

    this.updateResults(newResults);
  };

  removeRow = (index) => {
    const { onChange } = this.props;
    const { results } = this.state;

    const updatedResults = results
      .slice(0, index)
      .concat(results.slice(index + 1, results.length));
    this.setState({ results: updatedResults }, () => {
      onChange(updatedResults);
    });
  };

  handleNdcSelect = (ndcObj) => {
    const { set } = this.props;
    if (ndcObj != null) {
      set({
        [df.PRIMARY_DRUG_NAME.key]: ndcObj.brandName,
        [df.PRIMARY_NDC.key]: ndcObj.code,
        [df.BENEFIT_TYPES.key]: FilterFormBenefitType.PharmacyOnly,
        [df.DRUG_CODE_TYPE.key]: InsuranceCompanySupportedCodingTypes.Ndc,
        [df.REQUEST_DESCRIPTION.key]: ndcObj.brandName.toUpperCase(),
      });
    }
  };

  render() {
    const {
      max,
      units,
      disabled,
      hideModifiers,
      hideQuantity,
      modifiersOverride,
      highlightRequiredFields,
      maxQuantity,
      styleOverride,
      auth,
      firstColumnHeaderStyle,
      configResults,
    } = this.props;
    const { results } = this.state;

    const isShowNdc =
      configResults != null &&
      configResults[df.DRUG_CODE_TYPE.key] ===
        InsuranceCompanySupportedCodingTypes.Ndc;
    const isHideQuantity = hideQuantity || isShowNdc;

    const exceedsMaxQuantity = (value, maxQ) => {
      return maxQ && value > maxQ;
    };

    const ensurePositiveValue = (value) => {
      const parsedValue = parseInt(value);
      if (parsedValue === 0 || Number.isNaN(parsedValue)) return "1";
      return parseInt(value) < 0 ? Math.abs(parseInt(value)).toString() : value;
    };

    return (
      <TableContainer style={styleOverride?.container}>
        <Table style={styleOverride?.table}>
          <thead>
            <tr>
              <th style={firstColumnHeaderStyle}>
                {isShowNdc ? "NDC" : "HCPCS Code"}
              </th>
              {!isHideQuantity && (
                <th>{`Quantity ${
                  maxQuantity ? `(Max ${maxQuantity})` : ""
                }`}</th>
              )}
              {!_.isEmpty(units) && <th>Units</th>}
              {!hideModifiers && <th>Modifier</th>}
            </tr>
          </thead>
          <tbody>
            {_.map(results, (row, i) => (
              <tr key={`HCPCS_row_${i}`}>
                <td style={{ width: 400, ...styleOverride?.container }}>
                  {i === 0 && isShowNdc && auth ? (
                    <NdcSelector
                      onSelect={this.handleNdcSelect}
                      controlStyle={{ height: "46px" }}
                    />
                  ) : (
                    <PrimaryDrugSelector
                      auth={auth}
                      isPrimary={i === 0}
                      value={configResults[df[`HCPCS_${i}`].key]}
                      highlight={
                        highlightRequiredFields && !row[HCPCS_CODE_KEY]
                      }
                      onChange={(hcpcsCode) => {
                        this.updateManyFieldsInRow(i, {
                          [HCPCS_CODE_KEY]: hcpcsCode.toUpperCase(),
                          quantity: "1",
                        });
                      }}
                    />
                  )}
                </td>
                {!isHideQuantity && (
                  <td style={{ width: 100 }}>
                    <BaseText
                      data-cy="fieldHcpcsCodeQuantity"
                      type="number"
                      min="1"
                      value={row.quantity || ""}
                      onChange={({ target: { value } }) => {
                        if (!exceedsMaxQuantity(value, maxQuantity)) {
                          this.updateRow(
                            i,
                            "quantity",
                            ensurePositiveValue(value)
                          );
                        }
                      }}
                      highlight={highlightRequiredFields && !row.quantity}
                    />
                  </td>
                )}
                {!_.isEmpty(units) && (
                  <td style={{ width: 150 }}>
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: "46px",
                          ...styleOverride?.selector,
                          border:
                            highlightRequiredFields && !row.unit
                              ? `2px solid ${colors.red}`
                              : `${
                                  styleOverride?.selector?.border ??
                                  "1px solid #b0b0b0"
                                }`,
                        }),
                      }}
                      options={units}
                      onChange={(selected) => {
                        this.updateRow(
                          i,
                          "unit",
                          _.get(selected, "value") || ""
                        );
                      }}
                      value={units.find((unit) => unit.value === row.unit)}
                      isSearchable
                      isClearable
                    />
                  </td>
                )}
                {!hideModifiers && (
                  <td style={{ width: 200 }}>
                    <Creatable
                      style={{ maxWidth: 200, height: 40 }}
                      isSearchable
                      isClearable
                      createOptionPosition="first"
                      options={_.map(
                        modifiersOverride || hcpcsModifiers,
                        (modifier) => ({
                          label: `${
                            modifier.code ? `${modifier.code} :` : ""
                          } ${modifier.description}`,
                          value: modifier.code,
                        })
                      )}
                      filterOptions={(options, filterString) => {
                        const filterByCode = _.filter(options, (option) =>
                          option.value
                            .toLowerCase()
                            .includes(filterString.toLowerCase())
                        );

                        if (filterByCode.length >= 5) {
                          return filterByCode.slice(0, 5);
                        }

                        const filterByDescription = _.filter(
                          options,
                          (option) =>
                            option.label
                              .toLowerCase()
                              .includes(filterString.toLowerCase())
                        );
                        return _.uniq(
                          filterByCode.concat(filterByDescription)
                        ).slice(0, 5);
                      }}
                      onChange={(selected) => {
                        this.updateRow(
                          i,
                          "modifier",
                          _.get(selected, "value", "").toUpperCase()
                        );
                      }}
                      value={
                        row.modifier
                          ? { label: row.modifier, value: row.modifier }
                          : ""
                      }
                    />
                  </td>
                )}
                <td>
                  {i === 0 && styleOverride?.button && (
                    <div style={styleOverride?.button} />
                  )}
                  {i > 0 && (
                    <BaseButton
                      style={styleOverride?.button}
                      onClick={() => {
                        this.removeRow(i);
                      }}
                    >
                      Remove Row
                    </BaseButton>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!max || (results && results.length < max) || disabled) && (
          <AddRowText
            onClick={() => {
              this.setState({ results: results.concat({ ...newValue }) });
            }}
          >
            + Add Row
          </AddRowText>
        )}
      </TableContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    configResults: state.form.results,
  };
}

const mapDispatchToProps = (dispatch) => ({
  set(fields) {
    dispatch(setFormFields(fields));
  },
});

/**
 * @deprecated Use HCPSField instead
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withAlert()(HcpcsSelector)));
