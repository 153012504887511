import * as React from "react";
import { Tooltip } from "@@ui-kit";
import styled from "styled-components";

import BaseButton from "./BaseButton";

export const IconButtonStyled = styled(BaseButton)<{
  backgroundColor?: string;
  hoverColor?: string;
  hoverBackgroundColor?: string;
  borderColor?: string;
  color?: string;
}>`
  border-color: ${(props) => props.borderColor ?? props.theme.green};
  background: ${(props) => props.backgroundColor ?? props.theme.green};
  color: ${(props) => props.color ?? "white"};

  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor ?? "white"};
    color: ${(props) => props.hoverColor ?? props.theme.green};
  }
`;

const IconContainer = styled.div`
  margin-right: 10px;
  margin-top: -1px;
`;

export type IconButtonProps = React.ComponentProps<typeof IconButton>;

type TooltipWrapperProps = {
  children: React.ReactNode;
  enabled: boolean;
  text: string;
};

const TooltipWrapper = (props: TooltipWrapperProps) => {
  const { children, enabled, text } = props;

  if (enabled && text) {
    // We wrap in a span so that the tooltip still functions when the button is disabled
    return (
      <Tooltip content={text}>
        <span>{children}</span>
      </Tooltip>
    );
  }
  return <div>{children}</div>;
};

export const IconButton: React.VoidFunctionComponent<{
  id?: string;
  children?: React.ReactNode;
  color?: string;
  hoverColor?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  borderColor?: string;
  disabled?: boolean;
  icon: React.ComponentType;
  onClick?: () => void;
  style?: React.CSSProperties;
  tooltipEnabled?: boolean;
  tooltipText?: string;
}> = ({
  id,
  backgroundColor,
  children,
  color,
  borderColor,
  hoverColor,
  hoverBackgroundColor,
  disabled = false,
  icon,
  onClick = () => {},
  style,
  tooltipEnabled = false,
  tooltipText = "",
}) => {
  const IconComponent = icon;

  return (
    <TooltipWrapper enabled={tooltipEnabled} text={tooltipText}>
      <IconButtonStyled
        id={id}
        backgroundColor={backgroundColor}
        hoverColor={hoverColor ?? backgroundColor}
        hoverBackgroundColor={hoverBackgroundColor ?? color}
        borderColor={borderColor ?? backgroundColor}
        color={color}
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        <IconContainer>
          <IconComponent />
        </IconContainer>
        {children}
      </IconButtonStyled>
    </TooltipWrapper>
  );
};

/**
 * @deprecated Use a MUI component instead
 */
export default IconButton;
