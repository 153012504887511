import _ from "lodash";
import { useEffect, useState } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import Select from "react-select";
import colors from "Resources/colors";
import { Box, Flex, PrimaryButton, SecondaryButton, Text } from "@@ui-kit";
import { Input, Label, Radio } from "@@ui-kit/forms";
import filterInsuranceCompanyOptions from "../../../util/filterInsuranceCompanyOptions";
import {
  withUpsertInsuranceCompany,
  withUpdateInsuranceCompany,
} from "../../../graphql/InsuranceCompany";
import { Pill, CreateNew, SupportEditContainer } from "../SharedStyles";
import { InsuranceCompanySupportedCodingTypes } from "@samacare/graphql";
import ArchivePayer from "./ArchivePayer";

const baseState = {
  createNewPayer: false,
  updateSupportedCodingTypes: InsuranceCompanySupportedCodingTypes.Jcode,
  updateName: "",
  updateParentPlanId: null,
  updateSearchTags: [],
};

export const PayerEdit = ({
  selectedCompany,
  resetSelectedCompany,
  upsertInsuranceCompany,
  alert,
  clearSelectedCompany,
  insuranceCompanies,
  updateInsuranceCompany,
}) => {
  const [createNewPayer, setCreateNewPayer] = useState(false);
  const [updateSupportedCodingTypes, setUpdateSupportedCodingTypes] = useState(
    InsuranceCompanySupportedCodingTypes.Jcode
  );
  const [updateName, setUpdateName] = useState("");
  const [addTag, setAddTag] = useState("");
  const [updateParentPlanId, setUpdateParentPlanId] = useState(null);
  const [updateSearchTags, setUpdateSearchTags] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const setBaseState = () => {
    setCreateNewPayer(baseState.createNewPayer);
    setUpdateName(baseState.updateName);
    setUpdateParentPlanId(baseState.updateParentPlanId);
    setUpdateSearchTags(baseState.updateSearchTags);
    setUpdateSupportedCodingTypes(baseState.updateSupportedCodingTypes);
  };

  const updateSelectedPayer = (selectedCompanyParam) => {
    if (!_.isEmpty(selectedCompanyParam)) {
      setCreateNewPayer(false);
      setUpdateName(selectedCompanyParam.name);
      setUpdateParentPlanId(selectedCompanyParam.planParentCompanyId);
      setUpdateSearchTags([...selectedCompanyParam.searchTags]);
      setUpdateSupportedCodingTypes(selectedCompanyParam.supportedCodingTypes);
    }
  };

  useEffect(() => {
    updateSelectedPayer(selectedCompany);
  }, []);

  useEffect(() => {
    updateSelectedPayer(selectedCompany);
  }, [selectedCompany]);

  const checkForUnsavedChanges = () => {
    if (createNewPayer) {
      return updateName || updateParentPlanId || updateSearchTags.length;
    }
    return (
      updateName !== selectedCompany.name ||
      updateParentPlanId !== selectedCompany.planParentCompanyId ||
      !_.isEqual(
        _.sortBy(updateSearchTags),
        _.sortBy(selectedCompany.searchTags)
      )
    );
  };
  const clearForm = async () => {
    await resetSelectedCompany();
    setShowArchiveModal(false);
  };

  const saveChanges = async () => {
    if (!updateName) {
      alert.error("Please enter a name for this payer");
    } else {
      try {
        await upsertInsuranceCompany({
          variables: {
            ...(createNewPayer ? {} : { id: selectedCompany.id }),
            name: updateName,
            searchTags: updateSearchTags,
            parentId: updateParentPlanId,
            supportedCodingTypes: updateSupportedCodingTypes,
          },
        });
        if (!createNewPayer) {
          resetSelectedCompany();
          alert.info("Successfully updated insurance company");
        } else {
          setBaseState();
          alert.info("Successfully created insurance company");
        }
      } catch (e) {
        alert.error(`Failed to upsert ${e}`);
      }
    }
  };

  const hasPayer = !_.isEmpty(selectedCompany) || createNewPayer;

  if (!hasPayer) {
    return (
      <CreateNew
        data-cy="actionShowCreateNewPayer"
        onClick={() => {
          setBaseState();
          setCreateNewPayer(true);
        }}
      >
        Click to Create a New Insurance Company
      </CreateNew>
    );
  }
  return (
    <SupportEditContainer>
      <Flex flexDirection="column">
        {createNewPayer && (
          <Box
            marginBottom="10px"
            style={{ fontStyle: "italic", textAlign: "center" }}
          >
            <Text>
              Edit New Company Details and Click Save To Create New Record
            </Text>
          </Box>
        )}

        <Box marginBottom="10px">
          <Label>
            Name
            <Input
              data-cy="fieldPayerName"
              onChange={(e) => {
                setUpdateName(e.target.value);
              }}
              value={updateName}
              placeholder="Update Insurance Name"
            />
          </Label>
        </Box>

        <Box marginBottom="10px">
          <Label htmlFor="name">
            Parent Company
            <Select
              isClearable
              filterOption={(company, value) =>
                filterInsuranceCompanyOptions(company.data, value)
              }
              onChange={(selected = {}) => {
                setUpdateParentPlanId(selected?.id ?? null);
              }}
              options={insuranceCompanies}
              placeholder="Add or remove a parent insurance company"
              style={{ width: "100%" }}
              value={_.find(insuranceCompanies, { id: updateParentPlanId })}
              getOptionLabel={(item) => item.name}
              getOptionValue={(item) => item.id}
            />
          </Label>
        </Box>

        <Box marginBottom="10px">
          <Label>
            Supported Coding Types
            <Flex flexDirection="column">
              <Label>
                <Radio
                  checked={
                    updateSupportedCodingTypes ===
                    InsuranceCompanySupportedCodingTypes.Jcode
                  }
                  onChange={() =>
                    setUpdateSupportedCodingTypes(
                      InsuranceCompanySupportedCodingTypes.Jcode
                    )
                  }
                  value={InsuranceCompanySupportedCodingTypes.Jcode}
                />
                J-code
              </Label>
              <Label>
                <Radio
                  checked={
                    updateSupportedCodingTypes ===
                    InsuranceCompanySupportedCodingTypes.Ndc
                  }
                  onChange={() =>
                    setUpdateSupportedCodingTypes(
                      InsuranceCompanySupportedCodingTypes.Ndc
                    )
                  }
                  value={InsuranceCompanySupportedCodingTypes.Ndc}
                />
                NDC
              </Label>
              <Label>
                <Radio
                  checked={
                    updateSupportedCodingTypes ===
                    InsuranceCompanySupportedCodingTypes.NdcAndJcode
                  }
                  onChange={() =>
                    setUpdateSupportedCodingTypes(
                      InsuranceCompanySupportedCodingTypes.NdcAndJcode
                    )
                  }
                  value={InsuranceCompanySupportedCodingTypes.NdcAndJcode}
                />
                NDC + J-code
              </Label>
            </Flex>
          </Label>
        </Box>

        <Box marginBottom="10px">
          <Box>
            <Label htmlFor="search-tags">Search Tags</Label>
          </Box>
          <Flex flexDirection="row">
            <Box marginRight="10px" width={2 / 3}>
              <Input
                data-cy="fieldPayerSearchTag"
                id="search-tags"
                onChange={(e) => {
                  setAddTag(e.target.value);
                }}
                placeholder="Add a New Search Tag"
                value={addTag}
              />
            </Box>
            <Box width={1 / 3}>
              <PrimaryButton
                fluid
                inverted
                data-cy="actionAddSearchTag"
                onClick={() => {
                  if (addTag) setAddTag("");
                  setUpdateSearchTags((prevUpdateSearchTags) =>
                    _.uniq([...prevUpdateSearchTags, addTag])
                  );
                }}
              >
                Add Tag
              </PrimaryButton>
            </Box>
          </Flex>
          {updateSearchTags.length > 0 && (
            <Box paddingTop="12px" paddingBottom="12px">
              {updateSearchTags.map((searchTag) => (
                <Pill
                  onClick={() => {
                    setUpdateSearchTags((prevUpdateSearchTags) => {
                      return _.without(prevUpdateSearchTags, searchTag);
                    });
                  }}
                  key={`pill-${searchTag}`}
                >
                  {`${searchTag} X`}
                </Pill>
              ))}
            </Box>
          )}
        </Box>

        <Box marginBottom="10px">
          <div style={{ color: colors.red, textAlign: "center" }}>
            {checkForUnsavedChanges() ? "Unsaved Changes" : ""}
          </div>
        </Box>

        <Flex flexDirection="row">
          <SecondaryButton
            fluid
            onClick={() => {
              setCreateNewPayer(false);
              clearSelectedCompany();
            }}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            fluid
            onClick={saveChanges}
            style={{ marginRight: "10px" }}
            data-cy="actionCreateNewPayer"
          >
            Save
          </PrimaryButton>
          <PrimaryButton fluid onClick={() => setShowArchiveModal(true)}>
            {selectedCompany.isArchived ? "Unarchive" : "Archive"}
          </PrimaryButton>
        </Flex>
      </Flex>
      {showArchiveModal && (
        <ArchivePayer
          isArchived={selectedCompany.isArchived}
          updateInsuranceCompany={updateInsuranceCompany}
          insuranceCompanyId={selectedCompany.id}
          name={selectedCompany.name}
          clearForm={clearForm}
          back={() => {
            setShowArchiveModal(false);
          }}
        />
      )}
    </SupportEditContainer>
  );
};

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withUpsertInsuranceCompany,
  withUpdateInsuranceCompany
)(withAlert()(PayerEdit));
