import { Box } from "@samacare/design";
import Chip from "@samacare/design/core/Chip";
import Check from "@samacare/design/core/icons/Check";
import { LeftRightCenterV } from "../../../components/LeftRightV2";

interface UnusedColumnsProps {
  unusedHeaders: string[];
}
export const UnusedColumns: React.VFC<UnusedColumnsProps> = ({
  unusedHeaders,
}) => {
  return (
    <Box display="flex" alignItems="center">
      {unusedHeaders.length === 0 ? (
        <LeftRightCenterV>
          <Check />
          <Box marginLeft={({ spacing }) => spacing(1)}>All columns mapped</Box>
        </LeftRightCenterV>
      ) : (
        <>
          <Box sx={{ whiteSpace: "nowrap" }}>Unmapped Columns</Box>
          <LeftRightCenterV
            sx={{
              marginLeft: ({ spacing }) => spacing(2),
              flexWrap: "wrap",
              gap: ({ spacing }) => spacing(1),
            }}
          >
            {unusedHeaders.map((header) => (
              <Chip size="small" key={header} label={header} />
            ))}
          </LeftRightCenterV>
        </>
      )}
    </Box>
  );
};
