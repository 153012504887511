import _ from "lodash";
import {
  Account,
  Authorization,
  InsuranceCompany,
  Mutation,
  MutationCreatePatientOnAuthorizationArgs,
  Query,
} from "@samacare/graphql";
import FormSubmitButtons from "../../../components/AuthorizationSharedComponents/FormSubmitButtons";
import Section from "../../../components/AuthorizationSharedComponents/Section";
import SegmentSelector from "../../../components/Segment/SegmentSelector";
import { TopBottom } from "../../../components/TopBottom";
import { useSelector } from "../../../configureStore";
import { useSet } from "../../../hooks/useSet";
import filterInsuranceCompanyOptions from "../../../util/filterInsuranceCompanyOptions";
import { PatientSection } from "./PatientSection";
import React, { useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { createPatientOnAuthMutation } from "../../../graphql/Patient";
import { insuranceCompaniesQuery } from "../../../graphql/InsuranceCompany";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

const defaultFields = window.CONFIG.DEFAULT_FIELDS;

interface PatientAndInsuranceStepProps {
  auth: Authorization;
  checkAndHandleInvalid: (forceInvalid: boolean) => boolean;
  disabled?: boolean;
  nextStep: (forcedNextStep?: number, patientId?: string) => Promise<void>;
  account: Account;
}
const PatientAndInsuranceStep: React.VFC<PatientAndInsuranceStepProps> = (
  props
) => {
  const set = useSet();
  const results = useSelector((state) => state.form.results);
  const disabled =
    useSelector((state) => state.form.disabled) || props.disabled;

  const { data } = useQuery<Query>(insuranceCompaniesQuery);
  const insuranceCompanies = data?.insuranceCompaniesFindAll ?? [];

  const [createPatientOnAuth] = useMutation<
    Mutation,
    MutationCreatePatientOnAuthorizationArgs
  >(createPatientOnAuthMutation);

  const submit = useCallback(async () => {
    const requiredFieldKeys = [defaultFields.INSURANCE_STATE.key];

    if (
      props.checkAndHandleInvalid(
        requiredFieldKeys.some((key) => !results[key])
      )
    ) {
      return;
    }

    if (!props.auth.patient) {
      const res = await createPatientOnAuth({
        variables: {
          authorizationId: parseInt(props.auth.id),
          patientProps: {
            firstName: (
              results[defaultFields.PATIENT_FIRST_NAME.key] ?? ""
            ).trim(),
            lastName: (
              results[defaultFields.PATIENT_LAST_NAME.key] ?? ""
            ).trim(),
            dob: (results[defaultFields.PATIENT_DOB.key] ?? "").trim(),
          },
        },
      });

      const patientId = res.data?.createPatientOnAuthorization?.patient?.id;

      await props.nextStep(undefined, patientId);
    } else {
      await props.nextStep();
    }
  }, [createPatientOnAuth, props, results]);

  const selectedCompany = _.find(insuranceCompanies, {
    name: results[defaultFields.INSURANCE_COMPANY.key],
  });

  return (
    <TopBottom id="pendo_portalAuth_initializeSection">
      <PatientSection
        auth={props.auth}
        isDisabled={!!disabled}
        hasIntegrations={
          _.get(props, "account.institution.integrations", []).length > 0
        }
      />

      <Section
        section={{
          items: [
            defaultFields.PATIENT_MEMBER_ID,
            defaultFields.INSURANCE_TYPE,
            defaultFields.INSURANCE_STATE,
          ],
          title: "Insurance",
        }}
        disabled={disabled}
      >
        {/*
           We're collecting insurance details solely for reporting purposes, therefore not blocking users like we are in the
           forms flow
         */}
        <SegmentSelector
          disabled={disabled}
          onChange={(company: InsuranceCompany) => {
            if (company) {
              set({
                [defaultFields.INSURANCE_COMPANY.key]: company.name,
                [defaultFields.INSURANCE_PLAN.key]: null,
              });
            } else {
              set({ [defaultFields.INSURANCE_COMPANY.key]: null });
            }
          }}
          options={_.sortBy(insuranceCompanies, "name")}
          title={<>Company</>}
          value={selectedCompany}
          getOptionValue={(option: InsuranceCompany) => option.id}
          getOptionLabel={(option: InsuranceCompany) => option.name}
          sorted
          filterOption={(
            option: FilterOptionOption<InsuranceCompany>,
            input: string
          ) => filterInsuranceCompanyOptions(option.data, input)}
          isClearable
        />
      </Section>
      <FormSubmitButtons submit={submit} useLegacyButtons={true} />
    </TopBottom>
  );
};

export { PatientAndInsuranceStep };
