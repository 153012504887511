import { LoadingButton } from "@samacare/design/core";
import OpenInNewIcon from "@samacare/design/core/icons/OpenInNew";

export const RunBvLiteCheckButton = ({
  handleClick,
  isLoading,
  disabled,
  backgroundColor,
  withIcon = false,
  variant = "outlined",
}: {
  handleClick: () => void;
  isLoading: boolean;
  disabled: boolean;
  backgroundColor?: string;
  withIcon?: boolean;
  variant?: "outlined" | "contained";
}) => {
  return (
    <LoadingButton
      data-cy="actionRunEligibilityCheck"
      type="button"
      variant={variant}
      onClick={handleClick}
      loading={isLoading}
      disabled={disabled}
      sx={{ backgroundColor, width: "fit-content" }}
      endIcon={withIcon ? <OpenInNewIcon /> : null}
    >
      Check Coverage
    </LoadingButton>
  );
};
