import { PureComponent } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";

import SegmentNumber from "./SegmentNumber";
import SegmentText from "./SegmentText";
import SegmentOptions from "./SegmentOptions";
import SegmentCheckbox from "./SegmentCheckbox";
import SegmentDate from "./SegmentDate";
import SegmentPhone from "./SegmentPhone";
import SegmentSocial from "./SegmentSocial";
import SegmentExtension from "./SegmentExtension";
import SegmentState from "./SegmentState";
import SegmentUpload from "./SegmentUpload";
import SegmentButton from "./SegmentButton";
import SegmentIcd from "./SegmentIcd";
import SegmentZip from "./SegmentZip";
import SegmentTin from "./SegmentTin";
import SegmentNpi from "./SegmentNpi";
import SegmentMultiline from "./SegmentMultiline";
import RequiredTag from "../RequiredTag";
import {
  unsetRequiredFields,
  setRequiredFields,
} from "../../reducers/formReducer";
import { SegmentContainer, SegmentTitleContainer } from "./SegmentContainer";
import { HelpTag } from "@samacare/layout/HelpTag";

const { FORM_TYPES } = CONFIG.CONSTANTS;

const sizeToPx = {
  large: "600px",
  med: "350px",
  small: "200px",
};

const INLINE_TYPES = [FORM_TYPES.CHECKBOX];
class Segment extends PureComponent {
  componentDidMount() {
    const { item, setRequired } = this.props;
    if (item.required) {
      if (item.type === FORM_TYPES.OPTION) {
        setRequired(_.map(item.options, "key"));
      } else {
        setRequired(item.key);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { item, display, setRequired, unsetRequired } = this.props;

    if (prevProps.display && display === false) {
      this.recursivelyUnsetItem(item);
    }

    if (prevProps.item && prevProps.item.required !== item.required) {
      if (item.required === true) {
        setRequired(item.key);
      }
      if (item.required === false) {
        unsetRequired(item.key);
      }
    }
  }

  componentWillUnmount() {
    const { item, unsetRequired } = this.props;

    if (item.required) {
      if (item.type === FORM_TYPES.OPTION) {
        unsetRequired(_.map(item.options, "key"));
      } else {
        unsetRequired(item.key);
      }
    }
  }

  recursivelyUnsetItem = (item) => {
    const { set } = this.props;
    if (item.key) {
      set({ [item.key]: null });
    }
    if (item.items) {
      _.each(item.items, this.recursivelyUnsetItem);
    }
    if (item.options) {
      _.each(item.options, this.recursivelyUnsetItem);
    }
  };

  render() {
    const {
      item,
      set,
      display,
      leftPadding,
      size,
      disabled,
      highlightRequiredFields,
      defaultValue,
      isModal,
    } = this.props;

    // XXX(ndhoule): This is an optional attribute and is is only supported on
    // the SegmentText and SegmentDate elements.
    const { onChange, ref } = item;

    const isInline = _.includes(INLINE_TYPES, item.type);
    const isDisabled = disabled || item.disabled;
    // This is kind of a hack for now.  The Segment code is set to explicit pixel values and not put together
    // for modals or mobile optimization.
    const segmentWidth = isModal ? "100%" : sizeToPx[size];
    const cypressTag = _.camelCase(`field_${item.key ?? item.title}`);

    if (display) {
      return (
        <SegmentContainer
          className={classNames({ isInline })}
          isinline={isInline ? 1 : 0}
          paddingleft={leftPadding}
          width={segmentWidth}
          isConditional={item.displayIf}
        >
          {item.type === FORM_TYPES.CHECKBOX && (
            <SegmentCheckbox
              disabled={isDisabled}
              handleChange={set}
              item={item}
              name={item.key}
            />
          )}
          <SegmentTitleContainer
            isCheckbox={item.type === FORM_TYPES.CHECKBOX}
            isinline={isInline ? 1 : 0}
            paddingBottom={leftPadding}
          >
            <label htmlFor={item.key}>{item.title || item.key}</label>
            {item.required && <RequiredTag />}
            {item.help && <HelpTag text={item.help} />}
          </SegmentTitleContainer>
          {item.type === FORM_TYPES.ICD && (
            <SegmentIcd
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.STATE && (
            <SegmentState
              id={cypressTag}
              disabled={isDisabled}
              handleChange={set}
              item={item}
              width={sizeToPx[size]}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.OPTION && (
            <SegmentOptions
              id={cypressTag}
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.MULTILINE && (
            <SegmentMultiline
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
              defaultValue={defaultValue}
            />
          )}
          {(item.type === FORM_TYPES.TEXT ||
            !_.includes(_.values(FORM_TYPES), item.type)) && (
            <SegmentText
              ref={ref}
              disabled={isDisabled}
              onChange={onChange}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.NPI && (
            <SegmentNpi
              cypressTag={cypressTag}
              ref={ref}
              disabled={isDisabled}
              onChange={onChange}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.NUMBER && (
            <SegmentNumber
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.PHONE && (
            <SegmentPhone
              cypressTag={cypressTag}
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.SOCIAL && (
            <SegmentSocial
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.EXTENSION && (
            <SegmentExtension
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.UPLOAD && (
            <SegmentUpload
              disabled={isDisabled}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.ACTION && (
            <SegmentButton
              disabled={item.enable ? false : disabled}
              onClick={item.action}
              text={item.text}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.TIN && (
            <SegmentTin
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.ZIP && (
            <SegmentZip
              disabled={isDisabled}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {_.includes(
            [FORM_TYPES.DATE, FORM_TYPES.COMPOSED_DATE],
            item.type
          ) && (
            <SegmentDate
              ref={ref}
              disabled={isDisabled}
              onChange={onChange}
              handleChange={set}
              item={item}
              highlightIfMissing={highlightRequiredFields && item.required}
            />
          )}
          {item.type === FORM_TYPES.SUBFIELDS &&
            _.map(item.items, (subItem) => (
              <ConnectedSegment
                key={subItem.key}
                disabled={isDisabled}
                item={subItem}
                highlightIfMissing={highlightRequiredFields && item.required}
              />
            ))}
        </SegmentContainer>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state, props) {
  const display = props.item.displayIf
    ? !!state.form.results[props.item.displayIf]
    : true;
  return {
    display,
    highlightRequiredFields: state.form.highlightRequiredFields,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setRequired(toSet) {
    dispatch(setRequiredFields(toSet));
  },
  unsetRequired(toUnset) {
    dispatch(unsetRequiredFields(toUnset));
  },
});

const ConnectedSegment = connect(mapStateToProps, mapDispatchToProps)(Segment);
export default ConnectedSegment;
