import Modal from "@@components/Modal";
import * as React from "react";
import { Account } from "@samacare/graphql";
import { WhiteButton } from "@@ui-kit/WhiteButton";
import { useHubs } from "../../hooks/hubs";

import { MfaSetupModalBody } from "./MfaSetupModalBody";

interface IMfaSetupModalProps {
  isOpen: boolean;
  closeDialog: () => void;
  portalKey?: string;
  currentAccount: Account;
}

export const MfaSetupModal: React.VFC<IMfaSetupModalProps> = (props) => {
  const { isOpen, portalKey, closeDialog, currentAccount } = props;

  const { hubByPortalKey } = useHubs(currentAccount.institution);

  if (portalKey == null) return null;

  const hub = hubByPortalKey?.[portalKey];

  if (hub == null) return null;

  return (
    <Modal
      open={isOpen}
      onClick={closeDialog}
      header={`MFA Setup - ${hub?.title ?? ""}`}
      headerStyleOverride={{ textAlign: "left" }}
    >
      <MfaSetupModalBody
        currentAccount={currentAccount}
        portalKey={hub.portalKey}
        onDoneClick={closeDialog}
      >
        <WhiteButton onClick={closeDialog}>Skip</WhiteButton>
      </MfaSetupModalBody>
    </Modal>
  );
};

export * from "./MfaSetupModalBody";
