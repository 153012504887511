import {
  useForm,
  IcdField,
  FormProvider,
  PrescriberBlock,
  OfficeBlock,
  PatientBlock,
  AddressBlock,
  InsuranceBlock,
  MedicalRequestBlock,
  PatientInformationBlock,
  PlaceOfServiceBlock,
  ErrorMessage,
  PrescriptionInfoBlockForOcrevus,
} from "@samacare/form";
import Signature from "AuthorizationSharedSteps/SignatureSection";

import {
  Maybe,
  MedicationRequestPatient,
  MedicationRequestDiagnosis,
  MedicationRequestMedication,
  MedicationRequestLocation,
  MedicationRequestPrescriber,
  QuestionnaireResponseAnswerObjectType,
  QuestionnaireResponseAnswerInputObjectType,
  QuestionTypeEnumType,
  NewEnrollmentInputObjectType,
  EnrollmentProgram,
} from "@samacare/graphql";

import _ from "lodash";
import { useAlert } from "react-alert";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PhoneField from "@samacare/form/PhoneField";
import {
  Box,
  Link,
  Stack,
  FormHelperText,
  FormLabel,
} from "@samacare/design/core";
import { InsuranceCompanyOption } from "@samacare/form/hooks/useInsuranceCompanyOptions";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useConfig } from "../../hooks";

import {
  QuestionnaireField,
  QuestionnaireResponseType,
} from "../../components/Questionnaire";

import {
  convertBoolToYesNo,
  convertYesNoToBool,
} from "../../routes/Enrollment/utils";

import {
  useEnrollmentContext,
  useOcrevusQuestionnaire,
} from "../../routes/Enrollment/EnrollmentProvider";

import ROUTES from "../../routes/ROUTE_PATHS";
import useCreateNewEnrollment from "../../hooks/useCreateNewEnrollment";
import { EnrollmentRoutes } from "../../routes/Enrollment/Enrollment.types";
import { EnrollmentActions } from "../../routes/Enrollment/EnrollmentActions";
import { EnrollmentConsent } from "../../routes/Enrollment/EnrollmentConsent";
import { EnrollmentSection } from "../../routes/Enrollment/EnrollmentSection";
import useUpdateNewEnrollmentById from "../../hooks/useUpdateNewEnrollmentById";
import { GenentechOcrevusFormQuestions } from "./GenentechOcrevusFormQuestions";

type PlaceOfServiceType = {
  name: string;
  type: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

type EnrollmentFormDataType = {
  root: { serverError: string };
  patient: MedicationRequestPatient;
  diagnoses: MedicationRequestDiagnosis[];
  medications: MedicationRequestMedication[];
  insuranceCompany: InsuranceCompanyOption;
  PatientId?: string;
  prescriber: MedicationRequestPrescriber;
  location: MedicationRequestLocation;
  questionnaire: QuestionnaireResponseType;
  PrescriberId?: string;
  LocationId?: string;
  placeOfService?: PlaceOfServiceType;
  patientConsent: {
    hasConsent: "yes" | "no" | null;
    fileId?: number | null;
    fileTitle?: string | null;
  };
  SignatureFileId?: string;
};

function getQuestionnaireAnswers(
  items: QuestionnaireResponseAnswerObjectType[] = []
): {
  [x: string]: string | number | boolean | (string | undefined)[] | undefined;
} {
  const answers = {};

  for (const item of items) {
    let value: string | boolean = item.value as string;

    if (value === "true") value = true;
    if (value === "false") value = false;

    _.set(answers, item.id, value);
  }

  return answers;
}

function getDefaultMedication(
  enrollmentProgram: EnrollmentProgram | undefined,
  medications: Maybe<MedicationRequestMedication[]>
) {
  const defaultOcrevusMedication = {
    code: enrollmentProgram?.drugCode,
    name: enrollmentProgram?.drugName,
    quantity: null,
  };

  if (!medications || medications.length === 0) {
    return [defaultOcrevusMedication];
  }

  if (
    medications[0]?.code !== enrollmentProgram?.drugCode &&
    medications[0]?.name !== enrollmentProgram?.drugName
  ) {
    return [
      defaultOcrevusMedication,
      ...medications.filter(
        (item) => item?.code !== enrollmentProgram?.drugCode
      ),
    ];
  }

  return medications;
}

export const GenentechOcrevusForm: React.FC<{
  onNextStep: (stringParam?: string | undefined) => void;
}> = ({ onNextStep }) => {
  const {
    enrollment,
    isSubmitted,
    onAlert,
    onPatientSelected,
    programId,
    enrollmentProgram,
  } = useEnrollmentContext();
  const config = useConfig();

  const isPatientFoundationEnabled = useFeatureFlag<string>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnablePatientFoundation
  );

  const alert = useAlert();
  const history = useHistory();
  const { answers } = useOcrevusQuestionnaire();
  const [createNewEnrollment] = useCreateNewEnrollment();
  const [update, { loading }] = useUpdateNewEnrollmentById();

  const siteOfTreatmentName = "site-of-treatment";

  const methods = useForm<EnrollmentFormDataType>({
    defaultValues: {
      questionnaire: getQuestionnaireAnswers(
        enrollment?.questionnaire
          ?.answers as QuestionnaireResponseAnswerObjectType[]
      ),
      patient: enrollment?.MedicationRequest?.patient ?? {},
      medications: getDefaultMedication(
        enrollmentProgram,
        enrollment?.MedicationRequest?.medications ?? []
      ),
      diagnoses: enrollment?.MedicationRequest?.diagnoses ?? [],
      insuranceCompany:
        enrollment?.MedicationRequest?.insuranceCompany ??
        ({} as InsuranceCompanyOption),
      PatientId: enrollment?.MedicationRequest?.PatientId ?? undefined,
      LocationId: enrollment?.MedicationRequest?.LocationId ?? undefined,
      PrescriberId: enrollment?.MedicationRequest?.PrescriberId ?? undefined,
      location: enrollment?.MedicationRequest?.location ?? {},
      prescriber: enrollment?.MedicationRequest?.prescriber ?? {},
      placeOfService: JSON.parse(
        (_.pick(answers?.questionnaire, siteOfTreatmentName)[
          siteOfTreatmentName
        ] as unknown as string) ?? "{}"
      ) as PlaceOfServiceType,
      patientConsent: {
        hasConsent: convertBoolToYesNo(enrollment?.PatientConsent?.hasConsent),
        fileId: enrollment?.PatientConsent?.File?.id,
        fileTitle: enrollment?.PatientConsent?.File?.title,
      },
      SignatureFileId: enrollment?.SignatureFileId ?? undefined,
    },
  });

  const {
    watch,
    setError,
    formState: { errors },
    setValue,
    register,
  } = methods;

  const updateEnrollment = async ({
    enrollmentId,
    data,
  }: {
    enrollmentId: string | undefined;
    data: EnrollmentFormDataType;
  }) => {
    const patch: NewEnrollmentInputObjectType = {
      questionnaire: {
        answers: Object.entries({
          ...data.questionnaire,
          [siteOfTreatmentName]: JSON.stringify(data.placeOfService),
        }).map(([id, value]) => ({
          id,
          value: value?.toString(),
        })) as QuestionnaireResponseAnswerInputObjectType[],
      },
      MedicationRequest: {
        patient: {
          ...data.patient,
          phone: data.patient.phone === "" ? null : data.patient.phone,
        },
        diagnoses: data.diagnoses,
        medications: data.medications,
        prescriber: data.prescriber,
        location: data.location,
        PatientId: data.PatientId,
        LocationId: data.LocationId,
        PrescriberId: data.PrescriberId,
      },
      SignatureFileId:
        data?.SignatureFileId && data?.SignatureFileId?.length > 0
          ? data?.SignatureFileId
          : null,
    };

    const hasConsent = convertYesNoToBool(data.patientConsent?.hasConsent)!;

    if (typeof hasConsent === "boolean") {
      patch.PatientConsent = {
        hasConsent,
        fileId: data.patientConsent.fileId,
      };
    }

    await update({ variables: { id: Number(enrollmentId), patch } });
  };

  const updateSignature = (id: string) => {
    setValue("SignatureFileId", id);
  };

  useEffect(() => {
    _.delay(() => {
      // We use delay to prevent the alert from showing when watch triggers on first render
      const subscription = watch(
        _.debounce(
          (data) => {
            const enrollmentId = enrollment?.id;
            if (enrollment?.id == null) return;
            updateEnrollment({
              enrollmentId,
              data: data as EnrollmentFormDataType,
            })
              .then(() => alert.success("Enrollment saved!"))
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.error("Failed to auto-save enrollment", error);
                alert.error("Failed to auto-save enrollment");
              });
          },
          3000,
          {
            leading: false,
            trailing: true,
          }
        )
      );
      return () => subscription.unsubscribe();
    }, 3000);
  }, [watch]);

  // Note: Form fields using the sama- prefix will cause the field to be ignored by the Questionnaire resource in the submission bundle
  // Use the prefix to store custom form data that is not part of the genentech questionnaire
  const refBipa = useRef(null);
  const pos = watch("questionnaire.pos");

  const hasAppliedForPF = watch("questionnaire.applied-for-pf");
  const hasAppliedForBipa = watch("questionnaire.applied-for-bipa");
  const hasDoNotContact = watch("questionnaire.sama-do-not-contact");
  const hasReenrollment = watch("questionnaire.sama-reenrollment");
  const shipmentOption = watch("questionnaire.shipment-options");

  const hasShipmentUpfront = shipmentOption === "Upfront";
  const isServiceSelected = hasAppliedForBipa || hasAppliedForPF;

  /**
   * Handles submission from form
   * @param data EnrollmentFormDataType
   */
  async function onSubmit(data: EnrollmentFormDataType) {
    try {
      if (enrollment?.id == null) {
        const { data: createData } = await createNewEnrollment({
          variables: {
            EnrollmentProgramId: programId,
          },
        });

        const { id } = createData?.createNewEnrollment ?? {};
        history.push(
          `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.submission}/${id}`
        );
        await updateEnrollment({
          enrollmentId: id,
          data,
        });
      } else {
        await updateEnrollment({ enrollmentId: enrollment?.id, data });
        onNextStep();
      }
    } catch (error) {
      setError("root.serverError", {
        type: "400",
      });
      onAlert((error as Error).message);
    }
  }

  return (
    <FormProvider {...methods}>
      <form
        id="EnrollmentForm"
        name="EnrollmentForm"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        <EnrollmentSection title="Patient">
          <PatientBlock
            onSelected={async (patient) => {
              await onPatientSelected(patient);
            }}
            required
            disabled={isSubmitted}
          />
        </EnrollmentSection>
        <EnrollmentSection title="Service Type">
          <FormLabel required>Select type of services:</FormLabel>
          <Stack my={2} ml={2} spacing={2} direction="column">
            <QuestionnaireField
              id="applied-for-bipa"
              inputRef={refBipa}
              type={QuestionTypeEnumType.Boolean}
              text="Benefits Investigation"
              disabled={isSubmitted}
            />

            {isPatientFoundationEnabled && (
              <QuestionnaireField
                id="applied-for-pf"
                type={QuestionTypeEnumType.Boolean}
                text="Patient Foundation"
                disabled={isSubmitted}
                rules={{
                  validate: (value) => {
                    const { checked = false } =
                      refBipa?.current ?? ({} as { checked: boolean });

                    if (!value && !checked) {
                      return "Please select a service type";
                    }
                  },
                  deps: ["questionnaire.applied-for-bipa"],
                }}
              />
            )}
            <Box>
              <QuestionnaireField
                id="eligibility-reason"
                text="Eligibility Reason"
                radioGroupProps={{ row: false }}
                formControlProps={{ sx: { ml: 4 } }}
                type={QuestionTypeEnumType.Choice}
                disabled={isSubmitted}
                required
                hideLabel
                answerOption={[
                  {
                    valueString: "Insured: No Coverage for Medication",
                  },
                  {
                    valueString: "Insured: Can't afford Medication",
                  },
                  {
                    valueString: "No Insurance",
                  },
                ]}
                enableWhen={[
                  {
                    answerBoolean: true,
                    answerString: null,
                    id: "applied-for-pf",
                    operator: "=",
                    question: "applied-for-pf",
                  },
                ]}
                rules={{ required: "Please select an eligibility reason" }}
              />
            </Box>
            <ErrorMessage
              errors={errors}
              name="questionnaire.applied-for-pf"
              render={({ message }) => (
                <FormHelperText error>{message}</FormHelperText>
              )}
            />
          </Stack>
          <Stack>
            <FormLabel>Is this a reenrollment?</FormLabel>
            <QuestionnaireField
              id="sama-reenrollment"
              text="Yes, previous patient consent will be used"
              checkboxFieldProps={{ sx: { ml: 2, mb: 2 } }}
              type={QuestionTypeEnumType.Boolean}
              disabled={isSubmitted}
            />
          </Stack>
        </EnrollmentSection>
        <EnrollmentSection title="Patient Information">
          <AddressBlock required disabled={isSubmitted}>
            <PatientInformationBlock
              disabled={isSubmitted}
              GenderInputProps={{ required: true }}
              PhoneInputProps={{
                usePhoneType: true,
                disabled: isSubmitted,
                required: !hasDoNotContact,
                PhoneTypeProps: {
                  name: "patient.phoneType",
                  disabled: isSubmitted,
                  required: !hasDoNotContact,
                },
              }}
            />
            <QuestionnaireField
              id="sama-do-not-contact"
              type={QuestionTypeEnumType.Boolean}
              text="Do not contact patient"
              disabled={isSubmitted}
            />
          </AddressBlock>
        </EnrollmentSection>
        <EnrollmentSection title="JCODE/CPT code">
          <MedicalRequestBlock disabled />
        </EnrollmentSection>
        <EnrollmentSection title="Patient Diagnosis">
          <IcdField name="diagnoses" required disabled={isSubmitted} />
        </EnrollmentSection>
        <EnrollmentSection title="Patient Insurance Information">
          <InsuranceBlock
            required
            disabled={isSubmitted}
            PhoneInputProps={{ name: "questionnaire.sama-insurance-phone" }}
          />
        </EnrollmentSection>
        <EnrollmentSection title="Provider Details">
          <PrescriberBlock required disabled={isSubmitted} />
        </EnrollmentSection>
        <EnrollmentSection title="Location Details">
          <OfficeBlock required disabled={isSubmitted} />
        </EnrollmentSection>
        {isServiceSelected && (
          <EnrollmentSection title="Site of Treatment">
            <QuestionnaireField
              id="pos"
              text="Place of Service"
              type={QuestionTypeEnumType.Choice}
              disabled={isSubmitted}
              required
              hideLabel
              answerOption={[
                {
                  valueString: "In Office",
                },
                {
                  valueString: "Hospital Outpatient",
                },
                {
                  valueString: "Infusion Center",
                },
                {
                  valueString: "Unknown",
                },
              ]}
              enableWhen={[
                {
                  answerBoolean: true,
                  answerString: null,
                  id: "applied-for-bipa",
                  operator: "=",
                  question: "applied-for-bipa",
                },
                {
                  answerBoolean: true,
                  answerString: null,
                  id: "applied-for-pf",
                  operator: "=",
                  question: "applied-for-pf",
                },
                {
                  answerBoolean: true,
                  answerString: null,
                  id: "applied-for-appeals",
                  operator: "=",
                  question: "applied-for-appeals",
                },
              ]}
              radioGroupProps={{ sx: { mb: 2 }, row: true }}
            />
            {(pos === "Hospital Outpatient" || pos === "Infusion Center") && (
              <PlaceOfServiceBlock required disabled={isSubmitted} />
            )}
          </EnrollmentSection>
        )}
        <EnrollmentSection title="Office Contact">
          <Stack direction="row" spacing={1} maxWidth={600}>
            <PhoneField
              name="questionnaire.sama-office-phone"
              label="Phone"
              disabled={isSubmitted}
              required
              fullWidth
            />
            <PhoneField
              name="questionnaire.sama-office-fax"
              disabled={isSubmitted}
              label="Fax"
              required
              fullWidth
            />
          </Stack>
        </EnrollmentSection>
        {isServiceSelected && (
          <EnrollmentSection title="Questionnaire">
            <Stack my={2} ml={2} spacing={2} direction="column">
              <GenentechOcrevusFormQuestions />
            </Stack>
          </EnrollmentSection>
        )}
        {hasAppliedForPF && (
          <>
            <EnrollmentSection title="Shipment Information">
              <Stack my={2} ml={2} spacing={2} direction="column">
                <QuestionnaireField
                  id="shipment-options"
                  text="Select shipment option"
                  type={QuestionTypeEnumType.Choice}
                  disabled={isSubmitted}
                  required
                  answerOption={[
                    {
                      valueString: "Replacement",
                    },
                    {
                      valueString: "Upfront",
                    },
                  ]}
                />
              </Stack>
              <Stack spacing={2} ml={2} direction="column">
                <QuestionnaireField
                  id="ship-to"
                  type={QuestionTypeEnumType.Choice}
                  disabled={isSubmitted}
                  required
                  answerOption={[
                    {
                      valueString: "Prescriber",
                    },
                    {
                      valueString: "Practice",
                    },
                    { valueString: "Site of Treatment" },
                  ]}
                  text="Shipment to:"
                />
              </Stack>
            </EnrollmentSection>
            {hasShipmentUpfront && <PrescriptionInfoBlockForOcrevus />}
          </>
        )}
        {!hasReenrollment && (
          <EnrollmentConsent
            description={
              <>
                We urge practices to collect and submit patient consent along
                with the Enrollment as it is required by Pharma to access
                Personal Health Information and to respond promptly with service
                updates. Digital consent form for the patient is available{" "}
                <Link
                  href="http://go.gene.com/patientconsent"
                  rel="noopener"
                  target="_blank"
                >
                  here
                </Link>{" "}
                [
                <Link
                  href="http://go.gene.com/patientconsent"
                  rel="noopener"
                  target="_blank"
                >
                  http://go.gene.com/patientconsent
                </Link>
                ].
              </>
            }
          />
        )}
        {hasAppliedForPF && (
          <EnrollmentSection title="Health Care Provider Signature">
            <Stack my={2} spacing={2} direction="column">
              <Signature
                enrollment={enrollment}
                updateSignature={updateSignature}
                disabled={isSubmitted}
              />
              <input
                type="hidden"
                {...register("SignatureFileId", {
                  required: "Please select a signature",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="SignatureFileId"
                render={({ message }) => (
                  <FormHelperText error>{message}</FormHelperText>
                )}
              />
            </Stack>
          </EnrollmentSection>
        )}

        <EnrollmentActions loading={loading} disabled={isSubmitted} />
      </form>
    </FormProvider>
  );
};
