import FormSubmitButtons from "../../../components/AuthorizationSharedComponents/FormSubmitButtons";
import Section from "../../../components/AuthorizationSharedComponents/Section";
import IcdSelector from "../../../components/IcdSelector";
import {
  SegmentContainer,
  SegmentTitleContainer,
} from "../../../components/Segment/SegmentContainer";
import { TopBottom } from "../../../components/TopBottom";
import { icdChangeHandler } from "../../../util/requestDetailsChangeHandlers";
import { Authorization } from "@samacare/graphql";
import RequiredTag from "../../../components/RequiredTag";
import { useSet } from "../../../hooks/useSet";
import { useSelector } from "../../../configureStore";
import { useEffect } from "react";
import { useSetRequired } from "../../../hooks/useSetRequired";
import { useUnsetRequired } from "../../../hooks/useUnsetRequired";
import { ServiceSection } from "./ServiceSection";

const defaultFields = window.CONFIG.DEFAULT_FIELDS;

const FieldKeysNotInSegments = [defaultFields.ICD_0.key];

interface ServiceAndDiagnosisStepProps {
  auth: Authorization;
  disabled: boolean;
  back: () => void;
  nextStep: (forcedNextStep?: number, patientId?: string) => Promise<void>;
}
const ServiceAndDiagnosisStep: React.VFC<ServiceAndDiagnosisStepProps> = (
  props
) => {
  const { auth, disabled, back } = props;
  const { formFieldConfig } = auth;
  const maxIcdCount = formFieldConfig?.maxIcdCount ?? 3;

  const highlightRequiredFields = useSelector<boolean>(
    (state) => state.form.highlightRequiredFields
  );

  const set = useSet();
  const setRequired = useSetRequired();
  const unsetRequired = useUnsetRequired();

  useEffect(() => {
    const requiredFields = formFieldConfig?.requiredFields ?? [];

    for (const key of FieldKeysNotInSegments) {
      if (requiredFields.includes(key)) {
        setRequired(key);
      }
    }

    return () => {
      for (const key of FieldKeysNotInSegments) {
        if (requiredFields.includes(key)) {
          unsetRequired(key);
        }
      }
    };
  });

  const onSubmit = async () => {
    await props.nextStep();
  };

  const isIcdRequired = formFieldConfig?.requiredFields
    ? formFieldConfig.requiredFields.includes(defaultFields.ICD_0.key)
    : false;

  const icdTitle = `ICD-10 Codes (Max ${maxIcdCount} codes.  If you want to add additional codes, please add them in the additional clinical notes on the payer's portal.`;

  return (
    <TopBottom>
      <ServiceSection auth={props.auth} isDisabled={props.disabled} />

      <Section section={{ title: "Diagnosis", items: [] }}>
        <SegmentContainer width="600px">
          <SegmentTitleContainer paddingBottom="7px">
            {icdTitle}
            {isIcdRequired && <RequiredTag />}
          </SegmentTitleContainer>
          <IcdSelector
            disabled={disabled}
            max={maxIcdCount}
            highlightRequiredFields={highlightRequiredFields}
            initializedICDs={auth.ICDs}
            onChange={(newIcds) => {
              icdChangeHandler(newIcds, set);
            }}
          />
        </SegmentContainer>
      </Section>

      <FormSubmitButtons
        back={back}
        submit={onSubmit}
        useLegacyButtons={true}
      />
    </TopBottom>
  );
};

export { ServiceAndDiagnosisStep };
