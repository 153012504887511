import _ from "lodash";
import moment from "moment";
import { array, object, string } from "yup";

import {
  BenefitsVerification,
  Patient,
  UnifiedBenefitsCheckType,
} from "@samacare/graphql";
import { Config } from "../../hooks";
import { RequestBenefitVerificationFormDataType } from "./types";
import { InsuranceCompanyOption } from "@samacare/form/hooks/useInsuranceCompanyOptions";
import { isMedicare } from "../../util/isMedicare";

const phoneRegex = /^(\d{3})[- ](\d{3})[- ](\d{4})/;
const phoneSchema = string().matches(
  phoneRegex,
  'Phone number must be in the format "XXX-XXX-XXXX"'
);

const isEmpty = (s?: string | null) => s == null || s.trim() === "";
const cleanEmpty = (s?: string | null) => {
  if (s == null || s.trim() === "") {
    return null;
  }
  return s;
};

export const mapPatientStatusToHumanizeText = (patientStatus: string) => {
  return _.startCase(_.lowerCase(patientStatus));
};

export const buildBenefitVerificationRequestFormSchema = (
  config: Config,
  insuranceCompanies: InsuranceCompanyOption[] | undefined
) => {
  return object<RequestBenefitVerificationFormDataType>({
    benefitsCheckType: string().required(),

    // patient section
    PatientId: string(),
    patient: object().when("benefitsCheckType", ([type]) => {
      if (type === UnifiedBenefitsCheckType.Full) {
        return object({
          firstName: string().required(),
          lastName: string().required(),
          dob: string().required(),
          address: string().required(),
          city: string().required(),
          state: string().required(),
          zip: string().required(),
          gender: string().required(),
          phone: phoneSchema.required(),
        });
      }
      return object({
        firstName: string().required(),
        lastName: string().required(),
        dob: string().required(),
        address: string().optional().nullable(),
        city: string().optional().nullable(),
        state: string().optional().nullable(),
        zip: string().optional().nullable(),
        gender: string().optional().nullable(),
        phone: string()
          .optional()
          .nullable()
          .test(
            "valid-phone-when-not-empty",
            "Phone number must be valid",
            (value) => {
              if (!isEmpty(value)) {
                return phoneRegex.test(value!);
              }
              return true;
            }
          ),
      });
    }),

    // primary insurnace section
    primaryInsurance: object({
      InsuranceCompanyId: string().required(),
      planType: string().required(),
      memberId: string().required(),
      insuranceState: string().required(),
    }),
    primaryInsuranceCompanyPhone: string().optional().nullable(),

    // secondary insurnace section
    secondaryInsurance: object({
      InsuranceCompanyId: string().nullable(),
      planType: string().nullable(),
      memberId: string().nullable(),
      insuranceState: string().nullable(),
    }),
    secondaryInsuranceCompanyPhone: string().optional().nullable(),

    // services deatils section - only required for full BV
    DrugOptionId: string().when("benefitsCheckType", ([type]) =>
      type === UnifiedBenefitsCheckType.Full
        ? string().required()
        : string().optional().nullable()
    ),
    hcpcsCodes: array(),

    icds: array().when("benefitsCheckType", ([type]) =>
      type === UnifiedBenefitsCheckType.Full
        ? array()
            .required()
            .min(1, "Please select at least one ICD code to continue")
        : array()
    ),
    patientStatus: string().when("benefitsCheckType", ([type]) =>
      type === UnifiedBenefitsCheckType.Full
        ? string().required()
        : string().optional().nullable()
    ),
    prescribedDate: string().when("benefitsCheckType", ([type]) =>
      type === UnifiedBenefitsCheckType.Full
        ? string().required()
        : string().optional().nullable()
    ),

    // provider section
    PrescriberBlock: object().when("benefitsCheckType", ([type]) =>
      type === UnifiedBenefitsCheckType.Full
        ? object({
            id: string().required(),
          })
        : object({
            id: string().optional().nullable(),
          })
    ),
    prescriberPTAN: string().when(
      [
        "benefitsCheckType",
        "primaryInsurance.planType",
        "primaryInsurance.InsuranceCompanyId",
      ],
      ([benefitsCheckType, planType, InsuranceCompanyId]) => {
        if (benefitsCheckType !== UnifiedBenefitsCheckType.Full)
          return string().optional().nullable();

        const medicareAdvantageSelected =
          planType === config.CONSTANTS.INSURANCE_TYPES.IS_MEDICARE_ADVANTAGE;
        const medicareSelected = insuranceCompanies?.some(
          (company) =>
            company?.id === InsuranceCompanyId && isMedicare(company?.label)
        );

        return medicareAdvantageSelected || medicareSelected
          ? string().required()
          : string().optional().nullable();
      }
    ),

    // location section

    OfficeBlock: object().when("benefitsCheckType", ([type]) =>
      type === UnifiedBenefitsCheckType.Full
        ? object({
            id: string().required(),
          })
        : object({
            id: string().optional().nullable(),
          })
    ),
    locationPTAN: string().when(
      [
        "benefitsCheckType",
        "primaryInsurance.planType",
        "primaryInsurance.InsuranceCompanyId",
      ],
      ([benefitsCheckType, planType, InsuranceCompanyId]) => {
        if (benefitsCheckType !== UnifiedBenefitsCheckType.Full)
          return string().optional().nullable();

        const medicareAdvantageSelected =
          planType === config.CONSTANTS.INSURANCE_TYPES.IS_MEDICARE_ADVANTAGE;

        const medicareSelected = insuranceCompanies?.some(
          (company) =>
            company?.id === InsuranceCompanyId && isMedicare(company?.label)
        );

        return medicareAdvantageSelected || medicareSelected
          ? string().required()
          : string().optional().nullable();
      }
    ),
  });
};

export const buildPavbluFormSchema = () => {
  return object<RequestBenefitVerificationFormDataType>({
    benefitsCheckType: string().required(),

    // patient section
    PatientId: string(),
    patient: object({
      firstName: string().required(),
      lastName: string().required(),
      dob: string().required(),
      address: string().required(),
      city: string().required(),
      state: string().required(),
      zip: string().required(),
      gender: string().required(),
      phone: phoneSchema.required(),
      phoneType: string().required(),
    }),

    // primary insurnace section
    primaryInsurance: object({
      InsuranceCompanyId: string().required(),
      planType: string().required(),
      memberId: string().required(),
      insuranceState: string().required(),
    }),

    // secondary insurnace section
    secondaryInsurance: object({
      InsuranceCompanyId: string().nullable(),
      planType: string().nullable(),
      memberId: string().nullable(),
      insuranceState: string().nullable(),
    }),

    // services deatils section - only required for full BV
    DrugOptionId: string().required(),
    icds: array()
      .required()
      .min(1, "Please select at least one ICD code to continue"),

    // provider section
    PrescriberId: string().required(),

    // location section
    LocationId: string().required(),
    PrescriberBlock: object({
      id: string().required(),
    }),
    OfficeBlock: object({
      id: string().required(),
    }),
  });
};
// graphql BenefitsVerification to form data transformer

const cleanGraphqlObj = (
  obj: object | null | undefined,
  opts: { keys: string[] } = { keys: [] }
) => {
  const omitKeys = ["__typename", "id"].concat(opts.keys);
  return _.omit(obj, omitKeys);
};

const buildDefualtFormValues = () => {
  return {
    benefitsCheckType: null,

    // patient
    patient: {
      firstName: null,
      lastName: null,
      dob: null,
      address: null,
      city: null,
      state: null,
      zip: null,
    },

    // patient insurances
    primaryInsurance: {
      InsuranceCompanyId: null,
      planType: null,
      memberId: null,
      insuranceState: null,
    },
    primaryInsuranceCompanyPhone: null,
    secondaryInsurance: {
      InsuranceCompanyId: null,
      planType: null,
      memberId: null,
      insuranceState: null,
    },
    secondaryInsuranceCompanyPhone: null,
    // service details
    prescribedDate: null,
    DrugOptionId: null,
    hcpcsCodes: [],
    icds: [],
    patientStatus: null,
    PrescriberId: null,
    prescriber: null,
    prescriberPTAN: null,
    LocationId: null,
    location: null,
    locationPTAN: null,
  } as unknown as RequestBenefitVerificationFormDataType;
};

export const transformBvToFormValues = (bv: BenefitsVerification) => {
  return {
    benefitsCheckType: UnifiedBenefitsCheckType.Full,

    // patient
    PatientId: bv.patient.id,
    patient: { ...cleanGraphqlObj(bv.request.patientDetails) },

    // patient insurances
    primaryInsurance: {
      ...cleanGraphqlObj(bv.request.primaryInsuranceDetails),
    },
    primaryInsuranceCompanyPhone: bv.request.primaryInsuranceCompanyPhone,
    secondaryInsurance: {
      ...cleanGraphqlObj(bv.request.secondaryInsuranceDetails),
    },
    secondaryInsuranceCompanyPhone: bv.request.secondaryInsuranceCompanyPhone,

    // service details
    prescribedDate: bv.request.prescribedDate as string,
    DrugOptionId: bv.request.drugDetails.drugOptionId,
    hcpcsCodes: (bv.request.drugDetails.hcpcsCodes ?? []).map(
      (code: string) => {
        return { code, shortDescription: "" };
      }
    ),
    icds: (bv.request.drugDetails.icds || []).map((code: string) => {
      return { code, description: code };
    }),
    patientStatus: bv.request.patientStatus,
    PrescriberId: bv.request.prescriberDetails?.id,
    prescriber: { ...cleanGraphqlObj(bv.request.prescriberDetails) },
    prescriberInNetwork: bv.request.prescriberInNetwork,
    prescriberPTAN: bv.request.prescriberPTAN,
    LocationId: bv.request.locationDetails?.id,
    location: { ...cleanGraphqlObj(bv.request.locationDetails) },
    locationPTAN: bv.request.locationDetails?.PTAN,
  } as unknown as RequestBenefitVerificationFormDataType;
};

export const transformUrlInfoToFormValues = ({
  patient,
  type,
  drugOptionId,
  isPavblu,
}: {
  patient?: Patient | null;
  type: UnifiedBenefitsCheckType | null;
  drugOptionId: string | null;
  isPavblu: boolean;
}) => {
  if (patient) {
    return {
      benefitsCheckType: UnifiedBenefitsCheckType.Lite,

      // patient
      PatientId: patient.id,
      patient: { ...cleanGraphqlObj(patient) },

      // patient insurances
      primaryInsurance: {
        InsuranceCompanyId: patient.primaryInsurance?.insuranceCompany?.id,
        planType: patient.primaryInsurance?.planType,
        memberId: patient.primaryInsurance?.memberId,
        insuranceState: patient.primaryInsurance?.insuranceState,
      },
      primaryInsuranceCompanyPhone: null,
      secondaryInsurance: {
        InsuranceCompanyId: patient.secondaryInsurance?.insuranceCompany?.id,
        planType: patient.secondaryInsurance?.planType,
        memberId: patient.secondaryInsurance?.memberId,
        insuranceState: patient.secondaryInsurance?.insuranceState,
      },
      secondaryInsuranceCompanyPhone: null,

      // service details
      prescribedDate: null,
      DrugOptionId: null,
      hcpcsCodes: [],
      icds: [],
      patientStatus: null,
      PrescriberId: null,
      prescriber: null,
      prescriberPTAN: null,
      LocationId: null,
      location: null,
      locationPTAN: null,
      isPavblu,
    } as unknown as RequestBenefitVerificationFormDataType;
  } else if (type && drugOptionId) {
    const defaultValues = buildDefualtFormValues();
    return {
      ...defaultValues,
      benefitsCheckType: type,
      DrugOptionId: drugOptionId,
      isPavblu,
    };
  }

  return {};
};

// Form data to BenefitsVerification update mutation transfomer

export const transformToPatienPatch = (
  data: RequestBenefitVerificationFormDataType
) => {
  const { patient } = data;
  return {
    firstName: patient?.firstName,
    lastName: patient?.lastName,
    dob:
      patient?.dob == null ? null : moment(patient?.dob).format("YYYY-MM-DD"),
    address: cleanEmpty(patient?.address),
    city: cleanEmpty(patient?.city),
    state: cleanEmpty(patient?.state),
    zip: cleanEmpty(patient?.zip),
    gender: cleanEmpty(patient?.gender),
    phone: cleanEmpty(patient?.phone),
  };
};

export const transformToPrimaryInsurancePatch = (
  data: RequestBenefitVerificationFormDataType
) => {
  const { primaryInsurance } = data;
  return {
    InsuranceCompanyId: primaryInsurance?.InsuranceCompanyId,
    insuranceState: primaryInsurance?.insuranceState,
    planType: primaryInsurance?.planType,
    groupNumber: primaryInsurance?.groupNumber,
    memberId: primaryInsurance?.memberId,
  };
};

export const transformToSecondaryInsurancePatch = (
  data: RequestBenefitVerificationFormDataType
) => {
  const { secondaryInsurance } = data;
  return {
    InsuranceCompanyId: secondaryInsurance?.InsuranceCompanyId,
    insuranceState: secondaryInsurance?.insuranceState,
    planType: secondaryInsurance?.planType,
    groupNumber: secondaryInsurance?.groupNumber,
    memberId: secondaryInsurance?.memberId,
  };
};

const transformToBenefitsVerificationPatch = (
  data: RequestBenefitVerificationFormDataType
) => {
  return {
    primaryInsuranceCompanyPhone: cleanEmpty(data.primaryInsuranceCompanyPhone),
    ...(!!data.secondaryInsuranceCompanyPhone && {
      secondaryInsuranceCompanyPhone: data.secondaryInsuranceCompanyPhone,
    }),
    DrugOptionId: data.DrugOptionId,
    icds: data.icds.map((icd) => icd.code),
    hcpcsCodes: (data.hcpcsCodes || []).map((hcpcs) => hcpcs.code),
    prescribedDate:
      data.prescribedDate != null
        ? moment(data.prescribedDate).format("YYYY-MM-DD")
        : null,
    patientStatus: data.patientStatus,
    LocationId: data.LocationId,
    locationPtan: data.locationPTAN,
    PrescriberId: data.PrescriberId,
    prescriberPtan: data.prescriberPTAN,
    providerInNetwork: data.prescriberInNetwork,
  };
};

export const transformRequestFormToPatch = (
  data: RequestBenefitVerificationFormDataType
) => {
  const secondaryInsuranceCompanyId =
    data.secondaryInsurance?.InsuranceCompanyId;
  return {
    patientData: transformToPatienPatch(data),
    primaryInsuranceData: transformToPrimaryInsurancePatch(data),
    ...(secondaryInsuranceCompanyId != null && {
      secondaryInsuranceData: transformToSecondaryInsurancePatch(data),
    }),
    benefitsVerificationData: transformToBenefitsVerificationPatch(data),
  };
};
