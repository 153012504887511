import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import colors from "Resources/colors";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { withDuplicateAuthorization } from "../graphql/Authorization";
import getAuthorizationRoute from "../util/getAuthorizationRoute";
import Modal from "./Modal";
import PortalSelectionModal from "./PortalSelectionModal";
import BaseButton from "./BaseButton";
import LoadingSpinner from "./LoadingSpinner";
import {
  ModalBodyXL,
  ModalContent,
  ModalFooter,
} from "./ModalStyledComponents";
import { getAllPortalsForInstitution } from "../util/getAllPortalsForInstitution";

const CustomModalContent = styled(ModalContent)`
  padding: 20px 0;
  font-size: 16px;
  white-space: normal;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  justify-content: center;
  ${BaseButton} {
    margin-left: 10px;
  }
`;

const HelperText = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.darkGray};
`;

const BackButton = styled(BaseButton)`
  color: white;
  border-color: ${(props) => props.theme.lightGray};
  background-color: ${(props) => props.theme.lightGray};
  &:hover {
    color: white;
    background-color: ${(props) =>
      props.disabled ? props.theme.lightGray : props.theme.gray};
    border-color: ${(props) =>
      props.disabled ? props.theme.lightGray : props.theme.gray};
  }
`;

export class DuplicateAuthorizationButton extends PureComponent {
  state = {
    open: false,
    loading: false,
    showFormOptions: false,
    showPortalOptions: false,
    selectPortal: false,
  };

  toggleModal = (e) => {
    if (e) {
      e.stopPropagation();
    }
    const { open } = this.state;

    this.setState({ open: !open });
  };

  duplicateAuthorization = async (
    setNewForm,
    portalKey,
    isWebExtension = false,
    insuranceName,
    isExternal = false
  ) => {
    const { duplicateAuthorization, toAuthorization, authorization, alert } =
      this.props;
    const brandName = CONFIG.CONSTANTS.SAMACARE_BRAND_NAME;

    this.setState({ loading: true });
    try {
      const { data } = await duplicateAuthorization({
        variables: {
          authorizationId: authorization.id,
          setNewForm,
          portalKey,
          isWebExtension,
          insuranceName,
          isExternal,
        },
      });
      const duplicated = data.duplicateAuthorization;

      this.setState({ loading: false });
      this.toggleModal();

      toAuthorization(duplicated.id, getAuthorizationRoute(duplicated));
    } catch (e) {
      alert.error(
        `There was an error duplicating your authorization. If this problem persists, please contact ${brandName}.`
      );
      this.setState({ loading: false });
      this.toggleModal();
    }
  };

  render() {
    const { children, authorization, account } = this.props;
    const { open, loading, showFormOptions, showPortalOptions, selectPortal } =
      this.state;

    const { portalKey, isReferral, formId, portalTitle, type, isWebExtension } =
      authorization;

    const availablePortals = getAllPortalsForInstitution(account.institution);
    const stepOne = !showFormOptions && !showPortalOptions && !selectPortal;
    const hidePortals = isReferral || _.isEmpty(availablePortals);
    const allowSamePortal = _.includes(
      _.map(availablePortals, "key"),
      portalKey
    );
    const isExternalAuth =
      type === CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key;

    return (
      <div>
        <Modal
          header="Duplicate Authorization"
          onClick={this.toggleModal}
          open={open}
          styleOverride={{ overflowY: "visible" }}
        >
          <ModalBodyXL>
            <CustomModalContent>
              <div>
                Duplicating an authorization will create a new
                <span style={{ color: colors.presubmissionPink }}> draft </span>
                authorization based off the original.&nbsp;
                {!portalKey &&
                  !formId &&
                  "The form that was used to create this auth has been removed."}
                {portalKey &&
                  "If creating a Form Authorization then all non-structured information will be lost."}
              </div>
              <div style={{ marginTop: "10px" }}>
                Your patient&apos;s clinical information may be out of date in
                the new authorization. Be sure to double check your data before
                submitting.
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingSpinner
                    style={{ marginRight: "10px" }}
                    colorTop={colors.darkPurple}
                    color={colors.purple}
                  />
                  <span>Creating duplicate authorization...</span>
                </div>
              ) : (
                <HelperText>{`I want to duplicate this authorization ${
                  stepOne ? "as a" : "with"
                }...`}</HelperText>
              )}
            </CustomModalContent>

            <CustomModalFooter>
              {stepOne && !loading && (
                <>
                  <BaseButton
                    onClick={
                      formId
                        ? () => {
                            this.setState({ showFormOptions: true });
                          }
                        : () => {
                            this.duplicateAuthorization(true);
                          }
                    }
                  >
                    New Form Authorization
                  </BaseButton>
                  {!hidePortals && (
                    <BaseButton
                      onClick={
                        allowSamePortal
                          ? () => {
                              this.setState({ showPortalOptions: true });
                            }
                          : () => {
                              this.setState({ selectPortal: true });
                            }
                      }
                    >
                      New Portal Authorization
                    </BaseButton>
                  )}
                  <BaseButton
                    onClick={() => {
                      this.duplicateAuthorization(
                        true,
                        undefined,
                        false,
                        undefined,
                        true
                      );
                    }}
                  >
                    New External Authorization
                  </BaseButton>
                </>
              )}
              {showFormOptions && !loading && (
                <>
                  <div>
                    <BackButton
                      onClick={() => {
                        this.setState({ showFormOptions: false });
                      }}
                    >
                      Back
                    </BackButton>
                  </div>
                  {!isExternalAuth && (
                    <BaseButton
                      onClick={() => {
                        this.duplicateAuthorization(false);
                      }}
                    >
                      Same Form and Number
                    </BaseButton>
                  )}
                  <BaseButton
                    onClick={() => {
                      this.duplicateAuthorization(true);
                    }}
                  >
                    Different Form and Number
                  </BaseButton>
                </>
              )}
              {showPortalOptions && !loading && (
                <>
                  <div>
                    <BackButton
                      onClick={() => {
                        this.setState({ showPortalOptions: false });
                      }}
                    >
                      Back
                    </BackButton>
                  </div>
                  <BaseButton
                    onClick={() => {
                      this.duplicateAuthorization(
                        false,
                        portalKey,
                        isWebExtension
                      );
                    }}
                  >
                    {`Same Portal Integration (${portalTitle})`}
                  </BaseButton>
                  <BaseButton
                    onClick={() => {
                      this.setState({ selectPortal: true });
                    }}
                  >
                    Different Portal Integration
                  </BaseButton>
                </>
              )}
              {selectPortal && (
                <PortalSelectionModal
                  open={selectPortal}
                  institution={account.institution}
                  closeModal={() => {
                    this.setState({ selectPortal: false });
                  }}
                  onSelect={(portal) => {
                    this.setState({ selectPortal: false });
                    this.duplicateAuthorization(
                      false,
                      portal.key,
                      portal.isWebExtension,
                      portal.insuranceCompanyName
                    );
                  }}
                />
              )}
            </CustomModalFooter>
          </ModalBodyXL>
        </Modal>
        <div onClick={this.toggleModal}>{children}</div>
      </div>
    );
  }
}

const mapDispatchProps = (dispatch) => ({
  toAuthorization(id, routePrefix) {
    const route = `${routePrefix}?step=1&id=${id}`;
    dispatch(push(route));
  },
});

export default connect(
  () => ({}),
  mapDispatchProps
)(
  compose(withDuplicateAuthorization)(withAlert()(DuplicateAuthorizationButton))
);
