import { generatePath, matchPath } from "react-router-dom";
import _ from "lodash";
import * as React from "react";
import ROUTE_PATHS from "app/routes/ROUTE_PATHS";
import FactCheckOutlinedIcon from "@samacare/design/core/icons/FactCheckOutlined";
import VerifiedOutlinedIcon from "@samacare/design/core/icons/VerifiedOutlined";
import DashboardOutlinedIcon from "@samacare/design/core/icons/DashboardOutlined";
import VaccinesIcon from "@samacare/design/core/icons/VaccinesIcon";

import ConfirmationNumberOutlinedIcon from "@samacare/design/core/icons/ConfirmationNumberOutlined";
import SellOutlinedIcon from "@samacare/design/core/icons/SellOutlined";
import SupportAgentIcon from "@samacare/design/core/icons/SupportAgent";
import { RouterLink } from "@@ui-kit";
import { Account } from "@samacare/graphql";
import { useApplicationContext } from "app/providers/ApplicationProvider";
import List from "@samacare/design/core/List";

import Tooltip from "@samacare/design/core/Tooltip/Tooltip";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useConfig } from "../../hooks";

export const MainPageOptions: React.VoidFunctionComponent<{
  account: Account;
}> = ({ account }) => {
  const config = useConfig();

  const { isNavigationMenuOpen } = useApplicationContext();

  const { enabled: enableDashboard, institutions: dashboardInstitutions } =
    useFeatureFlag<{
      enabled: boolean;
      institutions?: number[];
    }>(config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableHealixDashboard, {
      enabled: false,
    }) ?? {};

  const enableEligibilityCheck = useFeatureFlag<string>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEligibilityCheck
  );
  const enablePatientView = useFeatureFlag<string>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.DisplayPatientTreatmentView
  );
  const enableBenefitsVerification = useFeatureFlag<string>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBenefitsVerification
  );
  const enabledEnrollmentPrograms =
    useFeatureFlag<number[]>(
      config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.AvailableEnrollmentPrograms
    ) ?? [];

  const theme = useTheme();

  const options = [
    {
      route: ROUTE_PATHS.DASHBOARD,
      isEnabled:
        account &&
        enableDashboard &&
        dashboardInstitutions?.includes(Number(account?.institution?.id)),
      Icon: DashboardOutlinedIcon,
    },
    {
      route: ROUTE_PATHS.AUTHORIZATION_LIST,
      isEnabled: true,
      Icon: FactCheckOutlinedIcon,
    },
    {
      route: ROUTE_PATHS.BENEFITS_VERIFICATIONS,
      isEnabled: enableEligibilityCheck || enableBenefitsVerification,
      Icon: VerifiedOutlinedIcon,
    },
    {
      route: ROUTE_PATHS.PATIENTS_TREATMENT,
      isEnabled: enablePatientView,
      Icon: VaccinesIcon,
    },
    {
      route: ROUTE_PATHS.ENROLLMENTS,
      isEnabled: enabledEnrollmentPrograms.length > 0,
      Icon: ConfirmationNumberOutlinedIcon,
    },
    {
      route: ROUTE_PATHS.OPS_HUB_ROUTE,
      isEnabled: _.get(account, "isSamaUser", false),
      Icon: SupportAgentIcon,
    },
    {
      route: ROUTE_PATHS.TAGGER,
      isEnabled: _.get(account, "isSamaUser", false),
      Icon: SellOutlinedIcon,
    },
  ];

  return (
    <List>
      {options.map(({ route, isEnabled, Icon }) => {
        const { path, headerText } = route;
        const isSelected = matchPath(location.hash.slice(1), {
          exact: true,
          path,
        });
        // do not show page button for disabled pages
        if (!isEnabled) {
          return;
        }

        return (
          <RouterLink
            key={headerText}
            to={generatePath(path)}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Tooltip
              title={isNavigationMenuOpen ? "" : headerText}
              placement="right"
            >
              <ListItem
                disablePadding
                sx={{
                  borderRadius: theme.spacing(1),
                  display: "block",
                  backgroundColor: ({ palette }) =>
                    isSelected ? palette.colors.P50 : "white",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    borderRadius: theme.spacing(1),
                    justifyContent: isNavigationMenuOpen ? "initial" : "center",
                  }}
                  data-cy={`action${_.upperFirst(_.camelCase(headerText))}`}
                  disableGutters
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      mx: 1.5,
                    }}
                  >
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={headerText}
                    sx={{
                      opacity: isNavigationMenuOpen ? 1 : 0,
                      transition: "ease-in-out .3s",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </RouterLink>
        );
      })}
    </List>
  );
};
