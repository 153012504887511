import { useQuery } from "@apollo/client";

import { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@samacare/design/core";
import IconButton from "@samacare/design/core/IconButton";
import { Location } from "@samacare/graphql";
import { GetLocationsQuery } from "@@generated/graphql";
import { getLocations } from "../../../graphql/Location";
import { UpsertLocationDialog } from "./UpsertLocationDialog";
import EditIcon from "@samacare/design/core/icons/Edit";
import { GridToolbar } from "../../../components/DataGrid/GridToolbar";
import GridWrapper from "../../../components/DataGrid/GridWrapper";
import { DeleteLocationIconButton } from "./DeleteLocationIconButton";
import { LocationDetailDrawer } from "./LocationDetailDrawer";
import { useConfig, useFeatureFlag } from "../../../hooks";
import { useRefreshReadySelectedObject } from "../_common/hooks/useRefreshReadySelectedObject";

function LocationTableNoResults() {
  return (
    <Box display="flex" justifyContent="center" mt={3}>
      <Typography variant="body">No locations have been added.</Typography>
    </Box>
  );
}

const LocationsTable: React.FC = () => {
  const config = useConfig();
  const [selectedLocationForEdit, setSelectedLocationForEdit] =
    useState<Location | null>(null);

  const isDrawerEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.AdminDrawers
  );

  const { data, loading } = useQuery<GetLocationsQuery>(getLocations);
  const locationList = useMemo(
    () => data?.currentAccount?.institution?.locations ?? [],
    [data]
  );

  const [selectedLocationForView, setSelectedLocationForView] =
    useRefreshReadySelectedObject<Location>(locationList as Location[]);

  useEffect(() => {
    if (selectedLocationForView && locationList) {
      const newLocation =
        locationList.find(
          (location) => location.id === selectedLocationForView.id
        ) ?? null;
      setSelectedLocationForView(newLocation as Location);
    }
  }, [locationList]);

  const gridColumns: GridColDef[] = [
    { field: "facilityName", headerName: "Facility Name" },
    { field: "name", headerName: "Name" },
    { field: "address", headerName: "Address" },
    { field: "city", headerName: "City" },
    { field: "state", headerName: "State" },
    { field: "zip", headerName: "Zip" },
    { field: "fax", headerName: "Fax" },
    { field: "taxId", headerName: "Tax Id" },
    { field: "PTAN", headerName: "PTAN" },
    { field: "NPI", headerName: "NPI" },
    {
      field: "buttons",
      headerName: "",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (props: GridRenderCellParams) => (
        <Stack direction="row" width="100%" justifyContent="flex-end">
          <IconButton
            onClick={() => setSelectedLocationForEdit(props.row as Location)}
          >
            <EditIcon />
          </IconButton>
          <DeleteLocationIconButton location={props.row as Location} />
        </Stack>
      ),
    },
  ];

  return (
    <GridWrapper>
      {!loading && (
        <DataGridPro
          rows={locationList}
          columns={gridColumns}
          onRowClick={(params) => {
            if (isDrawerEnabled) {
              setSelectedLocationForView(params.row as Location);
            }
          }}
          autosizeOnMount
          autosizeOptions={{ expand: true, includeHeaders: true }}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: LocationTableNoResults,
          }}
          slotProps={{
            toolbar: {
              onNewClick: () => setSelectedLocationForEdit({} as Location),
              newButtonDataCy: "actionNewLocation",
            },
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
            sorting: {
              sortModel: [
                { field: "facilityName", sort: "asc" },
                { field: "name", sort: "asc" },
              ],
            },
          }}
        />
      )}

      <UpsertLocationDialog
        location={selectedLocationForEdit}
        onClose={() => setSelectedLocationForEdit(null)}
      />
      <LocationDetailDrawer
        location={selectedLocationForView}
        onClose={() => setSelectedLocationForView(null)}
      />
    </GridWrapper>
  );
};

export default LocationsTable;
