import IconButton from "@samacare/design/core/IconButton";
import { Location } from "@samacare/graphql";
import DeleteIcon from "@samacare/design/core/icons/Delete";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import { useDeleteLocation } from "../../../graphql/Location";

interface DeleteLocationIconButtonProps {
  location: Location;
}
export const DeleteLocationIconButton: React.VFC<
  DeleteLocationIconButtonProps
> = (props) => {
  const deleteLocation = useDeleteLocation();
  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: `Delete the location named ${props.location.name}?`,
      confirm: async () => deleteLocation(+props.location.id),
      zIndexOffset: 1,
    });

  return (
    <>
      <IconButton onClick={() => setConfirmDialogOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog {...confirmDialogProps} />
    </>
  );
};
