import { Box, Typography, Paper, Stack } from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import ReportProblemIcon from "@samacare/design/core/icons/ReportProblemIcon";
import { useConfig, useSamaCareBrandName } from "../../hooks/config";
import { Patient } from "@samacare/graphql";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useCurrentAccount } from "../../graphql/Account";
import { ErrorStatus } from "./ErrorStatus";
import { StatusDetails } from "./StatusDetails";
import { RunBvLiteCheckButton } from "./RunBvLiteCheckButton";

export interface InsuranceCoverageCheckProps {
  patient: Patient | null;
  isLoading: boolean;
  onRunClick: () => void;
  disabled?: boolean;
  eligibilityCheckError: boolean;
  submitErrors?: string[];
}

export const InsuranceCoverageCheck = ({
  patient,
  isLoading,
  onRunClick,
  disabled = false,
  eligibilityCheckError,
  submitErrors,
}: InsuranceCoverageCheckProps) => {
  const theme = useTheme();
  const config = useConfig();
  const brandName = useSamaCareBrandName();
  const [currentAccount] = useCurrentAccount();
  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEligibilityCheck
  );

  const latestCoverageCheck = patient?.primaryInsurance?.latestCoverageCheck;

  const invalidOrNoPriorRecord =
    (!latestCoverageCheck || !latestCoverageCheck?.isValid) &&
    !eligibilityCheckError;

  //if the patient's primary insurance and insurance state combination does not support BV lite
  //or BV lite is not enabled
  if (
    !isEnabled ||
    (!patient?.primaryInsurance?.supportsCoverageCheck &&
      patient?.primaryInsurance?.insuranceState != null)
  ) {
    return <Box />;
  }

  //if the patient does not have insurance state on record, we need to allow user to create BV lite for copilot
  if (!isEnabled || !patient?.primaryInsurance?.supportsCoverageCheck) {
    return <Box />;
  }

  return (
    <Paper
      elevation={4}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        minWidth: 600,
        padding: 2,
        marginTop: 2,
      }}
    >
      <Stack spacing={2} direction="column">
        <Typography variant="body2" sx={{ display: "inline" }}>
          <strong>New!</strong> {`${brandName}`} BV Lite can instantly check
          coverage for this insurance plan
        </Typography>
        {invalidOrNoPriorRecord && (
          <Stack spacing={1} direction="row" alignItems="center">
            <ReportProblemIcon sx={{ color: theme.palette.warning.light }} />
            <Typography
              variant="body2"
              sx={{
                display: "inline",
              }}
            >
              Check not run
            </Typography>
          </Stack>
        )}
        {
          // Show this error message only when the check is run and the insurance is not valid
          eligibilityCheckError && <ErrorStatus theme={theme} />
        }
        {latestCoverageCheck?.isValid && !eligibilityCheckError && (
          <StatusDetails
            patient={patient}
            currentAccount={currentAccount}
            latestCoverageCheck={latestCoverageCheck}
            eligibilityCheckError={eligibilityCheckError}
          />
        )}
        <RunBvLiteCheckButton
          handleClick={onRunClick}
          isLoading={isLoading}
          disabled={disabled || eligibilityCheckError}
          variant="contained"
        />
        {submitErrors && submitErrors.length > 0 && (
          <Stack justifyContent="flex-end" marginBottom={1} width={600}>
            <Typography variant="body2" color="error">
              {submitErrors.map((error, i) => (
                <Typography key={i} variant="body2" color="error">
                  {error}
                </Typography>
              ))}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};
