import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";
import RequiredFieldModal from "AuthorizationSharedComponents/RequiredFieldModal";

import {
  withPracticeSignatures,
  withSignatures,
  withDeleteSignature,
} from "../../../graphql/Signature";
import { withCurrentAccount } from "../../../graphql/Account";
import { withUpdateAuthorization } from "../../../graphql/Authorization";
import { isDisplayAuthEnrollment } from "../../../util/enrollUtils";
import { displayLetterOfMedicaNecessityForAuth } from "../../AuthorizationSharedSteps/LomnSection/LomnShared";
import {
  CommonSignatureBlock,
  NEW_TAB,
  SAVED_TAB,
  UPLOAD_TAB,
  allowFalsySignature,
} from "./CommonSignatureBlock";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PDFContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${(props) => props.theme.lightGray};
`;

const HelperText = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.darkGray};
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`;

// form doesn't exist on initial page load
const hideSignature = (form, authorization) => {
  //We requre a signature for auths that have a letter of medical necessity
  if (authorization == null) return false;
  if (displayLetterOfMedicaNecessityForAuth(authorization)) return false;

  return (
    !_.get(form, "hasTaggedSignature") ||
    authorization.type ===
      CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.value
  );
};

export class Signature extends PureComponent {
  constructor(props) {
    super(props);

    this.isForEnrollment = props.authorization == null;

    const signatureId = this.isForEnrollment
      ? props.enrollment?.SignatureFileId
      : props.authorization.formDetails.signatureId;

    this.mySignature = null;
    this.state = {
      tab: !signatureId ? NEW_TAB : SAVED_TAB,
      selectedSignatureId: signatureId,
      requiredFieldModalOpen: false,
      deleteSignatureId: null,
      deleteSignatureUrl: "",
    };

    if (props.getSignatureId) {
      props.getSignatureId(this.saveAndGetSignatureId);
    }
  }

  componentWillUnmount() {
    const { getSignatureId } = this.props;

    if (getSignatureId) {
      getSignatureId(null);
    }
  }

  updateState = (newState) => {
    this.setState(newState);
  };

  saveAndSetSignature = async (dataURL, addToInstitution = false) => {
    const { addSignature, alert, updateSignature } = this.props;

    try {
      const res = await addSignature({
        variables: { data: dataURL, addToInstitution },
      });
      this.setState({
        selectedSignatureId: res.data.createSignature.id,
        tab: SAVED_TAB,
      });
      if (updateSignature) {
        updateSignature(res.data.createSignature.id);
      }
    } catch (e) {
      alert.error("There was an error setting your signature");
    }
  };

  saveAndGetSignatureId = async () => {
    const { selectedSignatureId } = this.state;

    return selectedSignatureId;
  };

  signAndSubmit = async () => {
    const { nextStep } = this.props;

    const signatureId = await this.saveAndGetSignatureId();
    nextStep(signatureId);
  };

  triggerSubmit = () => {
    const { authorization, disabled, account, nextStep, form } = this.props;
    const { tab, selectedSignatureId } = this.state;

    if (disabled) return;

    if (hideSignature(form, authorization)) {
      nextStep(null);
    } else {
      if (
        !allowFalsySignature(account) &&
        ((tab === NEW_TAB && !selectedSignatureId) ||
          (tab === SAVED_TAB && !selectedSignatureId) ||
          tab === UPLOAD_TAB)
      ) {
        this.setState({ requiredFieldModalOpen: true });
        return;
      }

      this.signAndSubmit();
    }
  };

  handleDeleteSignature = async () => {
    const { deleteSignatureId } = this.state;
    const { deleteSignature, alert } = this.props;
    this.setState({
      selectedSignatureId: null,
      deleteSignatureId: null,
      deleteSignatureUrl: "",
    });
    try {
      await deleteSignature({ variables: { id: deleteSignatureId } });
      alert.info("Successfully deleted prescriber signature!");
    } catch {
      alert.error("Error deleting prescriber signature");
    }
  };

  render() {
    const {
      pdfPath,
      back,
      signatures,
      disabled,
      authorization,
      form,
      account,
      practiceSignatures,
      hideSignatureContainer,
      updateSignature,
    } = this.props;
    const {
      tab,
      selectedSignatureId,
      requiredFieldModalOpen,
      deleteSignatureId,
      deleteSignatureUrl,
    } = this.state;
    // This happens when one user saves a signature and another user goes to submit
    let otherUserSignatureId;
    if (
      selectedSignatureId &&
      !_.find(signatures, { id: selectedSignatureId })
    ) {
      otherUserSignatureId = selectedSignatureId;
    }

    const isDisplayEnrollment = isDisplayAuthEnrollment(authorization);

    return (
      <FormContainer>
        {!this.isForEnrollment && (
          <>
            <PDFContainer>
              <object
                data={pdfPath}
                height="700px"
                type="application/pdf"
                width="900px"
              >
                <embed
                  height="500px"
                  src={`${pdfPath}#view=FitH`}
                  type="application/pdf"
                  width="800px"
                />
              </object>
            </PDFContainer>
            <HelperText>
              Note: Our fax number will be entered into the form to allow
              receiving and processing responses on your behalf.
            </HelperText>

            {!hideSignature(form, authorization) ? (
              <CommonSignatureBlock
                practiceSignatures={practiceSignatures}
                updateState={this.updateState}
                tab={tab}
                selectedSignatureId={selectedSignatureId}
                otherUserSignatureId={otherUserSignatureId}
                signatures={signatures}
                deleteSignatureId={deleteSignatureId}
                account={account}
                deleteSignatureUrl={deleteSignatureUrl}
                handleDeleteSignature={this.handleDeleteSignature}
                disabled={disabled}
                saveAndSetSignature={this.saveAndSetSignature}
              />
            ) : hideSignatureContainer ? null : (
              <div>This form does not require a signature</div>
            )}
            <FormSubmitButtons
              back={back}
              submit={disabled ? null : this.triggerSubmit}
              submitText={isDisplayEnrollment ? null : "Submit"}
            />
            <RequiredFieldModal
              closeModal={() => {
                this.setState({ requiredFieldModalOpen: false });
              }}
              open={requiredFieldModalOpen}
            />
          </>
        )}
        {this.isForEnrollment && (
          <CommonSignatureBlock
            practiceSignatures={practiceSignatures}
            updateState={this.updateState}
            tab={tab}
            selectedSignatureId={selectedSignatureId}
            otherUserSignatureId={otherUserSignatureId}
            signatures={signatures}
            deleteSignatureId={deleteSignatureId}
            account={account}
            deleteSignatureUrl={deleteSignatureUrl}
            handleDeleteSignature={this.handleDeleteSignature}
            disabled={disabled}
            saveAndSetSignature={this.saveAndSetSignature}
            updateSignature={updateSignature}
            isForEnrollment={this.isForEnrollment}
          />
        )}
      </FormContainer>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withPracticeSignatures,
  withSignatures,
  withCurrentAccount,
  withDeleteSignature,
  withUpdateAuthorization
)(withAlert()(Signature));
