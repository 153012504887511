import { Typography, Stack, Alert } from "@samacare/design/core";
import { RunBvLiteCheckButton } from "./RunBvLiteCheckButton";
import { useTheme } from "@samacare/design/core/styles";

export const BvLiteSupportedAlert = ({
  message,
  handleClick,
  isLoading,
  disabled,
  backgroundColor,
  withIcon = false,
  displayAsRow = true,
}: {
  message: string;
  handleClick: () => void;
  isLoading: boolean;
  disabled: boolean;
  backgroundColor: string;
  withIcon?: boolean;
  displayAsRow?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Alert
      severity="warning"
      icon={false}
      action={
        displayAsRow ? (
          <RunBvLiteCheckButton
            handleClick={handleClick}
            isLoading={isLoading}
            disabled={disabled}
            backgroundColor={backgroundColor}
            withIcon={withIcon}
          />
        ) : null
      }
    >
      <Stack spacing={2} direction={displayAsRow ? "row" : "column"}>
        <Typography
          variant="body2"
          sx={{ display: "inline", color: theme.palette.warning.main }}
        >
          <strong>New!</strong>
          {message}
        </Typography>
        {!displayAsRow && (
          <RunBvLiteCheckButton
            handleClick={handleClick}
            isLoading={isLoading}
            disabled={disabled}
            backgroundColor={backgroundColor}
            withIcon={withIcon}
          />
        )}
      </Stack>
    </Alert>
  );
};
