import useStates, { type StateOption } from "./useStateOptions";

export type { StateOption };

const useStateOption = (
  value: string
): { states: StateOption[]; selectedState: StateOption | null } => {
  const states = useStates();
  return {
    states,
    selectedState:
      states.find(({ label, id }) => label === value || id === value) || null,
  };
};

export default useStateOption;
