import { useFormContext } from "react-hook-form";
import ISO6391 from "iso-639-1";

import AutocompleteField, {
  AutocompleteFieldProps,
} from "../AutocompleteField";

export type LanguageFieldProps = Omit<
  AutocompleteFieldProps<string, false, true, false>,
  "options"
>;

export const LanguageField: React.FC<LanguageFieldProps> = ({
  name,
  required,
  disabled,
  ...props
}) => {
  const { watch } = useFormContext();
  const languages = ISO6391.getAllNames();

  return (
    <AutocompleteField<string, false, true, false>
      name={name}
      required={required}
      disabled={disabled}
      disableClearable
      placeholder="Preferred Language"
      options={languages}
      value={watch(name) ?? ""}
      {...props}
    />
  );
};
