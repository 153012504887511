import gql from "graphql-tag";
import { useCallback } from "react";
import * as React from "react";
import BaseSelect from "react-select";
import { useQuery } from "@apollo/client";
import { QueryNdCsArgs } from "@samacare/graphql";
import { NdCsQuery } from "@@generated/graphql";
import { useSelector } from "../configureStore";

interface NdcObject {
  code: string;
  brandName: string;
}

interface NdcOption {
  value: NdcObject;
  label: string;
}

const df = window.CONFIG.DEFAULT_FIELDS;

export const NDCQuery = gql`
  query NDCs($searchTerm: String!) {
    NDCs(searchTerm: $searchTerm) {
      code
      brandName
      genericName
    }
  }
`;

/**
 * @deprecated Use a MUI component instead
 */
export const NdcSelector: React.VoidFunctionComponent<{
  onSelect: (NDCObject: NdcObject | null) => void;
  styleOverride?: { width?: string; marginRight?: string };
  controlStyle?: React.CSSProperties;
}> = ({ onSelect, styleOverride = { width: "100%" }, controlStyle }) => {
  const results = useSelector((state) => state.form.results);
  const [selectedOption, setSelectedOption] = React.useState<NdcOption>();

  const { data, error, loading, refetch } = useQuery<NdCsQuery, QueryNdCsArgs>(
    NDCQuery,
    { variables: { searchTerm: "" } }
  );

  const searchNDC = useCallback(
    (searchTerm: string) => {
      if (searchTerm != null && searchTerm !== "") {
        void refetch({ searchTerm });
      }
    },
    [refetch]
  );

  const filterOptions = useCallback(() => true, []);

  const onInputChange = useCallback(
    (
      option: {
        value: {
          // __typename?: "NDC" | undefined;
          code: string;
          brandName: string;
          // genericName: string;
        };
        label: string;
      } | null
    ) => {
      if (option != null) {
        onSelect(option.value);
        setSelectedOption(option);
      }
    },
    [onSelect]
  );

  React.useEffect(() => {
    if (selectedOption == null && results[df.PRIMARY_NDC.key] != null) {
      const code = results[df.PRIMARY_NDC.key] ?? "";
      const brandName = results[df.PRIMARY_DRUG_NAME.key] ?? "";
      setSelectedOption({
        value: { code, brandName },
        label: `${brandName} - ${code}`,
      });
    }
  }, [results, selectedOption]);

  const mappedOptions = (data?.NDCs ?? []).map((opt) => ({
    value: {
      code: opt.code,
      brandName: opt.brandName,
    },
    label: `${opt.brandName} - ${opt.code}`,
  }));

  return (
    <div style={styleOverride}>
      {error && <div>Error loading NDCs</div>}
      <BaseSelect
        placeholder="Search NDCs by brandname or code"
        isLoading={loading}
        options={mappedOptions}
        value={selectedOption}
        isMulti={false}
        onInputChange={searchNDC}
        onChange={onInputChange}
        // Always show all options, we rely on the backend to filter properly
        filterOption={filterOptions}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            ...controlStyle,
          }),
        }}
      />
    </div>
  );
};
