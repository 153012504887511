const neutral = {
    N0: "#FFFFFF",
    N50: "#fafafa",
    N100: "#f5f5f5",
    N200: "#eeeeee",
    N300: "#e0e0e0",
    N400: "#bdbdbd",
    N500: "#9e9e9e",
    N600: "#757575",
    N700: "#616161",
    N800: "#424242",
    N900: "#212121",
    N1000: "#0A0D14",
    NA100: "#d5d5d5",
    NA200: "#aaaaaa",
    NA400: "#616161",
    NA700: "#303030",
};
const orange = {
    O50: "#fff3e0",
    O100: "#ffe0b2",
    O200: "#ffcc80",
    O300: "#ffb74d",
    O400: "#ffa726",
    O500: "#ff9800",
    O600: "#fb8c00",
    O700: "#f57c00",
    O800: "#ef6c00",
    O900: "#e65100",
    OA100: "#ffd180",
    OA200: "#ffab40",
    OA400: "#ff9100",
    OA700: "#ff6d00",
};
const red = {
    R50: "#feebee",
    R100: "#fecdd2",
    R200: "#ef9a9a",
    R300: "#e57373",
    R400: "#ef5350",
    R500: "#f44336",
    R600: "#e53935",
    R700: "#d32f2f",
    R800: "#c62828",
    R900: "#b71c1c",
    RA100: "#ff8a80",
    RA200: "#ff5252",
    RA400: "#ff1744",
    RA700: "#d50000",
};
const green = {
    G50: "#e8f5e9",
    G100: "#c8e6c9",
    G200: "#a5d6a7",
    G300: "#81c784",
    G400: "#66bb6a",
    G500: "#4caf50",
    G600: "#43a047",
    G700: "#388e3c",
    G800: "#2e7d32",
    G900: "#1b5e20",
    GA100: "#b9f6ca",
    GA200: "#69f0ae",
    GA400: "#00e676",
    GA700: "#00c853",
};
const blue = {
    B50: "#e3f2fd",
    B100: "#bbdefb",
    B200: "#90caf9",
    B300: "#64b6f7",
    B400: "#42a5f5",
    B500: "#2196F3",
    B600: "#1e88e5",
    B700: "#1565c0",
    B800: "#0057b2",
    B900: "#0d47a1",
    BA100: "#82b1ff",
    BA200: "#448aff",
    BA400: "#2979ff",
    BA700: "#2962ff",
};
const purple = {
    P50: "#ECECFD",
    P100: "#D0D0FB",
    P200: "#B1B1F9",
    P300: "#9292F6",
    P400: "#7A7AF4",
    P500: "#6363F2",
    P600: "#5B5BF0",
    P700: "#5151EE",
    P800: "#4747EC",
    P900: "#3535E8",
    PA100: "#bdaaf8",
    PA200: "#9d81fb",
    PA400: "#825bff",
    PA700: "#5e2ff8",
};
const teal = {
    T50: "#e0f2f1",
    T100: "#b2dfdb",
    T200: "#80cbc4",
    T300: "#4db6ac",
    T400: "#26a69a",
    T500: "#009688",
    T600: "#00897b",
    T700: "#00796b",
    T800: "#00695c",
    T900: "#004d40",
    TA100: "#a7ffeb",
    TA200: "#64ffda",
    TA400: "#1de9b6",
    TA700: "#00bfa5",
};
const cyan = {
    C50: "#e0f7fa",
    C100: "#b2ebf2",
    C200: "#80deea",
    C300: "#4dd0e1",
    C400: "#26c6da",
    C500: "#00bcd4",
    C600: "#00acc1",
    C700: "#0097a7",
    C800: "#00838f",
    C900: "#006064",
    CA100: "#84ffff",
    CA200: "#18ffff",
    CA400: "#00e5ff",
    CA700: "#00b8d4",
};
const blueGrey = {
    BG50: "#eceff1",
    BG100: "#cfd8dc",
    BG200: "#b0bec5",
    BG300: "#90a4ae",
    BG400: "#78909c",
    BG500: "#607d8b",
    BG600: "#546e7a",
    BG700: "#455a64",
    BG800: "#37474f",
    BG900: "#263238",
    BGA100: "#cfd8dc",
    BGA200: "#b0bec5",
    BGA400: "#78909c",
    BGA700: "#455a64",
};
const yellow = {
    Y50: "#fffde7",
    Y100: "#fff9c4",
    Y200: "#fff59d",
    Y300: "#fff176",
    Y400: "#ffee58",
    Y500: "#ffeb3b",
    Y600: "#fdd835",
    Y700: "#fbc02d",
    Y800: "#f9a825",
    Y900: "#f57f17",
    YA100: "#ffff8d",
    YA200: "#ffff00",
    YA400: "#ffea00",
    YA700: "#ffd600",
};
const lime = {
    L50: "#f9fbe7",
    L100: "#f0f4c3",
    L200: "#e6ee9c",
    L300: "#dce775",
    L400: "#d4e157",
    L500: "#cddc39",
    L600: "#c0ca33",
    L700: "#afb42b",
    L800: "#9e9d24",
    L900: "#827717",
    LA100: "#f4ff81",
    LA200: "#eeff41",
    LA400: "#c6ff00",
    LA700: "#aeea00",
};
const lightGreen = {
    LG50: "#f1f8e9",
    LG100: "#dcedc8",
    LG200: "#c5e1a5",
    LG300: "#aed581",
    LG400: "#8bc34a",
    LG500: "#8bc34a",
    LG600: "#7cb342",
    LG700: "#689f38",
    LG800: "#558b2f",
    LG900: "#33691e",
    LGA100: "#ccff90",
    LGA200: "#b2ff59",
    LGA400: "#76ff03",
    LGA700: "#64dd17",
};
const deepOrange = {
    DO50: "#fbe9e7",
    DO100: "#ffccbc",
    DO200: "#ffab91",
    DO300: "#ff8a65",
    DO400: "#ff7043",
    DO500: "#ff5722",
    DO600: "#f4511e",
    DO700: "#e64a19",
    DO800: "#d84315",
    DO900: "#bf360c",
    DOA100: "#ff9e80",
    DOA200: "#ff6e40",
    DOA400: "#ff3d00",
    DOA700: "#dd2c00",
};
const pink = {
    PK50: "#fce4ec",
    PK100: "#f8bbd0",
    PK200: "#f48fb1",
    PK300: "#f06292",
    PK400: "#ec407a",
    PK500: "#e91e63",
    PK600: "#d81b60",
    PK700: "#c2185b",
    PK800: "#ad1457",
    PK900: "#880e4f",
    PKA100: "#ff80ab",
    PKA200: "#ff4081",
    PKA400: "#f50057",
    PKA700: "#c51162",
};
const deepPurple = {
    DP50: "#ede7f6",
    DP100: "#d1c4e9",
    DP200: "#b39ddb",
    DP300: "#9575cd",
    DP400: "#7e57c2",
    DP500: "#673ab7",
    DP600: "#5e35b1",
    DP700: "#512da8",
    DP800: "#4527a0",
    DP900: "#311b92",
    DPA100: "#b388ff",
    DPA200: "#7c4dff",
    DPA400: "#651fff",
    DPA700: "#6200ea",
};
const indigo = {
    I50: "#e8eaf6",
    I100: "#c5cae9",
    I200: "#9fa8da",
    I300: "#7986cb",
    I400: "#5c6bc0",
    I500: "#3f51b5",
    I600: "#3949ab",
    I700: "#303f9f",
    I800: "#283593",
    I900: "#1a237e",
    IA100: "#8c9eff",
    IA200: "#536dfe",
    IA400: "#3d5afe",
    IA700: "#304ffe",
};
const lightBlue = {
    LB50: "#e1f5fe",
    LB100: "#b3e5fc",
    LB200: "#81d4fa",
    LB300: "#4fc3f7",
    LB400: "#29b6f6",
    LB500: "#03a9f4",
    LB600: "#039be5",
    LB700: "#0288d1",
    LB800: "#0277bd",
    LB900: "#01579b",
    LBA100: "#80d8ff",
    LBA200: "#40c4ff",
    LBA400: "#00b0ff",
    LBA700: "#0091ea",
};
const colors = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, neutral), blue), purple), green), orange), red), teal), cyan), blueGrey), yellow), lime), lightGreen), deepOrange), pink), deepPurple), indigo), lightBlue);
export default colors;
