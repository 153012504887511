import _ from "lodash";
import { PureComponent } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { compose } from "recompose";

import styled from "styled-components";
import strings from "Resources/strings";

import { MdArchive as ArchiveIcon } from "@react-icons/all-files/md/MdArchive";
import { MdDelete as DeleteIcon } from "@react-icons/all-files/md/MdDelete";
import { MdRemove as RemoveFromExpired } from "@react-icons/all-files/md/MdRemove";
import { MdVerticalAlignBottom as Download } from "@react-icons/all-files/md/MdVerticalAlignBottom";
import { MdContentCopy as Duplicate } from "@react-icons/all-files/md/MdContentCopy";
import { MdEdit as Edit } from "@react-icons/all-files/md/MdEdit";
import { MdCached as Resend } from "@react-icons/all-files/md/MdCached";

import { withCurrentAccount } from "../../graphql/Account";

import ToggleArchiveButton from "../ToggleArchiveButton";
import ToggleHideButton from "../ToggleHideButton";
import DeleteAuthorizationButton from "../DeleteAuthorizationButton";
import DownloadFormsButton from "../DownloadFormsButton";
import DuplicateAuthorizationButton from "../DuplicateAuthorizationButton";
import RequestResendButton from "../RequestResendButton";
import ResubmitPortalAuthButton from "../ResubmitPortalAuthButton";
import EditAndResubmitButton from "../EditAndResubmitButton";
import { AuthorizationType } from "@samacare/graphql";

const ActionButton = styled.button`
  display: flex;
  padding: 3px 5px;
  font-size: 13px;
  flex-direction: row;
  color: ${({ supported, theme }) => (supported ? "white" : theme.darkGray)};
  background-color: ${({ supported, theme }) =>
    supported ? theme.purple : "white"};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid
    ${({ supported, theme }) => (supported ? theme.purple : theme.darkGray)};
  margin-right: 15px;
  border-radius: 3px;

  &:hover {
    color: white;
    background: ${({ theme }) => theme.darkGray};
    border: 1px solid ${({ theme }) => theme.darkGray};
  }

  &:focus {
    outline: 0;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  font-size: 18px;
`;

const TextWrapper = styled.div`
  flex: 1;
`;

export const guardAuth = ({ createdById, createdAt }, accountId) =>
  createdById !== accountId &&
  moment(createdAt).isAfter(moment().subtract(10, "minutes"));

const { CONSTANTS } = CONFIG;

const ButtonWrap = ({ Icon, text, props }) => (
  <ActionButton {...props}>
    <IconWrapper>
      <Icon />
    </IconWrapper>
    <TextWrapper>{text}</TextWrapper>
  </ActionButton>
);

const { TERMINAL_AUTHORIZATION_STATUSES } = CONFIG.CONSTANTS;

export class ActionButtons extends PureComponent {
  getButtons = () => {
    const { authorization, authorizationList, loading, account } = this.props;
    const { tabTitle } = authorizationList.selected;
    const statuses = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
    const buttons = [];
    const isNotPortalCopilotAuth =
      authorization.type !== AuthorizationType.PortalCopilot;

    if (loading) return null;

    if (
      authorization.status === statuses.PRESUBMISSION &&
      !guardAuth(authorization, account.id)
    ) {
      buttons.push(
        <DeleteAuthorizationButton
          key={`authDeleteButton-id-${authorization.id}`}
          id={authorization.id}
          step={authorization.formDetails.currentStep}
          portalKey={authorization.portalKey}
          isReferral={authorization.isReferral}
          authorization={authorization}
        >
          <ButtonWrap Icon={DeleteIcon} text="Delete" />
        </DeleteAuthorizationButton>
      );
    }
    if (
      _.includes(
        CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ARCHIVABLE,
        authorization.status
      )
    ) {
      buttons.push(
        <ToggleArchiveButton
          key={`authArchiveButton-id-${authorization.id}`}
          id={authorization.id}
          isArchived={authorization.isArchived}
        >
          <ButtonWrap
            Icon={ArchiveIcon}
            text={
              authorization.isArchived
                ? strings.AUTHORIZATIONS.UNARCHIVE
                : strings.AUTHORIZATIONS.ARCHIVE
            }
          />
        </ToggleArchiveButton>
      );
    }
    if (
      tabTitle === strings.AUTHORIZATIONS.EXPIRING ||
      (tabTitle === strings.AUTHORIZATIONS.ARCHIVED &&
        authorization.hideFromExpiring)
    ) {
      buttons.push(
        <ToggleHideButton
          key={`authHideButton-id-${authorization.id}`}
          id={authorization.id}
          hideFromExpiring={authorization.hideFromExpiring}
        >
          <ButtonWrap
            Icon={RemoveFromExpired}
            text={
              authorization.hideFromExpiring
                ? strings.AUTHORIZATIONS.UNHIDE
                : strings.AUTHORIZATIONS.HIDE
            }
          />
        </ToggleHideButton>
      );
    }
    if (authorization.submittedAt) {
      buttons.push(
        <DuplicateAuthorizationButton
          key={`duplicateAuthorizationButton-id-${authorization.id}`}
          authorization={authorization}
          account={account}
        >
          <ButtonWrap Icon={Duplicate} text="Duplicate" />
        </DuplicateAuthorizationButton>
      );
    }
    if (!_.isEmpty(_.filter(authorization.correspondences, "fileURL"))) {
      buttons.push(
        <DownloadFormsButton
          key={`authDownloadFormsButton-id-${authorization.id}`}
          authorization={authorization}
        >
          <ButtonWrap Icon={Download} text="Download Files" />
        </DownloadFormsButton>
      );
    }

    if (
      // we want to allow re-editability if denied
      !_.includes(
        [
          statuses.PRESUBMISSION,
          statuses.EDIT_AND_RESUBMIT,
          statuses.MODIFIED_APPROVAL,
          statuses.APPROVED,
          statuses.DUPLICATE,
          statuses.NO_AUTH_REQUIRED,
          statuses.SENT_TO_HCP,
          statuses.VOIDED,
        ],
        authorization.status
      ) &&
      !authorization.portalKey
    ) {
      buttons.push(
        <RequestResendButton
          key={`authRequestResendButton-id-${authorization.id}`}
          authorizationId={authorization.id}
          isAdministrativeResend={
            authorization.status !== statuses.SENDING_FAILED
          }
          insuranceCompanyId={authorization.insuranceCompany?.id}
          authorizationFormId={authorization.formId}
        >
          <ButtonWrap Icon={Resend} text="Resend" />
        </RequestResendButton>
      );
      if (
        authorization.type !==
          CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.value &&
        isNotPortalCopilotAuth
      ) {
        buttons.push(
          <EditAndResubmitButton
            key={`editAndResubmitButton-id-${authorization.id}`}
            authorizationId={authorization.id}
          >
            <ButtonWrap
              data-cy="actionEditAndResend"
              Icon={Edit}
              text="Edit and Resend"
            />
          </EditAndResubmitButton>
        );
      }
    }

    if (
      !_.includes(
        [
          statuses.PRESUBMISSION,
          statuses.EDIT_AND_RESUBMIT,
          ...TERMINAL_AUTHORIZATION_STATUSES,
        ],
        authorization.status
      ) &&
      authorization.portalKey &&
      !authorization.isWebExtension &&
      isNotPortalCopilotAuth
    ) {
      buttons.push(
        <ResubmitPortalAuthButton
          key={`ResubmitPortalAuthButton-id-${authorization.id}`}
          authorizationId={authorization.id}
        >
          <ButtonWrap Icon={Resend} text="Resend" />
        </ResubmitPortalAuthButton>
      );
    }

    return buttons;
  };

  render() {
    const { authorization } = this.props;
    return (
      <ButtonRow key={`ButtonRow-${authorization.id}`}>
        {this.getButtons()}
      </ButtonRow>
    );
  }
}

function mapStateToProps(state) {
  return {
    authorizationList: state.authorizationList,
  };
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withCurrentAccount)(
  connect(mapStateToProps)(ActionButtons)
);
