import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  Box,
  LoadingButton,
  Typography,
  Stack,
  CircularProgress,
  Paper,
} from "@samacare/design/core";
import { Authorization } from "@samacare/graphql";
import Verified from "@samacare/design/core/icons/Verified";
import { useTheme } from "@samacare/design/core/styles";
import ReportProblemOutlinedIcon from "@samacare/design/core/icons/ReportProblemOutlined";
import {
  GetBenefitsVerificationByPatientAndDrugQueryQuery,
  GetBenefitsVerificationByPatientAndDrugQueryQueryVariables,
  CreateABvFromAnAuthMutation,
  CreateABvFromAnAuthMutationVariables,
} from "@@generated/graphql";
import { useConfig, useSamaCareBrandName } from "../../hooks/config";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import ROUTES from "../../routes/ROUTE_PATHS";
import GetBenefitsVerificationByPatientAndDrugQuery from "./GetBenefitsVerificationByPatientAndDrugQuery.gql";
import CreateABvFromAnAuth from "./CreateBvFromAuthMutation.gql";
import { useEnabledDrugOptions } from "../../hooks";

export interface SponsoredBvCreationSectionProps {
  auth: Authorization;
}

export const SponsoredBvCreationSection = ({
  auth,
}: SponsoredBvCreationSectionProps) => {
  const { patient, HCPCSCodes, DrugOptionId } = auth;

  const config = useConfig();
  const theme = useTheme();
  const brandName = useSamaCareBrandName();

  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBenefitsVerification
  );

  const { loading: drugOptionLoading, enabledDrugIds } =
    useEnabledDrugOptions();

  const { data, loading } = useQuery<
    GetBenefitsVerificationByPatientAndDrugQueryQuery,
    GetBenefitsVerificationByPatientAndDrugQueryQueryVariables
  >(GetBenefitsVerificationByPatientAndDrugQuery, {
    skip: !patient?.id || !DrugOptionId,
    variables: {
      patientId: Number(patient?.id),
      DrugOptionId,
    },
  });

  const [createBvFromAuth] = useMutation<
    CreateABvFromAnAuthMutation,
    CreateABvFromAnAuthMutationVariables
  >(CreateABvFromAnAuth, {
    refetchQueries: [
      GetBenefitsVerificationByPatientAndDrugQuery,
      "allBenefitsVerifications",
      "GetAuthorization",
    ],
  });

  const mostRecentBv = data?.getBenefitsVerificationsByPatientAndDrug?.[0];
  const bvNotSupported =
    DrugOptionId == null || !(enabledDrugIds ?? []).includes(DrugOptionId);

  if (!isEnabled || !HCPCSCodes || bvNotSupported) {
    return <Box />;
  }

  const getBvStatusAndButton = (): {
    message: string;
    icon: JSX.Element;
    button: JSX.Element;
  } => {
    if (mostRecentBv != null) {
      return {
        message: `Benefit Verification created on ${moment(
          mostRecentBv.createdAt
        ).format("YYYY-MM-DD HH:mm")}`,
        icon: <Verified sx={{ color: theme.palette.primary.main }} />,

        button: (
          <LoadingButton
            data-cy="actionViewBenefitsVerificationFromPa"
            type="button"
            size="small"
            variant="outlined"
            onClick={async () => {
              window.open(
                `${window.location.origin}/#${ROUTES.BENEFITS_VERIFICATIONS.path}/edit/${mostRecentBv.id}`,
                "_blank"
              );
            }}
            sx={{ marginTop: ({ spacing }) => spacing(1) }}
          >
            View Benefit Verification
          </LoadingButton>
        ),
      };
    }
    return {
      message: "Benefit Verification not yet created",
      icon: (
        <ReportProblemOutlinedIcon sx={{ color: theme.palette.error.light }} />
      ),
      button: (
        <LoadingButton
          data-cy="actionCreateBenefitsVerificationFromPa"
          type="button"
          size="small"
          variant="contained"
          onClick={async () => {
            const res = await createBvFromAuth({
              variables: {
                authId: Number(auth.id),
              },
            });
            window.open(
              `${window.location.origin}/#${ROUTES.BENEFITS_VERIFICATIONS.path}/edit/${res?.data?.createBvFromAuth}`,
              "_blank"
            );
          }}
          sx={{ marginTop: ({ spacing }) => spacing(1) }}
        >
          Create Benefit Verification
        </LoadingButton>
      ),
    };
  };

  return (
    <Paper
      elevation={4}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        maxWidth: 600,
        padding: 2,
        marginTop: 2,
        marginLeft: 0.5,
      }}
    >
      <Stack spacing={2} direction="column">
        {(loading || drugOptionLoading) && <CircularProgress />}
        <Typography variant="body2" sx={{ display: "inline" }}>
          <strong>New!</strong> {`${brandName}`} provides Benefit Verfication
          for this drug
        </Typography>
        <Stack spacing={1} direction="row" alignItems="center">
          {getBvStatusAndButton().icon}
          <Typography
            variant="body2"
            sx={{
              display: "inline",
            }}
          >
            {getBvStatusAndButton().message}
          </Typography>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="center">
          {getBvStatusAndButton().button}
          <Typography
            variant="body2"
            sx={{
              display: "inline",
            }}
          >
            (Opens in a new tab)
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
