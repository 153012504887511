import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import moment from "moment";
import PatientSearch from "../PatientSearch";
import { Patient } from "@samacare/graphql";
import Section from "../Section";
import SelectPatientModal from "../SelectPatientModal";
import {
  useSetPatientOnAuth,
  useRemovePatientOnAuth,
} from "../../../graphql/Patient";

const fields = window.CONFIG.DEFAULT_FIELDS;

interface PatientSectionProps {
  authorizationId: number;
  dob: string;
  firstName: string;
  lastName: string;
  institutionPatientId: string;
  patientId: number;
  showGenderField: boolean;
  showSearchByMRN?: boolean;
}

export const PatientSection: React.VFC<PatientSectionProps> = ({
  authorizationId,
  dob,
  firstName,
  lastName,
  institutionPatientId,
  patientId,
  showGenderField,
  showSearchByMRN,
}) => {
  const patientFirstNameInputRef = useRef<HTMLInputElement>(null);
  const patientLastNameInputRef = useRef<HTMLInputElement>(null);
  const patientDOBInputRef = useRef<HTMLInputElement>(null);
  const institutionPatientIdRef = useRef<HTMLInputElement>(null);
  const patientSearchListRef = useRef<typeof PatientSearch>(null);

  const [showPatientSelectionModal, setShowPatientSelectionModal] =
    useState(false);
  const [patientResults, setPatientResults] = useState<Patient[]>([]);
  const [matchingPatientResults, setMatchingPatientResults] = useState<
    Patient[]
  >([]);

  const hasPatientBeenSelected = !_.isNil(patientId);
  const [showPatientSearch, setShowPatientSearch] = useState(false);

  const [setPatient] = useSetPatientOnAuth();

  const [removePatient] = useRemovePatientOnAuth();

  const onBlur = useCallback(() => {
    const newMatchingPatientResults = patientResults.filter(
      (patient: Patient) =>
        moment(dob).isSame(patient.dob, "day") && firstName && lastName
    );
    setMatchingPatientResults(newMatchingPatientResults);
    setShowPatientSelectionModal(!_.isEmpty(newMatchingPatientResults));
  }, [dob, firstName, lastName, patientResults]);

  const handleDocumentClick = useCallback(
    (event: Event) => {
      // Filter out refs for any unrendered elements
      const refs = [
        patientFirstNameInputRef,
        patientLastNameInputRef,
        patientDOBInputRef,
        institutionPatientIdRef,
        patientSearchListRef,
      ].filter((ref) => !_.isNil(ref.current));

      // Determine if the click is targeting one of the patient input elements
      const clickTargetsRelevantInput = refs.some((ref) => {
        // XXX(ndhoule): This is a little janky, but with the way styled
        // components handles refs, we don't have a lot of choice
        const node =
          ref.current instanceof Component
            ? // eslint-disable-next-line react/no-find-dom-node
              ReactDOM.findDOMNode(ref.current)
            : ref.current;
        return (
          node != null &&
          node instanceof Element &&
          node.contains(event.target as Node)
        );
      });

      // If the click targets anything but one of our input elements or the
      // patient search results list, hide the patient search result list
      if (!clickTargetsRelevantInput) {
        setShowPatientSearch(false);
      }
    },
    [
      patientFirstNameInputRef,
      patientLastNameInputRef,
      patientDOBInputRef,
      patientSearchListRef,
      setShowPatientSearch,
    ]
  );

  // Because the list floats above other content, we want to make sure that this
  // UI element doesn't block them from accessing that content. Add a listener
  // that will dismiss the patient search list if the user clicks on an element
  // other than the search list or one of the inputs that feeds into it.
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  // In the event a user dismisses the patient search list by clicking on the
  // page, re-open it if they type into the first name/last name/DOB fields.
  const onInput = useCallback(
    () => setShowPatientSearch(true),
    [setShowPatientSearch]
  );

  return (
    <Section
      childrenAfterSection
      // If a patient has already been selected for this authorization,
      // disallow modification of the selected patient. If the user wants to
      // change the currently selected patient, they should click the "Change
      // Patient" button.
      disabled={hasPatientBeenSelected}
      section={{
        items: [
          [
            {
              ...fields.PATIENT_FIRST_NAME,
              onBlur,
              onChange: onInput,
              ref: patientFirstNameInputRef,
            },
            {
              ...fields.PATIENT_LAST_NAME,
              onBlur,
              onChange: onInput,
              ref: patientLastNameInputRef,
            },
            {
              ...fields.PATIENT_DOB,
              onBlur,
              onChange: onInput,
              ref: patientDOBInputRef,
            },
            ...(showSearchByMRN
              ? [
                  {
                    ...fields.INSTITUTION_PATIENT_ID,
                    onBlur,
                    onChange: onInput,
                    ref: institutionPatientIdRef,
                  },
                ]
              : []),
            ...(showGenderField
              ? [
                  {
                    key: "genderSelector",
                    title: "Patient Gender",
                    required: true,
                    type: window.CONFIG.CONSTANTS.FORM_TYPES.OPTION,
                    options: [
                      fields.PATIENT_GENDER_MALE,
                      fields.PATIENT_GENDER_FEMALE,
                    ],
                    onChange: onInput,
                  },
                ]
              : []),
            ...(hasPatientBeenSelected
              ? [
                  {
                    text: "Change Patient",
                    enable: true,
                    type: window.CONFIG.CONSTANTS.FORM_TYPES.ACTION,
                    action: async () => {
                      await removePatient({ variables: { authorizationId } });
                    },
                    onChange: onInput,
                  },
                ]
              : []),
          ],
        ],
        title: "Patient",
      }}
    >
      {
        // Don't show the patient search box for the current authorization if
        // a patient has already been selected; the user needs to click the
        // "Change Patient" button first.
        !hasPatientBeenSelected && showPatientSearch && (
          <PatientSearch
            floating
            ref={patientSearchListRef}
            firstName={firstName}
            lastName={lastName}
            dob={dob}
            institutionPatientId={institutionPatientId}
            setPatient={async (patient: Patient) => {
              await setPatient({
                variables: { patientId: parseInt(patient.id), authorizationId },
              });
            }}
            onResult={(patients: Patient[]) => setPatientResults(patients)}
          />
        )
      }
      {!_.isEmpty(matchingPatientResults) && (
        <SelectPatientModal
          open={showPatientSelectionModal}
          patients={matchingPatientResults}
          setPatient={async (patient: Patient) => {
            await setPatient({
              variables: { patientId: parseInt(patient.id), authorizationId },
            });
            setShowPatientSelectionModal(false);
          }}
          onCloseModal={() => setMatchingPatientResults([])}
        />
      )}
    </Section>
  );
};
