import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import { withDefaultProps } from "./utils";
import locationInfo from "./fragments/locationInfo";
import { useMutation } from "@apollo/client";
import { Location } from "@samacare/graphql";

export const LOCATIONS_QUERY = "getLocations";

export const getLocations = gql`
  query getLocations {
    currentAccount {
      id

      institution {
        id
        locations {
          ...locationInfo
        }
      }
    }
  }
  ${locationInfo}
`;

export const withLocations = graphql(getLocations, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    locations: _.get(data, "currentAccount.institution.locations"),
  })),
});

export const upsertLocationMutation = gql`
  mutation upsertLocation($id: Int, $patch: JSON!) {
    upsertLocation(id: $id, patch: $patch) {
      ...locationInfo
    }
  }
  ${locationInfo}
`;

export const withUpsertLocation = graphql(upsertLocationMutation, {
  name: "upsertLocation",
  options: () => ({ refetchQueries: () => [LOCATIONS_QUERY] }),
});

const deleteLocationMutation = gql`
  mutation deleteLocation($id: Int!) {
    deleteLocation(id: $id)
  }
`;

export const createLocationByNpiFromRegistryMutation = gql`
  mutation createLocationByNpiFromRegistry($npi: String!) {
    createLocationByNpiFromRegistry(npi: $npi) {
      success
      message
    }
  }
`;

export const useUpsertLocation = () => {
  const [upsertLocation] = useMutation(upsertLocationMutation, {
    refetchQueries: () => [LOCATIONS_QUERY],
  });

  return async (location: Location) => {
    await upsertLocation({
      variables: {
        id: location.id,
        patch: {
          ...location,
        },
      },
    });
  };
};

export const useDeleteLocation = () => {
  const [deleteLocation] = useMutation(deleteLocationMutation, {
    refetchQueries: () => [LOCATIONS_QUERY],
  });

  return async (locationId: number) => {
    await deleteLocation({
      variables: {
        id: locationId,
      },
    });
  };
};
