import { Box, Button, Stack, Typography } from "@samacare/design";
import {
  FormProvider,
  IcdField,
  PatientBlock,
  useForm,
  RequesterBlock,
  ProviderBlock,
  RenderingProviderBlock,
  ServiceBlock,
  EdiInsuranceBlock,
} from "@samacare/form";
import { EnrollmentSection } from "../Enrollment/EnrollmentSection";
import { LeftRightCenterAll } from "../../components/LeftRightV2";
import { useCreateEdiAuthorization } from "../../hooks/useCreateEdiAuthorization";
import { GenericAuth } from "../../types/GenericAuth";
import { useCurrentAccount } from "../../graphql/Account";
import { useAlert } from "react-alert";
import { useEffect } from "react";
import { useHistory } from "../../hooks";
import ROUTE_PATHS from "../ROUTE_PATHS";

export const EdiAuthCreate = () => {
  const alert = useAlert();
  const history = useHistory();
  const [currentAccount] = useCurrentAccount();
  const createEPaAuth = useCreateEdiAuthorization();
  const methods = useForm<any>({
    defaultValues: {
      isRenderingSameAsRequesting: true,
    },
  });

  useEffect(() => {
    if (currentAccount) {
      const requesterName = `${currentAccount.firstName} ${currentAccount.lastName}`;
      methods.setValue("requester.contactName", requesterName);
      methods.setValue("requester.phone", currentAccount.phone);
    }
  }, [currentAccount]);

  const handleSubmit = methods.handleSubmit(async (data) => {
    const renderingProvider = data.isRenderingSameAsRequesting
      ? { ...data.requestingProvider }
      : { ...data.renderingProvider };
    delete renderingProvider.roleCode;
    delete renderingProvider.PrescriberId;

    const genericAuth: GenericAuth = {
      PatientId: +data.PatientId,
      InstitutionId: currentAccount?.InstitutionId ?? 1,
      insurance: {
        memberId: data.patient.primaryInsurance.memberId,
        InsuranceCompanyId: +data.patient.primaryInsurance.InsuranceCompanyId,
        relationshipToSubscriber:
          data.patient.primaryInsurance.relationshipToSubscriber,
      },
      requester: data.requester,
      requestingProvider: data.requestingProvider,
      renderingProvider,
      icdCodes: data.diagnoses?.map((d: any) => d.code) ?? [],
      placeOfServiceCode: data.placeOfServiceCode,
      services: Object.keys(data.services).map((key) => ({
        hcpcsCode: data.services[key].hcpcs.code,
        quantityType: data.services[key].quantityType,
        quantity: data.services[key].quantity,
        description: data.services[key].description,
        startDate: data.services[key].startDate,
      })),
    };

    try {
      await createEPaAuth(genericAuth);
      alert.success("Prior Authorization created successfully");
      history.push(ROUTE_PATHS.AUTHORIZATION_LIST.path);
    } catch (e) {
      alert.error("Failed to create Prior Authorization");
    }
  });

  return (
    <Stack>
      <Box borderBottom="1px solid" borderColor="rgba(28, 28, 28, 0.1)">
        <Stack direction="row" spacing={1} m={2}>
          <Typography variant="h6">Prior Authorization</Typography>
        </Stack>
      </Box>
      <Box p={2} pb={4}>
        <FormProvider {...methods}>
          <EnrollmentSection title="Patient">
            <PatientBlock required />
          </EnrollmentSection>
          <EnrollmentSection title="Insurance">
            <EdiInsuranceBlock required />
          </EnrollmentSection>
          <EnrollmentSection title="Service">
            <ServiceBlock required />
          </EnrollmentSection>
          <EnrollmentSection title="Diagnosis">
            <IcdField
              name="diagnoses"
              required
              override={{ limit: 5 }}
              displayLimitInLabel
            />
          </EnrollmentSection>
          <EnrollmentSection title="Requester Details">
            <RequesterBlock required />
          </EnrollmentSection>
          <EnrollmentSection title="Requesting Provider">
            <ProviderBlock required />
          </EnrollmentSection>
          <EnrollmentSection title="Rendering Provider">
            <RenderingProviderBlock required />
          </EnrollmentSection>
          <LeftRightCenterAll sx={{ maxWidth: 600 }}>
            <Button variant="contained" onClick={handleSubmit}>
              Submit Prior Authorization
            </Button>
          </LeftRightCenterAll>
        </FormProvider>
      </Box>
    </Stack>
  );
};
