import { Stack } from "@samacare/design/core";
import PhoneField, { type PhoneFieldProps } from "../PhoneField";
import GenderField, { type GenderFieldProps } from "../GenderField";

const defaultGenderInputProps: GenderFieldProps = {
  name: "patient.gender",
  label: "Gender",
  sx: { maxWidth: 200 },
};

const defaultPhoneInputProps: PhoneFieldProps = {
  name: "patient.phone",
  label: "Phone",
};
export type PatientInformationBlockProps = {
  required?: boolean;
  disabled?: boolean;
  GenderInputProps?: Partial<GenderFieldProps>;
  PhoneInputProps?: Partial<PhoneFieldProps>;
};

const PatientInformationBlock: React.FC<PatientInformationBlockProps> = ({
  required = false,
  disabled = false,
  PhoneInputProps = {},
  GenderInputProps = {},
}) => {
  return (
    <Stack direction="row" spacing={1} maxWidth={600}>
      <GenderField
        {...GenderInputProps}
        required={required}
        disabled={disabled}
        {...defaultGenderInputProps}
        {...GenderInputProps}
        fullWidth
      />
      <PhoneField
        required={required}
        disabled={disabled}
        {...defaultPhoneInputProps}
        {...PhoneInputProps}
        fullWidth
      />
    </Stack>
  );
};

export default PatientInformationBlock;
