import { useHistory } from "react-router-dom";

import { Box, Button } from "@samacare/design";

import getAuthorizationRoute from "../../../util/getAuthorizationRoute";
import { SearchResultItem } from "../types";

export const GridCellAuthorization: React.FC<{
  authorization: SearchResultItem["authorization"];
}> = ({ authorization }) => {
  const history = useHistory();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {authorization?.id != null && (
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            history.push(
              `${getAuthorizationRoute(authorization)}?step=${
                (
                  authorization.formDetails as {
                    currentStep: number;
                  } | null
                )?.currentStep?.toString() ?? "1"
              }&id=${authorization.id}`
            );
          }}
        >
          View
        </Button>
      )}
    </Box>
  );
};
