import { PureComponent } from "react";
import _ from "lodash";

import ROUTE_PATHS from "../routes/ROUTE_PATHS";
import { getInputValue } from "../util/inputConfigurationManager";
import UserSessionRecorder from "../services/UserSessionRecorder";
import trimAuthorizationConfiguration from "../util/trimAuthorizationConfiguration";
import { AuthorizationType } from "@samacare/graphql";

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export const NewAuthorizationHoc = (WrappedComponent) =>
  class IntegrationWebSocketProvider extends PureComponent {
    constructor(props) {
      _.each(
        [
          "location",
          "setStep",
          "step",
          "goToLocation",
          "reset",
          "updateAuthorizationProgress",
          "alert",
          "updateFormDetails",
          "toggleHighlightRequiredFields",
        ],
        (requiredProp) => {
          if (_.isNil(props[requiredProp]))
            throw new Error(
              `Missing required prop ${requiredProp} in new authorization component`
            );
        }
      );

      super(props);
    }

    async componentDidMount() {
      const { location } = this.props;
      const params = new URLSearchParams(location.search);
      await UserSessionRecorder.tagAuthorizationSession(params.get("id"));
    }

    async componentWillUnmount() {
      const { reset } = this.props;
      reset();
    }

    saveAuthorizationChanges = async (overrides = {}) => {
      const {
        updateAuthorizationProgress,
        authorization,
        results,
        attachments,
        account,
        alert,
        updateAuthorizationProgressWithoutPatient,
        DrugOptionId,
      } = this.props;
      if (!account.isReadOnly) {
        try {
          let wrotePatient = false;
          if (
            updateAuthorizationProgressWithoutPatient &&
            overrides.patientId == null &&
            authorization.patient?.id == null
          ) {
            await updateAuthorizationProgressWithoutPatient({
              variables: {
                id: authorization.id,
                DrugOptionId,
                config: trimAuthorizationConfiguration(results),
                ...overrides,
              },
            });
          } else {
            const res = await updateAuthorizationProgress({
              variables: {
                id: authorization.id,
                DrugOptionId,
                config: trimAuthorizationConfiguration(results),
                attachmentIds: _.map(attachments || [], "id"),
                // note: if updating details in this method, you will run into bugs being overridden
                ...overrides,
                // This needs to be below overrides or else when the value is null, patientId won't default properly to authorization.patient.id
                patientId: overrides.patientId || authorization.patient?.id,
              },
            });
            if (
              (res?.data?.writeAuthorizationResultsToPatient ?? []).length > 0
            )
              wrotePatient = true;
          }
          alert.success(
            wrotePatient
              ? `Saved changes, patient "${authorization.config.PATIENT_LAST_NAME}" updated!`
              : "Successfully saved changes!"
          );
        } catch (e) {
          alert.error(
            "Failed to save authorization changes, if this persists, please contact SamaCare"
          );
          throw e;
        }
      }
    };

    setStepAndUpdateURL = (nextStep, pathnameOverride) => {
      const { location, setStep, goToLocation } = this.props;
      const params = new URLSearchParams(location.search);
      params.set("step", nextStep);

      goToLocation(
        `${pathnameOverride || location.pathname}?${params.toString()}`
      );
      setStep(nextStep);
      window.scrollTo(0, 0);
    };

    getStepFromURL = () => {
      const { authorization, location } = this.props;

      const params = new URLSearchParams(location.search);
      const urlStep = _.toNumber(params.get("step"));
      const lastPossibleStep = _.last(authorization.steps).number;

      return _.min([lastPossibleStep, urlStep]) || 1;
    };

    syncStepToUrl = () => {
      const { step, setStep, authorization } = this.props;
      if (!authorization) return;
      const urlStep = this.getStepFromURL();

      if (step !== urlStep) {
        setStep(urlStep);
      }
    };

    resultsAreValid = (forceResultsInvalid) => {
      const { results, requiredFieldKeys, authorization } = this.props;

      if (authorization.submittedAt) return true;
      if (forceResultsInvalid) return false;

      return _.every(requiredFieldKeys, (requiredField) => {
        if (_.isString(requiredField)) {
          if (_.isString(results[requiredField])) {
            return !!results[requiredField].trim();
          }
          return !!results[requiredField];
        }
        if (_.isArray(requiredField)) {
          return _.some(requiredField, (key) => {
            if (_.isString(results[key])) {
              return !!results[key].trim();
            }
            return !!results[key];
          });
        }
        if (_.isObject(requiredField)) {
          const val = getInputValue(requiredField, results);
          if (_.isString(val)) {
            return !!val.trim();
          }
          return !!val;
        }

        // eslint-disable-next-line no-console
        console.error("Expected array or string from required field keys");
        return false;
      });
    };

    takeStep = _.debounce(
      async (isForward) => {
        const { step, updateFormDetails, authorization, account } = this.props;

        const stepIndex = _.findIndex(authorization.steps, { number: step });
        const nextStepIndex = _.clamp(
          stepIndex + (isForward ? 1 : -1),
          0,
          authorization.steps.length - 1
        );

        const nextStep = authorization.steps[nextStepIndex];

        if (
          (!authorization.formDetails.currentStep ||
            authorization.formDetails.currentStep < nextStep.number) &&
          !account.isReadOnly
        ) {
          await updateFormDetails({
            variables: {
              details: { currentStep: nextStep.number },
              id: authorization.id,
            },
          });
        }

        this.setStepAndUpdateURL(nextStep.number);
      },
      1500,
      { leading: true, trailing: false }
    );

    hasInvalidResults = (forceResultsInvalid) => {
      const { toggleHighlightRequiredFields } = this.props;

      if (!this.resultsAreValid(forceResultsInvalid)) {
        toggleHighlightRequiredFields(true);
        return true;
      }

      toggleHighlightRequiredFields(false);
      return false;
    };

    onComponentUpdate = (
      prevProps,
      authorization,
      results,
      syncLocalStateMethod
    ) => {
      const { location } = this.props;

      this.syncStepToUrl();

      if (!authorization) {
        return;
      }

      if (!prevProps.authorization && authorization) {
        const stepToSet = this.getStepFromURL();

        let properPath;
        if (
          authorization.portalKey ||
          authorization.type === AuthorizationType.PortalCopilot
        ) {
          properPath = ROUTE_PATHS.PORTAL_AUTHORIZATION.path;
        } else if (authorization.isReferral) {
          properPath = ROUTE_PATHS.REFERRAL.path;
        } else if (
          authorization.type ===
          CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key
        ) {
          properPath = ROUTE_PATHS.MUTLI_PARTY_AUTH.path;
        } else {
          properPath = ROUTE_PATHS.FORM_AUTHORIZATION.path;
        }

        if (
          (authorization.portalKey &&
            !_.includes(
              location.pathname,
              ROUTE_PATHS.PORTAL_AUTHORIZATION.path
            )) ||
          (authorization.isReferral &&
            !_.includes(location.pathname, ROUTE_PATHS.REFERRAL.path)) ||
          (!authorization.portalKey &&
            !_.includes(location.pathname, ROUTE_PATHS.FORM_AUTHORIZATION.path))
        ) {
          this.setStepAndUpdateURL(stepToSet, properPath);
        }
      }

      if (
        authorization &&
        authorization.config &&
        !_.isEqual(
          authorization.config,
          _.get(prevProps, "authorization.config")
        )
      ) {
        syncLocalStateMethod();
      }
    };

    render() {
      return (
        <WrappedComponent
          syncStepToUrl={this.syncStepToUrl}
          hasInvalidResults={this.hasInvalidResults}
          setStepAndUpdateURL={this.setStepAndUpdateURL}
          saveAuthorizationChanges={this.saveAuthorizationChanges}
          onComponentUpdate={this.onComponentUpdate}
          takeStep={this.takeStep}
          {...this.props}
        />
      );
    }
  };

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default NewAuthorizationHoc;
