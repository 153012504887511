import { useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { HcpcsCode } from "@samacare/graphql";

import {
  SearchHcpcsQuery,
  SearchHcpcsQueryVariables,
} from "@@generated/graphql";

import AutocompleteField, {
  type AutocompleteFieldProps,
} from "../AutocompleteField";
import { useFormContext } from "react-hook-form";
import _ from "lodash";

export const HCPCSCodeSearchQuery = gql`
  query searchHCPCS($searchString: String!) {
    searchHCPCS(searchString: $searchString) {
      id
      code
      longDescription
      shortDescription
    }
  }
`;

export type HcpcsFieldProps = {
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
};
export type HcpcsBlockProps = Omit<
  AutocompleteFieldProps<HcpcsCode, boolean, true, false>,
  "options"
> &
  HcpcsFieldProps;

export const HcpcsField: React.FC<HcpcsBlockProps> = ({
  name,
  multiple,
  ...props
}) => {
  const { data, loading, refetch } = useQuery<
    SearchHcpcsQuery,
    SearchHcpcsQueryVariables
  >(HCPCSCodeSearchQuery, { variables: { searchString: "" } });

  const { watch } = useFormContext();
  const selected = watch(name);

  const search = useCallback(
    (searchString: string) => {
      if (searchString != null && searchString !== "") {
        void refetch({ searchString });
      }
    },
    [refetch]
  );

  const throttledSearch = useCallback(
    _.debounce(search, 500, {
      leading: false,
      trailing: true,
    }),
    [search]
  );

  return (
    <AutocompleteField<HcpcsCode, boolean, true, false>
      multiple={multiple ?? true}
      sx={{ width: 600 }}
      placeholder="Type to search HCPCS codes..."
      label="HCPCS Codes"
      name={name}
      loading={loading}
      value={selected}
      options={_.compact(data?.searchHCPCS ?? []) as HcpcsCode[]}
      filterSelectedOptions
      getOptionLabel={(hcpcsCode) =>
        `${_.compact([hcpcsCode.code, hcpcsCode.shortDescription]).join(":")}`
      }
      onInputChange={(e, value) => {
        throttledSearch(value);
      }}
      {...props}
    />
  );
};
