import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Stack,
  Typography,
} from "@samacare/design/core";

import AccordionSection from "../../../../layout/AccordionSection";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { EditOutcomeContainer } from "../BenefitsVerificationEditPanel";
import { useBenefitsVerificationContext } from "../BeneftsVerificationProvider";
import {
  ActionBar,
  BenefitsVerificationSummary,
  EntryDetails,
  PatientDetails,
  ResponseDetails,
  RightSideMenu,
} from "../components";

export const ViewBenefitsVerification: React.FC = () => {
  const history = useHistory();
  const [editMode, setEditMode] = React.useState(false);
  const { currentAccount, benefitsVerification } =
    useBenefitsVerificationContext();

  if (currentAccount == null || benefitsVerification == null) {
    return <Typography>No benefit verification found</Typography>;
  }

  const onClose = () => {
    history.push(ROUTE_PATHS.BENEFITS_VERIFICATIONS.path);
  };

  const drugLabel = _.compact([
    benefitsVerification.request.drugDetails.drugCode,
    benefitsVerification.request.drugDetails.drugName,
  ]).join(" - ");

  const shouldShowResponse =
    currentAccount.isSamaUser ||
    benefitsVerification.outcome?.state === "success";

  return (
    <Box padding={2} data-cy="componentBenefitsVerificationSummaryPage">
      <Breadcrumbs>
        <Link underline="hover" href="#/benefits-verification">
          <Typography variant="h6">Benefits Verification</Typography>
        </Link>
        <Typography variant="h6">
          #{benefitsVerification.id.slice(-5)}
        </Typography>
      </Breadcrumbs>
      <Box marginTop={({ spacing }) => spacing(4)}>
        <PatientDetails />
      </Box>
      <Stack direction="row" justifyContent="space-between" marginTop={3}>
        <Typography variant="h6">{drugLabel}</Typography>
        <ActionBar
          isEditMode={editMode}
          onEditClick={() => setEditMode(true)}
        />
      </Stack>
      <Stack direction="row" marginTop={3}>
        <Box flexGrow={1}>
          <BenefitsVerificationSummary />
          {shouldShowResponse && (
            <AccordionSection title="Response Details" defaultExpanded>
              {editMode ? (
                <EditOutcomeContainer onClose={() => setEditMode(false)} />
              ) : (
                <ResponseDetails />
              )}
            </AccordionSection>
          )}
          {currentAccount.isSamaUser && (
            <AccordionSection title="Entry Details">
              <EntryDetails />
            </AccordionSection>
          )}
          {currentAccount.isSamaUser &&
            benefitsVerification.request.primaryInsuranceDetails
              ?.latestCoverageCheck && (
              <AccordionSection title="Eligibility Check Repsonse">
                <Box maxHeight={600} maxWidth={800} overflow="scroll">
                  <pre>
                    {JSON.stringify(
                      benefitsVerification.request.primaryInsuranceDetails
                        ?.latestCoverageCheck,
                      undefined,
                      2
                    )}
                  </pre>
                </Box>
              </AccordionSection>
            )}
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop={2}
          >
            <Button variant="outlined" onClick={onClose}>
              Back to Benefits Verifications List
            </Button>
            {editMode ? (
              <Stack flexDirection="row" gap={2}>
                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  form="formBenefitsVerification"
                >
                  Save
                </Button>
              </Stack>
            ) : null}
          </Stack>
        </Box>
        <Box minWidth="230px">
          <RightSideMenu activeStep={2} />
        </Box>
      </Stack>
    </Box>
  );
};
