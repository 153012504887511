import _ from "lodash";
import {
  Account,
  PortalCredential,
  PortalToHubMapping,
} from "@samacare/graphql";
import { TableHeader } from "app/routes/Admin/AdminStyles";
import { MfaSetupModal } from "app/components/MfaSetupModal";
import PortalCredentialModal from "app/routes/UserSettings/PortalCredentialModal";
import { PortalCredentialRow } from "app/routes/UserSettings/PortalCredentialRow";
import { useState } from "react";
import * as React from "react";
import { useHubs } from "app/hooks/hubs";

interface IPortalLoginsPaneProps {
  currentAccount: Account;
}
export const PortalLoginsPane: React.FC<IPortalLoginsPaneProps> = ({
  currentAccount,
}) => {
  const [isPortalCredentialModalOpen, setIsPortalCredentialModalOpen] =
    useState(false);
  const [credentialToEdit, setCredentialToEdit] =
    useState<PortalCredential | null>(null);
  const [selectedHub, setSelectedHub] = useState<PortalToHubMapping | null>(
    null
  );
  const [isMfaDialogOpen, setIsMfaDialogOpen] = useState(false);

  const { hubByHubKey } = useHubs(currentAccount.institution);

  const portalCreds: PortalCredential[] =
    (currentAccount?.portalCredentials as PortalCredential[]) ?? [];

  const credsByHubKey: Record<string, PortalCredential> = {};
  portalCreds.forEach((pc) => {
    if (pc.hub != null) credsByHubKey[pc.hub] = pc;
  });

  const handleOpenCredentialModal = (
    hubKey: string,
    credential: PortalCredential | null
  ) => {
    setCredentialToEdit(credential);
    setSelectedHub(hubByHubKey?.[hubKey] ?? null);
    setIsPortalCredentialModalOpen(true);
  };

  const handleClosePortalCredentialModal = (shouldOpenMfaDialog: boolean) => {
    setIsPortalCredentialModalOpen(false);
    setCredentialToEdit(null);
    if (shouldOpenMfaDialog) {
      setIsMfaDialogOpen(true);
    }
  };

  const handleCloseMfaDialog = () => {
    setIsMfaDialogOpen(false);
  };

  const sortedHubs = _.sortBy(_.values(hubByHubKey), (h) => h.title);

  return (
    <>
      <table
        id="pendo_portalLoginsListView"
        style={{ tableLayout: "auto", marginTop: "8px" }}
      >
        <thead>
          <tr>
            <TableHeader style={{ width: "auto" }} />
            <TableHeader style={{ width: "auto" }} />
          </tr>
        </thead>
        <tbody>
          {_.map(sortedHubs, (h) => (
            <PortalCredentialRow
              key={h.hubKey}
              hub={h}
              portalCredential={credsByHubKey[h.hubKey]}
              onEdit={handleOpenCredentialModal}
            />
          ))}
        </tbody>
      </table>

      <PortalCredentialModal
        isOpen={isPortalCredentialModalOpen}
        credentialToEdit={credentialToEdit}
        hub={selectedHub}
        currentAccount={currentAccount}
        currentInstitutionId={currentAccount.institution?.id}
        onModalClose={handleClosePortalCredentialModal}
      />
      {selectedHub == null ? null : (
        <MfaSetupModal
          isOpen={isMfaDialogOpen}
          closeDialog={handleCloseMfaDialog}
          portalKey={selectedHub.portalKey}
          currentAccount={currentAccount}
        />
      )}
    </>
  );
};
