import _ from "lodash";
import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";

import { withDefaultProps } from "./utils";

export const getPortalPreview = gql`
  query getPortalPreview($key: String!) {
    getPortalPreview(key: $key)
  }
`;

export const portalsQuery = gql`
  query portalsFindAll {
    portalsFindAll {
      title
      key
      loginUrl
    }
  }
`;

export const withPortalList = graphql(portalsQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    portalList: _.get(data, "portalsFindAll"),
  })),
});
