import { useMemo } from "react";
import {
  Authorization,
  InsuranceCompanySupportedCodingTypes,
} from "@samacare/graphql";
import HcpcsSelector from "../../../components/HcpcsSelector";
import { LeftRightCenterV } from "../../../components/LeftRight";
import { SegmentContainer } from "../../../components/Segment/SegmentContainer";
import SegmentDate from "../../../components/Segment/SegmentDate";
import SegmentOptions from "../../../components/Segment/SegmentOptions";
import SegmentText from "../../../components/Segment/SegmentText";
import { SegmentTitle } from "../../../components/Segment/SegmentTitle";
import { TopBottom } from "../../../components/TopBottom";
import { useSelector } from "../../../configureStore";
import { useConfig } from "../../../hooks";
import { useFieldConfigs } from "../../../hooks/useFieldConfigs";
import { useHighlightRequiredFields } from "../../../hooks/useHighlightRequiredFields";
import { useSet } from "../../../hooks/useSet";
import { hcpcsChangeHandler } from "../../../util/requestDetailsChangeHandlers";
import { FormField } from "./FormField";
import { MedicalOrPharmacyRadioButtons } from "./MedicalOrPharmacyRadioButtons";
import { ServiceTypeDropdown } from "./ServiceTypeDropdown";

const df = window.CONFIG.DEFAULT_FIELDS;

const FieldKey = {
  REQUEST_URGENCY: "requestUrgency",
  REQUEST_TYPE: "requestType",
} as const;

interface ServiceSectionProps {
  isDisabled?: boolean;
  auth: Authorization;
}
const ServiceSection: React.FC<ServiceSectionProps> = (props) => {
  const set = useSet();
  const highlightRequiredFields = useHighlightRequiredFields();
  const results = useSelector((state) => state.form.results);
  const config = useConfig();

  const isNdc = useMemo(
    () =>
      results[df.DRUG_CODE_TYPE.key] ===
      InsuranceCompanySupportedCodingTypes.Ndc,
    [results]
  );

  const fields = useMemo(
    () => [
      ...(isNdc ? [df.PRIMARY_NDC] : [df.HCPCS_0, df.HCPCS_QUANTITY_0]),
      df.REQUEST_DESCRIPTION,
      df.SERVICE_TYPE_CODE,
      df.DATE_OF_SERVICE,
      df.END_DATE_OF_SERVICE,
      {
        key: FieldKey.REQUEST_TYPE,
        options: [df.INITIATION_REQUEST, df.CONTINUATION_REQUEST],
      },
      {
        key: FieldKey.REQUEST_URGENCY,
        options: [
          df.URGENT_REQUEST,
          df.NOT_URGENT_REQUEST,
          df.RETRO_REQUEST,
          df.EMERGENT_REQUEST,
        ],
      },
    ],
    [isNdc]
  );

  const fieldConfigs = useFieldConfigs(fields, props.auth.formFieldConfig);

  const { formFieldConfig } = props.auth;
  const maxHcpcsCount = formFieldConfig?.maxHcpcsCount ?? 1;
  const units = formFieldConfig?.hcpcsUnits ?? null;
  const isShowMedicalOrPharmacyChoice = (
    config.CONSTANTS.PORTAL_KEYS_NEED_NDC_SELECTOR as string[]
  ).includes(props.auth.portalKey ?? "");

  return (
    <SegmentContainer style={{ padding: 5 }}>
      <SegmentTitle>{`Service${maxHcpcsCount > 1 ? "s" : ""}`}</SegmentTitle>
      <TopBottom>
        {isShowMedicalOrPharmacyChoice && <MedicalOrPharmacyRadioButtons />}
        <HcpcsSelector
          disabled={!!props.isDisabled}
          highlightRequiredFields={highlightRequiredFields}
          max={maxHcpcsCount}
          units={units ?? undefined}
          initializedHCPCSCodes={props.auth.HCPCSCodes}
          hideModifiers
          onChange={(newHcpcs) => {
            hcpcsChangeHandler(newHcpcs, set);
          }}
          styleOverride={{ container: { marginTop: "14px" } }}
          auth={props.auth}
          firstColumnHeaderStyle={{
            fontWeight: "bold",
            textAlign: "left",
            paddingLeft: "5px",
          }}
          drugCodeType={results[df.DRUG_CODE_TYPE.key]}
        />
        <TopBottom style={{ width: "600px" }}>
          <FormField
            title="Description"
            fieldConfig={fieldConfigs[df.REQUEST_DESCRIPTION.key]}
            style={{ marginTop: "16px" }}
          >
            <SegmentText
              disabled={props.isDisabled}
              handleChange={set}
              item={{
                key: df.REQUEST_DESCRIPTION.key,
              }}
              highlightIfMissing={
                highlightRequiredFields &&
                fieldConfigs[df.REQUEST_DESCRIPTION.key].isRequired
              }
            />
          </FormField>
          <FormField
            title="Service Type"
            fieldConfig={fieldConfigs[df.SERVICE_TYPE_CODE.key]}
            style={{ marginTop: "24px" }}
          >
            <ServiceTypeDropdown
              isDisabled={props.isDisabled}
              isRequired={fieldConfigs[df.SERVICE_TYPE_CODE.key].isRequired}
              defaultValue={results[df.SERVICE_TYPE_CODE.key]}
              onChange={(value: string | null) => {
                set({
                  [df.SERVICE_TYPE_CODE.key]: value,
                });
              }}
            />
          </FormField>
          <LeftRightCenterV style={{ columnGap: "8px" }}>
            <FormField
              title="Start Date"
              fieldConfig={fieldConfigs[df.DATE_OF_SERVICE.key]}
            >
              <SegmentDate
                disabled={props.isDisabled}
                handleChange={set}
                item={{ key: df.DATE_OF_SERVICE.key, warnIfPast: true }}
                highlightIfMissing={
                  highlightRequiredFields &&
                  fieldConfigs[df.DATE_OF_SERVICE.key].isRequired
                }
                width="195px"
                isWarningOnBottom={true}
              />
            </FormField>
            <FormField
              title="End Date"
              fieldConfig={fieldConfigs[df.END_DATE_OF_SERVICE.key]}
            >
              <SegmentDate
                disabled={props.isDisabled}
                handleChange={set}
                item={{ key: df.END_DATE_OF_SERVICE.key, warnIfPast: true }}
                highlightIfMissing={
                  highlightRequiredFields &&
                  fieldConfigs[df.END_DATE_OF_SERVICE.key].isRequired
                }
                width="195px"
              />
            </FormField>
          </LeftRightCenterV>

          <FormField
            title="Is this a continuation or initiation request?"
            fieldConfig={fieldConfigs[FieldKey.REQUEST_TYPE]}
          >
            <SegmentOptions
              id={`field_${FieldKey.REQUEST_TYPE}`}
              disabled={props.isDisabled}
              handleChange={set}
              item={{
                options: [df.INITIATION_REQUEST, df.CONTINUATION_REQUEST],
              }}
              highlightIfMissing={
                highlightRequiredFields &&
                fieldConfigs[FieldKey.REQUEST_TYPE].isRequired
              }
            />
          </FormField>

          <FormField
            title="Urgency"
            fieldConfig={fieldConfigs[FieldKey.REQUEST_URGENCY]}
          >
            <SegmentOptions
              id={`field_${FieldKey.REQUEST_URGENCY}`}
              disabled={props.isDisabled}
              handleChange={set}
              item={{
                options: [
                  df.URGENT_REQUEST,
                  df.NOT_URGENT_REQUEST,
                  df.RETRO_REQUEST,
                  df.EMERGENT_REQUEST,
                ],
              }}
              highlightIfMissing={
                highlightRequiredFields &&
                fieldConfigs[FieldKey.REQUEST_URGENCY].isRequired
              }
            />
          </FormField>
        </TopBottom>
      </TopBottom>
    </SegmentContainer>
  );
};

export { ServiceSection };
