import _ from "lodash";

const defaultFields = CONFIG.DEFAULT_FIELDS;
const MAX_ICDS_AND_HCPCS = CONFIG.CONSTANTS.POSSIBLE_ICD_AND_HCPCS_ENTRIES;

export const icdChangeHandler = (newIcds, setter) => {
  const icdCodes = _.keys(newIcds);
  const icdDescriptions = _.values(newIcds);
  const toSet = {
    [defaultFields.ALL_ICDS.key]: icdCodes.join(", "),
    ..._.reduce(
      // We want a MAX_ICDS length array all the time
      icdCodes
        .concat(new Array(MAX_ICDS_AND_HCPCS))
        .slice(0, MAX_ICDS_AND_HCPCS),
      (tot, icd, i) => ({
        ...tot,
        [defaultFields[`ICD_${i}`].key]: icd || null,
        [defaultFields[`ICD_DESCRIPTION_${i}`].key]: icdDescriptions[i] || null,
      }),
      {}
    ),
  };

  setter(toSet);
};

export const hcpcsChangeHandler = (newHCPCSCodes, setter) => {
  const codes = _.map(newHCPCSCodes, (hcpcsCode) => {
    const modifierText = hcpcsCode.modifier ? ` (${hcpcsCode.modifier})` : "";

    return `${hcpcsCode.code}${modifierText} x${hcpcsCode.quantity}`;
  });
  const toSet = {
    [defaultFields.ALL_HCPCS.key]: codes.join(", "),
    ..._.reduce(
      newHCPCSCodes
        .concat(new Array(MAX_ICDS_AND_HCPCS))
        .slice(0, MAX_ICDS_AND_HCPCS),
      (tot, hcpcsCode, i) => {
        const unit = _.get(hcpcsCode, "unit", null);
        const additional = unit
          ? {
              [defaultFields[`HCPCS_UNIT_${i}`].key]: unit,
            }
          : {};

        const obj = {
          ...tot,
          [defaultFields[`HCPCS_${i}`].key]: _.get(hcpcsCode, "code", null),
          [defaultFields[`HCPCS_QUANTITY_${i}`].key]: _.get(
            hcpcsCode,
            "quantity",
            null
          ),
          ...additional,
        };
        return obj;
      },
      {}
    ),
  };

  setter(toSet);
};
