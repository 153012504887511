import { PureComponent } from "react";
import styled, { ThemeProps } from "styled-components";
import { MdKeyboardArrowDown as DownArrow } from "@react-icons/all-files/md/MdKeyboardArrowDown";
import Popper from "@samacare/design/core/Popper";

import colors from "Resources/colors";
import BaseButton from "./BaseButton";
import Stack from "@samacare/design/core/Stack";

const UpArrow = styled.div`
  position: absolute;
  left: 3px;
  top: -9px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 9px solid ${colors.purple};
`;

const MenuContainer = styled.div<{ color?: string } & ThemeProps<unknown>>`
  position: absolute;
  background: white;
  margin-top: 10px;
  margin-right: 20px;
  border-radius: 2px;
  border: 1px solid ${({ color }) => color || colors.purple};
  z-index: ${(props) => props.theme.zUpper};

  ${UpArrow} {
    border-bottom: 9px solid ${({ color }) => color || colors.purple};
  }
`;

interface MenuButtonProps {
  buttonText?: string;
  menuButtons?: React.ReactNode;
  children?: React.ReactNode;
  color?: string;
  cypressTag?: string;
}

interface MenuButtonState {
  showMenu: boolean;
}

class MenuButton extends PureComponent<MenuButtonProps, MenuButtonState> {
  public menuRef!: HTMLDivElement;
  public mainButtonRef!: HTMLDivElement;
  public menuContainerRef!: HTMLDivElement;

  public constructor(props: MenuButtonProps) {
    super(props);
    this.state = { showMenu: false };
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.closeMenu);
  }

  public toggleMenu = (e: React.MouseEvent) => {
    const { showMenu } = this.state;
    if (!showMenu) {
      this.openMenu(e);
    } else {
      e.preventDefault();
      e.stopPropagation();
      this.closeMenu(e.nativeEvent, true);
    }
  };

  public openMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  public closeMenu = (e: MouseEvent, force = false) => {
    if (force || !this.menuRef.contains(e.target as Node)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener("click", this.closeMenu);
      });
    }
  };

  public render() {
    const { buttonText, menuButtons, children, color, cypressTag } = this.props;
    const { showMenu } = this.state;

    return (
      <div
        data-cy={cypressTag}
        ref={(ref) => {
          this.menuRef = ref!;
        }}
      >
        <div
          ref={(ref) => {
            this.mainButtonRef = ref!;
          }}
          onClick={this.toggleMenu}
        >
          {children || (
            <BaseButton>
              <div style={{ marginRight: "5px", fontSize: "12px" }}>
                {buttonText}
              </div>
              <DownArrow />
            </BaseButton>
          )}
        </div>
        {showMenu && (
          <Popper
            open={showMenu}
            anchorEl={this.mainButtonRef}
            placement="bottom-start"
            sx={{ marginTop: "10px" }}
          >
            <Stack
              spacing={0.5}
              direction="column"
              sx={{ paddingBottom: 0.5, backgroundColor: "white" }}
            >
              <MenuContainer
                color={color}
                ref={(ref) => {
                  this.menuContainerRef = ref!;
                }}
                onClick={this.toggleMenu}
              >
                {menuButtons}
              </MenuContainer>
            </Stack>
          </Popper>
        )}
      </div>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export default MenuButton;
