import { Box, Typography, Stack, Alert } from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import { useConfig, useSamaCareBrandName } from "../../hooks/config";
import { Patient, UnifiedBenefitsCheckType } from "@samacare/graphql";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import ROUTE_PATHS from "../../routes/ROUTE_PATHS";
import { useCurrentAccount } from "../../graphql/Account";
import { ErrorStatus } from "./ErrorStatus";
import { StatusDetails } from "./StatusDetails";
import { RunBvLiteCheckButton } from "./RunBvLiteCheckButton";
import { BvLiteSupportedAlert } from "./BvLiteSupportedAlert";

interface InsuranceCoverageCheckProps {
  patient: Patient | null;
  isLoading: boolean;
  onRunClick: () => void;
  disabled?: boolean;
  eligibilityCheckError: boolean;
  submitErrors?: string[];
}

export const InsuranceCoverageCheckWithAlert = ({
  patient,
  isLoading,
  onRunClick,
  disabled = false,
  eligibilityCheckError,
  submitErrors,
  displayAsRow = true,
}: InsuranceCoverageCheckProps & { displayAsRow?: boolean }) => {
  const theme = useTheme();
  const config = useConfig();
  const [currentAccount] = useCurrentAccount();
  const brandName = useSamaCareBrandName();
  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEligibilityCheck
  );

  const latestCoverageCheck = patient?.primaryInsurance?.latestCoverageCheck;

  const invalidOrNoPriorRecord =
    (!latestCoverageCheck || !latestCoverageCheck?.isValid) &&
    !eligibilityCheckError;

  //if the patient's primary insurance and insurance state combination does not support BV lite
  //or BV lite is not enabled
  if (
    !isEnabled ||
    (!patient?.primaryInsurance?.supportsCoverageCheck &&
      patient?.primaryInsurance?.insuranceState != null)
  ) {
    return <Box />;
  }

  //if the patient's primary insurance and insurance state combination supports BV lite but no prior record
  if (
    invalidOrNoPriorRecord &&
    patient?.primaryInsurance?.supportsCoverageCheck
  ) {
    return (
      <BvLiteSupportedAlert
        message={` ${brandName} BV Lite can instantly check coverage for this insurance plan`}
        handleClick={onRunClick}
        isLoading={isLoading}
        disabled={disabled || eligibilityCheckError}
        backgroundColor={theme.palette.background.paper}
        displayAsRow={displayAsRow}
      />
    );
  }

  if (
    invalidOrNoPriorRecord &&
    patient?.primaryInsurance?.insuranceState == null
  ) {
    return (
      <BvLiteSupportedAlert
        message={` ${brandName} BV Lite may be able to check coverage for
        this patient`}
        handleClick={() => {
          window.open(
            `${window.location.origin}/#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/create?type=${UnifiedBenefitsCheckType.Lite}&patientId=${patient?.id}`,
            "_blank"
          );
        }}
        isLoading={isLoading}
        disabled={disabled || eligibilityCheckError}
        backgroundColor={theme.palette.background.paper}
        withIcon
        displayAsRow={displayAsRow}
      />
    );
  }

  return (
    <Alert
      severity="info"
      icon={false}
      action={
        displayAsRow ? (
          <RunBvLiteCheckButton
            handleClick={onRunClick}
            isLoading={isLoading}
            disabled={disabled || eligibilityCheckError}
            backgroundColor={theme.palette.background.paper}
          />
        ) : null
      }
    >
      <Stack spacing={2} direction={displayAsRow ? "row" : "column"}>
        {
          // Show this error message only when the check is run and the insurance is not valid
          eligibilityCheckError && <ErrorStatus theme={theme} />
        }

        {latestCoverageCheck?.isValid && !eligibilityCheckError && (
          <StatusDetails
            patient={patient}
            currentAccount={currentAccount}
            latestCoverageCheck={latestCoverageCheck}
            eligibilityCheckError={eligibilityCheckError}
          />
        )}

        {submitErrors && submitErrors.length > 0 && (
          <Stack justifyContent="flex-end" marginBottom={1} width={600}>
            <Typography variant="body2" color="error">
              {submitErrors.map((error, i) => (
                <Typography key={i} variant="body2" color="error">
                  {error}
                </Typography>
              ))}
            </Typography>
          </Stack>
        )}

        {!displayAsRow && (
          <RunBvLiteCheckButton
            handleClick={onRunClick}
            isLoading={isLoading}
            disabled={disabled || eligibilityCheckError}
            backgroundColor={theme.palette.background.paper}
          />
        )}
      </Stack>
    </Alert>
  );
};
