import _ from "lodash";
import styled from "styled-components";
import Modal from "../../Modal";
import {
  ModalBody,
  ModalFooter,
  ModalContent,
} from "../../ModalStyledComponents";
import BaseButton from "../../BaseButton";

import SignatureUpload from "./SignatureUpload";
import SignatureInput from "./SignatureInput";
import TabbedHeader, { Tab } from "../../MainList/TabbedHeader";
import { signatureByIdQuery } from "../../../graphql/Signature";
import { File, Account } from "@samacare/graphql";
import {
  SignatureByIdQuery,
  SignatureByIdQueryVariables,
} from "@@generated/graphql";
import { useQuery } from "@apollo/client";

const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignatureImageContainer = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>`
  border: ${(props) => (props.selected ? "3px" : "1px")} solid
    ${(props) => (props.disabled ? props.theme.lightGray : props.theme.purple)};
  padding: 2px;
  cursor: pointer;
  margin: 10px;
  display: flex;
  flex-direction: row;

  img {
    height: 50px;
  }
`;

const SignaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CustomModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  font-size: 16px;
`;

const DeleteSignatureButton = styled(BaseButton)`
  padding: 0 10px;
  border-radius: 3px;
  height: 100%;
  border: 0px solid transparent;
`;

const DeleteSignatureButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
`;

export const NEW_TAB = "NEW_TAB";
export const SAVED_TAB = "SAVED_TAB";
export const UPLOAD_TAB = "UPLOAD_TAB";
export const PRACTICE_SIGNATURES = "PRACTICE_SIGNATURES";

export const allowFalsySignature = (account: Account) =>
  _.get(account, "institution.featureFlags.OptionalSignature");

type CommonSignatureBlockProps = {
  practiceSignatures: File[];
  updateState: (state: any) => void;
  tab?: string;
  selectedSignatureId: string;
  otherUserSignatureId: number;
  signatures: File[];
  deleteSignatureId: string | null;
  account: Account;
  deleteSignatureUrl: string;
  handleDeleteSignature: (e: React.MouseEvent) => void;
  disabled: boolean;
  saveAndSetSignature: (id: string) => void;
  updateSignature: (id: string) => void;
  isForEnrollment: boolean;
};

export const CommonSignatureBlock = ({
  practiceSignatures,
  updateState,
  tab,
  selectedSignatureId,
  otherUserSignatureId,
  signatures,
  deleteSignatureId,
  account,
  deleteSignatureUrl,
  handleDeleteSignature,
  disabled,
  saveAndSetSignature,
  updateSignature,
  isForEnrollment,
}: CommonSignatureBlockProps) => {
  const tabsToSet: Tab[] = [
    { title: "New Signature", tabTitle: "New Signature", value: NEW_TAB },
    {
      title: "Saved Signatures",
      help: "Signatures you've previously used are saved here",
      tabTitle: "My Signatures",
      value: SAVED_TAB,
    },
    ...(_.isEmpty(practiceSignatures)
      ? []
      : [
          {
            title: "Practice Signatures",
            tabTitle: "Practice Signatures",
            value: PRACTICE_SIGNATURES,
          },
        ]),
    {
      title: "Upload Signature",
      help: "Upload your own signature as an image",
      tabTitle: "Upload Signature",
      value: UPLOAD_TAB,
    },
  ];

  const onSelect = (sig: File | undefined) => {
    if (disabled || sig == null) {
      return;
    }
    updateState({ selectedSignatureId: sig.id });
    if (updateSignature) {
      updateSignature(sig.id);
    }
  };

  const { data } = useQuery<SignatureByIdQuery, SignatureByIdQueryVariables>(
    signatureByIdQuery,
    {
      variables: { id: otherUserSignatureId },
      skip: otherUserSignatureId == null,
    }
  );

  return (
    <div>
      <div>
        <TabbedHeader
          onSelect={(newTab) => {
            updateState({ tab: newTab.value });
          }}
          selected={_.find(tabsToSet, { value: tab })}
          tabs={tabsToSet}
          showHeaderLine={false}
        />
      </div>
      {tab === PRACTICE_SIGNATURES && (
        <SignaturesContainer>
          {_.map(practiceSignatures, (sig) => (
            <SignatureImageContainer
              key={`SignatureImageContainer${sig.id}`}
              onClick={() => {
                onSelect(sig);
              }}
              selected={sig.id === selectedSignatureId}
              disabled={disabled}
            >
              <img src={sig.url ?? ""} />
            </SignatureImageContainer>
          ))}
        </SignaturesContainer>
      )}
      {tab === SAVED_TAB && (
        <>
          <SignaturesContainer>
            {otherUserSignatureId && data?.signatureById != null && (
              <SignatureImageContainer
                key={`SignatureImageContainer${data?.signatureById?.id}`}
                onClick={() => {
                  onSelect((data?.signatureById as File) ?? undefined);
                }}
                selected={data?.signatureById?.id === selectedSignatureId}
                disabled={disabled}
              >
                <img src={data?.signatureById?.url ?? ""} />
              </SignatureImageContainer>
            )}
            {_.map(signatures, (sig) => (
              <SignatureImageContainer
                key={`SignatureImageContainer${sig.id}`}
                onClick={() => {
                  onSelect(sig);
                }}
                selected={sig.id === selectedSignatureId}
                disabled={disabled}
              >
                <img src={sig.url ?? ""} />
                <DeleteSignatureButtonContainer>
                  <DeleteSignatureButton
                    type="button"
                    onClick={() => {
                      updateState({
                        deleteSignatureId: sig.id,
                        deleteSignatureUrl: sig.url,
                      });
                    }}
                  >
                    X
                  </DeleteSignatureButton>
                </DeleteSignatureButtonContainer>
              </SignatureImageContainer>
            ))}
          </SignaturesContainer>
          {deleteSignatureId && (
            <Modal
              header="Delete Confirmation"
              onClick={() => {
                updateState({ deleteSignatureId: null });
              }}
              open={!!deleteSignatureId}
            >
              <ModalBody>
                <CustomModalContent>
                  <div style={{ width: "100%", margin: "8px" }}>
                    <img
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={deleteSignatureUrl}
                    />
                  </div>
                  <div>Are you sure you want to delete this signature?</div>
                </CustomModalContent>
                <ModalFooter>
                  <BaseButton
                    style={{ width: "100%" }}
                    onClick={handleDeleteSignature}
                  >
                    Yes
                  </BaseButton>
                </ModalFooter>
              </ModalBody>
            </Modal>
          )}
        </>
      )}
      {tab === NEW_TAB && (
        <SignatureContainer>
          <SignatureInput
            required={!allowFalsySignature(account) || isForEnrollment}
            disabled={disabled}
            saveAndSetSignature={saveAndSetSignature}
          />
        </SignatureContainer>
      )}
      {tab === UPLOAD_TAB && (
        <SignatureUpload saveAndSetSignature={saveAndSetSignature} />
      )}
    </div>
  );
};
