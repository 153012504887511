import moment from "moment";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  Stack,
  FormHelperText,
  Link,
} from "@samacare/design/core";
import {
  useForm,
  FormProvider,
  PatientBlock,
  AddressBlock,
  PatientInformationBlock,
  OfficeBlock,
  MedicalRequestBlock,
  TextField,
  SignatureBlock,
  PrimaryAndSecondaryInsuranceBlock,
  ErrorMessage,
  OfficeContactInfoBlock,
  IcdField,
  ReferringProviderBlock,
  InfusionFacilityBlockBlock,
  PhoneField,
  AutocompleteField,
} from "@samacare/form";
import Signature from "AuthorizationSharedSteps/SignatureSection";
import ROUTES from "../../routes/ROUTE_PATHS";
import {
  MedicationRequestPatient,
  MedicationRequestMedication,
  MedicationRequestLocation,
  MedicationRequestPrescriber,
  QuestionnaireResponseAnswerInputObjectType,
  MedicationRequestDiagnosis,
  QuestionTypeEnumType,
} from "@samacare/graphql";
import { EnrollmentActions } from "../../routes/Enrollment/EnrollmentActions";
import {
  useEnrollmentContext,
  useApellisQuestionnaire,
} from "../../routes/Enrollment/EnrollmentProvider";
import { EnrollmentSection } from "../../routes/Enrollment/EnrollmentSection";
import { LanguageField } from "@samacare/form/LanguageField";
import useUpdateNewEnrollmentById from "../../hooks/useUpdateNewEnrollmentById";
import useCreateNewEnrollment from "../../hooks/useCreateNewEnrollment";
import { EnrollmentRoutes } from "../../routes/Enrollment/Enrollment.types";
import {
  QuestionnaireResponseType,
  QuestionnaireField,
} from "../../components/Questionnaire";
import { EnrollmentConsent } from "../../routes/Enrollment/EnrollmentConsent";
import { PrescriptionInfoBlock as TepezzaPrescriptionBlock } from "./HorizonTepezzaPrescriptionBlock";
import { PrescriptionInfoBlock as UpliznaPrescriptionBlock } from "./HorizonUpliznaPrescriptionBlock";
import { PrescriptionInfoBlock as HorizonKrystexxaPrescriptionBlock } from "./HorizonKrystexxaPrescriptionBlock";
import { HorizonUpliznaDiagnosisInfoBlock } from "./HorizonUpliznaDiagnosisInfo";
import {
  convertBoolToYesNo,
  convertYesNoToBool,
} from "../../routes/Enrollment/utils";
import { Typography } from "@samacare/design";
import { HorizonTepezzaDiagnosisInfoBlock } from "./HorizonTepezzaDiagnosisInfo";
import { HorizonKrystexxaDiagnosisBlock } from "./HorizonKrystexxaDiagnosisBlock";
import useInsuranceCompanyOptions from "../../../form/hooks/useInsuranceCompanyOptions";
import { HorizonKrystexxaCoAdministrationBlock } from "./HorizonKrystexxaCoAdministrationBlock";

type EnrollmentFormDataType = {
  root: { serverError: string };
  patient: MedicationRequestPatient;
  medications: MedicationRequestMedication[];
  PatientId?: string;
  prescriber: MedicationRequestPrescriber;
  location: MedicationRequestLocation;
  PrescriberId?: string;
  LocationId?: string;
  questionnaire: QuestionnaireResponseType;
  diagnoses: MedicationRequestDiagnosis[];
  selectedSignatureId?: string;
  patientConsent: {
    hasConsent: "yes" | "no" | null;
    fileId?: number | null;
    fileTitle?: string | null;
  };
  serviceType: string;
};

export const HorizonGeneralForm: React.FC<{
  onNextStep: (stringParam?: string | undefined) => void;
}> = ({ onNextStep }) => {
  const {
    enrollment,
    isSubmitted,
    onAlert,
    onPatientSelected,
    programId,
    enrollmentProgramQueryLoading,
    enrollmentProgram,
  } = useEnrollmentContext();

  const { answers } = useApellisQuestionnaire();

  const parseValidData = (fieldName: string) => {
    if (
      answers?.questionnaire?.[fieldName] === "" ||
      answers?.questionnaire?.[fieldName] == null
    ) {
      return;
    }
    return JSON.parse(answers?.questionnaire?.[fieldName] as string);
  };

  const methods = useForm<EnrollmentFormDataType>({
    defaultValues: {
      patient: enrollment?.MedicationRequest?.patient ?? {},
      medications: enrollment?.MedicationRequest?.medications ?? undefined,
      PatientId: enrollment?.MedicationRequest?.PatientId ?? undefined,
      LocationId: enrollment?.MedicationRequest?.LocationId ?? undefined,
      PrescriberId: enrollment?.MedicationRequest?.PrescriberId ?? undefined,
      location: enrollment?.MedicationRequest?.location ?? {},
      prescriber: enrollment?.MedicationRequest?.prescriber ?? {},
      diagnoses: enrollment?.MedicationRequest?.diagnoses ?? [],
      questionnaire: {
        ...answers?.questionnaire,
        BenefitsInvestigation: parseValidData("BenefitsInvestigation"),
        PatientAssistanceProgram: parseValidData("PatientAssistanceProgram"),
        "sama-prescriptionInfo-medicalUrgent": parseValidData(
          "sama-prescriptionInfo-medicalUrgent"
        ),
        "sama-prescriptionInfo-nursingOrder": parseValidData(
          "sama-prescriptionInfo-nursingOrder"
        ),
        secondaryDiagnoses: parseValidData("secondaryDiagnoses") ?? [],
      },
      selectedSignatureId: enrollment?.SignatureFileId ?? undefined,
      patientConsent: {
        hasConsent: convertBoolToYesNo(enrollment?.PatientConsent?.hasConsent),
        fileId: enrollment?.PatientConsent?.File?.id,
        fileTitle: enrollment?.PatientConsent?.File?.title,
      },
    },
  });

  const {
    setError,
    setValue,
    formState: { errors },
    register,
    getValues,
    watch,
  } = methods;

  const [update, { loading: updateErnollmentLoading }] =
    useUpdateNewEnrollmentById();

  useEffect(() => {
    setValue("medications", [
      {
        code: enrollmentProgram?.drugCode ?? "",
        name: enrollmentProgram?.drugName ?? "",
        quantity: null,
      },
    ]);
  }, [enrollmentProgram, setValue]);

  const [createNewEnrollment] = useCreateNewEnrollment();
  const isForUplizna = enrollmentProgram?.drugName === "Uplizna";
  const isForTepezza = enrollmentProgram?.drugName === "Tepezza";
  const isForKrystexxa = enrollmentProgram?.drugName === "Krystexxa";

  const getConsentForm = () => {
    if (isForUplizna) {
      return "https://powerforms.docusign.net/229e36e0-2d8a-4a86-b562-f405821a321c?env=na1&acct=2a554d3d-9587-4e9b-b08e-92294e575103&accountId=2a554d3d-9587-4e9b-b08e-92294e575103&recipientLang=en";
    } else if (isForTepezza) {
      return "https://powerforms.docusign.net/772f356b-6f7b-479c-a965-a8e1b56cb6ab?env=na1&acct=2a554d3d-9587-4e9b-b08e-92294e575103&accountId=2a554d3d-9587-4e9b-b08e-92294e575103&recipientLang=en";
    } else if (isForKrystexxa) {
      return "https://powerforms.docusign.net/5fb07184-92fc-4483-bc4a-927efda48d1b?env=na1&acct=2a554d3d-9587-4e9b-b08e-92294e575103&accountId=2a554d3d-9587-4e9b-b08e-92294e575103&recipientLang=en";
    }
    return undefined;
  };

  const history = useHistory();

  const updateSignature = (id: string) => {
    setValue("selectedSignatureId", id);
  };

  const { insuranceCompanies } = useInsuranceCompanyOptions();

  const updateEnrollment = async ({
    enrollmentId,
    data,
  }: {
    enrollmentId: string | undefined;
    data: EnrollmentFormDataType;
  }) => {
    const formatDate = (dateRawData: string | undefined) => {
      if (dateRawData == null || dateRawData === "") {
        return null;
      }
      return moment(dateRawData).format("YYYY-MM-DD");
    };

    await update({
      variables: {
        id: Number(enrollmentId),
        patch: {
          questionnaire: {
            answers: Object.entries({
              ...data.questionnaire,
              "sama-primaryInsurance-payerName":
                insuranceCompanies?.find(
                  (ins) =>
                    ins.id ===
                    getValues("patient.primaryInsurance.InsuranceCompanyId")
                )?.label ??
                data.questionnaire?.[
                  "sama-primaryInsurance-InsuranceCompanyName"
                ],
              "sama-secondaryInsurance-payerName":
                insuranceCompanies?.find(
                  (ins) =>
                    ins.id ===
                    getValues("patient.secondaryInsurance.InsuranceCompanyId")
                )?.label ??
                data.questionnaire?.[
                  "sama-secondaryInsurance-InsuranceCompanyName"
                ],
              signatureDate: moment(
                data.questionnaire.signatureDate as unknown as string
              ).format("YYYY-MM-DD"),
              "sama-prescriptionInfo-diagnosisDate": formatDate(
                data.questionnaire[
                  "sama-prescriptionInfo-diagnosisDate"
                ] as string
              ),
              "sama-prescriptionInfo-infusionDate": formatDate(
                data.questionnaire[
                  "sama-prescriptionInfo-infusionDate"
                ] as string
              ),
              "sama-primaryInsurance-policyHolderDob": formatDate(
                data.questionnaire[
                  "sama-primaryInsurance-policyHolderDob"
                ] as string
              ),
              "sama-secondaryInsurance-policyHolderDob": formatDate(
                data.questionnaire[
                  "sama-secondaryInsurance-policyHolderDob"
                ] as string
              ),
              secondaryDiagnoses: JSON.stringify(
                data.questionnaire.secondaryDiagnoses
              ),
            }).map(([id, value]) => ({
              id,
              value: value?.toString(),
            })) as QuestionnaireResponseAnswerInputObjectType[],
          },
          MedicationRequest: {
            patient: {
              ...data.patient,
              phone: data.patient.phone === "" ? null : data.patient.phone,
            },
            medications: data.medications,
            prescriber: data.prescriber,
            location: data.location,
            PatientId: data.PatientId,
            LocationId: data.LocationId,
            PrescriberId: data.PrescriberId,
            diagnoses: data.diagnoses,
          },
          SignatureFileId: data.selectedSignatureId,
          PatientConsent: {
            // should always have a value because `hasConsent` is validated
            hasConsent: convertYesNoToBool(data.patientConsent.hasConsent)!,
            fileId: data.patientConsent.fileId,
          },
        },
      },
    });
  };

  const onSubmit = async (data: EnrollmentFormDataType) => {
    let enrollmentId = enrollment?.id;

    try {
      if (enrollment?.id == null) {
        const createEnrollmentResult = await createNewEnrollment({
          variables: {
            EnrollmentProgramId: programId,
          },
        });
        enrollmentId = createEnrollmentResult?.data?.createNewEnrollment?.id;

        history.push(
          `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.submission}/${enrollmentId}`
        );
        await updateEnrollment({ enrollmentId, data });
      } else {
        await updateEnrollment({ enrollmentId, data });
        onNextStep();
      }
    } catch (error) {
      setError("root.serverError", {
        type: "400",
      });

      onAlert((error as Error).message);
    }
  };

  return (
    <FormProvider {...methods}>
      {enrollmentProgramQueryLoading && <CircularProgress sx={{ mt: 10 }} />}
      {!enrollmentProgramQueryLoading && (
        <form
          id="HorizonGeneralForm"
          name="HorizonGeneralForm"
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <EnrollmentSection title="Patient">
            <PatientBlock
              onSelected={async (patient) => {
                await onPatientSelected(patient);
              }}
              required
              disabled={isSubmitted}
            />
          </EnrollmentSection>
          <EnrollmentSection title="Patient Information">
            <AddressBlock disabled={isSubmitted}>
              <PatientInformationBlock
                disabled={isSubmitted}
                PhoneInputProps={{
                  usePhoneType: true,
                  disabled: isSubmitted,
                  PhoneTypeProps: {
                    name: "patient.phoneType",
                    disabled: isSubmitted,
                    required: true,
                  },
                  required: true,
                }}
                GenderInputProps={{
                  femaleandmaleonly: true,
                }}
              />
              <Stack direction="row" spacing={1} maxWidth={600}>
                <TextField
                  name="patient.email"
                  label="Email"
                  disabled={isSubmitted}
                  fullWidth
                />
                <LanguageField
                  name="patient.language"
                  label="Preferred Language"
                  disabled={isSubmitted}
                  fullWidth
                />
              </Stack>
              <Stack direction="row" spacing={1} maxWidth={600}>
                <QuestionnaireField
                  id="sama-patientAlternativeContact"
                  text="Alternative Contact"
                  type={QuestionTypeEnumType.String}
                  disabled={isSubmitted}
                  fullWidth
                />
                <PhoneField
                  name="questionnaire.sama-patientAlternativePhone"
                  label="Alternative Contact Phone"
                  disabled={isSubmitted}
                  fullWidth
                />
              </Stack>
              <Stack direction="column" spacing={1} maxWidth={600}>
                <QuestionnaireField
                  id="sama-consentToSendText"
                  text="Consent to send text message?"
                  type={QuestionTypeEnumType.Choice}
                  disabled={isSubmitted}
                  answerOption={[
                    {
                      valueString: "Yes",
                    },
                    {
                      valueString: "No",
                    },
                  ]}
                />
                <QuestionnaireField
                  id="sama-consentToLeaveVoicemail"
                  text="Consent to leave voice message at patient?"
                  type={QuestionTypeEnumType.Choice}
                  disabled={isSubmitted}
                  answerOption={[
                    {
                      valueString: "Yes",
                    },
                    {
                      valueString: "No",
                    },
                  ]}
                />
              </Stack>
            </AddressBlock>
          </EnrollmentSection>
          <EnrollmentSection title="Insurance">
            <PrimaryAndSecondaryInsuranceBlock
              disabled={isSubmitted}
              needIpaInfo={isForTepezza || isForKrystexxa}
              needReverification={isForKrystexxa}
            />
          </EnrollmentSection>
          <EnrollmentSection title="JCODE/CPT code">
            <MedicalRequestBlock disabled />
          </EnrollmentSection>
          <EnrollmentSection title="Patient Diagnosis">
            <Stack direction="column" spacing={2}>
              {isForKrystexxa && (
                <Typography variant="body">
                  Please select an ICD code that start with <strong>M1A</strong>
                </Typography>
              )}
              <IcdField
                name="diagnoses"
                required
                override={{
                  options: isForUplizna
                    ? ["G36.0"]
                    : isForTepezza
                    ? ["E05.00"]
                    : undefined,
                  limit: 1,
                }}
                disabled={isSubmitted}
              />
              {isForUplizna && (
                <HorizonUpliznaDiagnosisInfoBlock isSubmitted={isSubmitted} />
              )}
              {isForTepezza && (
                <HorizonTepezzaDiagnosisInfoBlock isSubmitted={isSubmitted} />
              )}
              {isForKrystexxa && (
                <HorizonKrystexxaDiagnosisBlock isSubmitted={isSubmitted} />
              )}
            </Stack>
          </EnrollmentSection>
          {isForKrystexxa && (
            <EnrollmentSection title="Co-administration Medication">
              <HorizonKrystexxaCoAdministrationBlock isDisabled={isSubmitted} />
            </EnrollmentSection>
          )}
          <EnrollmentSection title="Prescriber Information">
            <ReferringProviderBlock required disabled={isSubmitted} />
          </EnrollmentSection>
          <EnrollmentSection title="Location Details">
            <OfficeBlock required disabled={isSubmitted} />
            <OfficeContactInfoBlock isSubmitted={isSubmitted} isRequired />
            <Stack direction="row" maxWidth={600} spacing={2}>
              <QuestionnaireField
                id="sama-locationAffiliation"
                type={QuestionTypeEnumType.String}
                text="Clinic/hospital affiliation"
                disabled={isSubmitted}
                fullWidth
              />
              <AutocompleteField<string, false, true, false>
                disableClearable
                options={["Telephone", "Email"]}
                filterSelectedOptions
                label="Prefer communication"
                name="questionnaire.sama-locationPreferredCommunication"
                value={
                  (watch(
                    "questionnaire.sama-locationPreferredCommunication"
                  ) as string) ?? ""
                }
                disabled={isSubmitted}
              />
            </Stack>
          </EnrollmentSection>
          <EnrollmentSection title="Preferred Infusion Facility">
            <InfusionFacilityBlockBlock
              disabled={isSubmitted}
              showInfusionFacilityCheckBox
            />
          </EnrollmentSection>
          <EnrollmentSection title="Prescription Information">
            {isForTepezza && (
              <TepezzaPrescriptionBlock isSubmitted={isSubmitted} isRequired />
            )}
            {isForUplizna && (
              <UpliznaPrescriptionBlock isSubmitted={isSubmitted} isRequired />
            )}
            {isForKrystexxa && (
              <HorizonKrystexxaPrescriptionBlock
                isSubmitted={isSubmitted}
                isRequired
              />
            )}
          </EnrollmentSection>
          <EnrollmentConsent
            description={
              <>
                This form may not be processed without the Patient’s Signature
                and agreements to the Declaration on form.{" "}
                <Link
                  href={getConsentForm() ?? ""}
                  rel="noopener"
                  target="_blank"
                >
                  View form here
                </Link>
              </>
            }
            noLabelOverride="No, allow patient to be contacted for consent"
          />
          <EnrollmentSection title="Prescriber Certification">
            <SignatureBlock
              isSubmitted={isSubmitted}
              description="This form may not be processed without the Physician Signature and agreements to the Declaration on form."
            />
            <ErrorMessage
              errors={errors}
              name="selectedSignatureId"
              render={({ message }) => (
                <FormHelperText error>{message}</FormHelperText>
              )}
            />
            <Signature
              enrollment={enrollment}
              updateSignature={updateSignature}
              disabled={isSubmitted}
            />
            <input
              type="hidden"
              {...register("selectedSignatureId", {
                required: "Please select a signature",
                setValueAs: (v) => (v === "" ? null : v),
              })}
            />
          </EnrollmentSection>

          <EnrollmentActions
            disabled={isSubmitted}
            loading={updateErnollmentLoading}
          />
        </form>
      )}
    </FormProvider>
  );
};
