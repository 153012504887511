import styled from "styled-components";

/**
 * @deprecated Use a MUI component instead
 */
export const TopBottom = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * @deprecated Use a MUI component instead
 */
export const TopBottomCenterH = styled(TopBottom)`
  align-items: center;
`;
