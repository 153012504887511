import { Box, Button, LoadingButton, Stack } from "@samacare/design/core";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "@samacare/form";

import { BenefitsCheckOptionsField, RightSideMenu } from "../components";
import { UnifiedBenefitsCheckType } from "@samacare/graphql";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";

type SelectBenefitsVerificationTypeForm = {
  benefitsCheckType: UnifiedBenefitsCheckType | null;
  drugOptionId?: string | null;
};

export const SelectBenefitsVerificationType: React.FC = () => {
  const alert = useAlert();
  const history = useHistory();

  const search = new URLSearchParams(history.location.search);

  const methods = useForm<SelectBenefitsVerificationTypeForm>({
    defaultValues: {
      benefitsCheckType:
        (search.get("type") as UnifiedBenefitsCheckType) || null,
      drugOptionId: null,
    },
  });

  const onSubmit = async (data: SelectBenefitsVerificationTypeForm) => {
    const { benefitsCheckType, drugOptionId } = data;

    history.push(
      `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/create?type=${benefitsCheckType}&drugOptionId=${drugOptionId}`
    );
    alert.info("Successfully update Benefits Verification Type!");
  };

  return (
    <Box
      data-cy="componentBenefitsVerificationTypeSelection"
      width="100%"
      height="100%"
      position="absolute"
      top="0"
      left="0"
      p={2}
    >
      <Box
        sx={{
          width: "100%",
          height: "50px",
          borderBottom: "1px solid rgb(176, 176, 176)",
          margin: "10px",
          fontWeight: 700,
          fontSize: "1.5rem",
        }}
      >
        New Benefits Verification
      </Box>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Box py={4} px={2}>
          <FormProvider {...methods}>
            <form
              id="BenefitsVerificationType"
              name="BenefitsVerificationType"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <Stack direction="column">
                <Box sx={{ width: "70%" }}>
                  <EnrollmentSection title="BenefitsVerificationType">
                    <BenefitsCheckOptionsField
                      typeFieldName="benefitsCheckType"
                      drugOptionIdFieldName="drugOptionId"
                      data-cy="selectBenefitsVerificationType"
                    />
                  </EnrollmentSection>
                </Box>
                <Box display="flex" justifyContent="space-between" py={10}>
                  <Button
                    type="button"
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      history.push(ROUTE_PATHS.BENEFITS_VERIFICATIONS.path);
                    }}
                  >
                    Back
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    data-cy="actionBenefitsVerificationNextStep"
                  >
                    Continue
                  </LoadingButton>
                </Box>
              </Stack>
            </form>
          </FormProvider>
        </Box>
        <Box width="230px" margin={7}>
          <RightSideMenu activeStep={0} />
        </Box>
      </Stack>
    </Box>
  );
};
