import { Stack, Box } from "@samacare/design/core";
import {
  TextField,
  PhoneField,
  TextFieldProps,
  PhoneFieldProps,
} from "@samacare/form";

export type OfficeContactInfoBlockProps = {
  isSubmitted?: boolean;
  isRequired?: boolean;
  NameInputProps?: Partial<TextFieldProps>;
  PhoneInputProps?: Partial<PhoneFieldProps>;
  FaxInputProps?: Partial<PhoneFieldProps>;
  EmailInputProps?: Partial<TextFieldProps>;
};

const defaultProps = {
  required: false,
  disabled: false,
  NameInputProps: {
    name: "questionnaire.sama-Office-name",
    label: "Primary contact name",
  },
  PhoneInputProps: {
    name: "questionnaire.sama-Office-phone",
    label: "Phone",
  },
  FaxInputProps: {
    name: "questionnaire.sama-Office-fax",
    label: "Fax",
  },
  EmailInputProps: {
    name: "questionnaire.sama-Office-email",
    label: "Email",
  },
};

export const OfficeContactInfoBlock: React.FC<OfficeContactInfoBlockProps> = ({
  isSubmitted = false,
  isRequired = false,
  NameInputProps,
  PhoneInputProps,
  FaxInputProps,
  EmailInputProps,
}) => {
  return (
    <Box mt={3}>
      <Stack direction="row" spacing={2} maxWidth={600}>
        <TextField
          disabled={isSubmitted}
          fullWidth
          required={isRequired}
          rules={{ required: "This field is required" }}
          {...defaultProps.NameInputProps}
          {...NameInputProps}
        />
        <PhoneField
          disabled={isSubmitted}
          required={isRequired}
          rules={{ required: "This field is required" }}
          fullWidth
          {...defaultProps.PhoneInputProps}
          {...PhoneInputProps}
        />
      </Stack>
      <Stack direction="row" my={2} spacing={2} maxWidth={600}>
        <PhoneField
          disabled={isSubmitted}
          fullWidth
          {...defaultProps.FaxInputProps}
          {...FaxInputProps}
        />
        <TextField
          disabled={isSubmitted}
          fullWidth
          rules={{
            validate: (value) => {
              if (value.length > 0) {
                return /\S+@\S+\.\S+/.test(value);
              }
            },
          }}
          {...defaultProps.EmailInputProps}
          {...EmailInputProps}
        />
      </Stack>
    </Box>
  );
};
