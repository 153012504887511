import styled from "styled-components";

export const IntegrationFileAttachButtonContainer = styled.div`
  border: 1px dashed ${(props) => props.theme.purple};
  padding: 10px;
  margin: 10px 0;
  width: 400px;
  color: ${(props) => props.theme.purple};
  text-align: center;
`;
