import { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { withAlert } from "react-alert";
import { Flex } from "@@ui-kit";
import PrescriberAndLocationDetails from "AuthorizationSharedSteps/PrescriberSection";
import ResponseSection from "AuthorizationSharedSteps/ResponseSection";
import ContextPane from "AuthorizationSharedComponents/ContextPane";
import { ContextHeader } from "AuthorizationSharedComponents/ContextHeader";
import RequiredFieldModal from "AuthorizationSharedComponents/RequiredFieldModal";
import { isDisplayWebExtensionDetails } from "../../../util/portalUtils";
import { WebExtensionPubSub } from "../../../services/WebExtensionPubSub";

import ROUTE_PATHS from "../../ROUTE_PATHS";
import {
  resetForm,
  setCurrentStep,
  setFormFields,
  syncAuthorizationDetails,
  toggleHighlightRequiredFields,
} from "../../../reducers/formReducer";
import { PatientAndInsuranceStep } from "./PatientAndInsuranceStep";
import { WebExtensionInstallModal } from "./WebExtensionInstallModal";
import {
  Container,
  FormContainer,
  withAuthorization,
} from "../../NewAuthorization/index";
import {
  withSubmitAuthorization,
  withUpdateFormDetails,
  withUpdateAuthorizationProgress,
} from "../../../graphql/Authorization";
import { withCurrentAccount } from "../../../graphql/Account";
import NewAuthorizationHoc from "../../../components/NewAuthorizationHoc";
import {
  resultsPropType,
  locationPropTypes,
  accountPropTypes,
  authorizationPropTypes,
} from "./portalAuthorizationPropTypes";
import { WebExtensionContext } from "../../../contexts/webExtension";
import { ServiceAndDiagnosisStep } from "./ServiceAndDiagnosisStep";
import { PaStep } from "../../../../analytics/generated/segment";
import { segmentTypewriter } from "../../../../analytics/analytics";

const Step = {
  PATIENT_AND_INSURANCE: 1,
  SERVICE_AND_DIAGNOSIS: 2,
  PRESCRIBER_AND_LOCATION: 3,
  SUMMARY: 5,
};

const RESET_ON_RESTART = {
  portalLaunchErr: false,
};
export class PortalForm extends Component {
  static contextType = WebExtensionContext;

  static propTypes = {
    account: accountPropTypes,
    authorization: authorizationPropTypes,
    authorizationRefetch: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    goToLocation: PropTypes.func.isRequired,
    hasInvalidResults: PropTypes.func.isRequired,
    location: locationPropTypes,
    onComponentUpdate: PropTypes.func.isRequired,
    results: resultsPropType,
    saveAuthorizationChanges: PropTypes.func.isRequired,
    setStepAndUpdateURL: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    syncLocalState: PropTypes.func.isRequired,
    takeStep: PropTypes.func.isRequired,
  };

  state = {
    requiredFieldModalOpen: false,
    triggerReinputLogin: null,
    showWebExtensionInstallModal: true,
    ...RESET_ON_RESTART,
  };

  constructor(props) {
    super(props);
    this.WebExtensionMsg = new WebExtensionPubSub();
    this.WebExtensionMsg.addListener((msg) => {
      switch (msg.data?.type) {
        case "AuthSubmitted": {
          props.alert.success("Authorization status captured successfully!");
          props.goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
          break;
        }
        case "PortalLaunchSuccess":
          this.setState({ portalLaunchErr: false });
          props.alert.success("Portal Launched Successfully!");
          break;
        default:
          break;
      }
    });
  }

  componentDidMount() {
    this.syncLocalState();

    this.WebExtensionMsg.send("Ping");
  }

  syncLocalState() {
    const { authorization, syncLocalState } = this.props;

    if (authorization) {
      syncLocalState(authorization);
    }
  }

  componentDidUpdate(prevProps) {
    const { onComponentUpdate, authorization, results } = this.props;

    onComponentUpdate(
      prevProps,
      authorization,
      results,
      this.syncLocalState.bind(this)
    );
  }

  nextStep = async (forcedNextStep, patientId = null) => {
    const {
      takeStep,
      saveAuthorizationChanges,
      hasInvalidResults,
      setStepAndUpdateURL,
      account,
      authorization,
      step,
    } = this.props;

    if (hasInvalidResults() && forcedNextStep !== true) {
      this.setState({ requiredFieldModalOpen: true });
    } else {
      if (!account?.isReadOnly) {
        await saveAuthorizationChanges({ patientId });
      }

      if (!_.isNumber(forcedNextStep)) {
        await takeStep(true);
      } else {
        setStepAndUpdateURL(forcedNextStep);
      }

      const paStep = _.findKey(Step, (value) => value === step).toLowerCase();
      segmentTypewriter.paStepCompleted({
        accountId: account.id,
        email: account.email,
        isAdmin: account.isAdmin,
        institutionIsTop: account.institution.isTop,
        institutionName: account.institution.name,
        institutionSpecialty: account.institution.practicingSpecialty,
        internalAuthId: authorization.id,
        paType: authorization.type,
        paStep,
      });
    }
  };

  handlePrescriberLocationNextStep = async () => {
    const {
      hasInvalidResults,
      account,
      saveAuthorizationChanges,
      authorization,
      alert,
    } = this.props;

    if (hasInvalidResults()) {
      this.setState({ requiredFieldModalOpen: true });
    } else {
      if (!account.isReadOnly) {
        await saveAuthorizationChanges();
      }

      setTimeout(() => {
        if (!this.state.portalLaunchErr) {
          segmentTypewriter.paStepCompleted({
            accountId: account.id,
            email: account.email,
            isAdmin: account.isAdmin,
            institutionIsTop: account.institution.isTop,
            institutionName: account.institution.name,
            institutionSpecialty: account.institution.practicingSpecialty,
            internalAuthId: authorization.id,
            paType: authorization.type,
            paStep: PaStep.ProviderAndLocation,
          });
        } else {
          alert.error(
            `Portal did not launch. Please reload the page and try again.
            If the problem persists, please contact support.`
          );
        }
      }, 2000);

      alert.info("Launching external portal, please wait...");
      this.WebExtensionMsg.send("LaunchPortal", {
        authorizationId: authorization.id,
      });
      this.setState({ portalLaunchErr: true });
    }
  };

  back = () => {
    const { step, setStepAndUpdateURL, authorization, takeStep } = this.props;

    if (isDisplayWebExtensionDetails(authorization) && step === Step.SUMMARY) {
      setStepAndUpdateURL(step - 1.5);
    } else {
      takeStep(false);
    }
  };

  takeStepsBack = (stepsBack) => {
    const { step, setStepAndUpdateURL } = this.props;

    setStepAndUpdateURL(step - stepsBack);
  };

  checkAndHandlePatientOrInsuranceInvalid = (forceResultsInvalid) => {
    const { hasInvalidResults } = this.props;
    if (hasInvalidResults(forceResultsInvalid)) {
      this.setState({ requiredFieldModalOpen: true });
      return true;
    }

    return false;
  };

  render() {
    const {
      authorization,
      step,
      setStepAndUpdateURL,
      goToLocation,
      disabled,
      account,
      saveAuthorizationChanges,
    } = this.props;
    const {
      requiredFieldModalOpen,
      triggerReinputLogin,
      showWebExtensionInstallModal,
    } = this.state;
    const { isWebExtensionConnected } = this.context;

    let content;

    if (authorization) {
      switch (step) {
        case Step.PATIENT_AND_INSURANCE:
          content =
            authorization.type ===
            CONFIG.CONSTANTS.AUTHORIZATION_TYPES.PORTAL_COPILOT.key ? (
              <div>new view</div>
            ) : (
              <PatientAndInsuranceStep
                auth={authorization}
                checkAndHandleInvalid={
                  this.checkAndHandlePatientOrInsuranceInvalid
                }
                account={account}
                nextStep={this.nextStep}
                disabled={disabled}
              />
            );
          break;
        case Step.SERVICE_AND_DIAGNOSIS:
          content = (
            <ServiceAndDiagnosisStep
              auth={authorization}
              back={this.back}
              nextStep={this.nextStep}
              disabled={disabled}
            />
          );
          break;
        case Step.PRESCRIBER_AND_LOCATION:
          content = (
            <PrescriberAndLocationDetails
              authorization={authorization}
              back={this.back}
              nextStep={this.handlePrescriberLocationNextStep}
              disabled={disabled}
              account={account}
              saveChanges={saveAuthorizationChanges}
              useLegacyButtons={true}
            />
          );
          break;
        case Step.SUMMARY:
          content = (
            <div style={{ marginTop: "25px" }}>
              <ResponseSection
                back={this.back}
                goToLocation={() => {
                  this.props.goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
                }}
                authorization={authorization}
              />
            </div>
          );
          break;
        default:
          content = <div />;
      }

      return (
        <Flex flexDirection="column" padding="25px 50px 0 50px">
          <ContextHeader
            authorization={authorization}
            account={account}
            backToAuthorizations={() => {
              goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
            }}
            triggerReinputLogin={triggerReinputLogin}
            isDisabled={disabled}
          />
          <Container>
            <FormContainer>{content}</FormContainer>
            {authorization.type !==
              CONFIG.CONSTANTS.AUTHORIZATION_TYPES.PORTAL_COPILOT.key && (
              <>
                <ContextPane
                  authorizationId={authorization.id}
                  account={account}
                  backToAuthorizations={() => {
                    goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
                  }}
                  setStep={(stepTo) => {
                    if (stepTo === Step.SUMMARY || disabled) {
                      setStepAndUpdateURL(stepTo);
                    } else {
                      this.nextStep(stepTo);
                    }
                  }}
                />
                <RequiredFieldModal
                  closeModal={() => {
                    this.setState({ requiredFieldModalOpen: false });
                  }}
                  open={requiredFieldModalOpen}
                />
                <WebExtensionInstallModal
                  open={
                    !isWebExtensionConnected &&
                    authorization.isWebExtension &&
                    showWebExtensionInstallModal
                  }
                  onClose={() =>
                    this.setState({ showWebExtensionInstallModal: false })
                  }
                />
              </>
            )}
          </Container>
        </Flex>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    attachments: state.form.attachments,
    step: state.form.currentStep,
    disabled: state.form.disabled,
    requiredFieldKeys: state.form.requiredFieldKeys,
    DrugOptionId: state.form.DrugOptionId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    syncLocalState: ({
      config,
      status,
      attachments,
      portalTitle,
      DrugOptionId,
    }) => {
      dispatch(
        syncAuthorizationDetails({
          config,
          status,
          attachments,
          portalTitle,
          DrugOptionId,
        })
      );
    },
    goToLocation: (location) => {
      dispatch(push(location));
    },
    setToForm: (toSet) => dispatch(setFormFields(toSet)),
    setStep(step) {
      dispatch(setCurrentStep(step));
    },
    reset() {
      dispatch(resetForm());
    },
    toggleHighlightRequiredFields(shouldBeHighlighted) {
      dispatch(toggleHighlightRequiredFields(shouldBeHighlighted));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withUpdateFormDetails,
    withSubmitAuthorization,
    withAuthorization,
    withCurrentAccount,
    withUpdateAuthorizationProgress
  )(withApollo(withAlert()(NewAuthorizationHoc(PortalForm))))
);
