import * as React from "react";
import { Account, Location, Authorization } from "@samacare/graphql";

import LocationDetails from "../LocationDetails/LocationDetails";
import { Tab } from "../../../MainList/TabbedHeader";
import { ProviderType } from "@@components/AuthorizationSharedSteps/PrescriberSection/types/types";
import { FetchResult } from "@apollo/client";

interface ProviderTab extends Tab {
  providerType: ProviderType;
}

const MultiLocationDetails: React.VFC<{
  authorization: Authorization;
  disabled: boolean;
  referringProviderLocationId: string;
  selectedTab: ProviderTab;
  providerTabs: ProviderTab[];
  onTabSelect: (tab: Tab) => void;
  setLocationDetails: () => Promise<void>;
  trackUpsert: () => void;
  upsertPrescriberOrLocation: () =>
    | Promise<FetchResult<{ [upsertPrescriber: string]: Account }>>
    | Promise<FetchResult<{ [upsertLocation: string]: Location }>>;
}> = ({
  authorization,
  disabled,
  selectedTab,
  providerTabs,
  referringProviderLocationId,
  setLocationDetails,
  trackUpsert,
  upsertPrescriberOrLocation,
}) => {
  return (
    <>
      {selectedTab === providerTabs[0] && (
        <LocationDetails
          authorization={authorization}
          providerType={selectedTab.providerType}
          disabled={disabled}
          set={setLocationDetails}
          trackUpsert={trackUpsert}
          upsertPrescriberOrLocation={upsertPrescriberOrLocation}
        />
      )}
      {selectedTab === providerTabs[1] && (
        <LocationDetails
          authorization={authorization}
          providerType={selectedTab.providerType}
          locationId={referringProviderLocationId}
          disabled={disabled}
          set={setLocationDetails}
          trackUpsert={trackUpsert}
          upsertPrescriberOrLocation={upsertPrescriberOrLocation}
        />
      )}
    </>
  );
};

export default MultiLocationDetails;
