import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import colors from "Resources/colors";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";
import PrescriberDetails from "./PrescriberDetails/PrescriberDetails";
import AlertModal from "../../AlertModal";
import BaseButton from "../../BaseButton";
import LocationDetails from "./LocationDetails/LocationDetails";
import ROUTE_PATHS from "../../../routes/ROUTE_PATHS";
import { setFormFields } from "../../../reducers/formReducer";
import { withUpsertLocation } from "../../../graphql/Location";
import MultiProviderDetails from "./MultiProviderDetails/MultiProviderDetails";
import MultiLocationDetails from "./MultiLocationDetails/MultiLocationDetails";
import { LeftRightCenterAll } from "../../../components/LeftRight";
import { isDisplayWebExtensionDetails } from "../../../util/portalUtils";
import { RequesterSection } from "@@components/AuthorizationSharedComponents/RequesterSection";
import { withUpsertPrescriber } from "../../../graphql/Prescriber";
import { filterNullValue } from "../../../util/objectUtils";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const { DEFAULT_FIELDS } = CONFIG;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin-left: 10px;
    padding: 10px;
  }
`;

const MissingAuthWorkflowButton = styled(BaseButton)`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-top: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.purple};
`;

const DetailsRow = styled.div`
  margin: 2px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.purple};
`;

const ModelName = styled.div`
  margin: 5px 0;
  font-size: 14px;
  font-weight: 400;
`;

const HelperText = styled.div`
  margin: 10px 0;
  font-size: 16px;
  font-weight: 700;
`;

const BorderedInfoBox = styled.div`
  margin-top: 32px;
  border: 1px solid ${({ theme }) => theme.colors.purple};
  border-radius: 8px;
  padding: 8px 16px;
`;

const { PRESCRIBER, REFERRING_PROVIDER } = CONFIG.CONSTANTS.PRESCRIBER_TYPES;
const TABS = [
  { title: "Provider", tabTitle: "Provider", providerType: PRESCRIBER },
  {
    title: "Referring Provider",
    tabTitle: "Referring Provider",
    providerType: REFERRING_PROVIDER,
  },
];

export class Prescriber extends PureComponent {
  state = {
    dbPrescriber: {},
    dbReferringProvider: {},
    dbLocation: {},
    dbReferringProviderLocation: {},
    showUpdateModal: false,
    isSubmitting: false,
    providerTabView: TABS[0],
  };

  trackUpsert = (instance, isPrescriber, providerType = PRESCRIBER) => {
    if (isPrescriber) {
      switch (providerType) {
        case REFERRING_PROVIDER:
          this.setState({ dbReferringProvider: instance });
          break;
        default:
          this.setState({ dbPrescriber: instance });
          break;
      }
    } else {
      switch (providerType) {
        case REFERRING_PROVIDER:
          this.setState({ dbReferringProviderLocation: instance });
          break;
        default:
          this.setState({ dbLocation: instance });
          break;
      }
    }
  };

  getModelForUnsavedCheck = (isPrescriber, providerType) => {
    const {
      dbLocation,
      dbPrescriber,
      dbReferringProvider,
      dbReferringProviderLocation,
    } = this.state;

    if (!isPrescriber) {
      switch (providerType) {
        case REFERRING_PROVIDER:
          return dbReferringProviderLocation;
        default:
          return dbLocation;
      }
    }

    switch (providerType) {
      case REFERRING_PROVIDER:
        return dbReferringProvider;
      default:
        return dbPrescriber;
    }
  };

  checkForUnsaved = (isPrescriber, providerType = PRESCRIBER) => {
    const model = this.getModelForUnsavedCheck(isPrescriber, providerType);

    return (
      !_.isEmpty(model) &&
      !_.isEqual(
        filterNullValue(this.mapConfigToModelKeys(isPrescriber, providerType)),
        filterNullValue(
          _.pick(
            model,
            _.keys(this.mapConfigToModelKeys(isPrescriber, providerType))
          )
        )
      )
    );
  };

  mapConfigToModelKeys = (isPrescriber, providerType = PRESCRIBER) => {
    const { results } = this.props;
    if (isPrescriber) {
      switch (providerType) {
        case REFERRING_PROVIDER:
          return {
            firstName:
              results[DEFAULT_FIELDS.REFERRING_PROVIDER_FIRST_NAME.key],
            lastName: results[DEFAULT_FIELDS.REFERRING_PROVIDER_LAST_NAME.key],
            specialtyDescription:
              results[DEFAULT_FIELDS.REFERRING_PROVIDER_SPECIALTY.key],
            NPI: results[DEFAULT_FIELDS.REFERRING_PROVIDER_NPI.key],
            TIN: results[DEFAULT_FIELDS.REFERRING_PROVIDER_TIN.key],
          };
        default:
          return {
            firstName: results[DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME.key],
            lastName: results[DEFAULT_FIELDS.PRESCRIBER_LAST_NAME.key],
            specialtyDescription:
              results[DEFAULT_FIELDS.PRESCRIBER_SPECIALTY.key],
            NPI: results[DEFAULT_FIELDS.PRESCRIBER_NPI.key],
            TIN: results[DEFAULT_FIELDS.PRESCRIBER_TIN.key],
            specialtyCode:
              results[DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE.key],
            availityPayerAssignedProviderId:
              results[DEFAULT_FIELDS.PRESCRIBER_PAYER_ASSIGNED_PROVIDER_ID.key],
            availitySubmitterId:
              results[DEFAULT_FIELDS.PRESCRIBER_SUBMITTER_ID.key],
          };
      }
    }

    switch (providerType) {
      case REFERRING_PROVIDER:
        return {
          facilityName:
            results[DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME.key],
          address: results[DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS.key],
          city: results[DEFAULT_FIELDS.REFERRING_PROVIDER_CITY.key],
          state: results[DEFAULT_FIELDS.REFERRING_PROVIDER_STATE.key],
          zip: results[DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP.key],
        };
      default:
        return {
          name: results[DEFAULT_FIELDS.LOCATION_NAME.key],
          facilityName: results[DEFAULT_FIELDS.FACILITY_NAME.key],
          address: results[DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key],
          city: results[DEFAULT_FIELDS.PRESCRIBER_CITY.key],
          state: results[DEFAULT_FIELDS.PRESCRIBER_STATE.key],
          zip: results[DEFAULT_FIELDS.PRESCRIBER_ZIP.key],
          taxId: results[DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key],
          NPI: results[DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key],
        };
    }
  };

  upsertPrescriberOrLocation = async ({
    isPrescriber,
    providerType = PRESCRIBER,
    id = null,
  }) => {
    const { upsertPrescriber, upsertLocation } = this.props;
    const upsertFunction = isPrescriber ? upsertPrescriber : upsertLocation;
    return upsertFunction({
      variables: {
        id,
        patch: this.mapConfigToModelKeys(isPrescriber, providerType),
      },
    });
  };

  /*
   * This PDF submit functionality originally exists in FORM_DETAILS step (STEP 4)
   * for PA submission.
   * In Novartis PA submission workflow, we removed the FORM_DETAILS step,
   * so we added the novartisSubmitOverride in
   * PRESCRIBER_AND_LOCATION_DETAILS step (STEP 3)
   * for PDF submission
   */
  novartisSubmitOverride = () => {
    const { onSubmit, authorization } = this.props;
    onSubmit(
      () => {
        this.setState({ isSubmitting: true });
      },
      () => {
        this.setState({ isSubmitting: false });
      },
      () => {
        if (
          !_.includes(
            [
              CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key,
              CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key,
            ],
            authorization.type
          )
        ) {
          return false;
        }
        return true;
      },
      this.onInvalidFormState
    );
  };

  saveAndStep = async () => {
    const { nextStep } = this.props;
    const { dbPrescriber, dbLocation } = this.state;
    try {
      if (this.checkForUnsaved(true)) {
        this.upsertPrescriberOrLocation({
          isPrescriber: true,
          id: dbPrescriber.id,
        });
      }
      if (this.checkForUnsaved(false)) {
        this.upsertPrescriberOrLocation({
          isPrescriber: false,
          id: dbLocation.id,
        });
      }
      nextStep();
    } catch {
      alert.error(
        "There was an error updating prescriber or location information"
      );
    }
  };

  saveProgressAndBackToMain = async () => {
    const { toAuths, saveChanges } = this.props;
    const { dbPrescriber, dbLocation } = this.state;

    try {
      if (this.checkForUnsaved(true)) {
        this.upsertPrescriberOrLocation({
          isPrescriber: true,
          id: dbPrescriber.id,
        });
      }
      if (this.checkForUnsaved(false)) {
        this.upsertPrescriberOrLocation({
          isPrescriber: false,
          id: dbLocation.id,
        });
      }
      saveChanges();
      toAuths();
    } catch {
      alert.error(
        "There was an error updating prescriber or location information"
      );
    }
  };

  procureSubmitFunction = () => {
    const { isNovartis, nextStep, authorization } = this.props;

    if (
      isDisplayWebExtensionDetails(authorization) &&
      authorization.status !==
        CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION
    ) {
      return undefined; // don't show submit button
    } else if (this.checkForUnsaved(true) || this.checkForUnsaved(false)) {
      return () => {
        this.setState({ showUpdateModal: true });
      };
    } else if (isNovartis) {
      return () => {
        this.novartisSubmitOverride();
        nextStep();
      };
    }
    return async () => {
      this.setState({ isSubmitting: true });
      await nextStep();
      this.setState({ isSubmitting: false });
    };
  };

  handleUpdatedInfoNoClick = () => {
    this.setState({ showUpdateModal: false });
    this.props.nextStep();
  };

  handleUpdatedInfoYesClick = () => {
    this.setState({ showUpdateModal: false });
    this.saveAndStep();
  };

  render() {
    const {
      back,
      set,
      results,
      authorization,
      disabled,
      children,
      account,
      saveChanges,
      useLegacyButtons,
    } = this.props;

    const {
      showUpdateModal,
      isSubmitting,
      providerTabView,
      dbReferringProvider,
      dbReferringProviderLocation,
    } = this.state;

    const isDispWebExtensionDetails =
      isDisplayWebExtensionDetails(authorization);

    let submitText;
    if (isDispWebExtensionDetails) {
      submitText = "Proceed";
    } else if (
      authorization.type ===
        CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key &&
      authorization.submissionPhoneNumber == null
    ) {
      submitText = "Complete";
    }

    return (
      <FormContainer>
        <RequesterSection
          auth={authorization}
          account={account}
          disabled={disabled}
          saveChanges={saveChanges}
        />

        {account?.institution?.featureFlags?.ReferringProvider === true ? (
          <MultiProviderDetails
            authorization={authorization}
            disabled={disabled}
            results={results}
            selectedTab={providerTabView}
            providerTabs={TABS}
            prescriberId={authorization.formDetails.prescriberId}
            referringProviderId={dbReferringProvider.id}
            onTabSelect={(tab) => {
              this.setState({ providerTabView: tab });
            }}
            setPrescriberDetails={set}
            trackUpsert={this.trackUpsert}
            upsertPrescriberOrLocation={this.upsertPrescriberOrLocation}
          />
        ) : (
          <PrescriberDetails
            authorization={authorization}
            authorizationId={authorization.id}
            disabled={disabled}
            prescriberId={authorization.formDetails.prescriberId}
            set={set}
            results={results}
            providerType={PRESCRIBER}
            trackUpsert={this.trackUpsert}
            upsertPrescriberOrLocation={this.upsertPrescriberOrLocation}
          />
        )}
        {account?.institution?.featureFlags?.ReferringProvider === true ? (
          <MultiLocationDetails
            authorization={authorization}
            disabled={disabled}
            setLocationDetails={set}
            referringProviderLocationId={dbReferringProviderLocation.id}
            selectedTab={providerTabView}
            providerTabs={TABS}
            trackUpsert={this.trackUpsert}
            upsertPrescriberOrLocation={this.upsertPrescriberOrLocation}
          />
        ) : (
          <LocationDetails
            authorization={authorization}
            disabled={disabled}
            set={set}
            trackUpsert={this.trackUpsert}
            upsertPrescriberOrLocation={this.upsertPrescriberOrLocation}
          />
        )}
        {authorization.status ===
          CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PREMIUM_RX_SUPPORT && (
          <>
            <MissingAuthWorkflowButton
              color={colors.purple}
              onClick={this.saveProgressAndBackToMain}
            >
              Back to main list
            </MissingAuthWorkflowButton>
            <p style={{ "font-size": "12px", "text-align": "right" }}>
              (PremiumRx Support will take it from here)
            </p>
          </>
        )}
        {!children &&
          authorization.status !==
            CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PREMIUM_RX_SUPPORT && (
            <>
              {isDispWebExtensionDetails &&
                authorization.status ===
                  CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION && (
                  <LeftRightCenterAll>
                    <BorderedInfoBox>
                      Once you launch the portal, SamaCare will assist with
                      pre-filling the authorization based on the information you
                      have provided
                    </BorderedInfoBox>
                  </LeftRightCenterAll>
                )}
              <FormSubmitButtons
                back={back}
                submitText={submitText}
                submit={this.procureSubmitFunction()}
                disabled={isSubmitting}
                loading={isSubmitting}
                useLegacyButtons={useLegacyButtons}
              />
            </>
          )}
        {showUpdateModal && (
          <AlertModal
            closeModal={() => {
              this.setState({ showUpdateModal: false });
            }}
            open
            content={
              <div>
                <HelperText>
                  We detected some changes for the following items, would you
                  like to save these for future authorizations?
                </HelperText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {this.checkForUnsaved(true) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <ModelName>Updated Provider:</ModelName>
                      {_.map(this.mapConfigToModelKeys(true), (value, key) => (
                        <DetailsRow key={key}>{`${_.upperFirst(
                          key
                        )}: ${value}`}</DetailsRow>
                      ))}
                    </div>
                  )}
                  {this.checkForUnsaved(false) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <ModelName>Updated Location:</ModelName>
                      {_.map(this.mapConfigToModelKeys(false), (value, key) => (
                        <DetailsRow key={key}>{`${_.upperFirst(
                          key
                        )}: ${value}`}</DetailsRow>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            }
            header="Updated Information Detected"
            buttons={
              <ModalButtonContainer>
                <BaseButton
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    this.setState({ showUpdateModal: false });
                  }}
                >
                  Back
                </BaseButton>
                <BaseButton
                  style={{ marginRight: "10px" }}
                  onClick={this.handleUpdatedInfoNoClick}
                >
                  No, Do Not Save Changes
                </BaseButton>
                <BaseButton
                  style={{ marginRight: "10px" }}
                  onClick={this.handleUpdatedInfoYesClick}
                >
                  Yes, Save Changes
                </BaseButton>
              </ModalButtonContainer>
            }
          />
        )}
      </FormContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    location: state.router.location,
    results: state.form.results,
    disabled: state.form.disabled || ownProps.disabled,
  };
}

const mapDispatchToProps = (dispatch) => ({
  set(fields) {
    dispatch(setFormFields(fields));
  },
  toAuths: () => {
    dispatch(push(ROUTE_PATHS.AUTHORIZATION_LIST_FILTERED.path));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withUpsertPrescriber, withUpsertLocation)(withAlert()(Prescriber)));
