import { useQuery } from "@apollo/client";
import { parseISO } from "date-fns";
import _ from "lodash";
import moment from "moment";
import React from "react";

import NewPriorAuthButton from "@@components/NewPriorAuthButton";
import { AllBenefitsVerificationsQuery } from "@@generated/graphql";
import {
  Box,
  Button,
  DataGridPro,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from "@samacare/design/core";
import Tooltip from "@samacare/design/core/Tooltip/Tooltip";
import { Authorization } from "@samacare/graphql";
import { useHistory, useRouteMatch } from "react-router-dom";
import { GridToolbar } from "../../../components/DataGrid/GridToolbar";
import GridWrapper from "../../../components/DataGrid/GridWrapper";
import getAuthorizationRoute from "../../../util/getAuthorizationRoute";
import { getInsuranceType } from "../../../util/getInsuranceType";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { BenefitsVerificationAssigneeSelect } from "../components/AssigneeSelect";
import { StatusChip, StatusOption } from "../components/StatusChip";
import { humanizeDropdownValue } from "../bvUtils";
import BenefitsVerificationQuery from "../graphql/BenefitsVerificationQuery.gql";
import { useGridFilterUrlSync } from "../gridHooks";
import { humanizeBoolean } from "../humanizers";

export type BenefitsVerificationsGridRow = {
  id: string;
  payer: string;
  authRequired: boolean;
  authorization: Authorization | null;
  planType: string;
  insuranceCoverageStatus: string;
  patientMemberId: string;
  patientId: number;
  firstName: string;
  lastName: string;
  status: string;
  dob: string;
  drug: string;
  updatedAt: Date;
  createdAt: Date;
  requestedById: string;
};

export const BenefitsVerifications: React.FC = () => {
  const { data, loading } = useQuery<AllBenefitsVerificationsQuery>(
    BenefitsVerificationQuery,
    { fetchPolicy: "network-only" }
  );

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const { filterModel, setFilterModel } = useGridFilterUrlSync();
  const history = useHistory();
  const { path } = useRouteMatch();

  const columns = (): GridColDef[] => [
    {
      field: "patientId",
      headerName: "Patient ID",
    },
    {
      field: "firstName",
      headerName: "First name",
    },
    {
      field: "lastName",
      headerName: "Last name",
    },
    {
      field: "dob",
      headerName: "Date of birth",
    },
    {
      field: "status",
      headerName: "Status",
      cellClassName: "bv_status",
      width: 150,
      renderCell: (params: { row: BenefitsVerificationsGridRow }) => (
        <StatusChip status={_.lowerCase(params.row.status) as StatusOption} />
      ),
    },
    {
      field: "authRequired",
      headerName: "Auth Required",
      width: 150,
      renderCell: (params: { row: BenefitsVerificationsGridRow }) => {
        const { id, patientId, authRequired } = params.row;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Box mr={2}>{humanizeBoolean(authRequired)}</Box>
            {authRequired && (
              <NewPriorAuthButton
                patientId={patientId.toString()}
                configOverrides={{
                  benefitsVerificationId: id,
                }}
              >
                <Button variant="contained" size="small">
                  Add
                </Button>
              </NewPriorAuthButton>
            )}
          </Box>
        );
      },
    },
    {
      field: "authorization",
      headerName: "Auth",
      renderCell: (params: { row: BenefitsVerificationsGridRow }) => {
        const { authorization } = params.row;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            {authorization?.id != null && (
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `${getAuthorizationRoute(authorization)}?step=${
                      (
                        authorization.formDetails as {
                          currentStep: number;
                        } | null
                      )?.currentStep?.toString() ?? "1"
                    }&id=${authorization.id}`
                  );
                }}
              >
                View
              </Button>
            )}
          </Box>
        );
      },
    },
    {
      field: "drug",
      headerName: "Drug / Code",
      width: 160,
    },

    { field: "payer", headerName: "Payer" },
    { field: "planType", headerName: "Plan Type" },
    { field: "insuranceCoverageStatus", headerName: "Cov. Status" },
    { field: "patientMemberId", headerName: "Member ID#" },
    {
      field: "createdAt",
      headerName: "Created",
      type: "string",
      width: 120,
      renderCell: (params: { row: BenefitsVerificationsGridRow }) => {
        const { createdAt } = params.row;
        return (
          <Tooltip title={moment(createdAt).format("LLLL")}>
            <span>{moment(createdAt).format("L")}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Last updated",
      type: "string",
      width: 120,
      renderCell: (params: { row: BenefitsVerificationsGridRow }) => {
        const { updatedAt } = params.row;
        return (
          <Tooltip title={moment(updatedAt).format("LLLL")}>
            <span>{moment(updatedAt).format("L")}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "requestedById",
      headerName: "Assignee",
      type: "singleSelect",
      editable: true,
      width: 150,
      renderCell: (params: { row: BenefitsVerificationsGridRow }) => {
        return (
          <BenefitsVerificationAssigneeSelect
            benefitsVerificationId={params.row.id}
            requestedById={params.row.requestedById}
          />
        );
      },
    },
  ];

  const benefitsVerifications: BenefitsVerificationsGridRow[] = (
    data?.benefitsVerificationsPaginated || []
  ).map<BenefitsVerificationsGridRow>((item) => {
    const outcomeDataUpdatedAt = item.outcome?.data?.updatedAt as string;
    const insuranceCoverageStatus = item.outcome?.data?.insuranceCoverageStatus;
    const {
      patientDetails,
      drugDetails,
      primaryInsuranceDetails,
      requestedById,
    } = item.request;
    const { drugName, drugCode } = drugDetails;
    const insuranceCompanyName =
      primaryInsuranceDetails?.insuranceCompany?.name;
    const { firstName, lastName, dob } = patientDetails;
    return {
      id: item?.id,
      authRequired: item.outcome?.data?.isAuthRequired,
      authorization: item?.authorization as Authorization | null,
      planType:
        primaryInsuranceDetails?.planType &&
        getInsuranceType(primaryInsuranceDetails.planType),
      insuranceCoverageStatus:
        insuranceCoverageStatus &&
        humanizeDropdownValue(insuranceCoverageStatus),
      patientMemberId: primaryInsuranceDetails?.memberId,
      payer: insuranceCompanyName,
      patientId: item?.patient.id,
      status: item?.outcome?.data?.status ?? StatusOption.Draft,
      updatedAt: parseISO(outcomeDataUpdatedAt ?? item?.createdAt),
      createdAt: parseISO(item?.createdAt as string),
      dob: moment(dob).format("l"),
      drug: drugName && drugCode ? `${drugName} - ${drugCode}` : "",
      lastName,
      firstName,
      requestedById,
    } as BenefitsVerificationsGridRow;
  });

  return (
    <GridWrapper headerText="Benefits Verifications">
      <DataGridPro
        onCellClick={(params, event) => {
          const isActionCell = !!(event.target as HTMLElement).closest(
            ".MuiDataGrid-actionsCell"
          );
          const isSelectCell = !!(event.target as HTMLElement).closest(
            ".MuiDataGrid-cell--editable"
          );

          if (isActionCell || isSelectCell) {
            event.stopPropagation();
          }
        }}
        autoHeight
        columnVisibilityModel={{ patientId: false }}
        loading={loading}
        rows={benefitsVerifications}
        columns={columns()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        onRowSelectionModelChange={(newSelection: GridRowSelectionModel) => {
          setSelectionModel(newSelection);
          if (newSelection.length > 0) {
            const rowId = newSelection[0];
            const row = benefitsVerifications.find((bv) => bv.id === rowId);
            if (row?.status === StatusOption.Draft) {
              history.push(
                `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/edit/${rowId}`
              );
            } else {
              history.push(
                `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${rowId}`
              );
            }
          }
        }}
        rowSelectionModel={selectionModel}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            onNewClick: () => history.push(`${path}/create`),
            newButtonDataCy: "actionNewBenefitsVerification",
          },
        }}
      />
    </GridWrapper>
  );
};
