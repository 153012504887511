import {
  NewEnrollmentObjectType,
  MutationUpdateNewEnrollmentByIdArgs,
} from "@samacare/graphql";

import { gql, useMutation } from "@apollo/client";

import Maybe from "graphql/tsutils/Maybe";

const UPDATE_AUTH_ENROLLMENT_GQL = gql`
  mutation UpdateNewEnrollmentById(
    $id: Int!
    $patch: NewEnrollmentInputObjectType!
  ) {
    updateNewEnrollmentById(id: $id, patch: $patch) {
      id
      status
      questionnaire {
        id
        answers {
          id
          value
        }
      }
      SignatureFileId
      PatientConsent {
        hasConsent
      }
      MedicationRequest {
        id
        patient {
          id
          dob
          firstName
          lastName
          city
          state
          address
          zip
          primaryInsurance {
            planType
            memberId
            insuranceState
            InsuranceCompanyId
            groupNumber
          }
          secondaryInsurance {
            planType
            memberId
            insuranceState
            InsuranceCompanyId
            groupNumber
          }
          phone
          gender
        }
        medications {
          name
          code
          quantity
        }
        diagnoses {
          code
          description
        }
        location {
          id
          name
          NPI
          taxId
          address
          city
          state
          zip
        }
        prescriber {
          id
          firstName
          lastName
          specialtyDescription
          NPI
          TIN
        }
        insuranceCompany {
          id
          label: name
        }
        PatientId
        PrescriberId
        LocationId
      }
      EnrollmentProgram {
        id
        label
        questionnaire {
          id
          all {
            id
            linkId
            groupId
            text
            type
            required
            hidden
            enableBehavior
            enableWhen {
              id
              operator
              question
              answerBoolean
              answerString
            }
            answerOption {
              valueString
            }
          }
          groups {
            id
            title
            description
          }
        }
      }
    }
  }
`;

const useUpdateNewEnrollmentById = () => {
  return useMutation<
    Maybe<NewEnrollmentObjectType>,
    MutationUpdateNewEnrollmentByIdArgs
  >(UPDATE_AUTH_ENROLLMENT_GQL, { refetchQueries: () => ["Enrollments"] });
};

export default useUpdateNewEnrollmentById;
