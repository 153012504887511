import { useFormContext } from "react-hook-form";
import { useIMask } from "react-imask";

import TextField, { TextFieldProps } from "../TextField";
import AutocompleteField from "../AutocompleteField";
import { AutocompleteFieldProps } from "../AutocompleteField/AutocompleteField";
import { Stack } from "@samacare/design/core";

export type PhoneFieldProps = {
  usePhoneType?: boolean;
  PhoneTypeProps?: Partial<AutocompleteFieldProps<string, false, true, false>>;
} & TextFieldProps;

const PhoneField: React.FC<PhoneFieldProps> = ({
  usePhoneType = false,
  PhoneTypeProps = {},
  name,
  required,
  disabled,
  ...props
}) => {
  const { ref } = useIMask({
    mask: "000-000-0000",
    eager: "remove",
  });
  const { watch } = useFormContext();

  const typeSelectName = PhoneTypeProps.name ?? `${name}type`;

  return (
    <Stack direction="row" spacing={0} sx={{ width: "100%" }}>
      <TextField
        ref={ref as React.MutableRefObject<HTMLDivElement>}
        rules={{
          pattern: {
            value: /^(\d{3})[- ](\d{3})[- ](\d{4})/,
            message: "Invalid phone number",
          },
        }}
        InputProps={{
          sx: usePhoneType
            ? {
                "& fieldset": {
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                },
              }
            : {},
        }}
        name={name}
        required={required}
        disabled={disabled}
        {...props}
      />

      {usePhoneType && (
        <AutocompleteField<string, false, true, false>
          name={typeSelectName}
          required={required}
          disabled={disabled}
          disableClearable
          placeholder="type"
          options={["mobile", "home", "work"]}
          value={watch(typeSelectName) ?? ""}
          sx={{
            maxWidth: 120,
            "& fieldset": {
              borderLeft: "none",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          }}
          {...PhoneTypeProps}
        />
      )}
    </Stack>
  );
};

export default PhoneField;
