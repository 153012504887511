import { useIMask } from "react-imask";
import TextField, { type TextFieldProps } from "../TextField";

export const ZipField: React.FC<TextFieldProps> = (props) => {
  const { ref } = useIMask({
    mask: "00000-0000",
    eager: "remove",
  });

  return (
    <TextField
      ref={ref as React.MutableRefObject<HTMLDivElement>}
      rules={{
        pattern: {
          value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
          message: "Invalid zip code",
        },
      }}
      {...props}
    />
  );
};
