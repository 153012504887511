import { useQuery } from "@apollo/client";
import _ from "lodash";
import {
  createContext,
  useContext,
  useMemo,
  useState,
  SetStateAction,
  Dispatch,
} from "react";

import {
  CircularProgress,
  GridPaginationModel,
  GridFilterModel,
} from "@samacare/design/core";
import getServicesByPatientQuery from "./GetServicesByPatient.gql";
import {
  GetTreatmentsByPatientQuery,
  GetTreatmentsByPatientQueryVariables,
} from "@@generated/graphql";
import {
  ServicesGroupedByDrug,
  Patient as PatientType,
} from "@samacare/graphql";
import { useGridFilterUrlSync } from "../BenefitsVerifications/gridHooks";

export type PatientTreatmentsGridRow = PatientType & {
  drugs: string[];
  treatments: ServicesGroupedByDrug[];
  payerName?: string;
  hasBvInDraft: boolean;
  showBvSupportNote: boolean;
};

type PatientTreatmentsContextType = {
  patientTreatmentCount?: number | null;
  open: boolean;
  toggleDrawer: (newOpen: boolean) => void;
  activeRow?: PatientTreatmentsGridRow;
  setActiveRow: Dispatch<SetStateAction<PatientTreatmentsGridRow | undefined>>;
  setDuration: Dispatch<SetStateAction<string | undefined>>;
  setPaginationModel: Dispatch<SetStateAction<GridPaginationModel>>;
  paginationModel?: GridPaginationModel;
  duration?: string | undefined;
  rows?: PatientTreatmentsGridRow[];
  filterModel?: GridFilterModel;
  setFilterModel: Dispatch<SetStateAction<GridFilterModel>>;
};

const PatientTreatmentsContext = createContext<PatientTreatmentsContextType>({
  open: false,
  setFilterModel: () => {},
  setPaginationModel: () => {},
  toggleDrawer: () => {},
  setDuration: () => {},
  setActiveRow: () => {},
});

export const usePatientTreatmentsContext = (): PatientTreatmentsContextType =>
  useContext<PatientTreatmentsContextType>(PatientTreatmentsContext);

export const PatientTreatmentsProvider: React.FC = ({ children }) => {
  const [duration, setDuration] = useState<string | undefined>("1");

  /**
   * @state paginationModel
   */
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const { filterModel, setFilterModel } = useGridFilterUrlSync();

  const searchWords = filterModel?.quickFilterValues ?? [];
  const patientTreatmentsResult = useQuery<
    GetTreatmentsByPatientQuery,
    GetTreatmentsByPatientQueryVariables
  >(getServicesByPatientQuery, {
    variables: {
      duration: Number(duration ?? "1"),
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      filter: searchWords?.length > 0 ? searchWords[0] : null,
    },
    fetchPolicy: "network-only",
  });

  const [open, setOpen] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<
    PatientTreatmentsGridRow | undefined
  >();

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const patientTreatmentsData = useMemo(() => {
    if (patientTreatmentsResult.loading || patientTreatmentsResult.error)
      return;
    return {
      patientTreatmentCount:
        patientTreatmentsResult.data?.getTreatmentsByPatient?.count,
      rows: (
        patientTreatmentsResult.data?.getTreatmentsByPatient?.rows ?? []
      ).map((entry) => {
        return {
          id: entry?.patient?.id,
          ...entry?.patient,
          drugs: entry?.drugNames,
          treatments: entry?.treatments,
          payerName: entry?.patient?.primaryInsurance?.insuranceCompany?.name,
          hasBvInDraft: entry?.hasBvInDraft,
          showBvSupportNote: entry?.showBvSupportNote,
        };
      }),
    };
  }, [
    patientTreatmentsResult.data,
    patientTreatmentsResult.loading,
    patientTreatmentsResult.error,
  ]);

  const value = useMemo(() => {
    return {
      patientTreatmentCount: patientTreatmentsData?.patientTreatmentCount,
      rows: patientTreatmentsData?.rows as PatientTreatmentsGridRow[],
      open,
      toggleDrawer,
      activeRow: _.find(
        patientTreatmentsData?.rows as PatientTreatmentsGridRow[],
        { id: activeRow?.id }
      ),
      setActiveRow,
      setDuration,
      setPaginationModel,
      paginationModel,
      duration,
      filterModel,
      setFilterModel,
    };
  }, [patientTreatmentsData, open, activeRow, paginationModel, duration]);

  if (patientTreatmentsResult.loading) {
    return <CircularProgress />;
  }
  return (
    <PatientTreatmentsContext.Provider value={value}>
      {children}
    </PatientTreatmentsContext.Provider>
  );
};
