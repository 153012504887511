import Section from "AuthorizationSharedComponents/Section";
import PrescriberSelector from "./PrescriberSelector";
import { transformSections } from "../../../../util/sectionConfigurationManager";
import CreatePrescriberOrLocation from "../CreatePrescriberOrLocation";

const { DEFAULT_FIELDS } = CONFIG;
const { PRESCRIBER, REFERRING_PROVIDER } = CONFIG.CONSTANTS.PRESCRIBER_TYPES;

const getPrescriberFields = (providerType) => {
  switch (providerType) {
    case REFERRING_PROVIDER:
      return [
        [
          DEFAULT_FIELDS.REFERRING_PROVIDER_FIRST_NAME,
          DEFAULT_FIELDS.REFERRING_PROVIDER_LAST_NAME,
        ],
        [
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_SPECIALTY, required: false },
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_NPI },
        ],
        { ...DEFAULT_FIELDS.REFERRING_PROVIDER_TIN, required: false },
      ];
    default:
      return [
        [
          DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME,
          DEFAULT_FIELDS.PRESCRIBER_LAST_NAME,
        ],
        [
          { ...DEFAULT_FIELDS.PRESCRIBER_SPECIALTY, required: false },
          { ...DEFAULT_FIELDS.PRESCRIBER_NPI },
        ],
        [
          { ...DEFAULT_FIELDS.PRESCRIBER_TIN, required: false },
          { ...DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE, required: false },
        ],
        [
          {
            ...DEFAULT_FIELDS.PRESCRIBER_PAYER_ASSIGNED_PROVIDER_ID,
            required: false,
          },
          { ...DEFAULT_FIELDS.PRESCRIBER_SUBMITTER_ID, required: false },
        ],
      ];
  }
};

const getSectionItems = (providerType) => {
  switch (providerType) {
    case REFERRING_PROVIDER:
      return [
        [
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_FIRST_NAME, required: false },
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_LAST_NAME, required: false },
        ],
        [
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_SPECIALTY, required: false },
          { ...DEFAULT_FIELDS.REFERRING_PROVIDER_NPI, required: false },
        ],
        DEFAULT_FIELDS.REFERRING_PROVIDER_TIN,
      ];
    default:
      return [
        [
          DEFAULT_FIELDS.PRESCRIBER_FIRST_NAME,
          DEFAULT_FIELDS.PRESCRIBER_LAST_NAME,
        ],
        [DEFAULT_FIELDS.PRESCRIBER_SPECIALTY, DEFAULT_FIELDS.PRESCRIBER_NPI],
        DEFAULT_FIELDS.PRESCRIBER_TIN,
        DEFAULT_FIELDS.PRESCRIBER_SPECIALTY_CODE,
        DEFAULT_FIELDS.PRESCRIBER_PAYER_ASSIGNED_PROVIDER_ID,
        DEFAULT_FIELDS.PRESCRIBER_SUBMITTER_ID,
      ];
  }
};

const PrescriberDetails = ({
  providerType = PRESCRIBER,
  prescriberId,
  disabled,
  authorization,
  results,
  showSectionTitle,
  set,
  trackUpsert,
  upsertPrescriberOrLocation,
}) => (
  <div>
    <Section
      section={
        transformSections(
          [
            {
              items: getSectionItems(providerType),
              title: "Provider Details",
            },
          ],
          authorization.formFieldConfig
        )[0]
      }
      showTitle={showSectionTitle}
      disabled={disabled}
    >
      <div style={{ display: "flex", alignItems: "end" }}>
        <PrescriberSelector
          afterSelection={(account) => {
            // REALLY JANKY FIX
            // We save the authorization because there are some cases when the user doesn't have a phone saved
            // And setPrescriberOnAuth will clear out the number they input (because it refetches the auth + removes front end cached data)
            trackUpsert(account, true, providerType);
            set({
              [DEFAULT_FIELDS.REQUESTED_BY_PHONE.key]:
                results[DEFAULT_FIELDS.REQUESTED_BY_PHONE.key],
            });
          }}
          authorizationId={authorization.id}
          disabled={disabled}
          prescriberId={prescriberId}
          providerType={providerType}
          set={set}
        />

        <CreatePrescriberOrLocation
          isPrescriber
          providerType={providerType}
          trackUpsert={trackUpsert}
          upsertPrescriberOrLocation={upsertPrescriberOrLocation}
          fields={getPrescriberFields(providerType)}
        />
      </div>
    </Section>
  </div>
);

export default PrescriberDetails;
