import _ from "lodash";
import moment from "moment";
import { PureComponent } from "react";
import styled from "styled-components";
import { MdSwapHoriz as SwapIcon } from "@react-icons/all-files/md/MdSwapHoriz";
import UpdateAuthorizationDetailsButton from "@@components/UpdateAuthorizationDetailsButton";
import BaseButton from "@@components/BaseButton";
import AuthorizationNotes from "./AuthorizationNotes";

const { FORM_TYPES } = CONFIG.CONSTANTS;
const detailTopMargin = 5;
const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 600px;
  margin-top: -${detailTopMargin}px;
`;

const DetailPill = styled.div`
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-align: center;
  background: white;
  color: ${(props) => props.color || props.theme.darkGray};
  border: 1px solid ${(props) => props.color || props.theme.darkGray};
  padding: 3px 7px;
  font-size: 14px;
  margin-right: 5px;
  margin-top: ${detailTopMargin}px;
`;

const correspondenceFields = [
  { title: "Start", type: FORM_TYPES.DATE, key: "startDate" },
  { title: "End", type: FORM_TYPES.DATE, key: "endDate" },
  { title: "Approval #", type: FORM_TYPES.TEXT, key: "code", color: "#3b8b92" },
];

const DetailsButton = styled(BaseButton)`
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  margin-left: 5px;
  margin-top: ${detailTopMargin}px;
`;

class AuthorizationDetails extends PureComponent {
  state = { displayNotes: false };

  render() {
    const { authorization, inModal } = this.props;
    const { displayNotes } = this.state;

    const authorizationNoteDefaultProps = {
      latestNote: authorization.latestNote,
      authorizationId: authorization.id,
      inModal,
    };

    if (!_.includes(CONFIG.CONSTANTS.APPROVED_STATUSES, authorization.status)) {
      return <AuthorizationNotes {...authorizationNoteDefaultProps} />;
    }

    let details = [];
    if (authorization.latestCorrespondence) {
      _.each(correspondenceFields, (corrField) => {
        const value = authorization.latestCorrespondence[corrField.key];

        if (value) {
          const formattedValue =
            corrField.type === "date"
              ? moment(value).format("MMM Do, YY")
              : value;
          details.push({
            title: corrField.title,
            value: formattedValue,
            color: corrField.color,
          });
        }
      });

      details = details.concat(
        _.map(
          authorization.latestCorrespondence.authorizedProcedures,
          (procedure) => ({ ...procedure, color: "#2BBF96" })
        )
      );
    }

    if (!displayNotes) {
      return (
        <DetailsContainer>
          {_.map(details, (detail, i) => (
            <DetailPill
              key={`authorization_${authorization.id}_detail_${i}`}
              color={detail.color}
            >
              {detail.title}
              {": "}
              {detail.value}
            </DetailPill>
          ))}
          <UpdateAuthorizationDetailsButton
            authorizationType={authorization.type}
            authorizationId={authorization.id}
            latestCorrespondence={authorization.latestCorrespondence}
          />
          <DetailsButton
            selected={displayNotes}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ displayNotes: !displayNotes });
            }}
          >
            <SwapIcon />
          </DetailsButton>
        </DetailsContainer>
      );
    }

    return (
      <AuthorizationNotes
        {...authorizationNoteDefaultProps}
        hideNotes={(e) => {
          e.stopPropagation();
          this.setState({ displayNotes: false });
        }}
      />
    );
  }
}
export default AuthorizationDetails;
