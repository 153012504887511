import { Stack } from "@samacare/design/core";
import ZipField from "../ZipField";
import TextField, { type TextFieldProps } from "../TextField";
import StateField, { type StateFieldProps } from "../StateField";

export type AddressBlockProps = {
  required?: boolean;
  disabled?: boolean;
  ZipInputProps?: Partial<TextFieldProps>;
  CityInputProps?: Partial<TextFieldProps>;
  StateInputProps?: Partial<StateFieldProps>;
  AddressInputProps?: Partial<TextFieldProps>;
};

const defaultProps = {
  required: false,
  disabled: false,
  AddressInputProps: {
    name: "patient.address",
    label: "Street address",
  },
  CityInputProps: {
    name: "patient.city",
    label: "City",
  },
  StateInputProps: {
    name: "patient.state",
    label: "State",
  },
  ZipInputProps: {
    name: "patient.zip",
    label: "Zip",
  },
};

const AddressBlock: React.FC<AddressBlockProps> = ({
  children,
  required,
  disabled,
  AddressInputProps,
  CityInputProps,
  StateInputProps,
  ZipInputProps,
}) => {
  return (
    <Stack spacing={2} maxWidth={600}>
      <TextField
        required={required}
        disabled={disabled}
        fullWidth
        {...defaultProps.AddressInputProps}
        {...AddressInputProps}
      />
      <Stack direction="row" spacing={1}>
        <TextField
          required={required}
          disabled={disabled}
          fullWidth
          {...defaultProps.CityInputProps}
          {...CityInputProps}
        />
        <StateField
          required={required}
          disabled={disabled}
          fullWidth
          {...defaultProps.StateInputProps}
          {...StateInputProps}
        />
        <ZipField
          fullWidth
          required={required}
          disabled={disabled}
          {...defaultProps.ZipInputProps}
          {...ZipInputProps}
        />
      </Stack>
      {children}
    </Stack>
  );
};

export default AddressBlock;
