import * as React from "react";
import { Typography, Divider, Box } from "@samacare/design/core";

export type EnrollmentSectionProps = {
  title: string;
  description?: string | React.ReactNode;
  adornment?: React.ReactNode;
};

export const EnrollmentSection: React.FC<EnrollmentSectionProps> = ({
  title,
  description,
  adornment,
  children,
}) => (
  <Box minWidth={600}>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6" color="primary.main">
        {title}
      </Typography>
      {adornment}
    </Box>
    <Divider />
    {description && (
      <Typography variant="body" mt={2}>
        {description}
      </Typography>
    )}
    <Box mt={description ? 2 : 3} mb={6}>
      {children}
    </Box>
  </Box>
);
