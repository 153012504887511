import _ from "lodash";

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  TextFieldProps,
} from "@samacare/design/core";

import {
  UseControllerProps,
  useFormContext,
  useFormState,
  FieldError,
} from "react-hook-form";

import { getValidDate } from "../DateField";

export type DatePickerProps = MuiDatePickerProps<Date> &
  UseControllerProps & {
    textFieldProps?: TextFieldProps;
  };

const DatePickerField: React.FC<DatePickerProps> = ({
  name,
  value,
  rules = {},
  textFieldProps = {
    required: false,
  },
  disabled = false,
  shouldUnregister = false,
  ...props
}) => {
  const { register, control, getValues, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const dateValue = getValidDate(getValues(name) as Date | string | undefined);

  const error = _.get(errors, name) as FieldError;

  const { ref, onChange, onBlur, ...fields } = register(name, {
    ...rules,
    required: textFieldProps.required,
    shouldUnregister,
    value: dateValue,
  });

  return (
    <MuiDatePicker
      inputRef={ref}
      {...fields}
      onChange={async (date) => {
        if (date != null) {
          setValue(name, date);
        }
      }}
      disabled={disabled}
      format="MM/dd/yyyy"
      value={dateValue}
      slotProps={{
        textField: {
          fullWidth: true,
          InputLabelProps: {
            shrink: true,
          },
          inputProps: { "data-cy": _.camelCase(`field.${name}`) },
          helperText: error?.message,
          error: !!error,
          ...textFieldProps,
        },
        field: {
          clearable: true,
          onClear: () => setValue(name, null),
        },
      }}
      {...props}
    />
  );
};

export default DatePickerField;
