import { VFC, useState } from "react";
import { IngestJob } from "@samacare/graphql";
import { TopBottomCenterH } from "../../../components/TopBottomV2";
import { Box, Button, Typography } from "@samacare/design";
import { SkippedDialog } from "./SkippedDialog";
import { AddedUpdatedPopper } from "./AddedUpdatedPopper";
import { getFailureCount } from "./errorUtils";

interface UploadResultsProps {
  ingestJob: IngestJob;
}
export const UploadResults: VFC<UploadResultsProps> = ({ ingestJob }) => {
  const [isShowSkippedDialog, setIsShowSkippedDialog] = useState(false);

  const failureCount = getFailureCount(ingestJob);

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        gap: ({ spacing }) => spacing(2),
        margin: ({ spacing }) => spacing(4),
      }}
    >
      <TopBottomCenterH sx={{ width: "162px" }}>
        <Typography variant="h5">Rows</Typography>
        <Typography variant="h3">{ingestJob.originalRowCount ?? 0}</Typography>
      </TopBottomCenterH>

      <TopBottomCenterH sx={{ width: "162px" }}>
        <Typography variant="h5">Skipped</Typography>
        <Typography variant="h3">{failureCount}</Typography>
        {failureCount > 0 && (
          <Button size="small" onClick={() => setIsShowSkippedDialog(true)}>
            Details
          </Button>
        )}
      </TopBottomCenterH>

      <TopBottomCenterH sx={{ width: "162px" }}>
        <Typography variant="h5">Imported</Typography>
        <Typography variant="h3">{ingestJob.successCount ?? 0}</Typography>
        <AddedUpdatedPopper ingestJob={ingestJob} />
      </TopBottomCenterH>

      {isShowSkippedDialog && (
        <SkippedDialog
          ingestJob={ingestJob}
          closeDialog={() => setIsShowSkippedDialog(false)}
        />
      )}
    </Box>
  );
};
