import { PureComponent } from "react";
import { RouterLink, Flex } from "@@ui-kit";
import styled from "styled-components";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import strings from "Resources/strings";
import { BaseMultiline } from "Segment/StyledComponents";
import { generatePath } from "react-router-dom";
import colors from "Resources/colors";

import {
  withReportMissingInformation,
  withCreateAndAssociateAuthForm,
} from "../../graphql/AuthorizationForm";
import LoadingButton from "../../components/LoadingButton";
import BaseButton from "../../components/BaseButton";
import Upload from "../../components/Upload";
import ROUTE_PATHS from "../ROUTE_PATHS";
import { ModalBodyXL } from "../../components/ModalStyledComponents";
import { ConfirmationModalContent } from "../../components/ConfirmationModalContent";

const MAX_FILE_SIZE_B = 10000000;

const Title = styled.div`
  font-weight: 700;
  margin: 9px 4px 7px 4px;
`;

const StyledContainer = styled(Flex)`
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FileUploadedText = styled.div`
  text-align: center;
  padding: 10px;
`;

const { MISSING_INFORMATION_TYPES, AUTHORIZATION_TYPES } = CONFIG.CONSTANTS;
class MissingInformationModalContent extends PureComponent {
  state = {
    missingInformationDetails: "",
    uploadedFileKey: "",
    step: 1,
  };

  handleSubmit = async () => {
    const {
      state,
      reportMissingInformation,
      authorizationId,
      insuranceCompanyId,
      alert,
      type,
    } = this.props;
    const { missingInformationDetails, uploadedFileKey } = this.state;

    try {
      this.setState({ isLoading: true });
      await reportMissingInformation({
        variables: {
          type: type || MISSING_INFORMATION_TYPES.FORM,
          authorizationId,
          insuranceCompanyId,
          details: missingInformationDetails,
          state,
          fileKey: uploadedFileKey,
        },
      });
      alert.info("Request submitted");
    } catch (e) {
      alert.error(strings.MISSING_INFORMATION.ERROR);
      throw e;
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onDropError = () => {
    const { alert } = this.props;
    alert.error("There was an error uploading your file");
  };

  onDropSuccess = (file, res) => {
    const { data } = res;
    this.setState({ uploadedFileKey: data.fields.key });
  };

  createAuthorizationForm = async () => {
    const { alert } = this.props;
    const { createAuthorizationSpecificForm, authorizationId } = this.props;
    const { uploadedFileKey } = this.state;
    try {
      this.setState({ isLoading: true });
      await createAuthorizationSpecificForm({
        variables: {
          fileKey: uploadedFileKey,
          authorizationId,
        },
      });
    } catch (e) {
      alert.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { onCancel, authType, onCloseFormListModal } = this.props;
    const { uploadedFileKey, isLoading, step } = this.state;
    const isMultiPartyAuth = authType === AUTHORIZATION_TYPES.MULTI_PARTY.key;

    const modalBody =
      step === 1 ? (
        <ModalBodyXL>
          <div>
            <Title>Please provide relevant details</Title>
            <BaseMultiline
              onChange={(e) => {
                this.setState({ missingInformationDetails: e.target.value });
              }}
            />
            {!uploadedFileKey && (
              <div>
                <Title>Please upload a copy of the requested form</Title>
                <Upload
                  fullWidth
                  onDropSuccess={this.onDropSuccess}
                  onDropError={this.onDropError}
                  customMaxSize={MAX_FILE_SIZE_B}
                />
              </div>
            )}
            {uploadedFileKey && (
              <FileUploadedText>File Uploaded!</FileUploadedText>
            )}
          </div>
        </ModalBodyXL>
      ) : (
        <ConfirmationModalContent
          messages={[
            "Thank you for making our system smarter!",
            "We will update this shortly from our end.",
          ]}
        />
      );

    return (
      <StyledContainer>
        {modalBody}
        {step === 1 && (
          <ButtonContainer>
            <BaseButton
              style={{ marginRight: "auto" }}
              onClick={onCancel}
              color={colors.gray}
            >
              Back
            </BaseButton>
            <LoadingButton
              onClick={
                isMultiPartyAuth && !!uploadedFileKey
                  ? async () => {
                      await this.createAuthorizationForm();
                      await this.handleSubmit();
                      this.setState({
                        step: 2,
                      });
                    }
                  : async () => {
                      await this.handleSubmit();
                      this.setState({
                        step: 2,
                      });
                    }
              }
              loading={isLoading}
            >
              Submit Request
            </LoadingButton>
          </ButtonContainer>
        )}
        {step === 2 && !!uploadedFileKey && (
          <ButtonContainer>
            <LoadingButton
              style={{ padding: "10px 25px" }}
              onClick={() => {
                onCancel();
                onCloseFormListModal();
              }}
            >
              Done
            </LoadingButton>
          </ButtonContainer>
        )}
        {step === 2 && !uploadedFileKey && (
          <ButtonContainer>
            <RouterLink to={generatePath(ROUTE_PATHS.AUTHORIZATION_LIST.path)}>
              <LoadingButton>Close and return to the main list</LoadingButton>
            </RouterLink>
          </ButtonContainer>
        )}
      </StyledContainer>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withReportMissingInformation,
  withCreateAndAssociateAuthForm
)(withAlert()(MissingInformationModalContent));
