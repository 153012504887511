/*
 * This file was quickly converted to TypeScript. Please improve it if you touch
 * it!
 */

import _ from "lodash";

export interface File {
  [key: string]: unknown;
  awsKey: string;
}

export function syncAuthorizationDetails({
  config,
  status,
  attachments,
  portalTitle,
  DrugOptionId,
}: {
  config: Record<string, unknown>;
  status: string;
  attachments: File[];
  portalTitle: string;
  DrugOptionId: number;
}): {
  type: "SYNC_AUTHORIZATION_DETAILS";
  config: Record<string, unknown>;
  status: string;
  attachments: File[];
  portalTitle: string;
  DrugOptionId: number;
} {
  return {
    type: "SYNC_AUTHORIZATION_DETAILS",
    config,
    status,
    attachments,
    portalTitle,
    DrugOptionId,
  };
}

export function resetForm(): { type: "RESET_FORM" } {
  return { type: "RESET_FORM" };
}

export function setFormFields(toSet: Record<string, unknown> | null): {
  type: "SET_FORM_FIELD";
  toSet: Record<string, unknown> | null;
} {
  return { type: "SET_FORM_FIELD", toSet };
}

export function setCurrentStep(step: number): {
  type: "SET_CURRENT_STEP";
  step: number;
} {
  return { type: "SET_CURRENT_STEP", step };
}

export function setAttachment(file: File): {
  type: "SET_ATTACHMENT";
  attachment: File;
} {
  return { type: "SET_ATTACHMENT", attachment: file };
}

export function removeAttachment(file: File): {
  type: "REMOVE_ATTACHMENT";
  attachment: File;
} {
  return { type: "REMOVE_ATTACHMENT", attachment: file };
}

export function setRequiredFields(toSet: string | string[] | null): {
  type: "ADJUST_REQUIRED_FIELDS";
  toSet: string | string[] | null;
} {
  return { type: "ADJUST_REQUIRED_FIELDS", toSet };
}

export function unsetRequiredFields(toUnset: string | string[] | null): {
  type: "ADJUST_REQUIRED_FIELDS";
  toUnset: string | string[] | null;
} {
  return { type: "ADJUST_REQUIRED_FIELDS", toUnset };
}

export function toggleLoadingSegment(force: boolean): {
  type: "TOGGLE_LOADING_SEGMENT";
  forcedValue: boolean;
} {
  return { type: "TOGGLE_LOADING_SEGMENT", forcedValue: force };
}

export function setShouldIncludeCoverLetter(
  shouldIncludeCoverLetter: boolean
): {
  type: "SET_SHOULD_INCLUDE_COVER_LETTER";
  shouldIncludeCoverLetter: boolean;
} {
  return {
    type: "SET_SHOULD_INCLUDE_COVER_LETTER",
    shouldIncludeCoverLetter,
  };
}

export function toggleHighlightRequiredFields(
  highlightRequiredFields: boolean
): {
  type: "TOGGLE_HIGHLIGHT_REQUIRED_FIELDS";
  highlightRequiredFields: boolean;
} {
  return {
    type: "TOGGLE_HIGHLIGHT_REQUIRED_FIELDS",
    highlightRequiredFields,
  };
}

export function clearAttachments(): { type: "CLEAR_ATTACHMENTS" } {
  return { type: "CLEAR_ATTACHMENTS" };
}

export function setDrugOptionId(DrugOptionId: number | null): {
  type: "SET_DRUG_OPTION_ID";
  DrugOptionId: number | null;
} {
  return {
    type: "SET_DRUG_OPTION_ID",
    DrugOptionId,
  };
}

type Action =
  | ReturnType<typeof syncAuthorizationDetails>
  | ReturnType<typeof resetForm>
  | ReturnType<typeof setFormFields>
  | ReturnType<typeof setCurrentStep>
  | ReturnType<typeof setAttachment>
  | ReturnType<typeof removeAttachment>
  | ReturnType<typeof toggleLoadingSegment>
  | ReturnType<typeof setShouldIncludeCoverLetter>
  | ReturnType<typeof toggleHighlightRequiredFields>
  | ReturnType<typeof clearAttachments>
  | ReturnType<typeof setDrugOptionId>
  | {
      type: "ADJUST_REQUIRED_FIELDS";
      toSet?: string | string[] | null;
      toUnset?: string | string[] | null;
    };

export const defaultState = {
  results: {},
  currentStep: 1,
  disabled: false,
  warnUnsent: false,
  attachments: [] as File[],
  requiredFieldKeys: [] as Array<string | string[]>,
  segmentLoading: false,
  shouldIncludeCoverLetter: false,
  portalTitle: "",
  highlightRequiredFields: false,
  DrugOptionId: null as number | null,
};

export default function form(
  state: typeof defaultState = defaultState,
  action: Action
): typeof defaultState {
  switch (action.type) {
    case "TOGGLE_HIGHLIGHT_REQUIRED_FIELDS": {
      return {
        ...state,
        highlightRequiredFields: action.highlightRequiredFields,
      };
    }
    case "TOGGLE_LOADING_SEGMENT": {
      const segmentLoading =
        action.forcedValue === undefined
          ? !state.segmentLoading
          : action.forcedValue;
      return { ...state, segmentLoading };
    }
    case "SET_FORM_FIELD": {
      return { ...state, results: { ...state.results, ...action.toSet } };
    }
    case "SYNC_AUTHORIZATION_DETAILS": {
      const statuses = window.CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
      const disabled = !_.includes(
        [
          statuses.PRESUBMISSION,
          statuses.EDIT_AND_RESUBMIT,
          statuses.PREMIUM_RX_SUPPORT,
        ],
        action.status
      );
      const warnUnsent = statuses.EDIT_AND_RESUBMIT === action.status;
      return {
        ...state,
        results: { ...state.results, ...action.config },
        disabled,
        warnUnsent,
        attachments: action.attachments,
        portalTitle: action.portalTitle || "",
        DrugOptionId: action.DrugOptionId || null,
      };
    }
    case "SET_CURRENT_STEP": {
      return { ...state, currentStep: action.step };
    }
    case "SET_ATTACHMENT": {
      return {
        ...state,
        attachments: state.attachments.concat(action.attachment),
      };
    }
    case "REMOVE_ATTACHMENT": {
      return {
        ...state,
        attachments: _.reject(state.attachments, {
          awsKey: action.attachment.awsKey,
        }),
      };
    }
    case "ADJUST_REQUIRED_FIELDS": {
      const { toSet, toUnset } = action;
      let newRequiredFields = state.requiredFieldKeys;

      if (toSet) {
        newRequiredFields = [...newRequiredFields, toSet];
      }

      if (toUnset) {
        newRequiredFields = _.filter(
          newRequiredFields,
          (field) => !_.isEqual(field, toUnset)
        );
      }

      return { ...state, requiredFieldKeys: newRequiredFields };
    }
    case "SET_SHOULD_INCLUDE_COVER_LETTER": {
      return {
        ...state,
        shouldIncludeCoverLetter: action.shouldIncludeCoverLetter,
      };
    }
    case "RESET_FORM": {
      return defaultState;
    }
    case "CLEAR_ATTACHMENTS": {
      return { ...state, attachments: [] };
    }
    case "SET_DRUG_OPTION_ID": {
      return { ...state, DrugOptionId: action.DrugOptionId };
    }
    default:
      return state;
  }
}
