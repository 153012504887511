import { useState } from "react";
import styled from "styled-components";
import FormList from "./FormList";
import SelectPhoneList from "./SelectPhoneList";
import FormConfirmationView from "./FormConfirmationView";
import Modal from "../../../components/Modal";
import { getInsuranceType } from "../../../util/getInsuranceType";
import { Heading2, Paragraph } from "@samacare/design/core/Typography";
import { Stack } from "@samacare/design/core";

import { PrimaryButton, Flex } from "@@ui-kit";

const TwoPane = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const Step1Container = styled(Flex)`
  flex-direction: column;
  margin-top: 16px;
  max-width: 1280px;
  justify-content: center;
  min-height: 400px;
`;

const Step2Container = styled(Flex)`
  flex-direction: column;
  margin-top: 16px;
  max-width: 1280px;
  justify-content: center;
  min-height: 400px;
  height: 80vh;
  width: 100%;
`;

const Footer = styled(Flex)`
  flex-direction: row;
  margin-top: 28px;
`;

const LeftButtonContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  height: 36px;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledFormConfirmationView = styled(FormConfirmationView)`
  width: ${({ formId }) => formId && "70%"};
  height: 100%;
`;

const RightButtonContainer = styled(Flex)`
  flex-direction: row;
  height: 36px;
  margin-bottom: 8px;
  margin-left: auto;
  align-items: center;
`;

const GrayButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.lightGray};
  color: ${({ theme }) => theme.darkGray};
  margin-right: 8px;
  min-width: 80px;
`;

const PurpleButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.purple};
  border-color: ${({ theme }) => theme.purple};
  color: ${({ theme }) => theme.white};
  min-width: 80px;
`;

const StyledList = styled.ol`
  padding-left: 15px;
  margin-bottom: 0;
  font-weight: 600;
`;

const SelectFormModal = ({
  authorization,
  close,
  insuranceCompanyId,
  insuranceName,
  insuranceType,
  jCode,
  missingAuthSelected,
  onSubmit,
  open,
  state,
  showLocateNumberTip,
  NDC,
  benefitType,
  skipFormSelection,
}) => {
  // Always launch SelectFormModal with the form selection view unless it is an external authorization
  const isExternalAuth =
    authorization.type === CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key;

  // If the user has already selected a form, then use it. (This is especially
  // relevant to the external authorization flow, where we set the form on the
  // authorization when we create it.)
  const [formId, setFormId] = useState(
    isExternalAuth || skipFormSelection ? authorization.formId : null
  );
  const [requestNewForm, setRequestNewForm] = useState(false);
  const [showMemo, setShowMemo] = useState(false);

  const isMultiPartyAuth =
    authorization.type === CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key;
  const [step, setStep] = useState(
    !isExternalAuth && !formId && !skipFormSelection ? 1 : 2
  );
  const showTitle = true;

  const closeModal = () => {
    if (
      authorization.type !== CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key
    ) {
      setStep(null);
    }
    close();
  };

  return (
    <Modal
      header={
        requestNewForm
          ? ""
          : step === 1
          ? "Select Authorization Form"
          : skipFormSelection
          ? "Manually Uploaded Form"
          : `Showing all forms matching ${insuranceName} + ${state} + ${getInsuranceType(
              insuranceType
            )} + ${jCode}`
      }
      headerStyleOverride={
        step === 1
          ? { textAlign: "left", fontSize: "24px" }
          : {
              textAlign: "left",
              fontSize: "16px",
              color: "black",
            }
      }
      styleOverride={{
        width: requestNewForm ? "" : "100%",
        maxWidth: "1280px",
      }}
      onClick={closeModal}
      open={open}
    >
      {step === 1 && (
        <Step1Container
          style={{
            height: !requestNewForm ? "80vh" : "",
            width: !requestNewForm ? "100%" : "",
          }}
        >
          <TwoPane>
            <FormList
              jCode={jCode}
              insuranceType={insuranceType}
              authorizationId={authorization.id}
              insuranceName={insuranceName}
              insuranceCompanyId={insuranceCompanyId}
              onSelect={(selectedFormId) => {
                return setFormId(selectedFormId);
              }}
              selectedFormId={formId}
              state={state}
              missingAuthSelected={missingAuthSelected}
              institutionId={authorization.InstitutionId}
              NDC={NDC}
              benefitType={benefitType}
              isMultiPartyAuth={isMultiPartyAuth}
              requestNewForm={requestNewForm}
              setRequestNewForm={() => setRequestNewForm(false)}
            />
            {formId && (
              <StyledFormConfirmationView
                showTitle={showTitle}
                formId={formId}
              />
            )}
          </TwoPane>
          {!requestNewForm && (
            <Footer>
              <LeftButtonContainer>
                <GrayButton onClick={closeModal}>Cancel</GrayButton>
                <GrayButton
                  onClick={() => {
                    setShowMemo(true);
                  }}
                  data-cy="actionTriggerMissingFormReport"
                >
                  Form Missing
                </GrayButton>
              </LeftButtonContainer>
              <RightButtonContainer>
                <PurpleButton
                  onClick={() => setStep(2)}
                  data-cy="actionFormSelectionConfirm"
                >
                  Use this form
                </PurpleButton>
              </RightButtonContainer>
            </Footer>
          )}
        </Step1Container>
      )}
      {step === 2 && (
        <Step2Container>
          <TwoPane>
            <StyledFormConfirmationView formId={formId} showTitle={showTitle} />
            <SelectPhoneList
              parentStyleOverrides={{
                margin: "0 12px",
                width: "30%",
              }}
              authorizationFormId={formId}
              authorizationId={authorization.id}
              insuranceCompanyId={insuranceCompanyId}
              onSelect={(formNumberId) => {
                onSubmit({ formId, formNumberId });
              }}
              showLocateNumberTip={showLocateNumberTip}
              onBack={
                // External authorizations are assigned a pre-selected/generic
                // form. Prevent users from being able to navigate back to the
                // form confirmation screen.
                isExternalAuth || skipFormSelection ? null : () => setStep(1)
              }
              onSkip={
                // External authorizations don't require a fax number, so allow
                // the user to skip specifying a number.
                isExternalAuth
                  ? () => {
                      onSubmit({ formId, formNumberId: null });
                    }
                  : null
              }
              institutionId={authorization.InstitutionId}
              missingAuthSelected={
                // We don't offer support (PremiumRx flow) for externally tracked
                // authorizations, so don't show the support button.
                isExternalAuth || !missingAuthSelected
                  ? null
                  : () => {
                      setStep(null);
                      missingAuthSelected();
                    }
              }
            />
          </TwoPane>
        </Step2Container>
      )}
      <Modal
        header="Not seeing your form?"
        onClick={() => setShowMemo(false)}
        open={showMemo}
        styleOverride={{
          width: "660px",
          padding: "24px 42px",
          maxWidth: "748px",
        }}
        headerStyleOverride={{
          textAlign: "left",
        }}
      >
        <Heading2 sx={{ textTransform: "uppercase", opacity: 0.6 }}>
          try the following steps
        </Heading2>
        <StyledList>
          <li>
            Check that the &ldquo;Insurance Issuing State&rdquo; matches the
            member&apos;s plan.
          </li>
          <li>
            Check that the &ldquo;Insurance type&rdquo; matches the
            member&apos;s plan.
          </li>
        </StyledList>
        <Paragraph
          sx={{ fontWeight: 600, fontFamily: "unset", marginBottom: "20px" }}
        >
          You can verify this information using the member&apos;s insurance
          card.
        </Paragraph>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <GrayButton onClick={() => setShowMemo(false)}>Back</GrayButton>
          <PrimaryButton
            onClick={() => {
              setShowMemo(false);
              setFormId(null);
              setRequestNewForm(true);
            }}
          >
            Still can’t find my form
          </PrimaryButton>
        </Stack>
      </Modal>
    </Modal>
  );
};

export default SelectFormModal;
