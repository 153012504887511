import { useFormContext, UseControllerProps } from "react-hook-form";
import { FormControlLabel, FormControlLabelProps } from "@samacare/design/core";
import { useCombinedReferences } from "@@hooks/useCombinedReferences";
import { useRef } from "react";

export type FormControlLabelFieldProps = Omit<UseControllerProps, "control"> &
  FormControlLabelProps;

const FormControlLabelField: React.FC<FormControlLabelFieldProps> = ({
  name,
  value,
  rules = {},
  required = false,
  inputRef: materialInputRef = null,
  ...materialProperties
}) => {
  const inputRef = useRef(materialInputRef);

  const { register, watch } = useFormContext();

  const { ref, ...fields } = register(name, {
    value,
    required,
    ...rules,
  });

  const combinedRef = useCombinedReferences(ref, inputRef);

  const checked = watch(name);
  const id = `FormControlLabelField-${name}`;

  return (
    <FormControlLabel
      id={id}
      inputRef={combinedRef}
      checked={checked}
      {...fields}
      {...materialProperties}
    />
  );
};

export default FormControlLabelField;
