import { PureComponent } from "react";
import { compose } from "recompose";
import styled from "styled-components";
import { withAlert } from "react-alert";
import _ from "lodash";

import Modal from "./Modal";
import { withDeleteAuthorization } from "../graphql/Authorization";
import BaseButton from "./BaseButton";
import FormAuthorizationErrorModal from "./FormAuthorizationErrorModal";
import { ModalBody, ModalContent } from "./ModalStyledComponents";
import { AuthorizationStepKey } from "@samacare/graphql";

const GrayButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.lightGray};
  border-color: ${(props) => props.theme.lightGray};
  color: white;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
  font-size: 16px;
  margin-top: 5px;
`;

const CustomModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;

  ${BaseButton} {
    margin-top: 10px;
  }
`;

export class DeleteAuthorizationButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  // delete is a reserved word, changing as this now used as a prop
  deleteAuth = () => {
    const { id, deleteAuthorization, onDelete, alert } = this.props;
    deleteAuthorization({ variables: { id } }).then(
      () => {
        this.setState({ open: false });
        if (onDelete) {
          onDelete();
        }
      },
      () => {
        alert.error("There was an error in deleting the authorization");
        this.setState({ open: false });
      }
    );
  };

  /*
   * If a form authorization with a form selected is being deleted, we want
   * to check if the user is deleting the auth due to the form being mistagged
   * so we need to render the FormAuthorizationErrorModal component. In all
   * other cases, just render the default confirm deletion view.
   */
  isInProcessFormAuthorization = () => {
    const { portalKey, step, isReferral, authorization } = this.props;

    const inProgressFormSteps = [
      AuthorizationStepKey.RequestDetails,
      AuthorizationStepKey.PrescriberAndLocationDetails,
      AuthorizationStepKey.FormDetails,
      AuthorizationStepKey.ReviewSignSubmit,
      AuthorizationStepKey.Summary,
    ];

    const stepKey = _.find(authorization.steps, { number: step })?.key;
    if (!isReferral && !portalKey && _.includes(inProgressFormSteps, stepKey)) {
      return true;
    }
    return false;
  };

  getAuthorizationType = () => {
    const { authorization } = this.props;

    let type = "authorization";
    if (_.get(authorization, "isReferral")) {
      type = "referral";
    }
    return type;
  };

  render() {
    const {
      children,
      id,
      skipConfirmationAndDataCollection,
      cypressTag,
      authorization,
    } = this.props;
    const { open } = this.state;
    const authorizationType = this.getAuthorizationType();

    if (skipConfirmationAndDataCollection) {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.deleteAuth();
          }}
        >
          {children}
        </div>
      );
    }

    return (
      <div data-cy={cypressTag}>
        {this.isInProcessFormAuthorization() ? (
          <FormAuthorizationErrorModal
            authorization={authorization}
            close={() => {
              this.setState({ open: false });
            }}
            open={open}
            deleteAuth={this.deleteAuth}
            authorizationType={authorizationType}
          />
        ) : (
          <Modal
            header={`Delete this ${authorizationType}`}
            onClick={() => {
              this.setState({ open: false });
            }}
            open={open}
          >
            <ModalBody>
              <CustomModalContent>
                {`Are you sure you want to delete this ${authorizationType}?`}
              </CustomModalContent>
              <CustomModalFooter>
                <BaseButton
                  data-cy="actionAuthDeleteConfirm"
                  onClick={this.deleteAuth}
                >
                  Confirm deletion
                </BaseButton>
                <GrayButton
                  onClick={() => {
                    this.setState({ open: false });
                  }}
                >
                  Cancel
                </GrayButton>
              </CustomModalFooter>
            </ModalBody>
          </Modal>
        )}
        <div
          id={`deleteAuthorization_${id}`}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ open: true });
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

export const DeleteAuthorizationButtonWithAlert = withAlert()(
  DeleteAuthorizationButton
);

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withDeleteAuthorization)(
  DeleteAuthorizationButtonWithAlert
);
