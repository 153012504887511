import { useFormContext } from "react-hook-form";
import { Stack } from "@samacare/design/core";
import PhoneField from "../PhoneField";
import { QuestionnaireField } from "../../app/components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { useWatch, TextField, DateField } from "@samacare/form";
import { EnrollmentSection } from "../../app/routes/Enrollment/EnrollmentSection";
import InsuranceCompanyField from "../InsuranceCompanyField";
import AutocompleteField from "@samacare/form/AutocompleteField";
import { HorizonIpaBlock } from "../../app/enrollments/HorizonForm/HorizonIpaBlock";

type AssistRxInsuranceBlockProps = {
  disabled?: boolean;
  usePhone?: boolean;
  insuranceType: string;
  needIpaInfo?: boolean;
  required?: boolean;
};

type InsuranceBlockProps = {
  disabled?: boolean;
  children?: React.ReactNode;
  isForApi?: boolean;
  needIpaInfo?: boolean;
  needReverification?: boolean;
};

const AssistRxInsuranceBlock: React.FC<AssistRxInsuranceBlockProps> = (
  rawProps
) => {
  const { disabled, insuranceType, required, needIpaInfo } = rawProps;
  const { setValue } = useFormContext();

  const defaultProps = {
    PayerNameAndIdInputProps: {
      name: `questionnaire.sama-${insuranceType}-payerId`,
      label: "Payer ID",
    },
    InsurancePhoneInputProps: {
      name: `questionnaire.sama-${insuranceType}-phone`,
      label: "Phone",
    },
    PolicyHolderNameInputProps: {
      name: `questionnaire.sama-${insuranceType}-policyholderName`,
      label: "Policyholder Name",
    },
    PolicyHolderDobInputProps: {
      name: `questionnaire.sama-${insuranceType}-policyHolderDob`,
      label: "Policyholder DOB",
    },
    PolicyNumberInputProps: {
      name: `questionnaire.sama-${insuranceType}-policyNumber`,
      label: "Policy Number",
    },
    InsuranceCompanyInputProps: {
      name: `patient.${insuranceType}.InsuranceCompanyId`,
      label: "Payer Name",
    },
    GroupNumberInputProps: {
      name: `patient.${insuranceType}.groupNumber`,
      label: "Employer/Group Number",
    },
  };

  return (
    <Stack spacing={2} maxWidth={600}>
      <TextField
        size="small"
        {...defaultProps.PayerNameAndIdInputProps}
        fullWidth
        disabled={disabled}
      />
      <InsuranceCompanyField
        size="small"
        {...defaultProps.InsuranceCompanyInputProps}
        fullWidth
        required={required}
        disabled={disabled}
        onChange={(e, change) => {
          setValue(
            `questionnaire.sama-${insuranceType}-InsuranceCompanyName`,
            change.label
          );
        }}
      />
      <PhoneField
        {...defaultProps.InsurancePhoneInputProps}
        fullWidth
        disabled={disabled}
      />
      <TextField
        size="small"
        {...defaultProps.PolicyHolderNameInputProps}
        fullWidth
        disabled={disabled}
      />
      <DateField
        {...defaultProps.PolicyHolderDobInputProps}
        disabled={disabled}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        size="small"
        {...defaultProps.PolicyNumberInputProps}
        fullWidth
        required={required}
        disabled={disabled}
      />
      <TextField
        size="small"
        {...defaultProps.GroupNumberInputProps}
        fullWidth
        disabled={disabled}
      />
      {needIpaInfo && insuranceType === "primaryInsurance" && (
        <HorizonIpaBlock isDisabled={disabled} />
      )}
    </Stack>
  );
};

const AssistRxInsuranceBlockForApi: React.FC<AssistRxInsuranceBlockProps> = (
  rawProps
) => {
  const { disabled, insuranceType, required } = rawProps;
  const { getValues, setValue } = useFormContext();

  const defaultProps = {
    PayerNameAndIdInputProps: {
      name: `questionnaire.sama-${insuranceType}-payerId`,
      label: "Payer ID",
    },
    InsurancePhoneInputProps: {
      name: `questionnaire.sama-${insuranceType}-phone`,
      label: "Phone",
    },
    PolicyHolderFirstNameInputProps: {
      name: `questionnaire.sama-${insuranceType}-policyholderFirstName`,
      label: "Policyholder First Name",
    },
    PolicyHolderLastNameInputProps: {
      name: `questionnaire.sama-${insuranceType}-policyholderLastName`,
      label: "Policyholder Last Name",
    },
    PolicyHolderDobInputProps: {
      name: `questionnaire.sama-${insuranceType}-policyHolderDob`,
      label: "Policyholder DOB",
    },
    PolicyHolderPatientRelationship: {
      name: `questionnaire.sama-${insuranceType}-policyHoderPatientRelationship`,
      label: "Relationship To Policyholder",
    },
    PolicyNumberInputProps: {
      name: `questionnaire.sama-${insuranceType}-policyNumber`,
      label: "Policy Number",
    },
    InsuranceCompanyInputProps: {
      name: `patient.${insuranceType}.InsuranceCompanyId`,
      label: "Payer Name",
    },
    GroupNumberInputProps: {
      name: `patient.${insuranceType}.groupNumber`,
      label: "Employer/Group Number",
    },
    InsurancePlanType: {
      name: `questionnaire.sama-${insuranceType}-insurancePlanType`,
      label: "Insurance Plan Type",
    },
  };

  return (
    <Stack spacing={2} maxWidth={600}>
      <TextField
        size="small"
        {...defaultProps.PayerNameAndIdInputProps}
        fullWidth
        disabled={disabled}
      />
      <InsuranceCompanyField
        size="small"
        {...defaultProps.InsuranceCompanyInputProps}
        fullWidth
        required={required}
        disabled={disabled}
        onChange={(e, change) => {
          setValue(
            `questionnaire.sama-${insuranceType}-InsuranceCompanyName`,
            change.label
          );
        }}
      />
      <PhoneField
        {...defaultProps.InsurancePhoneInputProps}
        fullWidth
        disabled={disabled}
      />
      <TextField
        size="small"
        {...defaultProps.PolicyHolderFirstNameInputProps}
        fullWidth
        disabled={disabled}
        required={required}
      />
      <TextField
        size="small"
        {...defaultProps.PolicyHolderLastNameInputProps}
        fullWidth
        disabled={disabled}
        required={required}
      />
      <DateField
        {...defaultProps.PolicyHolderDobInputProps}
        disabled={disabled}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        size="small"
        {...defaultProps.PolicyNumberInputProps}
        fullWidth
        required={required}
        disabled={disabled}
      />
      <TextField
        size="small"
        {...defaultProps.GroupNumberInputProps}
        fullWidth
        disabled={disabled}
      />
      <AutocompleteField<string, false, true, false>
        size="small"
        {...defaultProps.PolicyHolderPatientRelationship}
        options={["Dependent", "Self", "Spouse", "Other"]}
        fullWidth
        required={required}
        disabled={disabled}
        value={getValues(defaultProps.PolicyHolderPatientRelationship.name)}
      />
      <AutocompleteField<string, false, true, false>
        size="small"
        {...defaultProps.InsurancePlanType}
        options={["Commercial", "Medicaid", "Medicare", "Other"]}
        fullWidth
        required={required}
        disabled={disabled}
        value={getValues(defaultProps.InsurancePlanType.name)}
      />
    </Stack>
  );
};

export const PrimaryAndSecondaryInsuranceBlock: React.FC<
  InsuranceBlockProps
> = ({ disabled, children, isForApi, needIpaInfo, needReverification }) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });

  return (
    <Stack spacing={2} maxWidth={600}>
      <QuestionnaireField
        id="ifPatientHasInsurance"
        type={QuestionTypeEnumType.Choice}
        text="Does patient have insurance?"
        disabled={disabled}
        answerOption={[{ valueString: "Yes" }, { valueString: "No" }]}
        required
      />
      {questionnaireData.ifPatientHasInsurance === "Yes" && (
        <Stack>
          <Stack spacing={2} maxWidth={600}>
            {needReverification && (
              <QuestionnaireField
                id="ifNeedReverification"
                type={QuestionTypeEnumType.Choice}
                text="Is this a reverification request?"
                disabled={disabled}
                answerOption={[{ valueString: "Yes" }, { valueString: "No" }]}
                required
              />
            )}
            <EnrollmentSection title="Primary Insurance">
              {isForApi ? (
                <AssistRxInsuranceBlockForApi
                  insuranceType="primaryInsurance"
                  disabled={disabled}
                  required
                />
              ) : (
                <AssistRxInsuranceBlock
                  insuranceType="primaryInsurance"
                  disabled={disabled}
                  required
                  needIpaInfo={needIpaInfo}
                />
              )}
            </EnrollmentSection>
          </Stack>
          <QuestionnaireField
            id="ifPatientHasSecondaryInsurance"
            type={QuestionTypeEnumType.Choice}
            text="Does patient have secondary insurance?"
            disabled={disabled}
            answerOption={[{ valueString: "Yes" }, { valueString: "No" }]}
            required
          />
          {questionnaireData.ifPatientHasSecondaryInsurance === "Yes" && (
            <Stack spacing={2} maxWidth={600}>
              <EnrollmentSection title="Secondary Insurance">
                {isForApi ? (
                  <AssistRxInsuranceBlockForApi
                    insuranceType="secondaryInsurance"
                    disabled={disabled}
                    required
                  />
                ) : (
                  <AssistRxInsuranceBlock
                    insuranceType="secondaryInsurance"
                    disabled={disabled}
                    required={
                      questionnaireData.ifPatientHasSecondaryInsurance === "Yes"
                    }
                  />
                )}
              </EnrollmentSection>
            </Stack>
          )}
        </Stack>
      )}

      {children}
    </Stack>
  );
};
