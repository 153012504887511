import _ from "lodash";
import { Box, Button, Stack } from "@samacare/design";
import {
  AutocompleteField,
  DateField,
  HcpcsField,
  TextField,
  useFormContext,
} from "@samacare/form";
import Delete from "@samacare/design/core/icons/Delete";
import { HcpcsCode, ServiceQuantityTypeCode } from "@samacare/graphql";
import { Rule } from "./Rule";

const toLabel = (value: string) => {
  return _.startCase(_.lowerCase(value));
};

const QuantityTypeOrder: ServiceQuantityTypeCode[] = [
  ServiceQuantityTypeCode.Units,
  ServiceQuantityTypeCode.Visits,
  ServiceQuantityTypeCode.Hours,
  ServiceQuantityTypeCode.Days,
  ServiceQuantityTypeCode.Months,
];

interface ServiceCodeBlockProps {
  codeId: number;
  required?: boolean;
  disabled?: boolean;
  handleDelete?: (id: number) => void;
}
export const ServiceCodeBlock = ({
  codeId,
  required,
  disabled,
  handleDelete,
}: ServiceCodeBlockProps) => {
  const { watch, setValue } = useFormContext();
  const quantityTypeTag = `services.service${codeId}.quantityType`;
  const quantityType = watch(quantityTypeTag);
  const descTag = `services.service${codeId}.description`;
  const desc = watch(descTag);

  return (
    <Stack spacing={2} width="100%">
      <Rule />
      <Stack direction="row" spacing={1}>
        <HcpcsField
          name={`services.service${codeId}.hcpcs`}
          multiple={false}
          required
          disabled={disabled}
          sx={{ flex: 2 }}
          onChange={(_event, value) => {
            if (!desc && value) {
              const val = value as HcpcsCode;
              setValue(
                descTag,
                val.shortDescription ?? val.longDescription ?? ""
              );
            } else if (!value) {
              setValue(descTag, "");
            }
          }}
        />
        <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          {handleDelete && (
            <Button onClick={() => handleDelete(codeId)} startIcon={<Delete />}>
              Delete
            </Button>
          )}
        </Box>
      </Stack>
      <Stack direction="row" spacing={1}>
        <AutocompleteField<string, false, true, false>
          disableClearable
          getOptionLabel={(val) => toLabel(val)}
          options={QuantityTypeOrder}
          label="Qnty type"
          name={quantityTypeTag}
          required
          disabled={disabled}
          value={quantityType ?? ""}
        />
        <TextField
          name={`services.service${codeId}.quantity`}
          label="Qnty"
          placeholder={quantityType ? `Number of ${quantityType}` : ""}
          required={required}
          disabled={disabled}
          fullWidth
        />
      </Stack>

      <Stack direction="row" spacing={1}>
        <TextField
          name={descTag}
          label="Description"
          required={required}
          disabled={disabled}
          sx={{ flex: "2" }}
        />
        <DateField
          name={`services.service${codeId}.startDate`}
          label="Start date"
          required={required}
          disabled={disabled}
          sx={{ flex: "1" }}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </Stack>
  );
};
