import { PureComponent } from "react";
import styled from "styled-components";
import { BaseText } from "Segment/StyledComponents";

import RequiredTag from "../../RequiredTag";
import LoadingButton from "../../LoadingButton";

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const SignerName = styled(BaseText)`
  margin-bottom: 20px;
`;

const SignatureCanvas = styled.canvas`
  border: 1px solid ${(props) => props.theme.lightGray};
  border-radius: 3px;
`;

export const CANVAS_WIDTH = 600;
export const CANVAS_HEIGHT = 85;
const PADDING = 25;

class SignatureInput extends PureComponent {
  state = { name: null };

  canvas = null;

  componentDidUpdate(noop, prevState) {
    const { disabled } = this.props;
    const { name } = this.state;

    if (!disabled && prevState.name !== name) {
      const ctx = this.canvas.getContext("2d");
      ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

      ctx.font = "60px Dawning of a New Day";

      ctx.fillText(
        name,
        PADDING,
        CANVAS_HEIGHT - PADDING,
        CANVAS_WIDTH - 2 * PADDING
      );
    }
  }

  saveCroppedSignature = async (institutionWide) => {
    const { saveAndSetSignature } = this.props;

    await saveAndSetSignature(this.canvas.toDataURL(), institutionWide);
  };

  toDataURL = () => this.canvas.toDataURL();

  isSigned = () => {
    const { name } = this.state;
    return !!name;
  };

  render() {
    const { disabled, required = false } = this.props;

    return (
      <Container>
        <div>
          Physician Stamp
          {required && <RequiredTag />}
        </div>
        <SignerName
          data-cy="fieldPhysicianStamp"
          disabled={disabled}
          onChange={(e) => {
            this.setState({ name: e.target.value });
          }}
          placeholder="Begin typing to generate stamp"
          type="text"
        />
        {/* Width and height need to be explicitly set on canvas */}
        <SignatureCanvas
          ref={(canvasRef) => (this.canvas = canvasRef)}
          style={{ height: CANVAS_HEIGHT, width: CANVAS_WIDTH }}
          height={`${CANVAS_HEIGHT}px`}
          width={`${CANVAS_WIDTH}px`}
        />
        <LoadingButton
          cypressTag="actionSaveToMyAccount"
          style={{ width: "200px", marginTop: "10px" }}
          onClick={() => {
            this.saveCroppedSignature(false);
          }}
          type="button"
          disabled={!this.isSigned()}
        >
          Use Signature
        </LoadingButton>
      </Container>
    );
  }
}

export default SignatureInput;
