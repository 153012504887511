import { Alert, Stack, Button, LoadingButton } from "@samacare/design/core";

import {
  useForm,
  TextField,
  SubmitHandler,
  PasswordBlock,
} from "@samacare/form";

import { useHistory } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { AuthTitle } from "./AuthTitle";
import { useAuth } from "../../providers/AuthProvider";

type AuthConfirmLoginForm = {
  newPassword: string;
  confirmPassword: string;
  userAttributes: {
    given_name: string;
    family_name: string;
  };
};

export const AuthConfirmLogin: React.FC = () => {
  const history = useHistory();
  const methods = useForm<AuthConfirmLoginForm>();
  const { onConfirmSignIn, onReset, error, loading, signInStep } = useAuth();

  const { missingAttributes = [] } = (signInStep || {}) as {
    missingAttributes: string[];
  };

  const onSubmit: SubmitHandler<AuthConfirmLoginForm> = async ({
    newPassword,
    userAttributes,
  }) => {
    await onConfirmSignIn({
      challengeResponse: newPassword,
      options: { userAttributes },
    });
  };

  return (
    <FormProvider {...methods}>
      <form name="login" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <AuthTitle>
          {missingAttributes.length > 0
            ? "Add Your Details"
            : "Setup New Password"}
        </AuthTitle>
        <Stack spacing={2}>
          {missingAttributes.length > 0 && (
            <>
              {missingAttributes.includes("given_name") && (
                <TextField
                  type="text"
                  name="userAttributes.given_name"
                  label="First name"
                  required
                />
              )}
              {missingAttributes.includes("family_name") && (
                <TextField
                  type="text"
                  name="userAttributes.family_name"
                  label="Family name"
                  autoComplete="off"
                  required
                />
              )}
              <br />
            </>
          )}
          <PasswordBlock />
          {error && <Alert color="error">{error}</Alert>}
          <br />
          <LoadingButton
            loading={loading}
            type="submit"
            size="large"
            variant="contained"
          >
            Continue
          </LoadingButton>
          <Button
            onClick={() => {
              onReset();
              history.push("/auth/login");
            }}
            size="large"
          >
            Back to Login
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
