import { useConfig } from "../../app/hooks";

export type StateOption = {
  id: string;
  label: string;
};

const useStateOptions = (): StateOption[] => {
  const { CONSTANTS } = useConfig();

  return Object.entries(CONSTANTS?.STATES).map(([id, label]) => ({
    id,
    label,
  }));
};

export default useStateOptions;
