import React from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Stack,
  Typography,
} from "@samacare/design/core";

import ROUTE_PATHS from "../../ROUTE_PATHS";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import {
  ActionBar,
  PatientDetails,
  RightSideMenuForBvLite,
} from "../components";
import { BenefitsCheckLiteSummary } from "../components/BenefitsCheckLiteSummary";
import { BenefitsCheckLiteDetails } from "../components/BenefitsCheckLiteDetails";
import { UnifiedBenefitsCheckStatus } from "@samacare/graphql";

export const ViewBenefitsCheckLite: React.FC = () => {
  const history = useHistory();
  const { currentAccount, unifiedBenefitsCheck } =
    useUnifiedBenefitsCheckContext();

  if (currentAccount == null || unifiedBenefitsCheck == null) {
    return <Typography>Unable to load benefits check</Typography>;
  }

  const onClose = () => {
    history.push(ROUTE_PATHS.BENEFITS_VERIFICATIONS.path);
  };

  return (
    <Box padding={2} data-cy="componentBenefitsCheckLiteSummaryPage">
      <Breadcrumbs>
        <Link underline="hover" href="#/benefits-verification">
          <Typography variant="h6">Benefits Verification</Typography>
        </Link>
        <Typography variant="h6">
          BVLite#{unifiedBenefitsCheck.id.slice(-5)}
        </Typography>
      </Breadcrumbs>
      <Box marginTop={({ spacing }) => spacing(4)}>
        <PatientDetails />
      </Box>
      <Stack direction="row" justifyContent="space-between" marginTop={3}>
        <Typography variant="h6">Benefits Verification Lite</Typography>
        <ActionBar isEditMode={false} />
      </Stack>
      <Stack direction="row" marginTop={3}>
        <Box flexGrow={1}>
          <BenefitsCheckLiteSummary />
          {unifiedBenefitsCheck.status ===
            UnifiedBenefitsCheckStatus.Active && <BenefitsCheckLiteDetails />}
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop={3}
          >
            <Button variant="outlined" onClick={onClose}>
              Back to Benefits Verifications List
            </Button>
          </Stack>
        </Box>
        <Box minWidth="230px">
          <RightSideMenuForBvLite activeStep={0} />
        </Box>
      </Stack>
    </Box>
  );
};
