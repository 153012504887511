import {
  Alert,
  Stack,
  Button,
  Typography,
  LoadingButton,
} from "@samacare/design/core";

import {
  useForm,
  TextField,
  SubmitHandler,
  PasswordBlock,
  FormProvider,
} from "@samacare/form";

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../providers/AuthProvider";
import { AUTH_USERNAME_STORAGE } from "./Auth.constants";
import { AuthTitle } from "./AuthTitle";

type AuthConfirmResetPasswordForm = {
  newPassword: string;
  confirmPassword: string;
  confirmationCode: string;
};

export const AuthConfirmResetPassword: React.FC = () => {
  const history = useHistory();
  const [username] = useState<string | null>(() =>
    localStorage.getItem(AUTH_USERNAME_STORAGE)
  );
  const methods = useForm<AuthConfirmResetPasswordForm>();
  const { onResetPassword, onConfirmResetPassword, onReset, error, loading } =
    useAuth();

  const onSubmit: SubmitHandler<AuthConfirmResetPasswordForm> = async ({
    newPassword,
    confirmationCode,
  }) => {
    await onConfirmResetPassword({
      username: username!,
      newPassword,
      confirmationCode,
    });
    history.push("/auth/login");
  };

  const onResendCode = async () => {
    await onResetPassword({
      username: username!,
    });
  };

  useEffect(() => {
    if (!username || username === null) {
      onReset();
    }
  }, [username, onReset]);

  return (
    <FormProvider {...methods}>
      <form name="login" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <AuthTitle>Reset Password</AuthTitle>
        <Stack spacing={2}>
          <Typography variant="body2" gutterBottom pb={3}>
            We sent a verification code to your email address. Please enter the
            code below to confirm.
          </Typography>
          <PasswordBlock />
          <Stack flexDirection="row" justifyContent="space-between">
            <TextField name="confirmationCode" label="Code" />
            <LoadingButton
              loading={loading}
              type="submit"
              size="large"
              variant="outlined"
              onClick={async () => onResendCode()}
            >
              Resend
            </LoadingButton>
          </Stack>

          {error && <Alert color="error">{error}</Alert>}
          <LoadingButton
            loading={loading}
            type="submit"
            size="large"
            variant="contained"
          >
            Reset Password
          </LoadingButton>
          <Button
            onClick={() => {
              onReset();
              history.push("/auth/login");
            }}
            size="large"
          >
            Back to Login
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
