import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import {
  ModalBodyLarge,
  ModalContent,
} from "@@components/ModalStyledComponents";
import { PrimaryButton } from "@@ui-kit";
import { Account } from "@samacare/graphql";
import { useAlert } from "react-alert";
import {
  LeftRightCenterAll,
  LeftRightCenterV,
  LeftRightSpreadCenterV,
} from "@@components/LeftRight";
import { WhiteButton } from "@@ui-kit/WhiteButton";
import { CheckCircle, Copy, Launch } from "@@ui-kit/Icons";
import colors from "Resources/colors";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import {
  currentAccountQuery,
  updateAccountMutation,
} from "../../graphql/Account";
import { formatPhiloPhoneNumberForDisplay } from "../../util/phoneUtils";
import { useHubs } from "../../hooks/hubs";

const TextBlock = styled(LeftRightCenterV)`
  text-align: left;
  line-height: 1.35em;
  margin-bottom: 16px;
`;

const IconWrapper = styled.div<{ isShown: boolean }>`
  opacity: ${(props) => (props.isShown ? 1 : 0)};
  transition: all 90ms ease-in-out;
`;

const ModifiedWhiteButton = styled(WhiteButton)`
  padding: 4px 8px;
  margin-left: 8px;
`;

const NumCircle = styled(LeftRightCenterAll)`
  font-size: 20px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 12px;
  background: ${colors.lightPurple};
`;

const addMfaPhoneNumberToAccountMutation = gql`
  mutation addMfaPhoneNumberToAccount {
    addMfaPhoneNumberToAccount {
      success
    }
  }
`;

const updateStatusCheckLoginRequiresAttnMutation = gql`
  mutation updateStatusCheckLoginRequiresAttn($portalKey: String!) {
    updateStatusCheckLoginRequiresAttn(portalKey: $portalKey) {
      success
    }
  }
`;

interface IMfaSetupModalBodyProps {
  portalKey: string;
  currentAccount: Account;
  onDoneClick: () => void;
  children?: React.ReactNode;
}
export const MfaSetupModalBody: React.VFC<IMfaSetupModalBodyProps> = (
  props
) => {
  const { portalKey, currentAccount, children, onDoneClick } = props;
  const [isShowCopyCompletedCheckmark, setIsShowCopyCompletedCheckmark] =
    useState(false);

  const checkmarkTimeoutHandle = useRef<number | null>(null);
  const clearCheckmarkTimeout = () => {
    if (checkmarkTimeoutHandle.current != null) {
      window.clearTimeout(checkmarkTimeoutHandle.current);
    }
  };

  const { hubByPortalKey } = useHubs(currentAccount.institution);
  const hub = hubByPortalKey?.[portalKey];
  // run on unmount
  useEffect(() => clearCheckmarkTimeout, []);
  const alert = useAlert();

  const [updateAccount] = useMutation(updateAccountMutation, {
    refetchQueries: () => [currentAccountQuery],
  });

  const [updateStatusCheckLoginRequiresAttn] = useMutation(
    updateStatusCheckLoginRequiresAttnMutation,
    {
      refetchQueries: () => ["allAuthorizations"],
    }
  );

  const handleDoneClick = async () => {
    // if this hub is not already marked as configured, add the key

    if (
      hub != null &&
      !currentAccount.mfaConfiguredHubKeys.includes(hub.hubKey)
    ) {
      try {
        await updateAccount({
          variables: {
            patch: {
              mfaConfiguredHubKeys: [
                ...currentAccount.mfaConfiguredHubKeys,
                hub.hubKey,
              ],
            },
          },
        });
        alert.info("Successfully set up MFA");
      } catch (err) {
        alert.error(err instanceof Error ? err.message : "Unknown error");
      }
    }

    try {
      await updateStatusCheckLoginRequiresAttn({
        variables: {
          portalKey,
        },
      });
    } catch (err) {
      alert.error(err instanceof Error ? err.message : "Unknown error");
    }

    onDoneClick();
  };

  const [addMfaPhoneNumberToAccount] = useMutation(
    addMfaPhoneNumberToAccountMutation,
    {
      refetchQueries: () => [currentAccountQuery],
    }
  );

  const handlePhoneNumberCopy = async () => {
    if (currentAccount.portalMfaPhoneNumber != null) {
      const formattedPhoneNumber = formatPhiloPhoneNumberForDisplay(
        currentAccount.portalMfaPhoneNumber
      );
      await navigator.clipboard.writeText(formattedPhoneNumber);

      setIsShowCopyCompletedCheckmark(true);
      clearCheckmarkTimeout();
      checkmarkTimeoutHandle.current = window.setTimeout(() => {
        setIsShowCopyCompletedCheckmark(false);
      }, 3000);
    }
  };

  const handleLaunchPortal = () => {
    if (window != null && hub != null && hub.loginUrl != null) {
      window.open(hub.loginUrl, "_blank")?.focus();
    }
  };

  useEffect(() => {
    if (currentAccount.portalMfaPhoneNumber == null) {
      void (async () => {
        await addMfaPhoneNumberToAccount({ variables: {} });
      })();
    }
  }, [currentAccount.portalMfaPhoneNumber, addMfaPhoneNumberToAccount]);

  const phoneNumber =
    currentAccount.portalMfaPhoneNumber != null
      ? `${currentAccount.portalMfaPhoneNumber.slice(
          1,
          4
        )}-${currentAccount.portalMfaPhoneNumber.slice(
          4,
          7
        )}-${currentAccount.portalMfaPhoneNumber.slice(7, 11)}`
      : "assigning...";

  return (
    <ModalBodyLarge>
      <ModalContent>
        <TextBlock style={{ marginTop: "16px", marginBottom: "16px" }}>
          This portal requires multi-factor authentication (MFA). Follow the
          steps below to complete setup.
        </TextBlock>

        <TextBlock>
          <NumCircle>1</NumCircle>
          <div>Copy this phone number</div>
          <ModifiedWhiteButton onClick={handlePhoneNumberCopy}>
            <LeftRightCenterAll style={{ width: "130px" }}>
              <div
                style={{
                  marginRight: "8px",
                  flexGrow: 1,
                  textAlign: "center",
                }}
              >
                {phoneNumber}
              </div>
              <Copy />
            </LeftRightCenterAll>
          </ModifiedWhiteButton>
          <IconWrapper isShown={isShowCopyCompletedCheckmark}>
            <CheckCircle
              size={20}
              color={colors.green}
              style={{ marginLeft: "8px" }}
            />
          </IconWrapper>
        </TextBlock>

        <TextBlock>
          <NumCircle>2</NumCircle>
          <div>
            <div>Set as your MFA phone number for</div>
            <div
              style={{
                marginTop: "4px",
              }}
            >
              {hub?.title}
            </div>
          </div>
          {hub?.loginUrl != null && (
            <ModifiedWhiteButton
              onClick={handleLaunchPortal}
              style={{ marginLeft: "16px" }}
            >
              <LeftRightCenterV>
                <div style={{ marginRight: "8px" }}>Open Portal</div>
                <Launch />
              </LeftRightCenterV>
            </ModifiedWhiteButton>
          )}
        </TextBlock>

        <TextBlock>
          <NumCircle>3</NumCircle>
          <div>
            <div>
              Upon completion, MFA details will be forwarded to your email:
            </div>
            <div
              style={{
                color: colors.purple,
                fontWeight: "bold",
                marginTop: "4px",
              }}
            >
              {currentAccount.email}
            </div>
          </div>
        </TextBlock>

        <LeftRightSpreadCenterV style={{ marginTop: "32px" }}>
          {children}
          <PrimaryButton onClick={handleDoneClick}>
            I&apos;ve done it
          </PrimaryButton>
        </LeftRightSpreadCenterV>
      </ModalContent>
    </ModalBodyLarge>
  );
};
