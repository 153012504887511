import _ from "lodash";
import axios from "axios";
import { PureComponent } from "react";
import { withAlert } from "react-alert";
import strings from "Resources/strings";
import client from "../services/client";
import SamaDropzone from "./SamaDropzone";

const MAX_FILE_SIZE_B = 50000000;
export const WARN_FILE_SIZE_B = 10000000;
const LARGE_FILE_WARNING_ALERT_TIMEOUT_MS = 1000 * 10;

export class Upload extends PureComponent {
  state = { loading: false };

  onDrop = (acceptedFiles) => {
    const {
      alert,
      onDrop = () => {},
      onDropSuccess = () => {},
      onDropError = () => {},
    } = this.props;

    // warn about large uploads
    const hasLargeFiles = !_.isEmpty(
      _.filter(acceptedFiles, ({ size }) => size > WARN_FILE_SIZE_B)
    );
    if (hasLargeFiles) {
      alert.info(
        strings.NEW_AUTHORIZATIONS.LARGE_FILE_WARNING(
          WARN_FILE_SIZE_B / 1000000
        ),
        { timeout: LARGE_FILE_WARNING_ALERT_TIMEOUT_MS }
      );
    }

    this.setState({ loading: true });
    onDrop();
    acceptedFiles.forEach((file) => {
      client
        .post("/upload", {
          name: file.name,
          contentType: file.type,
          contentDisposition: "inline",
        })
        .then((res) => {
          this.setState({ loading: false });
          const data = _.reduce(
            res.data.fields,
            (dataField, fieldValue, fieldKey) => {
              dataField.append(fieldKey, fieldValue);
              return dataField;
            },
            new FormData()
          );
          data.append("file", file);

          return axios({
            method: "POST",
            data,
            url: res.data.url,
          })
            .then(() => {
              onDropSuccess(file, res);
            })
            .catch((e) => {
              throw new Error("S3 Uploading error", e);
            });
        })
        .catch((e) => {
          this.setState({ loading: false });
          onDropError();
          alert.error("There was an error uploading the file");
          // eslint-disable-next-line no-console
          console.error("S3 Uploading error", e);
        });
    });
  };

  onDropRejected = () => {
    const { alert, customMaxSize, onDropError = () => {} } = this.props;
    alert.error(
      // The 1kb is actually false, since we accept down to 750 bytes, but it's roughly right
      "There was an error uploading your file, please make sure your file is no larger than " +
        `${
          (customMaxSize || MAX_FILE_SIZE_B) / 1000000
        } MB, and no smaller than 1kb`
    );
    this.setState({ loading: false });
    onDropError();
  };

  render() {
    const {
      disabled,
      multiple = false,
      customMaxSize = MAX_FILE_SIZE_B,
      fullWidth = false,
      acceptedTypes = [CONFIG.CONSTANTS.CONTENT_TYPES.PDF],
      buttonText = "Browse PDFs to upload",
      helperText = "(files must be in PDF format)",
    } = this.props;
    const { loading } = this.state;

    return (
      <SamaDropzone
        disabled={disabled}
        fullWidth={fullWidth}
        loading={loading}
        accept={acceptedTypes}
        className="dropzone"
        maxSize={customMaxSize}
        multiple={multiple}
        onDrop={this.onDrop}
        onDropRejected={this.onDropRejected}
        buttonText={buttonText}
        helperText={helperText}
      />
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default withAlert()(Upload);
