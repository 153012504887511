import {
  PORTAL_AUTH_ROUTE,
  FORM_AUTHORIZATION_ROUTE,
  MULTI_PARTY_ROUTE,
} from "../../../server/src/shared_code";

const RESET_PASSWORD_BASE = "/reset-password";

const ROUTE_PATHS = {
  DASHBOARD: {
    headerText: "Dashboard",
    path: "/dashboard",
  },
  PATIENTS_TREATMENT: {
    headerText: "Treatments",
    path: "/treatments",
  },
  ENROLLMENTS: {
    headerText: "Enrollments",
    path: "/enrollments",
  },
  ENROLLMENTS_CREATE: {
    headerText: "",
    path: "/enrollments/create",
  },
  BENEFITS_VERIFICATIONS: {
    headerText: "Benefits Verifications",
    path: "/benefits-verification",
  },
  BENEFITS_VERIFICATION_SUMMARY: {
    path: "/benefits-verification-summary",
    headerText: "",
  },
  // No header text because we have 'Back Button'
  PORTAL_AUTHORIZATION: {
    path: PORTAL_AUTH_ROUTE,
    headerText: "",
  },
  AUTHORIZATION_LIST: { path: "/", headerText: "Authorizations" },
  AUTHORIZATION_LIST_FILTERED: {
    path: "/filter",
    headerText: "Authorizations",
  },
  LOGIN: {
    path: window.CONFIG.COGNITO_ENABLED ? "/auth/login" : "/login",
    headerText: "Login",
  },
  // No header text because we have 'Back Button'
  FORM_AUTHORIZATION: {
    path: FORM_AUTHORIZATION_ROUTE,
    headerText: "",
  },
  // No header text because we have 'Back Button'
  REFERRAL: { path: "/referral", headerText: "" },
  // No header text because we have 'Back Button'
  MUTLI_PARTY_AUTH: { path: MULTI_PARTY_ROUTE, headerText: "" },
  TAGGER: { path: "/pdfTagger", headerText: "PDF Tagger" },
  RESET_PASSWORD_BASE: {
    path: RESET_PASSWORD_BASE,
    headerText: "Reset Password",
  },
  RESET_PASSWORD: {
    path: `${RESET_PASSWORD_BASE}/:nonce`,
    headerText: "Reset Password",
  },
  ADMIN_ROUTE: { path: "/admin", headerText: "Practice Settings" },
  USER_SETTINGS_ROUTE: { path: "/user-settings", headerText: "User Settings" },
  OPS_HUB_ROUTE: { path: "/ops-hub/:view?", headerText: "Ops Hub" },
  SECURE_FILE: { path: "/secure-file", headerText: "Secure file send" },
  STORY_PLAYER: { path: "/story-player", headerText: "SamaStory" },
  HELP_CENTER_ROUTE: {
    path: "https://samacare-help.freshdesk.com/support/home",
    headerText: "Help Center",
  },
  EDI_AUTH_CREATE: {
    path: "/edi-auth/create",
    headerText: "Create EDI Authorization",
  },
};

export default ROUTE_PATHS;
