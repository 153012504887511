import { useState } from "react";
import styled from "styled-components";
import { MdFavorite as FavoriteIcon } from "@react-icons/all-files/md/MdFavorite";
import { MdFavoriteBorder as EmptyFavoriteIcon } from "@react-icons/all-files/md/MdFavoriteBorder";

const SetFavoriteStyled = styled(EmptyFavoriteIcon)`
  color: ${(props) => props.theme.purple};
  margin-right: 3px;
  margin-bottom: 2px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const DeleteFavoriteStyled = styled(FavoriteIcon)`
  color: ${(props) => props.theme.purple};
  margin-right: 3px;
  margin-bottom: 2px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const Favorite = ({
  isFavorite,
  setFavorite,
  deleteFavorite,
  formId,
  numberId,
}) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return isFavorite ? (
    <span
      data-cy="actionDeleteFavoriteForm"
      onClick={() => {
        deleteFavorite({
          variables: {
            authorizationFormId: formId,
            formNumberId: numberId,
          },
        });
      }}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {hover ? <SetFavoriteStyled /> : <DeleteFavoriteStyled />}
    </span>
  ) : (
    <span
      data-cy="actionSetFavoriteForm"
      onClick={() => {
        setFavorite({
          variables: {
            authorizationFormId: formId,
            formNumberId: numberId,
          },
        });
      }}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {hover ? <DeleteFavoriteStyled /> : <SetFavoriteStyled />}
    </span>
  );
};

/**
 * @deprecated Reorganize me?
 */
export default Favorite;
