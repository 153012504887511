import * as React from "react";
import styled from "styled-components";
import { Authorization } from "@samacare/graphql";

import PrescriberDetails from "../PrescriberDetails/PrescriberDetails";
import TabbedHeader, {
  Tab,
} from "../../../../components/MainList/TabbedHeader";
import { useConfig } from "../../../../hooks";

const SegmentTitle = styled.div`
  color: ${(props) => props.theme.purple};
  border-bottom: 1px solid #cccccc;
  padding: 7px;
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
`;

const MultiProviderDetails: React.VFC<{
  authorization: Authorization;
  disabled: boolean;
  results: Record<string, string>;
  selectedTab: Tab;
  providerTabs: Tab[];
  prescriberId: number;
  referringProviderId: number;
  onTabSelect: (tab: Tab) => void;
  setPrescriberDetails: () => Promise<void>;
  trackUpsert: () => void;
  upsertPrescriberOrLocation: () => Promise<void>;
}> = ({
  authorization,
  disabled,
  results,
  selectedTab,
  providerTabs,
  prescriberId,
  referringProviderId,
  setPrescriberDetails,
  onTabSelect,
  trackUpsert,
  upsertPrescriberOrLocation,
}) => {
  const config = useConfig();

  return (
    <>
      <SegmentTitle>Provider Details</SegmentTitle>
      <TabbedHeader
        onSelect={onTabSelect}
        selected={selectedTab}
        tabs={providerTabs}
        showHeaderLine={false}
        marginBottom="8px"
      />
      {selectedTab === providerTabs[0] && (
        <PrescriberDetails
          authorization={authorization}
          authorizationId={authorization.id}
          disabled={disabled}
          prescriberId={prescriberId}
          set={setPrescriberDetails}
          results={results}
          showSectionTitle={false}
          providerType={config.CONSTANTS.PRESCRIBER_TYPES.PRESCRIBER}
          trackUpsert={trackUpsert}
          upsertPrescriberOrLocation={upsertPrescriberOrLocation}
        />
      )}
      {selectedTab === providerTabs[1] && (
        <PrescriberDetails
          authorization={authorization}
          authorizationId={authorization.id}
          disabled={disabled}
          prescriberId={referringProviderId}
          set={setPrescriberDetails}
          results={results}
          showSectionTitle={false}
          providerType={config.CONSTANTS.PRESCRIBER_TYPES.REFERRING_PROVIDER}
          trackUpsert={trackUpsert}
          upsertPrescriberOrLocation={upsertPrescriberOrLocation}
        />
      )}
    </>
  );
};

export default MultiProviderDetails;
