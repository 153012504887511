import _ from "lodash";
import {
  DataGridPro,
  GridColDef,
  Stack,
  Autocomplete,
  TextField,
  Link,
} from "@samacare/design/core";
import Chip from "@samacare/design/core/Chip";
import GridWrapper from "../../components/DataGrid/GridWrapper";
import { PatientTreatmentDetailPage } from "./PatientTreatmentDetailPage";
import { useTheme } from "@samacare/design/core/styles";
import GridQuickFilter from "../../components/DataGrid/GridQuickFilter";

import {
  PatientTreatmentsGridRow,
  usePatientTreatmentsContext,
} from "./PatientTreatmentsProvider";

const PatientTreatmentToolBar = (quickFilterText?: string) => {
  const { setDuration, duration } = usePatientTreatmentsContext();

  const options = [
    { title: "Last 24 hours", value: "1" },
    { title: "Last 30 days", value: "30" },
    { title: "Last 60 days", value: "60" },
    { title: "Last 90 days", value: "90" },
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={1} p={2}>
      <GridQuickFilter
        inputHeightOverride={40}
        inputWidthOverride={300}
        searchWord={quickFilterText}
        data-cy="patient-treatment-quick-filter"
      />
      <Autocomplete
        options={options}
        sx={{ width: 300 }}
        value={options.find((option) => option.value === duration)}
        data-cy="patientTreatmentDurationFilter"
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Duration"
              InputLabelProps={{ shrink: true }}
              data-cy={`patientTreatmentDurationFilter${_.camelCase(
                params.inputProps.value?.toString()
              )}`}
            />
          );
        }}
        getOptionLabel={(option) => option.title}
        onChange={(event, value) => {
          setDuration(value?.value);
        }}
      />
    </Stack>
  );
};

export const PatientTreatmentsGrid: React.FC = () => {
  const {
    toggleDrawer,
    setActiveRow,
    setPaginationModel,
    paginationModel,
    patientTreatmentCount,
    rows,
    filterModel,
    setFilterModel,
  } = usePatientTreatmentsContext();
  const theme = useTheme();

  const columns: GridColDef[] = [
    {
      field: "institutionPatientId",
      headerName: "MRN",
      minWidth: 100,
    },
    {
      field: "firstName",
      headerName: "First name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "lastName",
      headerName: "Last name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "dob",
      headerName: "Date of birth",
      editable: false,
      minWidth: 180,
    },
    {
      field: "payerName",
      headerName: "Payer",
      editable: false,
      minWidth: 180,
    },
    {
      field: "drugs",
      headerName: "Drugs / Treatment",
      editable: false,
      minWidth: 300,
      renderCell: (params: { row: PatientTreatmentsGridRow }) => {
        return params.row.drugs.map((drug, i) => (
          <Chip
            label={drug}
            size="small"
            sx={{
              marginLeft: ({ spacing }) => spacing(1),
            }}
            key={i}
          />
        ));
      },
    },
    {
      field: "actions",
      headerName: "Action",
      editable: false,
      minWidth: 180,
      renderCell: (params: { row: PatientTreatmentsGridRow }) => {
        return (
          <Stack direction="row" spacing={2}>
            {params.row.showBvSupportNote && (
              <Link
                sx={{
                  color: theme.palette.primary.main,
                }}
                href=" "
                component="button"
                onClick={() => {
                  toggleDrawer(true);
                  setActiveRow(_.find(rows, { id: params.row.id }));
                }}
              >
                Start BV
              </Link>
            )}
            {params.row.hasBvInDraft && (
              <Link
                sx={{
                  color: theme.palette.primary.main,
                }}
                href=" "
                component="button"
                onClick={() => {
                  toggleDrawer(true);
                  setActiveRow(_.find(rows, { id: params.row.id }));
                }}
              >
                Complete BV
              </Link>
            )}

            {params.row.primaryInsurance?.supportsCoverageCheck &&
              !params.row.primaryInsurance.latestCoverageCheck && (
                <Link
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                  href=" "
                  component="button"
                  onClick={() => {
                    toggleDrawer(true);
                    setActiveRow(_.find(rows, { id: params.row.id }));
                  }}
                >
                  Run BV Lite
                </Link>
              )}
          </Stack>
        );
      },
    },
  ];

  const searchWords = filterModel?.quickFilterValues ?? [];
  return (
    <GridWrapper headerText="Treatments">
      <DataGridPro
        disableColumnFilter
        slots={{
          toolbar: () =>
            PatientTreatmentToolBar(
              searchWords?.length > 0 ? searchWords[0] : null
            ),
        }}
        rows={rows ?? []}
        columns={columns}
        onRowClick={(params) => {
          toggleDrawer(true);
          setActiveRow(_.find(rows, { id: params.row.id }));
        }}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={patientTreatmentCount ?? 0}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {
          setFilterModel(newFilterModel);
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: "Search by Patient or Drug name",
        }}
        pageSizeOptions={[25, 50]}
        slotProps={{
          row: {
            "data-cy": "patientTreatmentRow",
          },
          pagination: {
            SelectProps: {
              inputProps: { "data-cy": "patientTreatmentPaginationSelect" },
            },
          },
        }}
      />
      <PatientTreatmentDetailPage />
    </GridWrapper>
  );
};
