import _ from "lodash";
import Select from "react-select";
import styled from "styled-components";
import { HelpTag } from "@samacare/layout/HelpTag";
import { Stack } from "@samacare/design/core";

const SelectorTitle = styled.div`
  font-weight: 700;
  margin: 9px 4px 7px 4px;
`;

const Selector = styled(Select)`
  width: ${(props) => props.width || "600px"};
  border: ${(props) => (props.highlight ? `2px solid ${props.theme.red}` : "")};
`;

const SegmentSelector = (props) => {
  const {
    sorted,
    title,
    setRef,
    options,
    filterOption,
    highlight,
    value,
    id,
    help,
    disabled,
  } = props;

  let sortableOptions = options;
  if (sorted && options) {
    sortableOptions = _.sortBy(options, "label");
  }

  const shouldHighlightField = highlight && !value;

  return (
    <div id={id}>
      <Stack direction="row" alignItems="center">
        {title && <SelectorTitle>{title}</SelectorTitle>}
        {help && <HelpTag text={help} />}
      </Stack>
      <Selector
        ref={setRef}
        menuStyle={{ backgroundColor: "#a9a9e0" }}
        placeholder="Begin typing to select an option"
        {...props}
        options={sortableOptions}
        filterOption={filterOption}
        highlight={shouldHighlightField}
        value={value}
        isClearable
        isDisabled={disabled}
        styles={{
          placeholder: (baseStyles) => ({
            ...baseStyles,
            lineHeight: 1,
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            height: "46px",
            border: "1px solid #b0b0b0",
          }),
        }}
      />
    </div>
  );
};

export default SegmentSelector;
