import { ChangeEvent, ReactNode } from "react";
import { useAlert } from "react-alert";
import { useFormContext } from "react-hook-form";

import {
  Box,
  Button,
  ButtonProps,
  Fade,
  FormControlLabel,
  FormLabel,
  Radio,
  Stack,
  FormHelperText,
  Typography,
} from "@samacare/design/core";
import { RadioGroupField, ErrorMessage } from "@samacare/form";

import { Delete } from "../../../ui-kit/Icons";
import { useFileUpload } from "../../hooks/useFIleUpload";
import { useEnrollmentContext } from "./EnrollmentProvider";
import { EnrollmentSection } from "./EnrollmentSection";

const ButtonLabel = Button as React.FC<
  ButtonProps<"label", { component?: "label" }>
>;

interface EnrollmentConsentProps {
  description?: ReactNode | null;
  noLabelOverride?: string;
}

export const EnrollmentConsent: React.FC<EnrollmentConsentProps> = (props) => {
  const alert = useAlert();
  const { onAlert, isSubmitted } = useEnrollmentContext();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const hasPatientConsentStr = watch("patientConsent.hasConsent") as string;
  const fileId = watch("patientConsent.fileId") as string;
  const fileTitle = watch("patientConsent.fileTitle") as string;

  const hasPatientConsent = hasPatientConsentStr === "yes";
  const fileUploaded = fileId != null;

  const {
    isSuccess: isFileUploadSuccess,
    upload: uploadFile,
    reset: resetFileUpload,
  } = useFileUpload();

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files == null || files.length === 0) {
      return;
    }
    if (files.length > 1) {
      alert.error("Please select only one file");
      return;
    }
    try {
      const file = files[0];
      const uploadFileResult = await uploadFile(file);

      const { fileId: uploadedFileId, fileTitle: uploadedFileTitle } =
        uploadFileResult;
      setValue("patientConsent.fileId", uploadedFileId);
      setValue("patientConsent.fileTitle", uploadedFileTitle);
    } catch (err) {
      onAlert("Failed to upload patient consent. Please try again.");
    }
  };

  return (
    <EnrollmentSection title="Patient Consent" description={props.description}>
      <Box display="flex">
        <RadioGroupField
          hideError
          name="patientConsent.hasConsent"
          label="Do you have patient consent?"
          rules={{
            required: "Please select an option",
          }}
        >
          <FormControlLabel
            name="patientConsent.yes"
            label="Yes"
            control={<Radio />}
            value="yes"
            disabled={isSubmitted}
          />
          <FormControlLabel
            name="patientConsent.no"
            control={<Radio />}
            label={
              props.noLabelOverride ??
              "No, I will use SamaAssist to collect Patient Consent"
            }
            value="no"
            disabled={isSubmitted}
          />
        </RadioGroupField>
        {hasPatientConsent && (
          <Stack spacing={2} mt={2}>
            {((!isSubmitted && !isFileUploadSuccess && !fileUploaded) ||
              (fileUploaded && fileTitle == null)) && (
              <Fade in>
                <Box>
                  <ButtonLabel
                    component="label"
                    variant="outlined"
                    size="small"
                  >
                    Attach file (Optional)
                    <input
                      hidden
                      accept="application/pdf"
                      multiple
                      type="file"
                      onChange={onFileChange}
                    />
                  </ButtonLabel>
                </Box>
              </Fade>
            )}
            {(isFileUploadSuccess || (fileUploaded && fileTitle != null)) && (
              <>
                <FormLabel>Uploaded patient consent file</FormLabel>
                <Stack direction="row" spacing={4}>
                  <Typography variant="caption">{fileTitle}</Typography>
                  <Delete
                    onClick={() => {
                      resetFileUpload();
                      setValue("patientConsent.fileId", null);
                      setValue("patientConsent.fileTitle", null);
                    }}
                  />
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Box>
      <ErrorMessage
        errors={errors}
        name="patientConsent.hasConsent"
        render={({ message }) => (
          <FormHelperText error>{message}</FormHelperText>
        )}
      />
    </EnrollmentSection>
  );
};
