import _ from "lodash";
import { useMemo, useState } from "react";
import BaseButton from "../BaseButton";
import Modal from "../Modal";
import List from "@samacare/design/core/List";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemButton,
  ListItemText,
  Typography,
} from "@samacare/design";
import { Account, Authorization } from "@samacare/graphql";
import {
  ListRedoxPatientDocumentReferencesQueryQuery,
  ListRedoxPatientDocumentReferencesQueryQueryVariables,
} from "@@generated/graphql";
import { useSelector } from "../../configureStore";
import { useLazyQuery } from "@apollo/client";
import listRedoxPatientDocumentReferencesQuery from "../../graphql/ListRedoxPatientDocumentReferencesQuery.gql";
import { useAttachRedoxDocument } from "@@hooks/useAttachRedoxDocument";
import { IntegrationFileAttachButtonContainer } from "AuthorizationSharedComponents/IntegrationFileAttachButtonContainer";
import { LeftRightCenterAll } from "../LeftRightV2";

export type RedoxFileType = {
  id: string;
  description: string;
  contentType: string;
  data?: string | null;
  updatedAt: string;
};

export const RedoxFilesSuggestionModal: React.VoidFunctionComponent<{
  authorization: Authorization;
  account?: Account;
  maxFileSizeMB?: number;
}> = ({ authorization, maxFileSizeMB }) => {
  const attachments = useSelector((state) => state.form.attachments);
  const [open, setOpen] = useState(false);

  const [
    fetchDocuments,
    { data: documentReferencesData, loading: documentListLoading },
  ] = useLazyQuery<
    ListRedoxPatientDocumentReferencesQueryQuery,
    ListRedoxPatientDocumentReferencesQueryQueryVariables
  >(listRedoxPatientDocumentReferencesQuery, {
    variables: { patientId: +authorization.patient!.id },
  });

  const { selectedFile, selectedIndex, selectFile, attachSelectedFile } =
    useAttachRedoxDocument(authorization, maxFileSizeMB);

  const handleOpenModal = () => {
    setOpen(true);
    fetchDocuments();
  };

  // filter out documents that have already been attached to the authorization, sort by updatedAt date
  const filteredDocuments = useMemo(() => {
    return _.sortBy(
      _.filter(
        documentReferencesData?.listRedoxPatientDocumentReferences || [],
        (file) => !_.find(attachments, { awsKey: file?.id })
      ),
      ({ updatedAt }: { updatedAt: string }) => updatedAt
    ).reverse() as RedoxFileType[];
  }, [documentReferencesData, attachments]);

  const renderLoading = () => {
    return (
      <LeftRightCenterAll sx={{ height: "400px" }}>
        <CircularProgress />
        <Typography variant="body1" color="primary" mt={2}>
          Loading PDFs...
        </Typography>
      </LeftRightCenterAll>
    );
  };

  const renderDocuments = () => {
    if (filteredDocuments.length === 0) {
      return (
        <LeftRightCenterAll sx={{ height: "400px" }}>
          <Typography variant="body1" color="textSecondary">
            No PDFs available from the EMR
          </Typography>
        </LeftRightCenterAll>
      );
    }
    return (
      <List sx={{ height: "400px", overflowY: "auto" }}>
        {_.map(filteredDocuments, (file, i) => (
          <ListItemButton
            key={`filtered_file_${file?.id}`}
            selected={selectedIndex === i}
            onClick={async () => selectFile(file, i)}
          >
            <ListItemText
              primary={file?.description}
              secondary={file?.updatedAt}
            />
          </ListItemButton>
        ))}
      </List>
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Modal
        header="Select uploads from EMR"
        onClick={() => setOpen(!open)}
        open={open}
        headerStyleOverride={{ overflowY: "hidden" }}
      >
        <Box
          m={1}
          display="flex"
          flexDirection="column"
          sx={{ width: "600px" }}
        >
          {documentListLoading ? renderLoading() : renderDocuments()}
          <Divider sx={{ my: 2 }} />
          <Box display="flex" justifyContent="center">
            <Button
              onClick={attachSelectedFile}
              disabled={!selectedFile || !authorization}
              variant="contained"
            >
              Attach
            </Button>
          </Box>
        </Box>
      </Modal>
      <IntegrationFileAttachButtonContainer>
        <BaseButton style={{ margin: "auto" }} onClick={handleOpenModal}>
          Attach from EMR
        </BaseButton>
      </IntegrationFileAttachButtonContainer>
    </div>
  );
};

export default RedoxFilesSuggestionModal;
