import _ from "lodash";
import { MenuItem, Stack, Typography } from "@samacare/design/core";
import {
  AUTH_APPROVAL_NUMBER,
  END_DATE,
  START_DATE,
  STATUS,
} from "./AssociateCorrespondenceModal";
import { useConfig } from "@@hooks/config";
import { DateField, TextField, SelectField } from "@samacare/form";

export const CorrespondenceFieldUpdateContainer: React.VoidFunctionComponent<{
  status: string;
  validationError: string;
}> = ({ status, validationError }) => {
  const { CONSTANTS } = useConfig();

  return (
    <Stack direction="column" spacing={2} sx={{ width: "250px" }}>
      {status === CONSTANTS.AUTHORIZATION_STATUSES.APPROVED && (
        <Typography variant="caption" color="error">
          {validationError}
        </Typography>
      )}
      <SelectField
        size="small"
        id={STATUS}
        label={_.startCase(STATUS)}
        name={STATUS}
        defaultValue={status}
      >
        {CONSTANTS.AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES.map((field) => (
          <MenuItem key={field} value={field}>
            {_.startCase(field)}
          </MenuItem>
        ))}
      </SelectField>
      {status === CONSTANTS.AUTHORIZATION_STATUSES.APPROVED && (
        <Stack direction="column" spacing={2}>
          <TextField
            size="small"
            fullWidth
            key={AUTH_APPROVAL_NUMBER}
            required
            label="Authorization Approval Number"
            name={AUTH_APPROVAL_NUMBER}
          />
          {[START_DATE, END_DATE].map((field) => (
            <DateField
              size="small"
              fullWidth
              key={field}
              required
              label={_.startCase(field)}
              name={field}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
