import { useQuery } from "@apollo/client";
import {
  PortalsFindAllQuery,
  PortalsFindAllQueryVariables,
} from "@@generated/graphql";
import { portalsQuery } from "../graphql/Portal";

export const useAllPortals = () => {
  const { data } = useQuery<PortalsFindAllQuery, PortalsFindAllQueryVariables>(
    portalsQuery
  );
  return data?.portalsFindAll ?? null;
};
