import { useFormContext } from "react-hook-form";
import useInsuranceTypeOption from "../hooks/useInsuranceTypeOption";
import useInsuranceTypeOptions, {
  type InsuranceTypeOption,
} from "../hooks/useInsuranceTypeOptions";
import AutocompleteField, {
  type AutocompleteFieldProps,
} from "../AutocompleteField";

export type InsuranceTypeFieldProps = Omit<
  AutocompleteFieldProps<InsuranceTypeOption, false, true, false>,
  "options"
>;

export const InsuranceTypeField: React.FC<InsuranceTypeFieldProps> = ({
  name,
  ...props
}) => {
  const { watch } = useFormContext();
  const selected: string | undefined = watch(name);
  const defaultValue = useInsuranceTypeOption(selected ?? "");
  const types = useInsuranceTypeOptions();

  return (
    <AutocompleteField<InsuranceTypeOption, false, true, false>
      name={name}
      disableClearable
      options={types}
      value={defaultValue ?? ""}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.id === value.id}
      setValueAs={(v: InsuranceTypeOption | string) => {
        if (typeof v === "string") {
          return v;
        }
        return v?.id;
      }}
      {...props}
    />
  );
};
