import _ from "lodash";
import { compose } from "recompose";
import SegmentSelector from "Segment/SegmentSelector";

import { withUpdateFormDetails } from "../../../../graphql/Authorization";
import { withLocations } from "../../../../graphql/Location";
import { useConfig } from "../../../../hooks/config/index";

const LocationSelector = ({
  providerType,
  disabled,
  authorizationId,
  locationId,
  locations,
  set,
  trackUpsert,
  updateFormDetails,
}) => {
  const config = useConfig();
  const { REFERRING_PROVIDER } = config.CONSTANTS.PRESCRIBER_TYPES;
  const selectedLocation = _.find(locations, { id: locationId });

  const handleLocationConfigUpdate = (newLocationId) => {
    const selected = _.find(locations, { id: newLocationId });

    switch (providerType) {
      case REFERRING_PROVIDER:
        set({
          [config.DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME.key]:
            selected.facilityName,
          [config.DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS.key]:
            selected.address,
          [config.DEFAULT_FIELDS.REFERRING_PROVIDER_CITY.key]: selected.city,
          [config.DEFAULT_FIELDS.REFERRING_PROVIDER_STATE.key]: selected.state,
          [config.DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP.key]: selected.zip,
        });
        break;
      default:
        set({
          [config.DEFAULT_FIELDS.LOCATION_NAME.key]: selected.name,
          [config.DEFAULT_FIELDS.FACILITY_NAME.key]:
            selected.facilityName || selected.name,
          [config.DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key]: selected.address,
          [config.DEFAULT_FIELDS.PRESCRIBER_CITY.key]: selected.city,
          [config.DEFAULT_FIELDS.PRESCRIBER_STATE.key]: selected.state,
          [config.DEFAULT_FIELDS.PRESCRIBER_ZIP.key]: selected.zip,
          [config.DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]: selected.taxId,
          [config.DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]: selected.NPI,
        });
    }

    trackUpsert(selected, false, providerType);
  };

  const handleOnChange = async (event) => {
    switch (providerType) {
      case REFERRING_PROVIDER:
        if (event) {
          handleLocationConfigUpdate(event.value);
        } else {
          set({
            [config.DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME.key]: "",
            [config.DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS.key]: "",
            [config.DEFAULT_FIELDS.REFERRING_PROVIDER_CITY.key]: "",
            [config.DEFAULT_FIELDS.REFERRING_PROVIDER_STATE.key]: "",
            [config.DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP.key]: "",
          });
        }
        break;
      default:
        if (event) {
          updateFormDetails({
            variables: {
              id: authorizationId,
              details: { locationId: event.value },
            },
          });
          handleLocationConfigUpdate(event.value);
        } else {
          updateFormDetails({
            variables: { id: authorizationId, details: { locationId: null } },
          });
        }
        break;
    }
  };

  return (
    <div>
      <SegmentSelector
        id="controlLocationSelector"
        disabled={disabled}
        onChange={handleOnChange}
        options={_.map(locations, (location) => ({
          value: location.id,
          label: location.name,
        }))}
        title="Saved Offices"
        help="To begin, type the locations's name or scroll to select the location from the dropdown. If not found, select 'Create & Set New' to have the option show up in the future."
        value={
          selectedLocation
            ? { value: selectedLocation.id, label: selectedLocation.name }
            : ""
        }
        sorted
      />
    </div>
  );
};

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(withUpdateFormDetails, withLocations)(LocationSelector);
