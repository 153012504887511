import React from "react";

import { CircularProgress, Typography } from "@samacare/design/core";

import { UnifiedBenefitsCheckType } from "@samacare/graphql";
import { BenefitsVerificationProvider } from "../BeneftsVerificationProvider";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { ViewBenefitsCheckLite } from "./ViewBenefitsCheckLite";
import { ViewBenefitsVerification } from "./ViewBenefitsVerification";

export const ViewUnifiedBenefitsCheck: React.FC = () => {
  const { loading, error, currentAccount, unifiedBenefitsCheck } =
    useUnifiedBenefitsCheckContext();

  if (loading) {
    return <CircularProgress />;
  }

  if (error != null || currentAccount == null || unifiedBenefitsCheck == null) {
    return <Typography>Unable to load benefits check</Typography>;
  }

  if (unifiedBenefitsCheck.type === UnifiedBenefitsCheckType.Lite) {
    return <ViewBenefitsCheckLite />;
  }

  return (
    <BenefitsVerificationProvider>
      <ViewBenefitsVerification />
    </BenefitsVerificationProvider>
  );
};
