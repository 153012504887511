/*
 * XXX(ndhoule): This file is extremely dynamic and was converted from
 * TypeScript. If you know the types in here and can help clean this up, please
 * make some improvements.
 */
import { memoize } from "lodash";
import colors from "Resources/colors";
import {
  Form,
  FormInput,
  getInputValue,
} from "../../../util/inputConfigurationManager";

export interface DimensionRatio {
  heightRatio: number;
  widthRatio: number;
}

export interface DimensionRatios {
  [page: number]: DimensionRatio | null;
}

export const calculateDimensionRatio = (
  form: Form,
  image: HTMLImageElement
): DimensionRatio => ({
  widthRatio: image.clientWidth / form.taggedWidth,
  heightRatio: image.clientHeight / form.taggedHeight,
});

export const getIsDisplayHidden = (
  input: FormInput,
  inputs: FormInput[],
  results: unknown
): boolean => {
  if (!input.displayIf || getInputValue(input, results)) {
    return false;
  }

  const displayIf = inputs.find(({ id }) => id === input.displayIf);
  // If there is no display if, what has happened is we set a display if on a dependent
  // then we deleted the reference displayIf and didnt update the dependent
  if (displayIf == null) {
    return false;
  }
  // If the displayIf parent has a box
  if (displayIf.x && displayIf.y) {
    return !getInputValue(displayIf, results);
  }

  // If the displayIf parent has no box, then check the grand parent's value
  if (displayIf.parentId) {
    return !getInputValue(
      inputs.find(({ id }) => id === displayIf.parentId)!,
      results
    );
  }
  // Otherwise, it is hidden
  return true;
};

const getPositionProps = ({
  dimensionRatios,
  form,
  input,
  inputTypes,
}: {
  dimensionRatios: DimensionRatios;
  form: Form;
  input: FormInput;
  inputTypes: { [key: string]: { key: string } };
}) => {
  const { widthRatio, heightRatio } = dimensionRatios[input.page] ?? {
    widthRatio: 0,
    heightRatio: 0,
  };

  if (inputTypes.RADIO_GROUP.key === input.type) {
    // returning unique type for radio group
    // we want to set a top and left to allow for automated scroll if user hasn't filled out a required radio group, but we don't want to give it a width or height
    return {
      top: (input.y - 100 + input.page * form.taggedHeight) * heightRatio,
      left: input.x * widthRatio,
    };
  }

  return {
    top: (input.y + input.page * form.taggedHeight) * heightRatio,
    left: input.x * widthRatio,
    width: input.width * widthRatio,
    height: input.height * heightRatio,
  };
};

const getDisplayProps = ({
  input,
  inputs,
  results,
  disabled,
  segmentLoading,
}: {
  input: FormInput;
  inputs: FormInput[];
  results: unknown;
  disabled: boolean;
  segmentLoading: boolean;
}) => {
  const isDisplayHidden = getIsDisplayHidden(input, inputs, results);
  const value = getInputValue(input, results);

  return {
    isDisplayHidden,
    disabled: disabled || segmentLoading,
    value,

    background: value ? colors.disabledGray : input.background,
  };
};

export const getDisplayInput = ({
  dimensionRatios,
  disabled,
  form,
  input,
  inputs,
  results,
  segmentLoading,
  setFormFields,
  inputTypes,
}: {
  dimensionRatios: DimensionRatios;
  disabled: boolean;
  form: Form;
  input: FormInput;
  inputs: FormInput[];
  results: unknown;
  segmentLoading: boolean;
  setFormFields: (fields: Record<string, unknown>) => void;
  inputTypes: { [key: string]: { key: string } };
}) => {
  return {
    ...getDisplayProps({ disabled, input, inputs, results, segmentLoading }),
    ...getPositionProps({ dimensionRatios, form, input, inputTypes }),
    onChange: setFormFields,
    id: input.id,
    type: input.type,
    samaTypes: input.samaTypes,
    importance: input.importance,
    siblingId: input.siblingId,
    tabIndex: input.tabIndex,
    characterBoxes: input.characterBoxes,
  };
};

// This function is expensive and is called in a fairly hot rendering path.
export const getSiblings = memoize(
  ({
    dimensionRatios,
    disabled,
    form,
    input,
    inputs,
    results,
    segmentLoading,
    setFormFields,
    inputTypes,
  }: {
    dimensionRatios: DimensionRatios;
    disabled: boolean;
    form: Form;
    input: FormInput;
    inputs: FormInput[];
    results: unknown;
    segmentLoading: boolean;
    setFormFields: (fields: Record<string, unknown>) => void;
    inputTypes: { [key: string]: { key: string } };
  }) => {
    const inputSiblings = inputs.filter(({ id }) => id === input.id);
    return inputSiblings.map((sibling) =>
      getDisplayInput({
        dimensionRatios,
        disabled,
        form,
        input: sibling,
        inputs,
        results,
        segmentLoading,
        setFormFields,
        inputTypes,
      })
    );
  },
  ({ input }) => input.id
);

// This function is expensive and is called in a fairly hot rendering path.
export const getChildren = memoize(
  ({
    dimensionRatios,
    disabled,
    form,
    input,
    inputs,
    results,
    segmentLoading,
    setFormFields,
    inputTypes,
  }: {
    dimensionRatios: DimensionRatios;
    disabled: boolean;
    form: Form;
    input: FormInput;
    inputs: FormInput[];
    results: unknown;
    segmentLoading: boolean;
    setFormFields: (fields: Record<string, unknown>) => void;
    inputTypes: { [key: string]: { key: string } };
  }) => {
    const inputChildren = inputs.filter(
      ({ parentId }) => parentId === input.id
    );
    return inputChildren.map((child) => {
      const { onChange, ...rest } = getDisplayInput({
        dimensionRatios,
        disabled,
        form,
        input: child,
        inputs,
        results,
        segmentLoading,
        setFormFields,
        inputTypes,
      });
      return rest;
    });
  },
  ({ input, inputs, results }) => {
    const parentInput = inputs.find(({ id }) => id === input.parentId);
    const parentHasValue = parentInput
      ? getInputValue(parentInput, results) != null
      : false;
    return `${input.id}${parentHasValue.toString()}`;
  }
);
