import { useFormContext } from "react-hook-form";

import AutocompleteField, {
  type AutocompleteFieldProps,
} from "./AutocompleteField";
import useEnrollmentProgramOptions from "./hooks/useEnrollmentProgramOptions";

import type { EnrollmentProgram as EnrollmentProgramType } from "@samacare/graphql";

export type EnrollmentProgramAutocompleteProps = Omit<
  AutocompleteFieldProps<EnrollmentProgramType, false, false, false>,
  "options"
>;

export const EnrollmentProgramField: React.FC<
  EnrollmentProgramAutocompleteProps
> = ({ name, ...props }) => {
  const { watch } = useFormContext();

  const { enrollmentPrograms } = useEnrollmentProgramOptions();

  return (
    <AutocompleteField<EnrollmentProgramType, false, false, false>
      name={name}
      value={watch(name) ?? ""}
      options={(enrollmentPrograms as EnrollmentProgramType[]) ?? []}
      filterSelectedOptions
      getOptionLabel={(option: EnrollmentProgramType) => option.label ?? ""}
      {...props}
    />
  );
};
