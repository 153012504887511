import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useAlert } from "react-alert";
import { object, string } from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Box, Divider, Grid } from "@samacare/design";
import { useMutation } from "@apollo/client";
import { Button, Stack, DialogContent } from "@samacare/design/core";
import Dialog from "@samacare/design/core/Dialog";
import DialogActions from "@samacare/design/core/DialogActions";
import DialogTitle from "@samacare/design/core/DialogTitle";
import { TextField } from "@samacare/form";
import {
  LOCATIONS_QUERY,
  createLocationByNpiFromRegistryMutation,
  useUpsertLocation,
} from "../../../graphql/Location";
import { Location } from "@samacare/graphql";

import {
  CreateLocationByNpiFromRegistryMutation,
  CreateLocationByNpiFromRegistryMutationVariables,
} from "@@generated/graphql";

const locationSchema = object<Location>({
  name: string().required("Name is required"),
  address: string(),
  city: string(),
  state: string(),
  zip: string(),
  fax: string(),
  facilityName: string(),
  taxId: string()
    .matches(/.{9,}/, {
      excludeEmptyString: true,
      message: "TIN must be 9 digits",
    })
    .matches(/^\d*$/, "TIN must only contain digits"),
  NPI: string()
    .required("NPI is required")
    .length(10, "NPI must be 10 digits")
    .matches(/^\d{10}$/, "NPI must only contain digits"),
  PTAN: string(),
});

interface UpsertLocationDialogProps {
  location: Location | null;
  onClose: () => void;
}
export const UpsertLocationDialog: React.FC<UpsertLocationDialogProps> = (
  props
) => {
  const [isEdit, setIsEdit] = useState(false);
  const alert = useAlert();
  const upsertLocation = useUpsertLocation();
  const methods = useForm<Location>({
    resolver: yupResolver(locationSchema),
    // mode: "onChange",
    // reValidateMode: "onChange",
  });

  const [createLocationByNpiFromRegistry] = useMutation<
    CreateLocationByNpiFromRegistryMutation,
    CreateLocationByNpiFromRegistryMutationVariables
  >(createLocationByNpiFromRegistryMutation, {
    refetchQueries: () => [LOCATIONS_QUERY],
  });

  const onSave = async (location: Location) => {
    try {
      await upsertLocation(location);
      alert.success("Success");
      props.onClose();
    } catch (err: unknown) {
      const e = err as Error;
      alert.error(`There was an error creating this location, ${e.message}`);
    }
  };

  useEffect(() => {
    methods.reset(props.location ?? {});
    if (props.location) {
      setIsEdit(!!props.location.id);
    }
  }, [props.location, methods, setIsEdit]);

  const handleSearch = async (npi: string) => {
    const { data } = await createLocationByNpiFromRegistry({
      variables: { npi },
    });

    if (data) {
      const { success, message } = data.createLocationByNpiFromRegistry;

      if (success) {
        alert.info(
          "Successfully searched the NPI Registry and created a new Location"
        );
        props.onClose();
      } else {
        alert.error(message);
        setIsEdit(true);
      }
    }
  };

  const title = isEdit ? "Edit Location" : "Add Location";
  const data = methods.watch();

  return (
    <Dialog
      open={!!props.location}
      onClose={props.onClose}
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle>{title}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <DialogContent sx={{ width: 600 }}>
            <Stack direction="row" spacing={2} pb={2}>
              <TextField
                fullWidth
                label="NPI"
                name="NPI"
                required
                inputProps={{ maxLength: 10 }}
              />
              <Box display="flex" width="100%">
                <Button
                  variant="contained"
                  disabled={data.NPI == null || isEdit}
                  onClick={async () => {
                    await handleSearch(data.NPI as string);
                  }}
                >
                  Search
                </Button>
              </Box>
            </Stack>

            <Divider />

            <Grid container spacing={2} columns={6} pt={2}>
              {[
                "facilityName",
                "name",
                "address",
                "city",
                "state",
                "zip",
                "fax",
                "taxId",
                "PTAN",
              ].map((field, ind) => {
                return (
                  <Grid width="50%" item key={`${field}_${ind}`}>
                    <TextField
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      fullWidth
                      label={_.startCase(field)}
                      name={field}
                      disabled={!isEdit}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="actionCloseEditLocationDialog"
              variant="outlined"
              onClick={() => {
                methods.reset();
                props.onClose();
              }}
            >
              Close
            </Button>
            <Button
              data-cy="actionSaveLocation"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isEdit}
            >
              {isEdit ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
