import { useQuery } from "@apollo/client";
import { useState } from "react";
import * as React from "react";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { PrimaryButton } from "@@ui-kit";
import { Select } from "@@ui-kit/forms";
import {
  Institution,
  AuthorizationCorrespondence,
  QueryGetInsuranceResponsesArgs,
  AuthorizationExtensionFilterValue,
} from "@samacare/graphql";

import { GetInsuranceResponsesQuery } from "@@generated/graphql";

import { withInsuranceResponsesQuery } from "../../../graphql/AuthorizationCorrespondence";
import { ResponseItem } from "./ResponseItem";
import { AutocompleteSelect } from "../../../components/AuthorizationSharedComponents/AutocompleteSelect";

const ResponseRow = styled.div`
  cursor: pointer;
  background-color: white;
  padding: 5px;

  :hover {
    color: ${({ theme }) => theme.purple};
    background-color: ${({ theme }) => theme.lightGray};
  }
`;

const SelectedResponseRow = styled(ResponseRow)`
  font-weight: bold;
  background-color: ${({ theme }) => theme.lightGray};
`;

const ScrollContainer = styled.div`
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  min-width: 100px;
  overflow-y: scroll;
  flex-grow: 1;
`;

export const ResponseList: React.VoidFunctionComponent<{
  institutions: Institution[];
  onSelect: (correspondence: AuthorizationCorrespondence) => void;
  hiddenQueue: { id: string; undoHideMethod: () => void }[];
  accountId: string;
  onHide: (AuthorizationCorrespondenceId: string) => void;
  filterByInstitutionId: string | null;
}> = ({ institutions, onSelect, hiddenQueue, onHide }) => {
  const [selectedId, setSelectedId] = useState("");
  const [institutionIdFilter, setInstitutionIdFilter] = useState("");
  const [authorizationExtensionFilter, setAuthorizationExtensionFilter] =
    useState(AuthorizationExtensionFilterValue.NonExtensionsOnly);
  const { data, loading, error } = useQuery<
    GetInsuranceResponsesQuery,
    QueryGetInsuranceResponsesArgs
  >(withInsuranceResponsesQuery, {
    variables: {
      authorizationExtensionFilter,
    },
    pollInterval: 60 * 1000 * 5,
  });

  if (loading || institutions == null || data == null) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading correspondences</div>;
  }

  const correspondences = data.getInsuranceResponses;
  if (correspondences == null) {
    return <div />;
  }
  const filteredCorrespondences = correspondences.filter(
    (corr) => corr != null
  ) as AuthorizationCorrespondence[];

  const selectableInstitutionIds = _.uniq(
    filteredCorrespondences.map(({ InstitutionId }) => InstitutionId)
  );
  const filteredInstitutions = institutions
    .filter(({ id }) => selectableInstitutionIds.includes(id))
    .map(({ id, name }) => ({ label: name, value: id }));

  return (
    <div
      style={{
        minWidth: "150px",
        maxWidth: "175px",
        display: "flex",
        flexDirection: "column",
      }}
      data-cy="controlFilterResponseByInstitution"
    >
      <Select
        isClearable
        options={filteredInstitutions}
        // @ts-ignore
        onChange={(val: { value: string; label: string } | null) => {
          if (val == null) {
            setInstitutionIdFilter("");
          } else {
            setInstitutionIdFilter(val.value ?? "");
          }
        }}
        value={filteredInstitutions.find(
          (selection) => selection.value === institutionIdFilter
        )}
        placeholder="Filter"
      />
      <AutocompleteSelect
        options={Object.values(AuthorizationExtensionFilterValue)}
        onChange={(k) =>
          setAuthorizationExtensionFilter(
            k as AuthorizationExtensionFilterValue
          )
        }
        getOptionLabel={(k) => _.startCase(_.toLower(k))}
        value={authorizationExtensionFilter}
      />
      <ScrollContainer>
        <div style={{ textAlign: "center" }}>
          <b>{`${filteredCorrespondences.length.toString()} `}</b>
          items
        </div>
        {filteredCorrespondences
          .filter((response) => {
            return institutionIdFilter === ""
              ? true
              : response.InstitutionId === institutionIdFilter;
          })
          // sort by ascending order for ease of user workflow
          .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
          .map((response) => {
            const children = _.some(hiddenQueue, { id: response.id }) ? (
              <div>
                <PrimaryButton
                  onClick={
                    hiddenQueue.find(({ id }) => id === response.id)
                      ?.undoHideMethod
                  }
                >
                  Undo
                </PrimaryButton>
              </div>
            ) : (
              <ResponseItem response={response} onHide={onHide} />
            );

            if (selectedId === response.id) {
              return (
                <SelectedResponseRow
                  key={`insurance_response_support_${response.id}`}
                >
                  {children}
                </SelectedResponseRow>
              );
            }

            return (
              <ResponseRow
                onClick={() => {
                  onSelect(response);
                  setSelectedId(response.id);
                }}
                key={`insurance_response_support_${response.id}`}
              >
                {children}
              </ResponseRow>
            );
          })}
      </ScrollContainer>
    </div>
  );
};

export default ResponseList;
