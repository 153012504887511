import _ from "lodash";

import {
  Autocomplete,
  TextField,
  type AutocompleteProps,
  type TextFieldProps,
} from "@samacare/design/core";

import {
  useFormContext,
  useFormState,
  type FieldError,
  type UseControllerProps,
  RegisterOptions,
} from "react-hook-form";
import { InputProps } from "@samacare/design/core/Input";

export type AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "renderInput"
> &
  Omit<TextFieldProps, "onChange"> &
  UseControllerProps & {
    endAdornment?: React.ReactNode;
  } & Omit<RegisterOptions, "onChange"> & { TextFieldInputProps?: InputProps };

export const AutocompleteField = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  defaultValue,
  multiple,
  options,
  label,
  placeholder,
  name,
  rules,
  endAdornment,
  required,
  setValueAs,
  onChange: onDefaultChange,
  TextFieldInputProps,
  ...materialProperties
}: AutocompleteFieldProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>): JSX.Element => {
  const { control, register, setValue } = useFormContext();

  const { errors } = useFormState({ control });

  const { ref, onChange, onBlur, ...registerProps } = register(name, {
    ...rules,
    required,
    setValueAs,
  });

  const error = _.get(errors, name) as FieldError;

  const renderEndAdornment = (defaultEndAdornment: React.ReactNode) => (
    <>
      {endAdornment}
      {defaultEndAdornment}
    </>
  );

  return (
    <Autocomplete
      openOnFocus
      fullWidth
      id={`field-${name}`}
      onChange={async (e, change, reason, details) => {
        if (materialProperties.disabled) return; //fixing a MUI bug that causes this to still be editable while disabled (see: https://github.com/mui/material-ui/issues/36867)
        if (onDefaultChange) onDefaultChange(e, change, reason, details);
        setValue(name, change, { shouldDirty: true });
      }}
      renderInput={(params) => (
        <TextField
          {...{
            error: Boolean(error),
            helperText: error?.message,
            placeholder,
            label,
            name,
            rules,
            required,
            ...params,
            InputProps: {
              "data-cy": _.camelCase(`field.${name}`),
              error: !!error,
              ...params.InputProps,
              endAdornment: renderEndAdornment(
                params?.InputProps?.endAdornment
              ),
              ...TextFieldInputProps,
            } as InputProps,
            InputLabelProps: { shrink: true },
          }}
        />
      )}
      {...{
        multiple,
        options,
        ...registerProps,
        ...materialProperties,
      }}
    />
  );
};

export default AutocompleteField;
