import _ from "lodash";
import { useMutation } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import gql from "graphql-tag";
import prescriberInfo from "./fragments/prescriberInfo";
import { withDefaultProps } from "./utils";
import { Prescriber } from "@samacare/graphql";

export const PRESCRIBERS_FIND_ALL_QUERY_NAME = "Prescribers";
export const allPrescribersQuery = gql`
  query Prescribers {
    prescribersFindAll {
      ...prescriberInfo
    }
  }
  ${prescriberInfo}
`;

export const withPrescribers = graphql(allPrescribersQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    prescribers: _.get(data, "prescribersFindAll"),
  })),
});

const upsertPrescriberMutation = gql`
  mutation upsertPrescriber($id: Int, $patch: JSON!) {
    upsertPrescriber(id: $id, patch: $patch) {
      ...prescriberInfo
    }
  }
  ${prescriberInfo}
`;

export const createPrescriberByNpiFromRegistryMutation = gql`
  mutation createPrescriberByNpiFromRegistry($npi: String!) {
    createPrescriberByNpiFromRegistry(npi: $npi) {
      success
      message
    }
  }
`;

export const withUpsertPrescriber = graphql(upsertPrescriberMutation, {
  name: "upsertPrescriber",
  options: { refetchQueries: () => [PRESCRIBERS_FIND_ALL_QUERY_NAME] },
});

export const useUpsertPrescriber = () => {
  const [upsertPrescriber] = useMutation(upsertPrescriberMutation, {
    refetchQueries: () => [PRESCRIBERS_FIND_ALL_QUERY_NAME],
  });

  return async (prescriber: Prescriber) => {
    await upsertPrescriber({
      variables: {
        id: prescriber.id,
        patch: {
          ...prescriber,
        },
      },
    });
  };
};
