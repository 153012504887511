import { useMutation } from "@apollo/client";
import _ from "lodash";
import moment from "moment";
import { Component, useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  Authorization,
  MutationRemovePatientOnAuthArgs,
  MutationSetPatientOnAuthArgs,
  Patient,
} from "@samacare/graphql";
import PatientSearch from "../../../components/AuthorizationSharedComponents/PatientSearch";
import SelectPatientModal from "../../../components/AuthorizationSharedComponents/SelectPatientModal";
import { LeftRightCenterV } from "../../../components/LeftRight";
import SegmentButton from "../../../components/Segment/SegmentButton";
import SegmentDate from "../../../components/Segment/SegmentDate";
import SegmentText from "../../../components/Segment/SegmentText";
import { TopBottom } from "../../../components/TopBottom";
import { useSelector } from "../../../configureStore";
import {
  removePatientMutation,
  setPatientMutation,
} from "../../../graphql/Authorization";
import { useFieldConfigs } from "../../../hooks/useFieldConfigs";
import { useHighlightRequiredFields } from "../../../hooks/useHighlightRequiredFields";
import { useSet } from "../../../hooks/useSet";
import { FormField } from "./FormField";

const df = window.CONFIG.DEFAULT_FIELDS;

const fields = [
  df.PATIENT_FIRST_NAME,
  df.PATIENT_LAST_NAME,
  df.PATIENT_DOB,
  df.INSTITUTION_PATIENT_ID,
];

interface PatientIdentifierFieldsProps {
  auth: Authorization;
  isDisabled: boolean;
  hasIntegrations: boolean;
}
export const PatientIdentifierFields: React.FC<
  PatientIdentifierFieldsProps
> = ({ hasIntegrations, auth, isDisabled }) => {
  const patientFirstNameInputRef = useRef<HTMLInputElement>(null);
  const patientLastNameInputRef = useRef<HTMLInputElement>(null);
  const patientDOBInputRef = useRef<HTMLInputElement>(null);
  const institutionPatientIdInputRef = useRef<HTMLInputElement>(null);
  const patientSearchListRef = useRef<typeof PatientSearch>(null);

  const [showPatientSelectionModal, setShowPatientSelectionModal] =
    useState(false);
  const [patientResults, setPatientResults] = useState<Patient[]>([]);
  const [matchingPatientResults, setMatchingPatientResults] = useState<
    Patient[]
  >([]);
  const [showPatientSearch, setShowPatientSearch] = useState(false);

  const results = useSelector((state) => state.form.results);
  const firstName = results[df.PATIENT_FIRST_NAME.key];
  const lastName = results[df.PATIENT_LAST_NAME.key];
  const institutionPatientId = results[df.INSTITUTION_PATIENT_ID.key];
  const dob = results[df.PATIENT_DOB.key];

  const hasPatientBeenSelected = !_.isNil(auth.patient?.id);

  const set = useSet();
  const highlightRequiredFields = useHighlightRequiredFields();
  const fieldConfigs = useFieldConfigs(fields, auth.formFieldConfig);

  const [setPatient] = useMutation<void, MutationSetPatientOnAuthArgs>(
    setPatientMutation
  );
  const [removePatient] = useMutation<void, MutationRemovePatientOnAuthArgs>(
    removePatientMutation
  );

  const onBlur = useCallback(() => {
    const newMatchingPatientResults = patientResults.filter(
      (patient: Patient) =>
        moment(dob).isSame(patient.dob, "day") &&
        firstName &&
        lastName &&
        institutionPatientId
    );
    setMatchingPatientResults(newMatchingPatientResults);
    setShowPatientSelectionModal(!_.isEmpty(newMatchingPatientResults));
  }, [dob, firstName, lastName, institutionPatientId, patientResults]);

  const handleDocumentClick = useCallback(
    (event: Event) => {
      // Filter out refs for any unrendered elements
      const refs = [
        patientFirstNameInputRef,
        patientLastNameInputRef,
        patientDOBInputRef,
        institutionPatientIdInputRef,
        patientSearchListRef,
      ].filter((ref) => !_.isNil(ref.current));

      // Determine if the click is targeting one of the patient input elements
      const clickTargetsRelevantInput = refs.some((ref) => {
        // XXX(ndhoule): This is a little janky, but with the way styled
        // components handles refs, we don't have a lot of choice
        const node =
          ref.current instanceof Component
            ? // eslint-disable-next-line react/no-find-dom-node
              ReactDOM.findDOMNode(ref.current)
            : ref.current;
        return (
          node != null &&
          node instanceof Element &&
          node.contains(event.target as Node)
        );
      });

      // If the click targets anything but one of our input elements or the
      // patient search results list, hide the patient search result list
      if (!clickTargetsRelevantInput) {
        setShowPatientSearch(false);
      }
    },
    [
      patientFirstNameInputRef,
      patientLastNameInputRef,
      patientDOBInputRef,
      institutionPatientIdInputRef,
      patientSearchListRef,
      setShowPatientSearch,
    ]
  );

  // Because the list floats above other content, we want to make sure that this
  // UI element doesn't block them from accessing that content. Add a listener
  // that will dismiss the patient search list if the user clicks on an element
  // other than the search list or one of the inputs that feeds into it.
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  // In the event a user dismisses the patient search list by clicking on the
  // page, re-open it if they type into the first name/last name/DOB fields.
  const onInput = useCallback(
    () => setShowPatientSearch(true),
    [setShowPatientSearch]
  );

  return (
    <TopBottom>
      <LeftRightCenterV style={{ columnGap: "8px" }}>
        <FormField
          title="First Name"
          fieldConfig={fieldConfigs[df.PATIENT_FIRST_NAME.key]}
          style={{ width: "100%" }}
        >
          <SegmentText
            ref={patientFirstNameInputRef}
            disabled={isDisabled || hasPatientBeenSelected}
            onChange={onInput}
            handleChange={set}
            item={{ key: df.PATIENT_FIRST_NAME.key, onBlur }}
            highlightIfMissing={
              highlightRequiredFields &&
              fieldConfigs[df.PATIENT_FIRST_NAME.key].isRequired
            }
          />
        </FormField>
        <FormField
          title="Last Name"
          fieldConfig={fieldConfigs[df.PATIENT_LAST_NAME.key]}
          style={{ width: "100%" }}
        >
          <SegmentText
            ref={patientLastNameInputRef}
            disabled={isDisabled || hasPatientBeenSelected}
            onChange={onInput}
            handleChange={set}
            item={{ key: df.PATIENT_LAST_NAME.key, onBlur }}
            highlightIfMissing={
              highlightRequiredFields &&
              fieldConfigs[df.PATIENT_LAST_NAME.key].isRequired
            }
          />
        </FormField>
        <FormField
          title="DOB"
          fieldConfig={fieldConfigs[df.PATIENT_DOB.key]}
          style={{ width: "100%" }}
        >
          <LeftRightCenterV style={{ position: "relative" }}>
            <SegmentDate
              ref={patientDOBInputRef}
              disabled={isDisabled || hasPatientBeenSelected}
              onChange={onInput}
              handleChange={set}
              item={{ key: df.PATIENT_DOB.key, onBlur }}
              highlightIfMissing={
                highlightRequiredFields &&
                fieldConfigs[df.PATIENT_DOB.key].isRequired
              }
              width="100%"
            />
            {hasPatientBeenSelected && (
              <div style={{ position: "absolute", left: "calc(100% + 5px)" }}>
                <SegmentButton
                  style={{ width: "162px" }}
                  onClick={async () => {
                    await removePatient({
                      variables: { authorizationId: parseInt(auth.id) },
                    });
                  }}
                  text="Change Patient"
                />
              </div>
            )}
          </LeftRightCenterV>
        </FormField>
      </LeftRightCenterV>
      {hasIntegrations ? (
        <FormField
          title="MRN"
          fieldConfig={fieldConfigs[df.INSTITUTION_PATIENT_ID.key]}
          style={{ width: "100%" }}
        >
          <SegmentText
            ref={institutionPatientIdInputRef}
            disabled={isDisabled || hasPatientBeenSelected}
            onChange={onInput}
            handleChange={set}
            item={{ key: df.INSTITUTION_PATIENT_ID.key, onBlur }}
          />
        </FormField>
      ) : null}
      {
        // Don't show the patient search box for the current authorization if
        // a patient has already been selected; the user needs to click the
        // "Change Patient" button first.
        !hasPatientBeenSelected && showPatientSearch && (
          <PatientSearch
            floating
            ref={patientSearchListRef}
            firstName={firstName}
            lastName={lastName}
            institutionPatientId={institutionPatientId}
            setPatient={async (patient: Patient) => {
              await setPatient({
                variables: {
                  patientId: parseInt(patient.id),
                  authorizationId: parseInt(auth.id),
                },
              });
            }}
            onResult={(patients: Patient[]) => setPatientResults(patients)}
          />
        )
      }
      {!_.isEmpty(matchingPatientResults) && (
        <SelectPatientModal
          open={showPatientSelectionModal}
          patients={matchingPatientResults}
          setPatient={async (patient: Patient) => {
            await setPatient({
              variables: {
                patientId: parseInt(patient.id),
                authorizationId: parseInt(auth.id),
              },
            });
            setShowPatientSelectionModal(false);
          }}
          onCloseModal={() => setMatchingPatientResults([])}
        />
      )}
    </TopBottom>
  );
};
