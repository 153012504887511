import _ from "lodash";
import { useState } from "react";
import styled from "styled-components";
import colors from "Resources/colors";
import Favorite from "../../../components/Favorite";
import moment from "moment";
import { Flex } from "@@ui-kit";

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ margin }) => margin}px;
  padding: 10px;
  background-color: ${({ isSelected }) => !isSelected && ""};
  justify-content: space-between;
`;

const FormDetails = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

const FormDescriptionContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FormDescription = styled(Flex)`
  flex-direction: column;
  font-size: 12px;
  color: ${(props) => props.theme.gray};
`;

const ItalicDiv = styled.div`
  font-style: italic;
`;

const DateDiv = styled.div`
  padding-top: 4px;
  font-style: italic;
`;

const BoldSpan = styled.span`
  font-weight: 700;
`;

const FormTitle = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`;

const FormButtonContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const FormContent = ({
  form,
  onSelect,
  setFavorite,
  deleteFavorite,
  isFavorite,
  isSelected,
}) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  return (
    <FormContainer
      margin="10"
      key={`formSelectContainer-formId-${form.id}`}
      onClick={() => {
        onSelect(form.id);
      }}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      style={{
        borderLeft: `${
          isSelected
            ? `4px solid ${colors.purple}`
            : "4px solid rgba(0, 0, 0, 0)"
        }`,
        backgroundColor: `${!isSelected && hover ? colors.lightPurple : ""}`,
        borderRadius: `${!isSelected && hover ? "8px" : ""}`,
        overflow: "hidden",
      }}
      hover={hover}
      data-cy="actionSelectFaxForm"
    >
      <FormDetails>
        <FormTitle>{form.title}</FormTitle>
        <FormDescriptionContainer>
          <FormDescription>
            {form.approvalCount != null ? (
              <div>
                Used <BoldSpan>{form.approvalCount}</BoldSpan>{" "}
                {form.approvalCount > 1 ? "times" : "time"} in the last 120 days
              </div>
            ) : (
              <ItalicDiv>Pending additional usage data</ItalicDiv>
            )}
            <DateDiv>Last updated {moment(form.updatedAt).format("L")}</DateDiv>
          </FormDescription>
          <FormButtonContainer>
            <Favorite
              isFavorite={isFavorite}
              setFavorite={setFavorite}
              deleteFavorite={deleteFavorite}
              formId={_.parseInt(form.id)}
            />
          </FormButtonContainer>
        </FormDescriptionContainer>
      </FormDetails>
    </FormContainer>
  );
};

export default FormContent;
