import * as React from "react";
import styled from "styled-components";

import { Flex } from "@@ui-kit";
import { Grid } from "@samacare/design";
import { BenefitsVerificationOutcomeData } from "@samacare/graphql";

import {
  FormFieldDefinition,
  FormSectionDefinition,
  col1Info,
  col2Info,
} from "../BenefitsVerificationConstants";
import { useBenefitsVerificationContext } from "../BeneftsVerificationProvider";
import { humanizeBVOutcomeValue } from "../bvUtils";
import { HCPCSCodeDetails } from "../interfaces";
import { FormSection } from "./FormSection";
import { ReadonlyField } from "./ReadonlyField";

export const SectionHeader = styled.div`
  color: ${(props) => props.theme.purple};
  font-weight: 400;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const BvDataRow = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
`;

export const BodyTitleText = styled.div`
  min-width: 225px;
`;

export const BvContentText = styled.div`
  text-align: left;
  margin-top: 4px;
`;

const generateHCPCSCodeDetails = (
  val: string | null,
  label: string
): void | JSX.Element[] => {
  if (val == null) {
    return;
  }

  return [
    ...(val as unknown as HCPCSCodeDetails[]).map(({ code, response }) => {
      return (
        <BvDataRow key={`${label} ${code}`}>
          <BodyTitleText> {`${label} ${code}`} </BodyTitleText>
          <BvContentText>{response ?? "N/A"}</BvContentText>
        </BvDataRow>
      );
    }),
  ];
};

export const renderOutcomeField = ({
  field,
  outcomeData,
}: {
  field: FormFieldDefinition;
  outcomeData?: BenefitsVerificationOutcomeData | null;
}) => {
  const { key, label, editType } = field;
  const val = (outcomeData?.[key] ?? null) as string | number | boolean | null;

  if (key === "HCPCSCodeDetails") {
    return generateHCPCSCodeDetails(val as string | null, label);
  }

  return (
    <ReadonlyField
      key={key}
      label={label}
      value={humanizeBVOutcomeValue(val, editType)}
    />
  );
};

export const renderOutcomeSection = ({
  section,
  outcomeData,
}: {
  section: FormSectionDefinition;
  outcomeData?: BenefitsVerificationOutcomeData | null;
}) => {
  const { title, data } = section;
  const rows = data.map((field) => renderOutcomeField({ field, outcomeData }));
  return (
    <FormSection key={title} title={title}>
      {rows}
    </FormSection>
  );
};

export const ResponseDetails: React.VoidFunctionComponent<{
  outcomeData?: BenefitsVerificationOutcomeData | null;
}> = () => {
  const { benefitsVerification } = useBenefitsVerificationContext();
  if (benefitsVerification == null) {
    return null;
  }

  const { request: bvRequest, outcome: bvOutcome } = benefitsVerification;
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        {col1Info.map((section) =>
          renderOutcomeSection({ section, outcomeData: bvOutcome?.data })
        )}
      </Grid>
      <Grid item xs={6}>
        {(bvRequest.secondaryInsuranceDetails == null
          ? col2Info.filter(
              ({ title }) => title !== "Seconday Insurance Details"
            )
          : col2Info
        ).map((section) =>
          renderOutcomeSection({ section, outcomeData: bvOutcome?.data })
        )}
      </Grid>
    </Grid>
  );
};
